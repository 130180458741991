import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './SEO';

const Logo = () => (
  <Link to="/" className="navbar-brand">
    <SEO />
    <span className="logo" style={{ display: 'flex', alignItems: 'center' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 256 256" style={{ marginRight: '4px' }}>
        <defs>
          <linearGradient id="logosDoctorElec0" x1="50%" x2="50%" y1="100%" y2="0%">
            <stop offset="0%" stopColor="#6200ee" />
            <stop offset="100%" stopColor="#9D8DF1" />
          </linearGradient>
        </defs>
        <path fill="#fff" d="M128 249.491c67.098 0 121.492-54.393 121.492-121.491S195.098 6.508 128 6.508S6.509 60.902 6.509 128S60.902 249.491 128 249.491"/>
        <path fill="#6200ee" d="M128 0C57.308 0 0 57.308 0 128s57.308 128 128 128s128-57.307 128-128C256 57.308 198.693 0 128 0m0 13.017c63.503 0 114.983 51.48 114.983 114.983S191.503 242.983 128 242.983S13.017 191.503 13.017 128S64.497 13.017 128 13.017"/>
        <path fill="url(#logosDoctorElec0)" d="M129.446 36.881c50.324 0 91.119 40.796 91.119 91.119s-40.795 91.118-91.12 91.118c-50.323 0-91.118-40.795-91.118-91.118c0-12.298 2.437-24.028 6.854-34.733c6.449 8.783 16.85 14.484 28.581 14.484c19.57 0 35.435-15.865 35.435-35.435c0-11.732-5.701-22.132-14.484-28.581c10.705-4.417 22.434-6.854 34.733-6.854"/>
        <path fill="#fff" d="M144.971 111.029h-12.014V91.014h-8.914v20.015h-20.015v12.914h20.015v20.015h8.914v-20.015h12.014z"/>
        <path fill="#9D8DF1" d="M141.971 107.029h-16.014V87.014h-10.914v20.015h-20.015v10.914h20.015v20.015h10.914v-20.015h16.014z"/>
      </svg>
      <span className="power-labs" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ 
          background: '#9D8DF1', 
          color: '6200ee', 
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 'bold',
          fontSize: '24px',
          marginRight: '2px'
        }}>Doctor</span>
        <span style={{ 
          color: '#6200ee',
          fontWeight: 'bold',
          fontSize: '24px'
        }}>Elec</span>
      </span>
    </span>
  </Link>
);

export default Logo;
