import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Section.css';

const Section = ({ title, content, image }) => {
  const { t } = useTranslation();

  return (
    <div className="section">
      <LazyLoadImage alt={t(title)} effect="blur" src={image} className="section-image" />
      <div className="section-content">
        <h2 className="section-title">{t(title)}</h2>
        <div className="section-text" dangerouslySetInnerHTML={{ __html: t(content) }} />
      </div>
    </div>
  );
};

export default Section;

