import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const NavbarItemTitle = styled.button`
  background: transparent;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  font-size: 18px;
  padding: 2rem 1.5rem 1.2rem 1.5rem;
  color: #6200EE;
  display: flex;
  justify-content: center;
  transition: opacity 250ms;
  cursor: pointer;
  position: relative;
  z-index: 2;
  &:hover, &:focus {
    opacity: 0.7;
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`;

const NavbarItemEl = styled.li`
  position: relative;
  @media (max-width: 768px) {
    position: static;
    width: 100%;
  }
`;

const DropdownSlot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1500px;
  @media (max-width: 768px) {
    position: static;
    transform: none;
    width: 100%;
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  }
`;

export default class NavbarItem extends Component {
  static propTypes = {
    onMouseEnter: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    children: PropTypes.node
  }

  constructor(props) {
    super(props);
    this.state = {
      isMobileOpen: false
    };
  }

  onMouseEnter = () => {
    this.props.onMouseEnter(this.props.index);
  }

  toggleMobileDropdown = () => {
    this.setState(prevState => ({
      isMobileOpen: !prevState.isMobileOpen
    }));
  }

  render() {
    const { title, children } = this.props;
    const isMobile = window.innerWidth <= 768;
    const { isMobileOpen } = this.state;

    return (
      <NavbarItemEl onMouseEnter={this.onMouseEnter} onFocus={this.onMouseEnter}>
        <NavbarItemTitle onClick={isMobile ? this.toggleMobileDropdown : null}>
          {title}
        </NavbarItemTitle>
        <DropdownSlot $isOpen={isMobile ? isMobileOpen : true}>{children}</DropdownSlot>
      </NavbarItemEl>
    );
  }
}


