import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Banner from '../Resources/Banner';
import Section from '../Resources/Section';
import resources from '../../data/resources';
import './ResourcesPage.css';
import SEO from '../SEO';

const ResourcesPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;

  const currentResource = resources.find(resource => resource.path === currentPath);

  if (!currentResource) {
    return (
      <div className="resources-page">
        <h1>{t('Resource not found')}</h1>
      </div>
    );
  }

  return (
    <div className="resources-page">
      <SEO />
      <Banner title={t(currentResource.title)} image={currentResource.image} />
      <div className="resources-content">
        <Section title={t(currentResource.title)} content={t(currentResource.content)} image={currentResource.image} />
        {currentResource.sections.map((section, index) => (
          <Section key={index} title={t(section.title)} content={t(section.content)} image={section.image} />
        ))}
      </div>
    </div>
  );
};

export default ResourcesPage;

