import React from 'react';

const Author = ({ author }) => {
  if (!author) return null; // Check if author is defined

  return (
    <div className="mt-8 text-gray-600">
      <h4 className="text-xl font-semibold">Written by: {author.name}</h4>
      <p>{author.bio}</p>
      {author.website && (
        <a href={author.website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          Visit Author's Website
        </a>
      )}
    </div>
  );
};

export default Author;
