import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosProfile from '../utils/axiosProfile';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import Logo from './Logo';
import styled from 'styled-components';
import DropdownContainer from './DropdownContainer';
import ProductsDropdown from './DropdownContents/ProductsDropdown';
import CompanyDropdown from './DropdownContents/CompanyDropdown';
import ResourcesDropdown from './DropdownContents/ResourcesDropdown';
import NavbarItem from './NavbarItem';
import './Navbar.css';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${({ darkMode }) => (darkMode ? '#41463D' : '#fff')};
  color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const NavbarLinks = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    align-items: stretch;

    & > li {
      width: 100%;
      margin: 10px 0;
    }

    & > li > a,
    & > li > .login-button,
    & > li > .register-button {
      display: block;
      width: 100%;
      text-align: center;
      padding: 12px;
    }
  }
`;

const ProfileMenuContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: ${({ darkMode }) => (darkMode ? '#41463D' : '#fff')};
  color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 1000;
`;

const ProfilePhoto = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;

`;

const Burger = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  @media (max-width: 768px) {
    display: block;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: ${({ darkMode }) => (darkMode ? '#fff' : '#000')};
    margin: 5px;
    transition: all 0.3s ease;
  }
`;

function Navbar() {
  const { user, setUser, isPatient, isDoctor } = useAuth();
  const { darkMode, toggleDarkMode } = useTheme();
  const { t } = useTranslation();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const closeDropdown = () => setDropdownOpen(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axiosProfile.get('/profiles/');
        if (response.data.photo) {
          setProfilePhoto(`http://127.0.0.1:5003/uploads/${response.data.photo}`);
        }
        if (response.data.first_name) {
          setFirstName(response.data.first_name);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };
    if (user) {
      fetchProfile();
    }
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUser(null);
    navigate('/');
  };

  const handleMouseEnter = (index) => {
    setActiveDropdown(index);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const navbarConfig = [
    { title: t("medicalServices"), dropdown: ProductsDropdown },
    { title: t("resources"), dropdown: ResourcesDropdown },
    { title: t("company"), dropdown: CompanyDropdown },
  ];

  return (
    <NavbarContainer $darkMode={darkMode}>
      <Logo />
      {!user && (
        <Burger $darkMode={darkMode} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </Burger>
      )}
      <div onMouseLeave={handleMouseLeave}>
        <NavbarLinks $isOpen={menuOpen}>
          {!user && (
            <>
              {navbarConfig.map((n, index) => (
                <NavbarItem
                  key={n.title}
                  title={n.title}
                  index={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                >
                  {activeDropdown === index && (
                    <DropdownContainer duration={300}>
                      <n.dropdown />
                    </DropdownContainer>
                  )}
                </NavbarItem>
              ))}
              <li className="nav-item"><Link to="/pricing" className={`nav-link ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleLinkClick}>{t('pricing')}</Link></li>
              <li className="nav-item button_padding"><Link to="/login" className={`login-button ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleLinkClick}>{t('login')}</Link></li>
              <li className="nav-item"><Link to="/register" className={`register-button ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleLinkClick}>{t('register')}</Link></li>
            </>
          )}
        </NavbarLinks>
      </div>
      {user && (
        <ProfileMenuContainer onClick={toggleDropdown}>
          {profilePhoto ? (
            <ProfilePhoto src={profilePhoto} alt="Profile" />
          ) : (
            <ProfilePhoto src="images/default-profile.png" alt="Profile" />
          )}
          {dropdownOpen && (
            <DropdownMenu $darkMode={darkMode}>
              <Link to="/" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('hello')} {firstName} 👋</Link>
              <Link to="/dashboard" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('dashboard')}</Link>
              {isPatient() && (
                <>
                  <Link to="/wellness" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('Wellness')}</Link>
                  <Link to="/appointments" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('Appointments')}</Link>
                  <Link to="/payment" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('Payment')}</Link>
                </>
              )}
              {isDoctor() && (
                <>
                  <Link to="/" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('Report')}</Link>
                  <Link to="/billing" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('Billing')}</Link>
                  <Link to="/logs-doctor" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('Logs')}</Link>

                </>
              )}
              <Link to="/support" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('support')}</Link>
              {firstName ? (
                <Link to="/edit-profile" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={closeDropdown}>{t('Settings')}</Link>
              ) : (
                <Link to="/create-profile" className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={closeDropdown}>{t('createProfile')}</Link>
              )}
              <button className={`dropdown-item ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={toggleDarkMode}>{darkMode ? t('lightMode') : t('darkMode')}</button>
              <Link to="/" className={`dropdown-item logout ${darkMode ? 'dark-mode' : 'light-mode'}`} onClick={handleLogout}>{t('logout')}</Link>
            </DropdownMenu>
          )}
        </ProfileMenuContainer>
      )}
    </NavbarContainer>
  );
}

export default Navbar;