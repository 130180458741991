import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import axiosConsultation from "../../utils/axiosConsultation";
import axiosProfile from "../../utils/axiosProfile";
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
} from "../ui/Card";
import { Link } from "react-router-dom";
import {
  CircularProgress,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Box,
  Pagination,
  Switch,               
  FormControlLabel,    
  Typography, 
} from "@mui/material";
import {
  HomeIcon,
  ClipboardIcon,
  CreditCardIcon,
  ChartIcon,
  PillIcon,
  CalendarIcon,
  HeartPulseIcon,
} from "../Icons";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import { parseISO, format } from "date-fns";
import { useTranslation } from "react-i18next"; // Importa el hook de traducción
import "./doctorDashboard.css";

const ProfilePhoto = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

const DoctorDashboard = () => {
  const { t } = useTranslation(); // Inicializa el hook de traducción
  const { user } = useAuth();
  const [consultations, setConsultations] = useState([]);
  const [patientProfiles, setPatientProfiles] = useState({});
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [doctorProfile, setDoctorProfile] = useState({});
  const [searchTerm, setSearchTerm] = useState(""); //##########NUEVO Estado para el término de búsqueda
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const response = await axiosConsultation.get("/logs-doctor", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const sortedConsultations = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setConsultations(sortedConsultations);

        const patientIds = sortedConsultations.map((log) => log.patient_id);
        fetchPatientProfiles(patientIds);
      } catch (error) {
        setError(
          error.response?.data?.message || t("Failed to fetch consultations")
        );
      }
    };

    const fetchPatientProfiles = async (patientIds) => {
      try {
        const uniquePatientIds = [...new Set(patientIds)];
        const queryString = uniquePatientIds
          .map((id) => `ids[]=${id}`)
          .join("&");

        const profilesResponse = await axiosProfile.get(
          `/profiles/?role=patient&${queryString}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const profilesMap = profilesResponse.data.reduce((acc, profile) => {
          acc[profile.user_id] = profile;
          return acc;
        }, {});
        setPatientProfiles(profilesMap);
      } catch (error) {
        setError(
          error.response?.data?.message || t("Failed to fetch patient profiles")
        );
      }
    };

    const fetchDoctorProfile = async () => {
      try {
        const response = await axiosProfile.get(`/profiles/${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        setDoctorProfile(response.data);

        if (response.data.photo) {
          setProfilePhoto(`http://127.0.0.1:5003/uploads/${response.data.photo}`);
        }
      } catch (error) {
        setError(
          error.response?.data?.message || t("Failed to fetch doctor profile")
        );
      }
    };

    if (user?.role === "doctor") {
      fetchConsultations();
      fetchDoctorProfile();
    }
    setLoading(false);
  }, [user]);

  const toggleDoctorStatus = async () => {
    try {
      const updatedStatus = !doctorProfile.status;

      await axiosProfile.patch(
        `/profiles/${user.user_id}/status`,
        { status: updatedStatus },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setDoctorProfile({ ...doctorProfile, status: updatedStatus });
    } catch (error) {
      setError(
        error.response?.data?.message || t("Failed to update doctor status")
      );
    }
  };

  const getStatusClassName = (status) => {
    switch (status.toString().toLowerCase()) {
      case "scheduled":
        return "badge bg-primary";
      case "in progress":
        return "badge bg-warning";
      case "pending prescription": // Nuevo estado
        return "badge bg-info"; 
      case "canceled":
        return "badge bg-danger";
      case "completed":
        return "badge bg-success";
      default:
        return "badge bg-secondary";
    }
  };

  const filteredConsultations = consultations.filter((consultation) => {
    //##########NUEVO Filtrado por nombre de paciente y estado de la consulta
    const status = consultation.status.toLowerCase();
    const patientName = `${patientProfiles[consultation.patient_id]?.first_name || ""} ${patientProfiles[consultation.patient_id]?.last_name || ""}`.toLowerCase();
    return (
      status ===
        (selectedTab === 0
          ? "scheduled"
          : selectedTab === 1
          ? "in progress"
          : selectedTab === 2
          ? "pending prescription" // Nuevo estado
          : selectedTab === 3
          ? "completed"
          : "canceled") &&
      patientName.includes(searchTerm.toLowerCase())
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentConsultations = filteredConsultations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); //##########NUEVO Actualiza el término de búsqueda
    setCurrentPage(1); //##########NUEVO Reinicia la paginación cuando hay una nueva búsqueda
  };

  return (
    <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex-1 overflow-auto py-2">
            <nav className="grid items-start px-4 text-sm font-medium">
              <Link
                to="/selectdoctor"
                className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <HeartPulseIcon className="h-6 w-6" />
                {t("Start Consultation")}
              </Link>

              <Link
                to="/dashboard"
                className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary"
              >
                <HomeIcon className="h-6 w-6" />
                {t("Dashboard")}
              </Link>

              <Link
                to="/appointments"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <CalendarIcon className="h-6 w-6" />
                {t("Appointments")}
              </Link>
              <Link
                to="/treatments"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <PillIcon className="h-6 w-6" />
                {t("Treatments")}
              </Link>
              <Link
                to="/billing"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <CreditCardIcon className="h-6 w-6" />
                {t("Billing")}
              </Link>
              <Link to="/statistics" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <ChartIcon className="h-6 w-6" />
                {t('Statistics')}
              </Link>
              <Link
                to="/logs-doctor"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <ClipboardIcon className="h-6 w-6" />
                {t("Doctor Logs")}
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-14 lg:h-[60px] items-center gap-4 border-b bg-muted/40 px-6">
          <Link to="/" className="lg:hidden">
            <HomeIcon className="h-6 w-6" />
            <span className="sr-only">{t("Home")}</span>
          </Link>
          <div className="w-full flex-1">
            <form>
              <div className="relative">
                <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <input
                  type="search"
                  placeholder={t("Search patients")}
                  className="w-full bg-background shadow-none appearance-none pl-8 md:w-2/3 lg:w-1/3"
                  value={searchTerm} //##########NUEVO Vínculo con el estado de búsqueda
                  onChange={handleSearchChange} //##########NUEVO Maneja los cambios en la búsqueda
                />
              </div>
            </form>
          </div>
        </header>
        <main className="flex-col gap-4 p-4 md:gap-8 md:p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 card-container">
            <Card className="col-span-1 card-one-third">
              <CardHeader>
                <CardTitle>{t("Doctor Profile")}</CardTitle>
                <CardDescription>
                  {t("Key information about the doctor")}
                </CardDescription>
              </CardHeader>
              <CardContent>
                {loading ? (
                  <CircularProgress />
                ) : error ? (
                  <Alert severity="error">{error}</Alert>
                ) : (
                  <div className="grid gap-2">
                    <div className="flex flex-col items-center">
                      <img
                        src={profilePhoto || "images/default-profile.png"}
                        alt={t("Profile")}
                        style={{
                          width: "175px",
                          height: "175px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                      />
                      <div className="text-center">
                        <div className="font-medium text-primary">
                          Dr@ {doctorProfile.first_name}{" "}
                          {doctorProfile.last_name}
                        </div>
                        <div className="text-sm text-secondary">
                          {doctorProfile.medical_speciality}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <div className="flex flex-col">
                        <span className="text-primary font-bold">
                          {t("License Number")}
                        </span>
                        <span className="text-secondary">
                          {doctorProfile.license_number}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-primary font-bold">
                          {t("Certification")}
                        </span>
                        <span className="text-secondary">
                          {doctorProfile.certification}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-primary font-bold">
                          {t("Accreditation")}
                        </span>
                        <span className="text-secondary">
                          {doctorProfile.accreditation}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-primary font-bold">
                          {t("Contact")}
                        </span>
                        <span className="text-secondary">{user.email}</span>
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <Typography variant="h6" gutterBottom>
                        {t("Doctor Status")}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {t(
                          "Use the switch to activate or deactivate your availability."
                        )}
                      </Typography>

                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(doctorProfile.status)}
                            onChange={toggleDoctorStatus}
                            name="doctorStatus"
                            color="success"
                          />
                        }
                        label={
                          doctorProfile.status
                            ? t("Activate")
                            : t("Deactivate")
                        }
                      />
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>

            <Card className="col-span-2 w-full">
              <CardHeader>
                <CardTitle>{t("Consultations")}</CardTitle>
                <CardDescription>
                  {t("List of your consultations")}
                </CardDescription>
              </CardHeader>
              <CardContent className="flex-grow">
                {loading ? (
                  <CircularProgress />
                ) : error ? (
                  <Alert severity="error">{error}</Alert>
                ) : (
                  <Box>
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      variant="fullWidth"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <Tab label={t("Scheduled")} />
                      <Tab label={t("In Progress")} />
                      <Tab label={t("Pending Prescription")} />
                      <Tab label={t("Completed")} />
                      <Tab label={t("Canceled")} />
                    </Tabs>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="font-purple">
                            {t("Patient")}
                          </TableCell>
                          <TableCell className="font-purple">
                            {t("Status")}
                          </TableCell>
                          <TableCell className="font-purple">
                            {t("Details")}
                          </TableCell>
                          <TableCell className="font-purple">
                            {t("Assignment Date")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentConsultations.map((consultation) => (
                          <TableRow key={consultation.id}>
                            <TableCell>
                              {patientProfiles[consultation.patient_id] ? (
                                <div className="flex items-center">
                                  <img
                                    className="profile-photo"
                                    src={`http://127.0.0.1:5003/uploads/${
                                      patientProfiles[consultation.patient_id]
                                        ?.photo || "default-profile.png"
                                    }`}
                                    alt={t("Patient")}
                                    style={{
                                      width: "75px",
                                      height: "75px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <span className="ml-3">
                                    {
                                      patientProfiles[
                                        consultation.patient_id
                                      ].first_name
                                    }{" "}
                                    {
                                      patientProfiles[
                                        consultation.patient_id
                                      ].last_name
                                    }
                                  </span>
                                </div>
                              ) : (
                                <span>{t("Unknown Patient")}</span>
                              )}
                            </TableCell>
                            <TableCell>
                              <span
                                className={getStatusClassName(
                                  consultation.status
                                )}
                              >
                                {t(consultation.status)}
                              </span>
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/consultation/${consultation.id}`}
                                className="btn btn-sm btn-purple font-white"
                              >
                                {t("Open Consultation")}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {formatDate(consultation.created_at)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={Math.ceil(
                        filteredConsultations.length / itemsPerPage
                      )}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      style={{ marginTop: "16px", justifyContent: "center" }}
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
          </div>
        </main>
      </div>
    </div>
  );
};

const formatDate = (dateString) => {
  if (!dateString) {
    return "No Date";
  }
  try {
    const date = parseISO(dateString);
    return format(date, "MM/dd/yyyy");
  } catch (error) {
    return "Invalid Date";
  }
};

export default DoctorDashboard;

// import React, { useState, useEffect } from "react";
// import { useAuth } from "../../context/AuthContext";
// import axiosConsultation from "../../utils/axiosConsultation";
// import axiosProfile from "../../utils/axiosProfile";
// import {
//   Card,
//   CardHeader,
//   CardDescription,
//   CardTitle,
//   CardContent,
// } from "../ui/Card";
// import { Link } from "react-router-dom";
// import {
//   CircularProgress,
//   Alert,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Tabs,
//   Tab,
//   Box,
//   Pagination,
//   Switch,
//   FormControlLabel,
//   Typography,
// } from "@mui/material";
// import {
//   HomeIcon,
//   ClipboardIcon,
//   CreditCardIcon,
//   PillIcon,
//   CalendarIcon,
//   HeartPulseIcon,
// } from "../Icons";
// import SearchIcon from "@mui/icons-material/Search";
// import styled from "styled-components";
// import { parseISO, format } from "date-fns";
// import { useTranslation } from "react-i18next"; // Importa el hook de traducción
// import "./doctorDashboard.css";

// const ProfilePhoto = styled.img`
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   object-fit: cover;
// `;

// const DoctorDashboard = () => {
//   const { t } = useTranslation(); // Inicializa el hook de traducción
//   const { user, setUser } = useAuth();
//   const [consultations, setConsultations] = useState([]);
//   const [patientProfiles, setPatientProfiles] = useState({});
//   const [profilePhoto, setProfilePhoto] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [doctorProfile, setDoctorProfile] = useState({});
//   const itemsPerPage = 5;

//   useEffect(() => {
//     const fetchConsultations = async () => {
//       try {
//         const response = await axiosConsultation.get("/logs-doctor", {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         });

//         const sortedConsultations = response.data.sort(
//           (a, b) => new Date(b.created_at) - new Date(a.created_at)
//         );

//         setConsultations(sortedConsultations);

//         const patientIds = sortedConsultations.map((log) => log.patient_id);
//         fetchPatientProfiles(patientIds);
//       } catch (error) {
//         setError(
//           error.response?.data?.message || t("Failed to fetch consultations")
//         );
//       }
//     };

//     const fetchPatientProfiles = async (patientIds) => {
//       try {
//         const uniquePatientIds = [...new Set(patientIds)];
//         const queryString = uniquePatientIds
//           .map((id) => `ids[]=${id}`)
//           .join("&");

//         const profilesResponse = await axiosProfile.get(
//           `/profiles/?role=patient&${queryString}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user.token}`,
//             },
//           }
//         );

//         const profilesMap = profilesResponse.data.reduce((acc, profile) => {
//           acc[profile.user_id] = profile;
//           return acc;
//         }, {});
//         setPatientProfiles(profilesMap);
//       } catch (error) {
//         setError(
//           error.response?.data?.message || t("Failed to fetch patient profiles")
//         );
//       }
//     };

//     const fetchDoctorProfile = async () => {
//       try {
//         const response = await axiosProfile.get(`/profiles/${user.user_id}`, {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         });

//         setDoctorProfile(response.data);
//         console.log("Doctor profile fetched:", response.data); 

//         if (response.data.photo) {
//           setProfilePhoto(`http://127.0.0.1:5003/uploads/${response.data.photo}`);
//         }
//       } catch (error) {
//         setError(
//           error.response?.data?.message || t("Failed to fetch doctor profile")
//         );
//       }
//     };

//     if (user?.role === "doctor") {
//       fetchConsultations();
//       fetchDoctorProfile();
//     }
//     setLoading(false);
//   }, [user]);

//   const toggleDoctorStatus = async () => {
//     try {
//       const updatedStatus = !doctorProfile.status;

//       await axiosProfile.patch(
//         `/profiles/${user.user_id}/status`,
//         { status: updatedStatus },
//         {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         }
//       );

//       setDoctorProfile({ ...doctorProfile, status: updatedStatus });
//     } catch (error) {
//       setError(
//         error.response?.data?.message || t("Failed to update doctor status")
//       );
//     }
//   };

//   const getStatusClassName = (status) => {
//     switch (status.toString().toLowerCase()) {
//       case "scheduled":
//         return "badge bg-primary";
//       case "in progress":
//         return "badge bg-warning";
//       case "canceled":
//         return "badge bg-danger";
//       case "completed":
//         return "badge bg-success";
//       default:
//         return "badge bg-secondary";
//     }
//   };

//   const filteredConsultations = consultations.filter((consultation) => {
//     const status = consultation.status.toLowerCase();
//     switch (selectedTab) {
//       case 0:
//         return status === "scheduled";
//       case 1:
//         return status === "in progress";
//       case 2:
//         return status === "completed";
//       case 3:
//         return status === "canceled";
//       default:
//         return true;
//     }
//   });

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentConsultations = filteredConsultations.slice(
//     indexOfFirstItem,
//     indexOfLastItem
//   );

//   const handlePageChange = (event, newPage) => {
//     setCurrentPage(newPage);
//   };

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//     setCurrentPage(1);
//   };

//   return (
//     <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
//       <div className="hidden border-r bg-muted/40 lg:block">
//         <div className="flex h-full max-h-screen flex-col gap-2">
//           <div className="flex-1 overflow-auto py-2">
//             <nav className="grid items-start px-4 text-sm font-medium">
//               <Link
//                 to="/selectdoctor"
//                 className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <HeartPulseIcon className="h-6 w-6" />
//                 {t("Start Consultation")}
//               </Link>

//               <Link
//                 to="/dashboard"
//                 className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary"
//               >
//                 <HomeIcon className="h-6 w-6" />
//                 {t("Dashboard")}
//               </Link>

//               <Link
//                 to="/appointments"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <CalendarIcon className="h-6 w-6" />
//                 {t("Appointments")}
//               </Link>
//               <Link
//                 to="/treatments"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <PillIcon className="h-6 w-6" />
//                 {t("Treatments")}
//               </Link>
//               <Link
//                 to="/billing"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <CreditCardIcon className="h-6 w-6" />
//                 {t("Billing")}
//               </Link>
//               <Link
//                 to="/logs-doctor"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <ClipboardIcon className="h-6 w-6" />
//                 {t("Doctor Logs")}
//               </Link>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col">
//         <header className="flex h-14 lg:h-[60px] items-center gap-4 border-b bg-muted/40 px-6">
//           <Link to="/" className="lg:hidden">
//             <HomeIcon className="h-6 w-6" />
//             <span className="sr-only">{t("Home")}</span>
//           </Link>
//           <div className="w-full flex-1">
//             <form>
//               <div className="relative">
//                 <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
//                 <input
//                   type="search"
//                   placeholder={t("Search patients")}
//                   className="w-full bg-background shadow-none appearance-none pl-8 md:w-2/3 lg:w-1/3"
//                 />
//               </div>
//             </form>
//           </div>
//         </header>
//         <main className="flex-col gap-4 p-4 md:gap-8 md:p-6">
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-6 card-container">
//             <Card className="col-span-1 card-one-third">
//               <CardHeader>
//                 <CardTitle>{t("Doctor Profile")}</CardTitle>
//                 <CardDescription>
//                   {t("Key information about the doctor")}
//                 </CardDescription>
//               </CardHeader>
//               <CardContent>
//                 {loading ? (
//                   <CircularProgress />
//                 ) : error ? (
//                   <Alert severity="error">{error}</Alert>
//                 ) : (
//                   <div className="grid gap-2">
//                     <div className="flex flex-col items-center">
//                       <img
//                         src={profilePhoto || "images/default-profile.png"}
//                         alt={t("Profile")}
//                         style={{
//                           width: "175px",
//                           height: "175px",
//                           borderRadius: "50%",
//                           objectFit: "cover",
//                           marginBottom: "10px",
//                         }}
//                       />
//                       <div className="text-center">
//                         <div className="font-medium text-primary">
//                           Dr@ {doctorProfile.first_name}{" "}
//                           {doctorProfile.last_name}
//                         </div>
//                         <div className="text-sm text-secondary">
//                           {doctorProfile.medical_speciality}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="grid grid-cols-2 gap-4 mt-4">
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">
//                           {t("License Number")}
//                         </span>
//                         <span className="text-secondary">
//                           {doctorProfile.license_number}
//                         </span>
//                       </div>
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">
//                           {t("Certification")}
//                         </span>
//                         <span className="text-secondary">
//                           {doctorProfile.certification}
//                         </span>
//                       </div>
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">
//                           {t("Accreditation")}
//                         </span>
//                         <span className="text-secondary">
//                           {doctorProfile.accreditation}
//                         </span>
//                       </div>
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">
//                           {t("Contact")}
//                         </span>
//                         <span className="text-secondary">{user.email}</span>
//                       </div>
//                     </div>
//                     <div className="mt-4 text-center">
//                       <Typography variant="h6" gutterBottom>
//                         {t("Doctor Status")}
//                       </Typography>
//                       <Typography
//                         variant="body2"
//                         color="textSecondary"
//                         gutterBottom
//                       >
//                         {t(
//                           "Use the switch to activate or deactivate your availability."
//                         )}
//                       </Typography>

//                       <FormControlLabel
//                         control={
//                           <Switch
//                             checked={Boolean(doctorProfile.status)}
//                             onChange={toggleDoctorStatus}
//                             name="doctorStatus"
//                             color="success"
//                           />
//                         }
//                         label={
//                           doctorProfile.status
//                             ? t("Activate")
//                             : t("Deactivate")
//                         }
//                       />
//                     </div>
//                   </div>
//                 )}
//               </CardContent>
//             </Card>

//             <Card className="col-span-2 w-full">
//               <CardHeader>
//                 <CardTitle>{t("Consultations")}</CardTitle>
//                 <CardDescription>
//                   {t("List of your consultations")}
//                 </CardDescription>
//               </CardHeader>
//               <CardContent className="flex-grow">
//                 {loading ? (
//                   <CircularProgress />
//                 ) : error ? (
//                   <Alert severity="error">{error}</Alert>
//                 ) : (
//                   <Box>
//                     <Tabs
//                       value={selectedTab}
//                       onChange={handleTabChange}
//                       variant="fullWidth"
//                       indicatorColor="primary"
//                       textColor="primary"
//                     >
//                       <Tab label={t("Scheduled")} />
//                       <Tab label={t("In Progress")} />
//                       <Tab label={t("Completed")} />
//                       <Tab label={t("Canceled")} />
//                     </Tabs>
//                     <Table>
//                       <TableHead>
//                         <TableRow>
//                           <TableCell className="font-purple">
//                             {t("Patient")}
//                           </TableCell>
//                           <TableCell className="font-purple">
//                             {t("Status")}
//                           </TableCell>
//                           <TableCell className="font-purple">
//                             {t("Details")}
//                           </TableCell>
//                           <TableCell className="font-purple">
//                             {t("Assignment Date")}
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {currentConsultations.map((consultation) => (
//                           <TableRow key={consultation.id}>
//                             <TableCell>
//                               {patientProfiles[consultation.patient_id] ? (
//                                 <div className="flex items-center">
//                                   <img
//                                     className="profile-photo"
//                                     src={`http://127.0.0.1:5003/uploads/${
//                                       patientProfiles[consultation.patient_id]
//                                         ?.photo || "default-profile.png"
//                                     }`}
//                                     alt={t("Patient")}
//                                     style={{
//                                       width: "75px",
//                                       height: "75px",
//                                       borderRadius: "50%",
//                                       objectFit: "cover",
//                                     }}
//                                   />
//                                   <span className="ml-3">
//                                     {
//                                       patientProfiles[
//                                         consultation.patient_id
//                                       ].first_name
//                                     }{" "}
//                                     {
//                                       patientProfiles[
//                                         consultation.patient_id
//                                       ].last_name
//                                     }
//                                   </span>
//                                 </div>
//                               ) : (
//                                 <span>{t("Unknown Patient")}</span>
//                               )}
//                             </TableCell>
//                             <TableCell>
//                               <span
//                                 className={getStatusClassName(
//                                   consultation.status
//                                 )}
//                               >
//                                 {t(consultation.status)}
//                               </span>
//                             </TableCell>
//                             <TableCell>
//                               <Link
//                                 to={`/consultation/${consultation.id}`}
//                                 className="btn btn-sm btn-purple font-white"
//                               >
//                                 {t("Open Consultation")}
//                               </Link>
//                             </TableCell>
//                             <TableCell>
//                               {formatDate(consultation.created_at)}
//                             </TableCell>
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     </Table>
//                     <Pagination
//                       count={Math.ceil(
//                         filteredConsultations.length / itemsPerPage
//                       )}
//                       page={currentPage}
//                       onChange={handlePageChange}
//                       color="primary"
//                       style={{ marginTop: "16px", justifyContent: "center" }}
//                     />
//                   </Box>
//                 )}
//               </CardContent>
//             </Card>
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// };

// const formatDate = (dateString) => {
//   if (!dateString) {
//     return "No Date";
//   }
//   try {
//     const date = parseISO(dateString);
//     return format(date, "MM/dd/yyyy");
//   } catch (error) {
//     return "Invalid Date";
//   }
// };

// export default DoctorDashboard;

// import React, { useState, useEffect } from "react";
// import { useAuth } from "../../context/AuthContext";
// import axiosConsultation from "../../utils/axiosConsultation";
// import axiosProfile from "../../utils/axiosProfile";
// import {
//   Card,
//   CardHeader,
//   CardDescription,
//   CardTitle,
//   CardContent,
// } from "../ui/Card";
// import { Link } from "react-router-dom";
// import {
//   CircularProgress,
//   Alert,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Tabs,
//   Tab,
//   Box,
//   Pagination,
//   Switch,
//   FormControlLabel,
//   Typography,
// } from "@mui/material";
// import {
//   HomeIcon,
//   ClipboardIcon,
//   CreditCardIcon,
//   PillIcon,
//   CalendarIcon,
//   HeartPulseIcon,
// } from "../Icons";
// import SearchIcon from "@mui/icons-material/Search";
// import styled from "styled-components";
// import { parseISO, format } from "date-fns";
// import "./doctorDashboard.css";

// const ProfilePhoto = styled.img`
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   object-fit: cover;
// `;

// const DoctorDashboard = () => {
//   const { user, setUser } = useAuth();
//   const [consultations, setConsultations] = useState([]);
//   const [patientProfiles, setPatientProfiles] = useState({});
//   const [profilePhoto, setProfilePhoto] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [doctorProfile, setDoctorProfile] = useState({});
//   const itemsPerPage = 5;

//   useEffect(() => {
//     const fetchConsultations = async () => {
//       try {
//         const response = await axiosConsultation.get("/logs-doctor", {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         });

//         const sortedConsultations = response.data.sort(
//           (a, b) => new Date(b.created_at) - new Date(a.created_at)
//         );

//         setConsultations(sortedConsultations);

//         const patientIds = sortedConsultations.map((log) => log.patient_id);
//         fetchPatientProfiles(patientIds);
//       } catch (error) {
//         setError(
//           error.response?.data?.message || "Failed to fetch consultations"
//         );
//       }
//     };

//     const fetchPatientProfiles = async (patientIds) => {
//       try {
//         const uniquePatientIds = [...new Set(patientIds)];
//         const queryString = uniquePatientIds
//           .map((id) => `ids[]=${id}`)
//           .join("&");

//         const profilesResponse = await axiosProfile.get(
//           `/profiles/?role=patient&${queryString}`,
//           {
//             headers: {
//               Authorization: `Bearer ${user.token}`,
//             },
//           }
//         );

//         const profilesMap = profilesResponse.data.reduce((acc, profile) => {
//           acc[profile.user_id] = profile;
//           return acc;
//         }, {});
//         setPatientProfiles(profilesMap);
//       } catch (error) {
//         setError(
//           error.response?.data?.message || "Failed to fetch patient profiles"
//         );
//       }
//     };

//     const fetchDoctorProfile = async () => {
//       try {
//         const response = await axiosProfile.get(`/profiles/${user.user_id}`, {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         });

//         setDoctorProfile(response.data);
//         console.log("Doctor profile fetched:", response.data); 
//         // console.log(setDoctorProfile)

//         if (response.data.photo) {
//           setProfilePhoto(`http://127.0.0.1:5003/uploads/${response.data.photo}`);
//         }
//       } catch (error) {
//         setError(
//           error.response?.data?.message || "Failed to fetch doctor profile"
//         );
//       }
//     };

//     if (user?.role === "doctor") {
//       fetchConsultations();
//       fetchDoctorProfile();
//     }
//     setLoading(false);
//   }, [user]);

//   const toggleDoctorStatus = async () => {
//     try {
//       const updatedStatus = !doctorProfile.status;

//       await axiosProfile.patch(
//         `/profiles/${user.user_id}/status`,
//         { status: updatedStatus },
//         {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         }
//       );

//       setDoctorProfile({ ...doctorProfile, status: updatedStatus });
//     } catch (error) {
//       setError(
//         error.response?.data?.message || "Failed to update doctor status"
//       );
//     }
//   };

//   const getStatusClassName = (status) => {
//     switch (status.toString().toLowerCase()) {
//       case "scheduled":
//         return "badge bg-primary";
//       case "in progress":
//         return "badge bg-warning";
//       case "canceled":
//         return "badge bg-danger";
//       case "completed":
//         return "badge bg-success";
//       default:
//         return "badge bg-secondary";
//     }
//   };

//   const filteredConsultations = consultations.filter((consultation) => {
//     const status = consultation.status.toLowerCase();
//     switch (selectedTab) {
//       case 0:
//         return status === "scheduled";
//       case 1:
//         return status === "in progress";
//       case 2:
//         return status === "completed";
//       case 3:
//         return status === "canceled";
//       default:
//         return true;
//     }
//   });

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentConsultations = filteredConsultations.slice(
//     indexOfFirstItem,
//     indexOfLastItem
//   );

//   const handlePageChange = (event, newPage) => {
//     setCurrentPage(newPage);
//   };

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//     setCurrentPage(1);
//   };

//   return (
//     <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
//       <div className="hidden border-r bg-muted/40 lg:block">
//         <div className="flex h-full max-h-screen flex-col gap-2">
//           <div className="flex-1 overflow-auto py-2">
//             <nav className="grid items-start px-4 text-sm font-medium">
//               <Link
//                 to="/selectdoctor"
//                 className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <HeartPulseIcon className="h-6 w-6" />
//                 Start Consultation
//               </Link>

//               <Link
//                 to="/dashboard"
//                 className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary"
//               >
//                 <HomeIcon className="h-6 w-6" />
//                 Dashboard
//               </Link>

//               <Link
//                 to="/appointments"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <CalendarIcon className="h-6 w-6" />
//                 Appointments
//               </Link>
//               <Link
//                 to="/treatments"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <PillIcon className="h-6 w-6" />
//                 Treatments
//               </Link>
//               <Link
//                 to="/billing"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <CreditCardIcon className="h-6 w-6" />
//                 Billing
//               </Link>
//               <Link
//                 to="/logs-doctor"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <ClipboardIcon className="h-6 w-6" />
//                 Doctor Logs
//               </Link>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col">
//         <header className="flex h-14 lg:h-[60px] items-center gap-4 border-b bg-muted/40 px-6">
//           <Link to="/" className="lg:hidden">
//             <HomeIcon className="h-6 w-6" />
//             <span className="sr-only">Home</span>
//           </Link>
//           <div className="w-full flex-1">
//             <form>
//               <div className="relative">
//                 <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
//                 <input
//                   type="search"
//                   placeholder="Search patients"
//                   className="w-full bg-background shadow-none appearance-none pl-8 md:w-2/3 lg:w-1/3"
//                 />
//               </div>
//             </form>
//           </div>
//         </header>
//         <main className="flex-col gap-4 p-4 md:gap-8 md:p-6">
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-6 card-container">
//             <Card className="col-span-1 card-one-third">
//               <CardHeader>
//                 <CardTitle>Doctor Profile</CardTitle>
//                 <CardDescription>
//                   Key information about the doctor
//                 </CardDescription>
//               </CardHeader>
//               <CardContent>
//               {loading ? (
//                   <CircularProgress />
//                 ) : error ? (
//                   <Alert severity="error">{error}</Alert>
//                 ) : (
//                   <div className="grid gap-2">
//                     <div className="flex flex-col items-center">
//                       <img
//                         src={profilePhoto || "images/default-profile.png"}
//                         alt="Profile"
//                         style={{
//                           width: "175px",
//                           height: "175px",
//                           borderRadius: "50%",
//                           objectFit: "cover",
//                           marginBottom: "10px",
//                         }}
//                       />
//                       <div className="text-center">
//                         <div className="font-medium text-primary">
//                           Dr@ {doctorProfile.first_name} {doctorProfile.last_name}
//                         </div>
//                         <div className="text-sm text-secondary">
//                           {doctorProfile.medical_speciality}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="grid grid-cols-2 gap-4 mt-4">
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">License Number</span>
//                         <span className="text-secondary">{doctorProfile.license_number}</span>
//                       </div>
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">Certification</span>
//                         <span className="text-secondary">{doctorProfile.certification}</span>
//                       </div>
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">Accreditation</span>
//                         <span className="text-secondary">{doctorProfile.accreditation}</span>
//                       </div>
//                       <div className="flex flex-col">
//                         <span className="text-primary font-bold">Contact</span>
//                         <span className="text-secondary">{user.email}</span>
//                       </div>
//                     </div>
//                     <div className="mt-4 text-center">
//                       <Typography variant="h6" gutterBottom>
//                         Doctor Status
//                       </Typography>
//                       <Typography variant="body2" color="textSecondary" gutterBottom>
//                         Use the switch to activate or deactivate your availability.
//                       </Typography>
             
//                       <FormControlLabel
//                         control={
//                           <Switch
//                             checked={Boolean(doctorProfile.status)}
//                             onChange={toggleDoctorStatus}
//                             name="doctorStatus"
//                             color="success"
//                           />
//                         }
//                         label={doctorProfile.status ? "Activate" : "Desactivate"}
//                       />
//                     </div>
//                   </div>
//                 )}

      
//               </CardContent>
//             </Card>

//             <Card className="col-span-2 w-full">
//               <CardHeader>
//                 <CardTitle>Consultations</CardTitle>
//                 <CardDescription>List of your consultations</CardDescription>
//               </CardHeader>
//               <CardContent className="flex-grow">
//                 {loading ? (
//                   <CircularProgress />
//                 ) : error ? (
//                   <Alert severity="error">{error}</Alert>
//                 ) : (
//                   <Box>
//                     <Tabs
//                       value={selectedTab}
//                       onChange={handleTabChange}
//                       variant="fullWidth"
//                       indicatorColor="primary"
//                       textColor="primary"
//                     >
//                       <Tab label="Scheduled" />
//                       <Tab label="In Progress" />
//                       <Tab label="Completed" />
//                       <Tab label="Canceled" />
//                     </Tabs>
//                     <Table>
//                       <TableHead>
//                         <TableRow>
//                           <TableCell className="font-purple">Patient</TableCell>
//                           <TableCell className="font-purple">Status</TableCell>
//                           <TableCell className="font-purple">Details</TableCell>
//                           <TableCell className="font-purple">
//                             Assignment Date
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         {currentConsultations.map((consultation) => (
//                           <TableRow key={consultation.id}>
//                             <TableCell>
//                               {patientProfiles[consultation.patient_id] ? (
//                                 <div className="flex items-center">
//                                   <img
//                                     className="profile-photo"
//                                     src={`http://127.0.0.1:5003/uploads/${
//                                       patientProfiles[consultation.patient_id]
//                                         ?.photo || "default-profile.png"
//                                     }`}
//                                     alt="Patient"
//                                     style={{
//                                       width: "75px",
//                                       height: "75px",
//                                       borderRadius: "50%",
//                                       objectFit: "cover",
//                                     }}
//                                   />
//                                   <span className="ml-3">
//                                     {
//                                       patientProfiles[
//                                         consultation.patient_id
//                                       ].first_name
//                                     }{" "}
//                                     {
//                                       patientProfiles[
//                                         consultation.patient_id
//                                       ].last_name
//                                     }
//                                   </span>
//                                 </div>
//                               ) : (
//                                 <span>Unknown Patient</span>
//                               )}
//                             </TableCell>
//                             <TableCell>
//                               <span
//                                 className={getStatusClassName(
//                                   consultation.status
//                                 )}
//                               >
//                                 {consultation.status}
//                               </span>
//                             </TableCell>
//                             <TableCell>
//                               <Link
//                                 to={`/consultation/${consultation.id}`}
//                                 className="btn btn-sm btn-purple font-white"
//                               >
//                                 Open Consultation
//                               </Link>
//                             </TableCell>
//                             <TableCell>
//                               {formatDate(consultation.created_at)}
//                             </TableCell>
//                           </TableRow>
//                         ))}
//                       </TableBody>
//                     </Table>
//                     <Pagination
//                       count={Math.ceil(
//                         filteredConsultations.length / itemsPerPage
//                       )}
//                       page={currentPage}
//                       onChange={handlePageChange}
//                       color="primary"
//                       style={{ marginTop: "16px", justifyContent: "center" }}
//                     />
//                   </Box>
//                 )}
//               </CardContent>
//             </Card>
//           </div>
    
//         </main>
//       </div>
//     </div>
//   );
// };

// const formatDate = (dateString) => {
//   if (!dateString) {
//     return "No Date";
//   }
//   try {
//     const date = parseISO(dateString);
//     return format(date, "MM/dd/yyyy");
//   } catch (error) {
//     return "Invalid Date";
//   }
// };

// export default DoctorDashboard;
