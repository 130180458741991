// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import io from 'socket.io-client';
// import VideoCall from './VideoCall';
// // import Chat from './Chat';
// // import ParticipantsList from './ParticipantsList';

// const Room = () => {
//     const { roomId } = useParams();
//     const [socket, setSocket] = useState(null);
//     const [participants, setParticipants] = useState([]);

//     useEffect(() => {
//         const newSocket = io('http://localhost:8083');
//         setSocket(newSocket);

//         // newSocket.emit('join-room', { roomId, peerId: YOUR_PEER_ID, userName: YOUR_USERNAME });

//         newSocket.on('user-joined', (user) => {
//             setParticipants((prev) => [...prev, user]);
//         });

//         newSocket.on('user-disconnected', (peerId) => {
//             setParticipants((prev) => prev.filter(user => user.peerId !== peerId));
//         });

//         return () => newSocket.close();
//     }, [roomId]);

//     return (
//         <div>
//             <VideoCall socket={socket} participants={participants} />
//             {/* <Chat socket={socket} /> */}
//             {/* <ParticipantsList participants={participants} /> */}
//         </div>
//     );
// };

// export default Room;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import VideoCall from './VideoCall';
import { useAuth } from '../../context/AuthContext'; 
import Peer from 'peerjs';

const Room = () => {
    const { roomId } = useParams();
    const [socket, setSocket] = useState(null);
    const [peer, setPeer] = useState(null); 
    const [participants, setParticipants] = useState([]);
    const { user } = useAuth(); // Obtén la información del usuario actual

    useEffect(() => {
        const newSocket = io('http://localhost:8083');
        setSocket(newSocket);

        if (user && roomId) {
            console.log(`user_id: ${user.user_id}`)
            const peerId = user.user_id; // Esto depende de cómo almacenes el ID del usuario
            console.log(`peedID: ${peerId}`)
            const userName = user.username || `${user.first_name} ${user.last_name}`; // Asumiendo que tienes first_name y last_name
            const role = user.role;
            // Asegúrate de que esto no es undefined o null
            console.log(`role: ${role}`);


            const newPeer = new Peer(peerId, {
                host: 'localhost',  // Cambia 'localhost' si estás usando una IP externa o un dominio
                port: 9000,         // Asegúrate de que el puerto coincide con el del PeerServer
                path: '/',
            });

            newPeer.on('open', (id) => {
                console.log('Peer connected with ID:', id);
                setPeer(newPeer); // Guarda la instancia del Peer
                newSocket.emit('join-room', { roomId, peerId: id, userName, role:user.role });
            });
            // newSocket.emit('join-room', { roomId, peerId, userName });

            newSocket.on('user-joined', (user) => {
                setParticipants((prev) => [...prev, user]);
            });

            newSocket.on('user-disconnected', (peerId) => {
                setParticipants((prev) => prev.filter(user => user.peerId !== peerId));
            });
        } else {
            console.error("User or Room ID is undefined.");
        }

        return () => newSocket.close();
    }, [roomId, user]);

    return (
        <div>
            {socket && roomId ? (
                <VideoCall socket={socket} participants={participants} roomId={roomId} />
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
    
    // return (
        
    //     <div>
    //         <VideoCall socket={socket} participants={participants} roomId={roomId} />
    //     </div>
    // );
};

export default Room;



// const Room = () => {
//     const { roomId } = useParams(); // Obtiene el roomId de la URL
//     const [socket, setSocket] = useState(null);
//     const [participants, setParticipants] = useState([]);

//     useEffect(() => {
//         if (roomId) {  // Asegúrate de que roomId existe
//             const newSocket = io('http://localhost:8083');
//             setSocket(newSocket);

//             newSocket.emit('join-room', { roomId, peerId: YOUR_PEER_ID, userName: YOUR_USERNAME });

//             newSocket.on('user-joined', (user) => {
//                 setParticipants((prev) => [...prev, user]);
//             });

//             newSocket.on('user-disconnected', (peerId) => {
//                 setParticipants((prev) => prev.filter(user => user.peerId !== peerId));
//             });

//             return () => newSocket.close();
//         } else {
//             console.error("Room ID is undefined.");
//         }
//     }, [roomId]);

//     return (
//         <div>
//             <VideoCall socket={socket} participants={participants} roomId={roomId} /> {/* Pasa roomId a VideoCall */}
//         </div>
//     );
// };

// export default Room;

