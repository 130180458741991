
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
import './ActivationAccount.css';
import axiosInstance from '../utils/axiosInstance';
// Asegúrate de importar los estilos

function ActivateAccount() {
    const { uidb64, token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await axiosInstance.get(`/activate/${uidb64}/${token}/`);
                setMessage("🎉 Yay! Your account is activated! Welcome aboard! 🎉");
                setIsSuccess(true);
                setTimeout(() => {
                    navigate('/login');
                }, 5000); // Aumentar tiempo de redirección a 5000ms (5 segundos)
            } catch (error) {
                setMessage('😞 Activation failed. Please try again.');
                setIsSuccess(false);
            }
        };

        activateAccount();
    }, [uidb64, token, navigate]);

    return (
        <div className="activate-container">
            <div className="activate-card">
                <h2>Account Activation</h2>
                <p className={isSuccess ? 'message' : 'error'}>{message}</p>
                {isSuccess && (
                    <div>
                        <p>Get ready to explore all the amazing features we have in store for you!</p>
                        <p>Redirecting you to the login page in a few seconds...</p>
                    </div>
                )}
            </div>
        </div>
    );
}


export default ActivateAccount;
