import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Section.css';

const Section = ({ title, content, image }) => {
  const { t } = useTranslation();

  return (
    <div className="section">
      <div className="banner">
        <svg xmlns="http://www.w3.org/2000/svg" width="450" height="450" viewBox="0 0 256 256" className="banner-svg">
          <defs>
            <clipPath id="clipCircle">
              <circle cx="129.446" cy="128" r="91.119" />
            </clipPath>
          </defs>
          <path fill="#fff" d="M128 246c65 0 118-53 118-118S193 10 128 10 10 63 10 128s53 118 118 118"/>
          <path fill="#6200ee" d="M128 0C57.308 0 0 57.308 0 128s57.308 128 128 128s128-57.307 128-128C256 57.308 198.693 0 128 0m0 13.017c63.503 0 114.983 51.48 114.983 114.983S191.503 242.983 128 242.983S13.017 191.503 13.017 128S64.497 13.017 128 13.017"/>
          <foreignObject x="0" y="0" width="256" height="256">
            <LazyLoadImage
              alt={t(title)}
              effect="blur"
              src={image}
              width="100%"
              height="100%"
              className="banner-image"
              style={{ clipPath: 'url(#clipCircle)' }}
            />
          </foreignObject>
        </svg>
        <div className="banner-content">
          <h2>{t(title)}</h2>
          <p>{t(content)}</p>
          <Link to="/register" className="banner-button">{t('Get Started')}</Link>
        </div>
      </div>
    </div>
  );
};

export default Section;
