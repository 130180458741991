import React, { useState, useEffect } from 'react';
import axiosPayment from '../../utils/axiosPayment';
import axiosConsultation from '../../utils/axiosConsultation';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { Card, Button, Alert, ListGroup } from 'react-bootstrap';
import './PayNow.css'; // Crear este archivo para estilos personalizados
import { FaCheckCircle } from 'react-icons/fa'; // Asegúrate de tener react-icons instalado

const PayNow = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const doctor = location.state?.doctor || {};
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axiosPayment.get(`/get_cards/${user.user_id}`, {
          headers: { Authorization: `Bearer ${user.token}` }
        });
        if (response.data.length > 0) {
          setSelectedCard(response.data.find(card => card.default) || response.data[0]);
        }
      } catch (err) {
        console.error("Error fetching cards:", err.response?.data || err.message);
      }
    };

    if (user && user.token) {
      fetchCards();
    }
  }, [user]);

  const handlePayment = () => {
    setLoading(true);
    axiosPayment.post('/process_payment', {
      user_id: user.user_id,
      card_id: selectedCard.id,
      doctor_id: doctor.id,
      amount: 10000, // Example amount in cents
    }, {
      headers: { Authorization: `Bearer ${user.token}` }
    })
    .then(response => {
      if (response.status === 200) {
        setMessage('Payment successful');
        axiosConsultation.post('/consultations', {
          doctor_id: doctor.user_id,
          patient_id: user.user_id,
          consultation_type: 'general', // Example type
          status: 'scheduled',
        }, {
          headers: { Authorization: `Bearer ${user.token}` }
        })
        .then(res => {
          if (res.status === 201) {
            navigate(`/consultation/${res.data.id}`);
          } else {
            setMessage('Consultation creation failed');
          }
        })
        .catch(err => {
          console.error("Error creating consultation:", err.response?.data || err.message);
          setMessage('Consultation creation failed');
        });
        
      } else {
        setMessage('Payment failed');
      }
    })
    .catch(error => {
      console.error("Error processing payment:", error.response?.data || error.message);
      setMessage('Payment failed');
    })
    .finally(() => {
      setLoading(false);
    });
  };


  if (!selectedCard) {
    return <div>Loading...</div>;
  }

  const benefits = [
    "Videocall with the doctor",
    "Personalized Prescription",
    "Follow-up Consultation",
    "24/7 Support",
    "Access to Medical Records"
  ];

  return (
    <div className="pay-now-container">
      <Card className="bg-light" style={{ borderColor: '#6200ee' }}>
        <Card.Body>
          <Card.Title className="text-purple" style={{ color: '#6200ee' }}>Confirm Payment</Card.Title>
          <p className="text-purple"><strong>Doctor:</strong> {doctor.first_name} {doctor.last_name}</p>
          <p className="text-purple"><strong>Specialty:</strong> {doctor.medical_speciality}</p>
          <p className="text-purple"><strong>Amount:</strong> $100.00</p>
          <Card.Text className="text-purple"><strong>Teleconsultation Benefits:</strong></Card.Text>
          <ListGroup>
            {benefits.map((benefit, index) => (
              <ListGroup.Item key={index} className="d-flex align-items-center">
                <FaCheckCircle className="text-success me-2" />
                {benefit}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button 
            variant="primary" 
            onClick={handlePayment} 
            disabled={loading}
            style={{ width: '100%', marginTop: '20px', backgroundColor: '#6200ee', color: '#fff' }}
          >
            {loading ? 'Processing...' : 'Pay Now'}
          </Button>
          {message && <Alert variant={message.includes('successful') ? 'success' : 'danger'} style={{ marginTop: '20px' }}>{message}</Alert>}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PayNow;
