
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Navbar from './components/Navbar';
import ClinicalHistoryForm from './components/ClinicalHistoryForm';
import Footer from './components/Footer';
import Faq from './components/Faq';
import Features from './components/Features';
import About from './components/About';
import Terms from './components/Terms';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import MainContent from './components/MainContent';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { AuthProvider } from './context/AuthContext';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ActivateAccount from './components/ActivateAccount';
import CreateProfile from './components/CreateP';
import EditProfile from './components/EditProfile'; 
import ServicePage from './components/Services/ServicePage';
import services from './data/services';
import ResourcesPage from './components/Resources/ResourcesPage';
import resources from './data/resources';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ThemeProvider } from './context/ThemeContext';
import './index.css';
import RoleSelection from './components/RoleSelection';
import DoctorRegisterForm from './components/DoctorRegisterForm';
import PatientRegisterForm from './components/PatientRegisterForm';
import './globals.css';
import Reports from './components/Reports';
import Appointments from './components/Appointments';
import DoctorDashboard from './components/Doctor/DoctorDashboard';
import PatientDashboard from './components/Patient/PatientDashboard';
import DashboardRedirect from './components/DashboardRedirect';
import Unauthorized from './components/Unauthorized';
import Treatment from './components/Patient/Treatment';
import Wellness from './components/Patient/Wellness';
import Payment from './components/Patient/Payment';
import Logs from './components/Patient/Logs';
import Consultation from './components/Consultation/Consultation';
import SelectDoctor from './components/Consultation/SelectDoctor';
import Room from './components/Consultation/Room';
import PayNow from './components/Patient/PayNow'; 
import LogsDoctor from './components/Doctor/LogsDoctor';
import Statistics from './components/Doctor/Statistics'
import Billing from './components/Doctor/Billing'
import PublicDoctorProfile from './components/Doctor/PublicDoctorProfile';
import Error404 from './components/Error404';
import Error500 from './components/Error500';
import Blog from './components/Blog/Blog';
import Article from './components/Blog/Article';

// ErrorBoundary para capturar errores no manejados
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Error500 />;
    }

    return this.props.children; 
  }
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <BrowserRouter>
          <Navbar />
          <ErrorBoundary>
            <TransitionGroup>
              <CSSTransition timeout={300} classNames="page" unmountOnExit>
                <Routes>
                  <Route element={<PublicRoute />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<RoleSelection />} />
                    <Route path="/register/doctor" element={<DoctorRegisterForm />} />
                    <Route path="/register/patient" element={<PatientRegisterForm />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/password-reset/:uidb64/:token" element={<ResetPassword />} />
                    <Route path="/activate/:uidb64/:token" element={<ActivateAccount />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="/:username" element={<PublicDoctorProfile />} />
                    <Route path="/blog" element={<Blog />} />

                    {/* Ruta para un artículo individual */}
                    <Route path="/article/:slug" element={<Article />} />


                  </Route>
                  <Route path="/" element={<MainContent />} />
                  <Route path="/features" element={<Features />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/support" element={<Contact />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/faq" element={<Faq />} />
                  {services.map(service => (
                    <Route 
                      key={service.path} 
                      path={service.path} 
                      element={
                        <ServicePage 
                          title={`services.${service.path.slice(1)}.title`} 
                          image={service.image} 
                          content={`services.${service.path.slice(1)}.content`} 
                          sections={service.sections.map((section, index) => ({
                            ...section,
                            title: `services.${service.path.slice(1)}.sections.${index}.title`,
                            content: `services.${service.path.slice(1)}.sections.${index}.content`
                          }))} 
                        />
                      } 
                    />
                  ))}
                  {resources.map(resource => (
                    <Route 
                      key={resource.path} 
                      path={resource.path} 
                      element={
                        <ResourcesPage 
                          title={`resources.${resource.path.slice(1)}.title`} 
                          image={resource.image} 
                          content={`resources.${resource.path.slice(1)}.content`} 
                          sections={resource.sections.map((section, index) => ({
                            ...section,
                            title: `resources.${resource.path.slice(1)}.sections.${index}.title`,
                            content: `resources.${resource.path.slice(1)}.sections.${index}.content`
                          }))} 
                        />
                      } 
                    />
                  ))}
                  <Route element={<PrivateRoute allowedRoles={['doctor', 'patient']} />}>
                    <Route path="/dashboard" element={<DashboardRedirect />} />
                  </Route>
                  <Route element={<PrivateRoute allowedRoles={['patient']} />}>
                    <Route path="/patient-dashboard" element={<PatientDashboard />} />
                    <Route path="/treatments" element={<Treatment />} />
                    <Route path="/reports" element={<Reports  />}/>
                    <Route path="/wellness" element={<Wellness  />}/>
                    <Route path="/payment" element={<Payment />}/>
                    <Route path="/logs" element={<Logs />} />
                  
                    <Route path="/selectdoctor" element={<SelectDoctor />} />
                    <Route path="/paynow" element={<PayNow />} />
                  </Route>
                  <Route element={<PrivateRoute allowedRoles={['doctor']} />}>
                    <Route path="/doctor-dashboard" element={<DoctorDashboard />} />
                    <Route path="/logs-doctor" element={<LogsDoctor />} />
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/billing" element={<Billing />}/>
                    
                  </Route>
                  <Route element={<PrivateRoute allowedRoles={['doctor', 'patient']} />}>
                    <Route path="/appointments" element={<Appointments />} />
                    <Route path="/create-profile" element={<CreateProfile />} />
                    <Route path="/edit-profile" element={<EditProfile />} />
                    <Route path="/complete-profile" element={<ClinicalHistoryForm />} />
                    <Route path="/consultation/:consultationId" element={<Consultation />} />
                    <Route path="/room/:roomId" element={<Room />} />
                  </Route>

                  {/* Ruta de Error 404 */}
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </CSSTransition>
            </TransitionGroup>
          </ErrorBoundary>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
// import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
// import Navbar from './components/Navbar';
// import ClinicalHistoryForm from './components/ClinicalHistoryForm';
// import Footer from './components/Footer';
// import Faq from './components/Faq';
// import Features from './components/Features';
// import About from './components/About';
// import Terms from './components/Terms';
// import Pricing from './components/Pricing';
// import Contact from './components/Contact';
// import MainContent from './components/MainContent';
// import Login from './components/Login';
// import PrivateRoute from './components/PrivateRoute';
// import PublicRoute from './components/PublicRoute';
// import { AuthProvider } from './context/AuthContext';
// import ForgotPassword from './components/ForgotPassword';
// import ResetPassword from './components/ResetPassword';
// import ActivateAccount from './components/ActivateAccount';
// import CreateProfile from './components/CreateP';
// import EditProfile from './components/EditProfile'; 
// import ServicePage from './components/Services/ServicePage';
// import services from './data/services';
// import ResourcesPage from './components/Resources/ResourcesPage';
// import resources from './data/resources';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import { ThemeProvider } from './context/ThemeContext';
// import './index.css';
// import RoleSelection from './components/RoleSelection';
// import DoctorRegisterForm from './components/DoctorRegisterForm';
// import PatientRegisterForm from './components/PatientRegisterForm';
// import './globals.css';
// import Reports from './components/Reports';
// import Appointments from './components/Appointments';
// import DoctorDashboard from './components/Doctor/DoctorDashboard';
// import PatientDashboard from './components/Patient/PatientDashboard';
// import DashboardRedirect from './components/DashboardRedirect';
// import Unauthorized from './components/Unauthorized';
// import Treatment from './components/Patient/Treatment';
// import Wellness from './components/Patient/Wellness';
// import Payment from './components/Patient/Payment';
// import Logs from './components/Patient/Logs';
// import Consultation from './components/Consultation/Consultation';
// import SelectDoctor from './components/Consultation/SelectDoctor';
// import Room from './components/Consultation/Room';
// import PayNow from './components/Patient/PayNow'; 
// import LogsDoctor from './components/Doctor/LogsDoctor';
// import Statistics from './components/Doctor/Statistics'
// import Billing from './components/Doctor/Billing'
// import PublicDoctorProfile from './components/Doctor/PublicDoctorProfile';
// import Error404 from './components/Error404';
// import Error500 from './components/Error500';


// function App() {
//   return (
//     <AuthProvider>
//       <ThemeProvider>
//         <BrowserRouter>
//           <Navbar />
//           <TransitionGroup>
//             <CSSTransition timeout={300} classNames="page" unmountOnExit>
//               <Routes>
//                 <Route element={<PublicRoute />}>
//                   <Route path="/login" element={<Login />} />
//                   <Route path="/register" element={<RoleSelection />} />
//                   <Route path="/register/doctor" element={<DoctorRegisterForm />} />
//                   <Route path="/register/patient" element={<PatientRegisterForm />} />
//                   <Route path="/forgot-password" element={<ForgotPassword />} />
//                   <Route path="/password-reset/:uidb64/:token" element={<ResetPassword />} />
//                   <Route path="/activate/:uidb64/:token" element={<ActivateAccount />} />
//                   <Route path="/unauthorized" element={<Unauthorized />} /> {/* Ruta de Unauthorized */}
//                   <Route path="/:username" element={<PublicDoctorProfile />} />
//                 </Route>
//                 <Route path="/" element={<MainContent />} />
//                 <Route path="/features" element={<Features />} />
//                 <Route path="/pricing" element={<Pricing />} />
//                 <Route path="/support" element={<Contact />} />
//                 <Route path="/terms" element={<Terms />} />
//                 <Route path="/about" element={<About />} />
//                 <Route path="/faq" element={<Faq />} />
//                 {services.map(service => (
//                   <Route 
//                     key={service.path} 
//                     path={service.path} 
//                     element={
//                       <ServicePage 
//                         title={`services.${service.path.slice(1)}.title`} 
//                         image={service.image} 
//                         content={`services.${service.path.slice(1)}.content`} 
//                         sections={service.sections.map((section, index) => ({
//                           ...section,
//                           title: `services.${service.path.slice(1)}.sections.${index}.title`,
//                           content: `services.${service.path.slice(1)}.sections.${index}.content`
//                         }))} 
//                       />
//                     } 
//                   />
//                 ))}
//                 {resources.map(resource => (
//                   <Route 
//                     key={resource.path} 
//                     path={resource.path} 
//                     element={
//                       <ResourcesPage 
//                         title={`resources.${resource.path.slice(1)}.title`} 
//                         image={resource.image} 
//                         content={`resources.${resource.path.slice(1)}.content`} 
//                         sections={resource.sections.map((section, index) => ({
//                           ...section,
//                           title: `resources.${resource.path.slice(1)}.sections.${index}.title`,
//                           content: `resources.${resource.path.slice(1)}.sections.${index}.content`
//                         }))} 
//                       />
//                     } 
//                   />
//                 ))}
//                 <Route element={<PrivateRoute allowedRoles={['doctor', 'patient']} />}>
//                   <Route path="/dashboard" element={<DashboardRedirect />} />
//                 </Route>
//                 <Route element={<PrivateRoute allowedRoles={['patient']} />}>
//                   <Route path="/patient-dashboard" element={<PatientDashboard />} />
//                   <Route path="/treatments" element={<Treatment />} />
//                   <Route path="/reports" element={<Reports  />}/>
//                   <Route path="/wellness" element={<Wellness  />}/>
//                   <Route path="/payment" element={<Payment />}/>
//                   <Route path="/logs" element={<Logs />} />
                
//                   <Route path="/selectdoctor" element={<SelectDoctor />} />
//                   <Route path="/paynow" element={<PayNow />} />
//                 </Route>
//                 <Route element={<PrivateRoute allowedRoles={['doctor']} />}>
//                   <Route path="/doctor-dashboard" element={<DoctorDashboard />} />
//                   <Route path="/logs-doctor" element={<LogsDoctor />} />
//                   <Route path="/statistics" element={<Statistics />} />
//                   <Route path="/billing" element={<Billing />}/>
                  
//                 </Route>
//                 <Route element={<PrivateRoute allowedRoles={['doctor', 'patient']} />}>
//                   <Route path="/appointments" element={<Appointments />} />
//                   <Route path="/create-profile" element={<CreateProfile />} />
//                   <Route path="/edit-profile" element={<EditProfile />} />
//                   <Route path="/complete-profile" element={<ClinicalHistoryForm />} />
//                   <Route path="/consultation/:consultationId" element={<Consultation />} />
//                   <Route path="/room/:roomId" element={<Room />} />
//                 </Route>
//               </Routes>
//             </CSSTransition>
//           </TransitionGroup>
//           <Footer />
//         </BrowserRouter>
//       </ThemeProvider>
//     </AuthProvider>
//   );
// }

// export default App;
