// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// const DashboardRedirect = () => {
//   const { user } = useAuth();

//   if (!user) {
//     return <Navigate to="/login" />;
//   }

//   return user.role === 'doctor' ? <Navigate to="/doctor-dashboard" /> : <Navigate to="/patient-dashboard" />;
// };

// export default DashboardRedirect;
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const DashboardRedirect = () => {
    const { user } = useAuth();

    if (user.role === 'doctor') {
        return <Navigate to="/doctor-dashboard" />;
    }

    if (user.role === 'patient') {
        return <Navigate to="/patient-dashboard" />;
    }

    return <Navigate to="/login" />;
};

export default DashboardRedirect;
