import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './register.css';
import Alert from './Alert';
import axiosInstance from '../utils/axiosInstance';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function DoctorRegisterForm() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    useEffect(() => {
        axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');
    }, []);

    const handleRegistrationSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) {
            return;
        }
        if (!termsAccepted) {
            setErrorMessage("You must accept the terms and conditions to register.");
            return;
        }
        setIsLoading(true);

        const formData = {
            username,
            email,
            phone_number,
            password1,
            password2,
            role: 'doctor'
        };

        const csrfToken = getCookie('csrftoken');

        try {
            const response = await axiosInstance.post('/register/', formData, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                setSuccessMessage("Registration successful! 🎉 Please check your email. 📧");
                setErrorMessage(null);
                setUsername('');
                setEmail('');
                setPhoneNumber('');
                setPassword1('');
                setPassword2('');
                setTermsAccepted(false);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessages = Object.values(error.response.data).flat();
                setErrorMessage(errorMessages[0]);
            } else {
                setErrorMessage("An error occurred. Please try again.");
            }
            setSuccessMessage(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAlertClose = () => {
        setErrorMessage(null);
        setSuccessMessage(null);
    };

    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleTermsChange = () => {
        setTermsAccepted(!termsAccepted);
    };

    return (
        <div className="register-container">
            {errorMessage && <Alert message={errorMessage} onClose={handleAlertClose} type="danger" />}
            {successMessage && <Alert message={successMessage} onClose={handleAlertClose} type="success" />}
            <h2>Register as a Doctor on <span className="highlight">DoctorElec</span> inclusive Healthcare website</h2>
            <form onSubmit={handleRegistrationSubmit} className="register-form">
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone_number">Phone Number:</label>
                    <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password1">Password:</label>
                    <div className="password-input-container">
                        <input
                            type={showPassword1 ? 'text' : 'password'}
                            id="password1"
                            name="password1"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            required
                        />
                        <button type="button" className="show-hide-button" onClick={toggleShowPassword1}>
                            {showPassword1 ? 'Hide' : 'Show'}
                        </button>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="password2">Confirm Password:</label>
                    <div className="password-input-container">
                        <input
                            type={showPassword2 ? 'text' : 'password'}
                            id="password2"
                            name="password2"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            required
                        />
                        <button type="button" className="show-hide-button" onClick={toggleShowPassword2}>
                            {showPassword2 ? 'Hide' : 'Show'}
                        </button>
                    </div>
                </div>
                <div className="form-group terms">
                    <input
                        type="checkbox"
                        id="terms"
                        name="terms"
                        checked={termsAccepted}
                        onChange={handleTermsChange}
                        required
                    />
                    <label htmlFor="terms" className="terms-label">
                        I accept the <a href="/terms" target="_blank" rel="noopener noreferrer">terms and conditions</a>
                    </label>
                </div>
                <button type="submit" className="register-button" disabled={isLoading}>Register as Doctor</button>
            </form>
        </div>
    );
}

export default DoctorRegisterForm;


