import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axiosPatient from '../../utils/axiosPatient';
import {
  Card, CardHeader, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, TablePagination
} from '@mui/material';
import HealthDataChart from '.././HealthDataChart';

import { 
  HeartPulseIcon,
  HomeIcon,
  CalendarIcon,
  AppleIcon,
  PillIcon,
  CreditCardIcon,
  ChartIcon,
  ClipboardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../Icons';

function Wellness() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [healthData, setHealthData] = useState([]);
  const [clinicalHistory, setClinicalHistory] = useState(null);
  const [newHealthDataDialogOpen, setNewHealthDataDialogOpen] = useState(false);
  const [newHealthData, setNewHealthData] = useState({
    weight: '',
    glucose: '',
    waist_circumference: '',
    hip_circumference: ''
  });
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const pages = [
    { path: '/dashboard', label: 'Dashboard' },
    { path: '/wellness', label: 'Wellness' },
    { path: '/appointments', label: 'Appointments' },
    { path: '/treatments', label: 'Treatments' },
    { path: '/payment', label: 'Payment' },
    { path: '/reports', label: 'Reports' },
    { path: '/logs', label: 'Logs' },
  ];

  useEffect(() => {
    const fetchHealthData = async () => {
      try {
        const response = await axiosPatient.get('/health_data');
        const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setHealthData(sortedData);
      } catch (error) {
        console.error('Error fetching health data:', error);
      }
    };

    const fetchClinicalHistory = async () => {
      try {
        const response = await axiosPatient.get(`/clinical_history/${user.user_id}`);
        console.log('Clinical History:', response.data);
        setClinicalHistory(response.data);
      } catch (error) {
        console.error('Error fetching clinical history:', error);
      }
    };

    fetchHealthData();
    fetchClinicalHistory();
  }, [user.user_id]);

  const handleOpenNewHealthDataDialog = () => {
    setNewHealthDataDialogOpen(true);
  };

  const handleCloseNewHealthDataDialog = () => {
    setNewHealthDataDialogOpen(false);
  };

  const handleInputChange = (e) => {
    setNewHealthData({ ...newHealthData, [e.target.name]: e.target.value });
  };

  const handleAddHealthData = async () => {
    try {
      const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
      const clinicalHistory = clinicalHistoryResponse.data;

      const newHealthDataWithClinicalInfo = {
        ...newHealthData,
        height_cm: clinicalHistory.anthropometry.heightCm,
        gender: clinicalHistory.genderAtBirth,
        age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
        activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
      };

      const response = await axiosPatient.post(`/health_data`, newHealthDataWithClinicalInfo);
      setHealthData((prevHealthData) => {
        const updatedData = [...prevHealthData, response.data];
        return updatedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      });
      setNewHealthData({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
    } catch (error) {
      console.error("Error adding health data:", error);
    }
  };

  const handlePrevious = () => {
    const newIndex = currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };

  const handleNext = () => {
    const newIndex = currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex-1 overflow-auto py-2">
            <nav className="grid items-start px-4 text-sm font-medium">
              <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
                <HeartPulseIcon className="h-6 w-6" />
                Start Consultation
              </Link>
              <Link to="/dashboard" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <HomeIcon className="h-6 w-6" />
                Dashboard
              </Link>
              <Link to="/wellness" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
                <AppleIcon className="h-6 w-6" />
                Wellness
              </Link>
              <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <CalendarIcon className="h-6 w-6" />
                Appointments
              </Link>
              <Link to="/treatments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <PillIcon className="h-6 w-6" />
                Treatments
              </Link>
              <Link to="/payment" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <CreditCardIcon className="h-6 w-6" />
                Payment
              </Link>
              <Link to="/reports" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <ChartIcon className="h-6 w-6" />
                Reports
              </Link>
            
              <Link to="/logs" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <ClipboardIcon className="h-6 w-6" />
                Logs
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-4">
        <div className="flex items-center gap-4">
          <h1 className="font-semibold text-lg md:text-xl">{pages[currentPageIndex].label}</h1>
          <div className="ml-auto flex items-center gap-2">
            <Button variant="outline" size="icon" onClick={handlePrevious}>
              <ChevronLeftIcon className="h-4 w-4" />
              <span className="sr-only">Previous</span>
            </Button>
            <Button variant="outline" size="icon" onClick={handleNext}>
              <ChevronRightIcon className="h-4 w-4" />
              <span className="sr-only">Next</span>
            </Button>
          </div>
        </div>
        <div>
          <HealthDataChart />
          <Button color="primary" onClick={handleOpenNewHealthDataDialog}>
            Add New Health Data
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Glucose</TableCell>
              <TableCell>Waist Circumference</TableCell>
              <TableCell>Hip Circumference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {healthData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
              <TableRow key={index}>
                <TableCell>{new Date(data.created_at).toLocaleDateString()}</TableCell>
                <TableCell>{data.weight}</TableCell>
                <TableCell>{data.glucose}</TableCell>
                <TableCell>{data.waist_circumference}</TableCell>
                <TableCell>{data.hip_circumference}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[8, 16, 24]}
          component="div"
          count={healthData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog open={newHealthDataDialogOpen} onClose={handleCloseNewHealthDataDialog}>
          <DialogTitle>Add New Health Data</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Weight (kg)"
              fullWidth
              variant="standard"
              value={newHealthData.weight}
              onChange={(e) => setNewHealthData({ ...newHealthData, weight: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Glucose (mg/dL)"
              fullWidth
              variant="standard"
              value={newHealthData.glucose}
              onChange={(e) => setNewHealthData({ ...newHealthData, glucose: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Waist Circumference (cm)"
              fullWidth
              variant="standard"
              value={newHealthData.waist_circumference}
              onChange={(e) => setNewHealthData({ ...newHealthData, waist_circumference: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Hip Circumference (cm)"
              fullWidth
              variant="standard"
              value={newHealthData.hip_circumference}
              onChange={(e) => setNewHealthData({ ...newHealthData, hip_circumference: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewHealthDataDialog}>Cancel</Button>
            <Button onClick={handleAddHealthData}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default Wellness;
