import React from 'react';
import { Heading, Icon, DropdownSection } from '../styles';
import './ResourcesDropdown.css';
import resources from '../../data/resources';
import { t } from 'i18next';

const ResourcesDropdown = () => {
  return (
    <div className="resources-dropdown">
      <DropdownSection data-first-dropdown-section>
        <div>
          <Heading>{t('Guides and Manuals')}</Heading>
          <p>{t('Access our comprehensive guides and manuals')}</p>
          <div className="flex">
            <div>
              <h4>{t('Professional Guides')}</h4>
              <ul>
                {resources.slice(0, 3).map((resource, index) => (
                  <li key={index}>
                    <a href={resource.path}>{resource.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4>{t('Patient Resources')}</h4>
              <ul>
                {resources.slice(3, 6).map((resource, index) => (
                  <li key={index}>
                    <a href={resource.path}>{resource.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </DropdownSection>
      <DropdownSection>
     
        <ul>
   
        <li className="help-center-item">
              <span>
                <svg height="16" width="16" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" />
                  <text x="12" y="16" fontSize="12" textAnchor="middle" fill="white">B</text>
                </svg>
              </span>
              <a href="/blog">Blog &rsaquo;</a>
            </li>
  
        <li className="help-center-item">
              <span>
                <svg height="16" width="16" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" />
                  <text x="12" y="16" fontSize="12" textAnchor="middle" fill="white">C</text>
                </svg>
              </span>
              <a href="/faq">{t('faq')} &rsaquo;</a>
            </li>
            <li className="help-center-item">
              <span>
                <svg height="16" width="16" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" />
                  <text x="12" y="16" fontSize="12" textAnchor="middle" fill="white">T</text>
                </svg>
              </span>
              <a href="/support">{t('technical support')} &rsaquo;</a>
            </li>
          {/* {resources.slice(6).map((resource, index) => (
            <li key={index} className="heading-link">
              <a href={resource.path}>
                <Icon /> {resource.title}
              </a>
            </li>
          ))} */}
        </ul>
      </DropdownSection>
    </div>
  );
};

export default ResourcesDropdown;


// import React from 'react';
// import { Heading, Icon, DropdownSection } from '../styles';
// import './ResourcesDropdown.css';

// const ResourcesDropdown = () => {
//   return (
//     <div className="resources-dropdown">
//       <DropdownSection data-first-dropdown-section>
//         <div>
//           <Heading>Guides and Manuals</Heading>
//           <p>Access our comprehensive guides and manuals</p>
//           <div className="flex">
//             <div>
//               <h4>Professional Guides</h4>
//               <ul>
//                 <li>
//                   <a href="/guides/telemedicine">Telemedicine Guide</a>
//                 </li>
//                 <li>
//                   <a href="/training-courses">Remote Care Guide</a>
//                 </li>
//                 <li>
//                   <a href="/guides/consultations">Effective Consultations</a>
//                 </li>
//               </ul>
//             </div>
//             <div>
//               <h4>Patient Resources</h4>
//               <ul>
//                 <li>
//                   <a href="/patient/preparing-consultation">Preparing for Consultation</a>
//                 </li>
//                 <li>
//                   <a href="/patient/using-platform">Using Our Platform</a>
//                 </li>
//                 <li>
//                   <a href="/patient/health-articles">Health Articles</a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </DropdownSection>
//       <DropdownSection>
//         <ul>
//           <li className="heading-link">
//             <a href="/support">
//               <Icon /> Technical Support
//             </a>
//           </li>
//           <li className="heading-link">
//             <a href="/faq">
//               <Icon /> FAQs
//             </a>
//           </li>
//           <li className="heading-link">
//             <a href="/blog">
//               <Icon /> Blog
//             </a>
//           </li>
//         </ul>
//       </DropdownSection>
//     </div>
//   );
// };

// export default ResourcesDropdown;
// import React from 'react';
// import { Heading, Icon, DropdownSection } from '../styles';
// import './ResourcesDropdown.css';

// const ResourcesDropdown = () => {
//   return (
//     <div className="resources-dropdown">
//       <DropdownSection data-first-dropdown-section>
//         <div>
//           <Heading>Guides and Manuals</Heading>
//           <p>Access our comprehensive guides and manuals</p>
//           <div className="flex">
//             <div>
//               <h4>Professional Guides</h4>
//               <ul>
//                 <li>
//                   <a href="/guides-healthcare-professionals">Guides for Healthcare Professionals</a>
//                 </li>
//                 <li>
//                   <a href="/training-courses">Training and Courses</a>
//                 </li>
//                 <li>
//                   <a href="/patient-information">Patient Information</a>
//                 </li>
//               </ul>
//             </div>
//             <div>
//               <h4>Patient Resources</h4>
//               <ul>
//                 <li>
//                   <a href="/clinical-tools">Clinical Tools</a>
//                 </li>
//                 <li>
//                   <a href="/technical-support">Technical Support</a>
//                 </li>
//                 <li>
//                   <a href="/faqs">FAQs</a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </DropdownSection>
//       <DropdownSection>
//         <ul>
//           <li className="heading-link">
//             <a href="/blog">
//               <Icon /> Blog
//             </a>
//           </li>
//         </ul>
//       </DropdownSection>
//     </div>
//   );
// };

// export default ResourcesDropdown;
