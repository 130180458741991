// src/components/PublicRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PublicRoute = () => {
    const { user, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>; // O algún indicador visual de carga
    }

    return user ? <Navigate to="/dashboard" /> : <Outlet />;
};

export default PublicRoute;
