import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../utils/axiosInstance';
import axiosAppointments from '../../utils/axiosAppointments';
import axiosPatient from '../../utils/axiosPatient';
import RefreshToken from '../../utils/RefreshToken';
import Alert from '.././Alert';
import DataAnalysis from '.././DataAnalysis';
import PropertyCard from '.././PropertyCard';
import ScheduleAppointment from '.././ScheduleAppointment';
import ProfileCompleteBanner from '.././ProfileCompleteBanner';
import ClinicalHistoryComplete from '.././ClinicalHistoryComplete';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, TextField, Typography, Button
} from '@mui/material';
import Chart from 'react-apexcharts';
import { Card, CardHeader, CardDescription, CardTitle, CardContent } from '.././ui/Card';
import { Separator } from '.././ui/Separator';
import { Link } from 'react-router-dom';
import axios from 'axios';
import HealthDataChart from '.././HealthDataChart';
import axiosProfile from '../../utils/axiosProfile';
import Reports from '.././Reports';
import { useNavigate } from 'react-router-dom';
import {
  HomeIcon,
  ClipboardIcon,
  CreditCardIcon,
  PillIcon,
  CalendarIcon,
  HeartPulseIcon,
  AppleIcon, 
  ChartIcon, 
  ChevronLeftIcon,
  ChevronRightIcon
} from "../Icons";

// Definición de la función formatDate
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

// Definición de la función chartOptions
const chartOptions = (categories, series) => ({
  options: { chart: { id: 'health-chart' }, xaxis: { categories } },
  series: [{ name: 'Value', data: series }]
});

const TabPanel = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index}>
    {value === index && <div>{children}</div>}
  </div>
);

function PatientDashboard() {
  const { user } = useAuth();
  const [username, setUsername] = useState("");
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [message, setMessage] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [hasClinicalHistory, setHasClinicalHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [value, setValue] = useState(0);
  const [clinicalHistory, setClinicalHistory] = useState(null);
  const [healthData, setHealthData] = useState([]);
  const [newHealthData, setNewHealthData] = useState({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
  const [editHealthData, setEditHealthData] = useState(null);
  const [profile, setProfile] = useState({ firstName: '', lastName: '' });
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const pages = [
    { path: '/dashboard', label: 'Dashboard' },
    { path: '/wellness', label: 'Wellness' },
    { path: '/appointments', label: 'Appointments' },
    { path: '/treatments', label: 'Treatments' },
    { path: '/payment', label: 'Payment' },
    { path: '/reports', label: 'Reports' },
    { path: '/logs', label: 'Logs' },
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get('/user/');
        setUsername(response.data.username);
        setMessage(`Welcome back, ${response.data.username}!`);
        setLoggedIn(true);
        setIsProfileComplete(response.data.isProfileComplete);
      } catch (error) {
        setMessage("Session expired. Please log in again.");
        setLoggedIn(false);
      }
    };

    const fetchAppointments = async () => {
      try {
        const response = await axiosAppointments.get('/appointments/');
        setAppointments(response.data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    const checkClinicalHistory = async () => {
      try {
        setIsLoading(true);
        const response = await axiosPatient.get('/clinical_history/exists');
        setHasClinicalHistory(response.data.exists);
      } catch (error) {
        console.error("Error checking clinical history:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchClinicalHistory = async () => {
      try {
        const response = await axiosPatient.get(`/clinical_history/${user.user_id}`);
        console.log('Clinical History:', response.data);
        setClinicalHistory(response.data);
      } catch (error) {
        console.error('Error fetching clinical history:', error);
      }
    };

    const fetchHealthData = async () => {
      try {
        const response = await axiosPatient.get('/health_data');
        setHealthData(response.data);
      } catch (error) {
        console.error('Error fetching health data:', error);
      }
    };

    const fetchProfile = async () => {
      try {
        const response = await axiosProfile.get('/profiles/');
        setProfile({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
        });
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    Promise.all([
      fetchUserData(),
      fetchAppointments(),
      fetchProfile(),
      checkClinicalHistory()
    ]).then(() => {
      if (user?.role === 'patient' && user?.user_id) {
        fetchClinicalHistory();
        fetchHealthData();
      }
    });

    const refreshInterval = setInterval(async () => {
      const token = await RefreshToken();
      if (!token) {
        setMessage("Session expired. Please log in again.");
        setLoggedIn(false);
        clearInterval(refreshInterval);
      }
    }, 60 * 1000);

    const timer = setTimeout(() => setShowAlert(false), 30000);

    return () => {
      clearInterval(refreshInterval);
      clearTimeout(timer);
    };
  }, [user]);

  const handleCloseAlert = () => setShowAlert(false);

  const handlePrevious = () => {
    const newIndex = currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };
  
  const handleNext = () => {
    const newIndex = currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };

  const handleAddHealthData = async () => {
    try {
      const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
      const clinicalHistory = clinicalHistoryResponse.data;

      const newHealthDataWithClinicalInfo = {
        ...newHealthData,
        height_cm: clinicalHistory.anthropometry.heightCm,
        gender: clinicalHistory.genderAtBirth,
        age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
        activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
      };

      const response = await axiosPatient.post(`/health_data`, newHealthDataWithClinicalInfo);
      setHealthData([...healthData, response.data]);
      setNewHealthData({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
    } catch (error) {
      console.error("Error adding health data:", error);
    }
  };

  const handleUpdateHealthData = async () => {
    try {
      const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
      const clinicalHistory = clinicalHistoryResponse.data;

      const updatedHealthDataWithClinicalInfo = {
        ...editHealthData,
        height_cm: clinicalHistory.anthropometry.heightCm,
        gender: clinicalHistory.genderAtBirth,
        age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
        activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
      };

      const response = await axiosPatient.put(`/health_data/${editHealthData.id}`, updatedHealthDataWithClinicalInfo);
      setHealthData(healthData.map(data => (data.id === editHealthData.id ? response.data : data)));
      setEditHealthData(null);
    } catch (error) {
      console.error("Error updating health data:", error);
    }
  };

  const weightData = healthData.map(data => data.weight);
  const glucoseData = healthData.map(data => data.glucose);
  const categories = healthData.map(data => new Date(data.created_at).toLocaleDateString());

  return (
    <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex-1 overflow-auto py-2">
            <nav className="grid items-start px-4 text-sm font-medium">
              <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
              <HeartPulseIcon className="h-6 w-6" />
                Start Consultation
              </Link>
              <Link to="/dashboard" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
              <HomeIcon className="h-6 w-6" />
                Dashboard
              </Link>
              <Link to="/wellness" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <AppleIcon className="h-6 w-6" />
                Wellness
              </Link>
              <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <CalendarIcon className="h-6 w-6" />
                Appointments
              </Link>
              <Link to="/treatments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <PillIcon className="h-6 w-6" />
                Treatments
              </Link>
              <Link to="/payment" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <CreditCardIcon className="h-6 w-6" />
                Payment
              </Link>
              <Link to="/reports" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <ChartIcon className="h-6 w-6" />
                Reports
              </Link>
              <Link to="/logs" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <ClipboardIcon className="h-6 w-6" />
                Logs
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
        <div className="flex items-center gap-4">
          <h1 className="font-semibold text-lg md:text-xl">{pages[currentPageIndex].label}</h1>
          <div className="ml-auto flex items-center gap-2">
            <Button variant="outline" size="icon" onClick={handlePrevious}>
              <ChevronLeftIcon className="h-4 w-4" />
              <span className="sr-only">Previous</span>
            </Button>
            <Button variant="outline" size="icon" onClick={handleNext}>
              <ChevronRightIcon className="h-4 w-4" />
              <span className="sr-only">Next</span>
            </Button>
          </div>
        </div>
          <div className="grid gap-6">
            {isLoading ? (
              <div>Loading...</div>  // Muestra un loader mientras los datos se están cargando
            ) : (
              !hasClinicalHistory && user?.role === 'patient' && <ProfileCompleteBanner className="profile-complete-banner" />
            )}
  
            <div className="grid md:grid-cols-3 gap-6">
              <Card>
                <CardHeader>
                  <CardTitle variant="h6">Patient Overview</CardTitle>
                  <Typography variant="subtitle1">Key information about the patient's health status.</Typography>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4 p-4">
                    <div className="grid gap-2">
                      <Typography variant="body2">Name</Typography>
                      <Typography variant="h6">{profile.firstName} {profile.lastName}</Typography>
                      <Typography variant="body2">Gender</Typography>
                      <Typography variant="h6">{clinicalHistory?.genderAtBirth ? clinicalHistory.genderAtBirth.charAt(0).toUpperCase() + clinicalHistory.genderAtBirth.slice(1).toLowerCase() : 'N/A'}</Typography>
                    </div>
                    <div className="grid gap-2">
                      <Typography variant="body2">Age</Typography>
                      <Typography variant="h6">{clinicalHistory ? new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear() : 'N/A'}</Typography>
                      <Typography variant="body2">Condition</Typography>
                      <Typography variant="h6">{clinicalHistory?.medicalConditions?.join(', ') || 'N/A'}</Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardDescription>Weight</CardDescription>
                  <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].weight} kg` : 'N/A'}</CardTitle>
                </CardHeader>
                <CardContent>
                  <Chart options={chartOptions(categories, weightData).options} series={chartOptions(categories, weightData).series} type="line" height={250} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardDescription>Glucose</CardDescription>
                  <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].glucose} mg/dL` : 'N/A'}</CardTitle>
                </CardHeader>
                <CardContent>
                  <Chart options={chartOptions(categories, glucoseData).options} series={chartOptions(categories, glucoseData).series} type="line" height={250} />
                </CardContent>
              </Card>
            </div>

            <div className="grid md:grid-cols-3 gap-6">
              <Card>
                <CardHeader>
                  <CardDescription>Treatments</CardDescription>
                  <CardTitle>Medication, Physical Therapy</CardTitle>
                </CardHeader>
                <CardContent>
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Treatment</th>
                        <th className="px-4 py-2">Start Date</th>
                        <th className="px-4 py-2">End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-4 py-2">Medication</td>
                        <td className="border px-4 py-2">2023-06-01</td>
                        <td className="border px-4 py-2">2023-12-31</td>
                      </tr>
                      <tr>
                        <td className="border px-4 py-2">Physical Therapy</td>
                        <td className="border px-4 py-2">2023-07-15</td>
                        <td className="border px-4 py-2">2023-09-30</td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardDescription>Appointments</CardDescription>
                  <CardTitle>Next Appointment</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-2">
                    {appointments.map((appointment) => (
                      <div key={appointment.id} className="flex items-center justify-between">
                        <div>
                          <div className="font-medium">{appointment.doctor_name}</div>
                          <div className="text-sm text-muted-foreground">{new Date(appointment.appointment_date).toLocaleString()}</div>
                        </div>
                        <Button variant="outline" size="sm" onClick={() => setDetailDialogOpen(true)}>
                          View Details
                        </Button>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardDescription>Vital Signs</CardDescription>
                  <CardTitle>Recent Measurements</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-2">
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium">Temperature</div>
                        <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.bodyTemperatureC}°C</div>
                      </div>
                      <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
                    </div>
                    <Separator />
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium">Oxygen Saturation</div>
                        <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.oxygenSaturation}%</div>
                      </div>
                      <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
                    </div>
                    <Separator />
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium">Blood Pressure</div>
                        <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.bloodPressureSystolic}/{clinicalHistory?.anthropometry.bloodPressureDiastolic} mmHg</div>
                      </div>
                      <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
                    </div>
                    <Separator />
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium">Height</div>
                        <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.heightCm} cm</div>
                      </div>
                      <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
                    </div>
                    <Separator />
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium">Waist Circumference</div>
                        <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.waistCircumference} cm</div>
                      </div>
                      <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
                    </div>
                    <Separator />
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium">Hip Circumference</div>
                        <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.hipCircumference} cm</div>
                      </div>
                      <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="grid md:grid-cols-3 gap-6">
              <Card>
                <CardHeader>
                  <CardDescription>Add Health Data</CardDescription>
                  <CardTitle>Update Health Data</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-2">
                    <TextField
                      margin="dense"
                      label="Weight (kg)"
                      fullWidth
                      variant="standard"
                      value={newHealthData.weight}
                      onChange={(e) => setNewHealthData({ ...newHealthData, weight: e.target.value })}
                    />
                    <TextField
                      margin="dense"
                      label="Glucose (mg/dL)"
                      fullWidth
                      variant="standard"
                      value={newHealthData.glucose}
                      onChange={(e) => setNewHealthData({ ...newHealthData, glucose: e.target.value })}
                    />
                    <TextField
                      margin="dense"
                      label="Waist Circumference (cm)"
                      fullWidth
                      variant="standard"
                      value={newHealthData.waist_circumference}
                      onChange={(e) => setNewHealthData({ ...newHealthData, waist_circumference: e.target.value })}
                    />
                    <TextField
                      margin="dense"
                      label="Hip Circumference (cm)"
                      fullWidth
                      variant="standard"
                      value={newHealthData.hip_circumference}
                      onChange={(e) => setNewHealthData({ ...newHealthData, hip_circumference: e.target.value })}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddHealthData}>
                      Update
                    </Button>
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardDescription>Edit Health Data</CardDescription>
                  <CardTitle>Edit Selected Health Data</CardTitle>
                </CardHeader>
                <CardContent>
                  {editHealthData ? (
                    <div className="grid gap-2">
                      <TextField
                        margin="dense"
                        label="Weight (kg)"
                        fullWidth
                        variant="standard"
                        value={editHealthData.weight}
                        onChange={(e) => setEditHealthData({ ...editHealthData, weight: e.target.value })}
                      />
                      <TextField
                        margin="dense"
                        label="Glucose (mg/dL)"
                        fullWidth
                        variant="standard"
                        value={editHealthData.glucose}
                        onChange={(e) => setEditHealthData({ ...editHealthData, glucose: e.target.value })}
                      />
                      <TextField
                        margin="dense"
                        label="Waist Circumference (cm)"
                        fullWidth
                        variant="standard"
                        value={editHealthData.waist_circumference}
                        onChange={(e) => setEditHealthData({ ...editHealthData, waist_circumference: e.target.value })}
                      />
                      <TextField
                        margin="dense"
                        label="Hip Circumference (cm)"
                        fullWidth
                        variant="standard"
                        value={editHealthData.hip_circumference}
                        onChange={(e) => setEditHealthData({ ...editHealthData, hip_circumference: e.target.value })}
                      />
                      <Button variant="contained" color="primary" onClick={handleUpdateHealthData}>
                        Update Health Data
                      </Button>
                      <Button variant="contained" color="secondary" onClick={() => setEditHealthData(null)}>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Typography variant="body1">Select a health data entry to edit.</Typography>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PatientDashboard;





// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import axiosInstance from '../../utils/axiosInstance';
// import axiosAppointments from '../../utils/axiosAppointments';
// import axiosPatient from '../../utils/axiosPatient';
// import RefreshToken from '../../utils/RefreshToken';
// import Alert from '.././Alert';
// import DataAnalysis from '.././DataAnalysis';
// import PropertyCard from '.././PropertyCard';
// import ScheduleAppointment from '.././ScheduleAppointment';
// import ProfileCompleteBanner from '.././ProfileCompleteBanner';
// import ClinicalHistoryComplete from '.././ClinicalHistoryComplete';
// import {
//   Dialog, DialogActions, DialogContent, DialogContentText, 
//   DialogTitle, TextField, Typography, Button
// } from '@mui/material';
// import Chart from 'react-apexcharts';
// import { Card, CardHeader, CardDescription, CardTitle, CardContent } from '.././ui/Card';
// import { Separator } from '.././ui/Separator';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import HealthDataChart from '.././HealthDataChart';
// import axiosProfile from '../../utils/axiosProfile';
// import Reports from '.././Reports';
// import { useNavigate } from 'react-router-dom';
// import {
//   HomeIcon,
//   ClipboardIcon,
//   CreditCardIcon,
//   PillIcon,
//   CalendarIcon,
//   HeartPulseIcon,
//   AppleIcon, 
//   ChartIcon, 
//   ChevronLeftIcon,
//   ChevronRightIcon
// } from "../Icons";

// const TabPanel = ({ children, value, index }) => (
//   <div role="tabpanel" hidden={value !== index}>
//     {value === index && <div>{children}</div>}
//   </div>
// );

// function PatientDashboard() {
//   const { user } = useAuth();
//   const [username, setUsername] = useState("");
//   const [isLoggedIn, setLoggedIn] = useState(false);
//   const [showAlert, setShowAlert] = useState(true);
//   const [message, setMessage] = useState("");
//   const [appointments, setAppointments] = useState([]);
//   const [isProfileComplete, setIsProfileComplete] = useState(false);
//   const [hasClinicalHistory, setHasClinicalHistory] = useState(false);
//   const [isLoading, setIsLoading] = useState(true); // Nueva bandera para manejar la carga
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
//   const [detailDialogOpen, setDetailDialogOpen] = useState(false);
//   const [currentAppointment, setCurrentAppointment] = useState(null);
//   const [value, setValue] = useState(0);
//   const [clinicalHistory, setClinicalHistory] = useState(null);
//   const [healthData, setHealthData] = useState([]);
//   const [newHealthData, setNewHealthData] = useState({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
//   const [editHealthData, setEditHealthData] = useState(null);
//   const [profile, setProfile] = useState({ firstName: '', lastName: '' });
//   const navigate = useNavigate();
//   const [currentPageIndex, setCurrentPageIndex] = useState(0);
//   const pages = [
//     { path: '/dashboard', label: 'Dashboard' },
//     { path: '/wellness', label: 'Wellness' },
//     { path: '/appointments', label: 'Appointments' },
//     { path: '/treatments', label: 'Treatments' },
//     { path: '/payment', label: 'Payment' },
//     { path: '/reports', label: 'Reports' },
//     { path: '/logs', label: 'Logs' },
//   ];

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const response = await axiosInstance.get('/user/');
//         setUsername(response.data.username);
//         setMessage(`Welcome back, ${response.data.username}!`);
//         setLoggedIn(true);
//         setIsProfileComplete(response.data.isProfileComplete);
//       } catch (error) {
//         setMessage("Session expired. Please log in again.");
//         setLoggedIn(false);
//       }
//     };

//     const fetchAppointments = async () => {
//       try {
//         const response = await axiosAppointments.get('/appointments/');
//         setAppointments(response.data);
//       } catch (error) {
//         console.error("Error fetching appointments:", error);
//       }
//     };

//     const checkClinicalHistory = async () => {
//       try {
//         setIsLoading(true); // Inicia la carga
//         const response = await axiosPatient.get('/clinical_history/exists');
//         setHasClinicalHistory(response.data.exists);
//       } catch (error) {
//         console.error("Error checking clinical history:", error);
//       } finally {
//         setIsLoading(false); // Termina la carga
//       }
//     };

//     const fetchClinicalHistory = async () => {
//       try {
//         const response = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//         console.log('Clinical History:', response.data);
//         setClinicalHistory(response.data);
//       } catch (error) {
//         console.error('Error fetching clinical history:', error);
//       }
//     };

//     const fetchHealthData = async () => {
//       try {
//         const response = await axiosPatient.get('/health_data');
//         setHealthData(response.data);
//       } catch (error) {
//         console.error('Error fetching health data:', error);
//       }
//     };

//     const fetchProfile = async () => {
//       try {
//         const response = await axiosProfile.get('/profiles/');
//         setProfile({
//           firstName: response.data.first_name,
//           lastName: response.data.last_name,
//         });
//       } catch (error) {
//         console.error('Error fetching profile:', error);
//       }
//     };

//     fetchUserData();
//     fetchAppointments();
//     fetchProfile();
//     checkClinicalHistory();

//     if (user?.role === 'patient' && user?.user_id) {
//       fetchClinicalHistory();
//       fetchHealthData();
//     }

//     const refreshInterval = setInterval(async () => {
//       const token = await RefreshToken();
//       if (!token) {
//         setMessage("Session expired. Please log in again.");
//         setLoggedIn(false);
//         clearInterval(refreshInterval);
//       }
//     }, 60 * 1000);

//     const timer = setTimeout(() => setShowAlert(false), 30000);

//     return () => {
//       clearInterval(refreshInterval);
//       clearTimeout(timer);
//     };
//   }, [user]);

//   const handleCloseAlert = () => setShowAlert(false);
//   const handleAppointmentAdded = (newAppointment) => setAppointments([...appointments, newAppointment]);
//   const handleEditClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setEditDialogOpen(true);
//   };
//   const handleDeleteClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setDeleteDialogOpen(true);
//   };
//   const handleDetailClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setDetailDialogOpen(true);
//   };
//   const handleEditSubmit = async () => {
//     try {
//       const response = await axiosAppointments.put(`/appointments/${currentAppointment.id}`, currentAppointment);
//       setAppointments(appointments.map(appt => (appt.id === currentAppointment.id ? response.data : appt)));
//       setEditDialogOpen(false);
//       setCurrentAppointment(null);
//     } catch (error) {
//       console.error("Error updating appointment:", error);
//     }
//   };
//   const handleDeleteSubmit = async () => {
//     try {
//       await axiosAppointments.delete(`/appointments/${currentAppointment.id}`);
//       setAppointments(appointments.filter(appt => appt.id !== currentAppointment.id));
//       setDeleteDialogOpen(false);
//       setCurrentAppointment(null);
//     } catch (error) {
//       console.error("Error deleting appointment:", error);
//     }
//   };
//   const handleChange = (event, newValue) => setValue(newValue);

//   const handleDownloadPDF = async () => {
//     try {
//       const response = await axiosPatient.get('/clinical_history/download', { responseType: 'blob' });
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'clinical_history.pdf');
//       document.body.appendChild(link);
//       link.click();
//       link.remove();
//     } catch (error) {
//       console.error('Error downloading PDF:', error);
//     }
//   };

//   const chartOptions = (categories, series) => ({
//     options: { chart: { id: 'health-chart' }, xaxis: { categories } },
//     series: [{ name: 'Value', data: series }]
//   });

//   const weightData = healthData.map(data => data.weight);
//   const bmiData = healthData.map(data => data.bmi);
//   const bmiInterpretation = healthData.map(data => data.bmi_interpretation);
//   const glucoseData = healthData.map(data => data.glucose);
//   const categories = healthData.map(data => new Date(data.created_at).toLocaleDateString());

//   const caloriesData = healthData.length > 0 ? healthData[healthData.length - 1].estimated_calories : null;
//   const waistHipRatio = healthData.length > 0 ? healthData[healthData.length - 1].whr : null;
//   const waistHipInterpretation = healthData.length > 0 ? healthData[healthData.length - 1].whr_interpretation : null;

//   const formatDate = (dateString) => {
//     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
//     return new Date(dateString).toLocaleDateString('en-US', options);
//   };

//   const handleAddHealthData = async () => {
//     try {
//       const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//       const clinicalHistory = clinicalHistoryResponse.data;

//       const newHealthDataWithClinicalInfo = {
//         ...newHealthData,
//         height_cm: clinicalHistory.anthropometry.heightCm,
//         gender: clinicalHistory.genderAtBirth,
//         age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
//         activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
//       };

//       const response = await axiosPatient.post(`/health_data`, newHealthDataWithClinicalInfo);
//       setHealthData([...healthData, response.data]);
//       setNewHealthData({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
//     } catch (error) {
//       console.error("Error adding health data:", error);
//     }
//   };

//   const handleUpdateHealthData = async () => {
//     try {
//       const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//       const clinicalHistory = clinicalHistoryResponse.data;

//       const updatedHealthDataWithClinicalInfo = {
//         ...editHealthData,
//         height_cm: clinicalHistory.anthropometry.heightCm,
//         gender: clinicalHistory.genderAtBirth,
//         age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
//         activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
//       };

//       const response = await axiosPatient.put(`/health_data/${editHealthData.id}`, updatedHealthDataWithClinicalInfo);
//       setHealthData(healthData.map(data => (data.id === editHealthData.id ? response.data : data)));
//       setEditHealthData(null);
//     } catch (error) {
//       console.error("Error updating health data:", error);
//     }
//   };

//   const handleDeleteHealthData = async (id) => {
//     try {
//       await axiosPatient.delete(`/health_data/${id}`);
//       setHealthData(healthData.filter(data => data.id !== id));
//     } catch (error) {
//       console.error("Error deleting health data:", error);
//     }
//   };

//   const handlePrevious = () => {
//     const newIndex = currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
//     setCurrentPageIndex(newIndex);
//     navigate(pages[newIndex].path);
//   };
  
//   const handleNext = () => {
//     const newIndex = currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
//     setCurrentPageIndex(newIndex);
//     navigate(pages[newIndex].path);
//   };

//   return (
//     <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
//       <div className="hidden border-r bg-muted/40 lg:block">
//         <div className="flex h-full max-h-screen flex-col gap-2">
//           <div className="flex-1 overflow-auto py-2">
//             <nav className="grid items-start px-4 text-sm font-medium">
//               <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
//               <HeartPulseIcon className="h-6 w-6" />
//                 Start Consultation
//               </Link>
//               <Link to="/dashboard" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
//               <HomeIcon className="h-6 w-6" />
//                 Dashboard
//               </Link>
//               <Link to="/wellness" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <AppleIcon className="h-6 w-6" />
//                 Wellness
//               </Link>
//               <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <CalendarIcon className="h-6 w-6" />
//                 Appointments
//               </Link>
//               <Link to="/treatments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <PillIcon className="h-6 w-6" />
//                 Treatments
//               </Link>
//               <Link to="/payment" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <CreditCardIcon className="h-6 w-6" />
//                 Payment
//               </Link>
//               <Link to="/reports" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <ChartIcon className="h-6 w-6" />
//                 Reports
//               </Link>
//               <Link to="/logs" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <ClipboardIcon className="h-6 w-6" />
//                 Logs
//               </Link>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col">
//         <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
//         <div className="flex items-center gap-4">
//           <h1 className="font-semibold text-lg md:text-xl">{pages[currentPageIndex].label}</h1>
//           <div className="ml-auto flex items-center gap-2">
//             <Button variant="outline" size="icon" onClick={handlePrevious}>
//               <ChevronLeftIcon className="h-4 w-4" />
//               <span className="sr-only">Previous</span>
//             </Button>
//             <Button variant="outline" size="icon" onClick={handleNext}>
//               <ChevronRightIcon className="h-4 w-4" />
//               <span className="sr-only">Next</span>
//             </Button>
//           </div>
//         </div>
//           <div className="grid gap-6">
//             {isLoading ? (
//               <div>Loading...</div>  // Muestra un loader mientras los datos se están cargando
//             ) : (
//               !hasClinicalHistory && user?.role === 'patient' && <ProfileCompleteBanner className="profile-complete-banner" />
//             )}
  
//             <div className="grid md:grid-cols-3 gap-6">
//               <Card>
//                 <CardHeader>
//                   <CardTitle variant="h6">Patient Overview</CardTitle>
//                   <Typography variant="subtitle1">Key information about the patient's health status.</Typography>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid grid-cols-2 gap-4 p-4">
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Name</Typography>
//                       <Typography variant="h6">{profile.firstName} {profile.lastName}</Typography>
//                       <Typography variant="body2">Gender</Typography>
//                       <Typography variant="h6">{clinicalHistory?.genderAtBirth ? clinicalHistory.genderAtBirth.charAt(0).toUpperCase() + clinicalHistory.genderAtBirth.slice(1).toLowerCase() : 'N/A'}</Typography>
//                     </div>
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Age</Typography>
//                       <Typography variant="h6">{clinicalHistory ? new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear() : 'N/A'}</Typography>
//                       <Typography variant="body2">Condition</Typography>
//                       <Typography variant="h6">{clinicalHistory?.medicalConditions?.join(', ') || 'N/A'}</Typography>
//                     </div>
//                   </div>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Weight</CardDescription>
//                   <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].weight} kg` : 'N/A'}</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <Chart options={chartOptions(categories, weightData).options} series={chartOptions(categories, weightData).series} type="line" height={250} />
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Glucose</CardDescription>
//                   <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].glucose} mg/dL` : 'N/A'}</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <Chart options={chartOptions(categories, glucoseData).options} series={chartOptions(categories, glucoseData).series} type="line" height={250} />
//                 </CardContent>
//               </Card>
              
//             </div>

//             <div className="grid md:grid-cols-3 gap-6">
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Treatments</CardDescription>
//                   <CardTitle>Medication, Physical Therapy</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <table className="min-w-full">
//                     <thead>
//                       <tr>
//                         <th className="px-4 py-2">Treatment</th>
//                         <th className="px-4 py-2">Start Date</th>
//                         <th className="px-4 py-2">End Date</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td className="border px-4 py-2">Medication</td>
//                         <td className="border px-4 py-2">2023-06-01</td>
//                         <td className="border px-4 py-2">2023-12-31</td>
//                       </tr>
//                       <tr>
//                         <td className="border px-4 py-2">Physical Therapy</td>
//                         <td className="border px-4 py-2">2023-07-15</td>
//                         <td className="border px-4 py-2">2023-09-30</td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Appointments</CardDescription>
//                   <CardTitle>Next Appointment</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid gap-2">
//                     {appointments.map((appointment) => (
//                       <div key={appointment.id} className="flex items-center justify-between">
//                         <div>
//                           <div className="font-medium">{appointment.doctor_name}</div>
//                           <div className="text-sm text-muted-foreground">{new Date(appointment.appointment_date).toLocaleString()}</div>
//                         </div>
//                         <Button variant="outline" size="sm" onClick={() => handleDetailClick(appointment)}>
//                           View Details
//                         </Button>
//                       </div>
//                     ))}
//                   </div>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Vital Signs</CardDescription>
//                   <CardTitle>Recent Measurements</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid gap-2">
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Temperature</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.bodyTemperatureC}°C</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Oxygen Saturation</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.oxygenSaturation}%</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Blood Pressure</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.bloodPressureSystolic}/{clinicalHistory?.anthropometry.bloodPressureDiastolic} mmHg</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Height</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.heightCm} cm</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Waist Circumference</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.waistCircumference} cm</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Hip Circumference</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.hipCircumference} cm</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                   </div>
//                 </CardContent>
//               </Card>
//             </div>
//             <div className="grid md:grid-cols-3 gap-6">
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Add Health Data</CardDescription>
//                   <CardTitle>Update Health Data</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid gap-2">
//                     <TextField
//                       margin="dense"
//                       label="Weight (kg)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.weight}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, weight: e.target.value })}
//                     />
//                     <TextField
//                       margin="dense"
//                       label="Glucose (mg/dL)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.glucose}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, glucose: e.target.value })}
//                     />
//                     <TextField
//                       margin="dense"
//                       label="Waist Circumference (cm)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.waist_circumference}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, waist_circumference: e.target.value })}
//                     />
//                     <TextField
//                       margin="dense"
//                       label="Hip Circumference (cm)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.hip_circumference}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, hip_circumference: e.target.value })}
//                     />
//                     <Button variant="contained" color="primary" onClick={handleAddHealthData}>
//                       Update
//                     </Button>
//                   </div>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Edit Health Data</CardDescription>
//                   <CardTitle>Edit Selected Health Data</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   {editHealthData ? (
//                     <div className="grid gap-2">
//                       <TextField
//                         margin="dense"
//                         label="Weight (kg)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.weight}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, weight: e.target.value })}
//                       />
//                       <TextField
//                         margin="dense"
//                         label="Glucose (mg/dL)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.glucose}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, glucose: e.target.value })}
//                       />
//                       <TextField
//                         margin="dense"
//                         label="Waist Circumference (cm)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.waist_circumference}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, waist_circumference: e.target.value })}
//                       />
//                       <TextField
//                         margin="dense"
//                         label="Hip Circumference (cm)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.hip_circumference}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, hip_circumference: e.target.value })}
//                       />
//                       <Button variant="contained" color="primary" onClick={handleUpdateHealthData}>
//                         Update Health Data
//                       </Button>
//                       <Button variant="contained" color="secondary" onClick={() => setEditHealthData(null)}>
//                         Cancel
//                       </Button>
//                     </div>
//                   ) : (
//                     <Typography variant="body1">Select a health data entry to edit.</Typography>
//                   )}
//                 </CardContent>
//               </Card>
//             </div>
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// }

// export default PatientDashboard;






// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import axiosInstance from '../../utils/axiosInstance';
// import axiosAppointments from '../../utils/axiosAppointments';
// import axiosPatient from '../../utils/axiosPatient';
// import RefreshToken from '../../utils/RefreshToken';
// import Alert from '.././Alert';
// import DataAnalysis from '.././DataAnalysis';
// import PropertyCard from '.././PropertyCard';
// import ScheduleAppointment from '.././ScheduleAppointment';
// import ProfileCompleteBanner from '.././ProfileCompleteBanner';
// import ClinicalHistoryComplete from '.././ClinicalHistoryComplete';
// import {
//   Dialog, DialogActions, DialogContent, DialogContentText, 
//   DialogTitle, TextField, Typography, Button
// } from '@mui/material';
// import Chart from 'react-apexcharts';
// import { Card, CardHeader, CardDescription, CardTitle, CardContent } from '.././ui/Card';
// import { Separator } from '.././ui/Separator';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import HealthDataChart from '.././HealthDataChart';
// import axiosProfile from '../../utils/axiosProfile';
// import Reports from '.././Reports';
// import { useNavigate } from 'react-router-dom';
// import {
//   HomeIcon,
//   ClipboardIcon,
//   CreditCardIcon,
//   PillIcon,
//   CalendarIcon,
//   HeartPulseIcon,
//   AppleIcon, 
//   ChartIcon, 
//   ChevronLeftIcon,
//   ChevronRightIcon


// } from "../Icons";

// const TabPanel = ({ children, value, index }) => (
//   <div role="tabpanel" hidden={value !== index}>
//     {value === index && <div>{children}</div>}
//   </div>
// );

// function PatientDashboard() {
//   const { user } = useAuth();
//   const [username, setUsername] = useState("");
//   const [isLoggedIn, setLoggedIn] = useState(false);
//   const [showAlert, setShowAlert] = useState(true);
//   const [message, setMessage] = useState("");
//   const [appointments, setAppointments] = useState([]);
//   const [isProfileComplete, setIsProfileComplete] = useState(false);
//   const [hasClinicalHistory, setHasClinicalHistory] = useState(false);
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
//   const [detailDialogOpen, setDetailDialogOpen] = useState(false);
//   const [currentAppointment, setCurrentAppointment] = useState(null);
//   const [value, setValue] = useState(0);
//   const [clinicalHistory, setClinicalHistory] = useState(null);
//   const [healthData, setHealthData] = useState([]);
//   const [newHealthData, setNewHealthData] = useState({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
//   const [editHealthData, setEditHealthData] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [profile, setProfile] = useState({ firstName: '', lastName: '' });
//   // const [firstName, setFirstName] = useState("");
//   // const [lastName, setLastName] = useState("");
//   const navigate = useNavigate();
//   const [currentPageIndex, setCurrentPageIndex] = useState(0);
//   const pages = [
//     { path: '/dashboard', label: 'Dashboard' },
//     { path: '/wellness', label: 'Wellness' },
//     { path: '/appointments', label: 'Appointments' },
//     { path: '/treatments', label: 'Treatments' },
//     { path: '/payment', label: 'Payment' },
//     { path: '/reports', label: 'Reports' },
//     { path: '/logs', label: 'Logs' },
//   ];


//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const response = await axiosInstance.get('/user/');
//         setUsername(response.data.username);
//         setMessage(`Welcome back, ${response.data.username}!`);
//         setLoggedIn(true);
//         setIsProfileComplete(response.data.isProfileComplete);
//       } catch (error) {
//         setMessage("Session expired. Please log in again.");
//         setLoggedIn(false);
//       }
//     };

//     const fetchAppointments = async () => {
//       try {
//         const response = await axiosAppointments.get('/appointments/');
//         setAppointments(response.data);
//       } catch (error) {
//         console.error("Error fetching appointments:", error);
//       }
//     };

//     const checkClinicalHistory = async () => {
//       try {
//         setIsLoading(true);
//         const response = await axiosPatient.get('/clinical_history/exists');
//         setHasClinicalHistory(response.data.exists);
//       } catch (error) {
//         console.error("Error checking clinical history:", error);
//       }finally {
//         setIsLoading(false);
//     }
//     };

//     const fetchClinicalHistory = async () => {
//       try {
//         const response = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//         console.log('Clinical History:', response.data);
//         setClinicalHistory(response.data);
//       } catch (error) {
//         console.error('Error fetching clinical history:', error);
//       }
//     };


//     const fetchHealthData = async () => {
//       try {
//         const response = await axiosPatient.get('/health_data');
//         setHealthData(response.data);
//       } catch (error) {
//         console.error('Error fetching health data:', error);
//       }
//     };

//     const fetchProfile = async () => {
//       try {
//         const response = await axiosProfile.get('/profiles/');
//         setProfile({
//           firstName: response.data.first_name,
//           lastName: response.data.last_name,
//         });
//       } catch (error) {
//         console.error('Error fetching profile:', error);
//       }
//     };

//     fetchUserData();
//     fetchProfile();
//     fetchAppointments();
//     checkClinicalHistory();

//     if (user?.role === 'patient' && user?.user_id) {
//       fetchClinicalHistory();
//       fetchHealthData();
//     }

//     const refreshInterval = setInterval(async () => {
//       const token = await RefreshToken();
//       if (!token) {
//         setMessage("Session expired. Please log in again.");
//         setLoggedIn(false);
//         clearInterval(refreshInterval);
//       }
//     }, 60 * 1000);

//     const timer = setTimeout(() => setShowAlert(false), 30000);

//     return () => {
//       clearInterval(refreshInterval);
//       clearTimeout(timer);
//     };
//   }, [user]);

//   const handleCloseAlert = () => setShowAlert(false);
//   const handleAppointmentAdded = (newAppointment) => setAppointments([...appointments, newAppointment]);
//   const handleEditClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setEditDialogOpen(true);
//   };
//   const handleDeleteClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setDeleteDialogOpen(true);
//   };
//   const handleDetailClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setDetailDialogOpen(true);
//   };
//   const handleEditSubmit = async () => {
//     try {
//       const response = await axiosAppointments.put(`/appointments/${currentAppointment.id}`, currentAppointment);
//       setAppointments(appointments.map(appt => (appt.id === currentAppointment.id ? response.data : appt)));
//       setEditDialogOpen(false);
//       setCurrentAppointment(null);
//     } catch (error) {
//       console.error("Error updating appointment:", error);
//     }
//   };
//   const handleDeleteSubmit = async () => {
//     try {
//       await axiosAppointments.delete(`/appointments/${currentAppointment.id}`);
//       setAppointments(appointments.filter(appt => appt.id !== currentAppointment.id));
//       setDeleteDialogOpen(false);
//       setCurrentAppointment(null);
//     } catch (error) {
//       console.error("Error deleting appointment:", error);
//     }
//   };
//   const handleChange = (event, newValue) => setValue(newValue);

//   const handleDownloadPDF = async () => {
//     try {
//       const response = await axiosPatient.get('/clinical_history/download', { responseType: 'blob' });
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'clinical_history.pdf');
//       document.body.appendChild(link);
//       link.click();
//       link.remove();
//     } catch (error) {
//       console.error('Error downloading PDF:', error);
//     }
//   };

//   const chartOptions = (categories, series) => ({
//     options: { chart: { id: 'health-chart' }, xaxis: { categories } },
//     series: [{ name: 'Value', data: series }]
//   });

//   const weightData = healthData.map(data => data.weight);
//   const bmiData = healthData.map(data => data.bmi);
//   const bmiInterpretation = healthData.map(data => data.bmi_interpretation);
//   const glucoseData = healthData.map(data => data.glucose);
//   const categories = healthData.map(data => new Date(data.created_at).toLocaleDateString());

//   const caloriesData = healthData.length > 0 ? healthData[healthData.length - 1].estimated_calories : null;
//   const waistHipRatio = healthData.length > 0 ? healthData[healthData.length - 1].whr : null;
//   const waistHipInterpretation = healthData.length > 0 ? healthData[healthData.length - 1].whr_interpretation : null;

//   const formatDate = (dateString) => {
//     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
//     return new Date(dateString).toLocaleDateString('en-US', options);
//   };

//   const handleAddHealthData = async () => {
//     try {
//       const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//       const clinicalHistory = clinicalHistoryResponse.data;

//       const newHealthDataWithClinicalInfo = {
//         ...newHealthData,
//         height_cm: clinicalHistory.anthropometry.heightCm,
//         gender: clinicalHistory.genderAtBirth,
//         age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
//         activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
//       };

//       const response = await axiosPatient.post(`/health_data`, newHealthDataWithClinicalInfo);
//       setHealthData([...healthData, response.data]);
//       setNewHealthData({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
//     } catch (error) {
//       console.error("Error adding health data:", error);
//     }
//   };

//   const handleUpdateHealthData = async () => {
//     try {
//       const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//       const clinicalHistory = clinicalHistoryResponse.data;

//       const updatedHealthDataWithClinicalInfo = {
//         ...editHealthData,
//         height_cm: clinicalHistory.anthropometry.heightCm,
//         gender: clinicalHistory.genderAtBirth,
//         age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
//         activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
//       };

//       const response = await axiosPatient.put(`/health_data/${editHealthData.id}`, updatedHealthDataWithClinicalInfo);
//       setHealthData(healthData.map(data => (data.id === editHealthData.id ? response.data : data)));
//       setEditHealthData(null);
//     } catch (error) {
//       console.error("Error updating health data:", error);
//     }
//   };

//   const handleDeleteHealthData = async (id) => {
//     try {
//       await axiosPatient.delete(`/health_data/${id}`);
//       setHealthData(healthData.filter(data => data.id !== id));
//     } catch (error) {
//       console.error("Error deleting health data:", error);
//     }
//   };

//   const handlePrevious = () => {
//     const newIndex = currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
//     setCurrentPageIndex(newIndex);
//     navigate(pages[newIndex].path);
//   };
  
//   const handleNext = () => {
//     const newIndex = currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
//     setCurrentPageIndex(newIndex);
//     navigate(pages[newIndex].path);
//   };

//   return (
//     <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
//       <div className="hidden border-r bg-muted/40 lg:block">
//         <div className="flex h-full max-h-screen flex-col gap-2">
//           <div className="flex-1 overflow-auto py-2">
//             <nav className="grid items-start px-4 text-sm font-medium">
//               <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
//               <HeartPulseIcon className="h-6 w-6" />
//                 Start Consultation
//               </Link>
//               <Link to="/dashboard" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
//               <HomeIcon className="h-6 w-6" />
//                 Dashboard
//               </Link>
//               <Link to="/wellness" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <AppleIcon className="h-6 w-6" />
//                 Wellness
//               </Link>
//               <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <CalendarIcon className="h-6 w-6" />
//                 Appointments
//               </Link>
//               <Link to="/treatments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <PillIcon className="h-6 w-6" />
//                 Treatments
//               </Link>
//               <Link to="/payment" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <CreditCardIcon className="h-6 w-6" />
//                 Payment
//               </Link>
//               <Link to="/reports" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <ChartIcon className="h-6 w-6" />
//                 Reports
//               </Link>
//               <Link to="/logs" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
//               <ClipboardIcon className="h-6 w-6" />
//                 Logs
//               </Link>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col">
//         {/* <header className="flex h-14 lg:h-[60px] items-center gap-4 border-b bg-muted/40 px-6">
//           <Link to="/" className="lg:hidden">
//             <HeartPulseIcon className="h-6 w-6" />
//             <span className="sr-only">Home</span>
//           </Link>
//           <div className="w-full flex-1">
//             <form>
//               <div className="relative">
//                 <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
//                 <input
//                   type="search"
//                   placeholder="Search patients"
//                   className="w-full bg-background shadow-none appearance-none pl-8 md:w-2/3 lg:w-1/3"
//                 />
//               </div>
//             </form>
//           </div>
//         </header> */}
//         <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
//         <div className="flex items-center gap-4">
//           <h1 className="font-semibold text-lg md:text-xl">{pages[currentPageIndex].label}</h1>
//           <div className="ml-auto flex items-center gap-2">
//             <Button variant="outline" size="icon" onClick={handlePrevious}>
//               <ChevronLeftIcon className="h-4 w-4" />
//               <span className="sr-only">Previous</span>
//             </Button>
//             <Button variant="outline" size="icon" onClick={handleNext}>
//               <ChevronRightIcon className="h-4 w-4" />
//               <span className="sr-only">Next</span>
//             </Button>
//           </div>
//         </div>
//           <div className="grid gap-6">
//             {!hasClinicalHistory && user?.role === 'patient' && <ProfileCompleteBanner className="profile-complete-banner" />}
  


       
//             {/* <HealthDataChart /> */}
//             {/* <div className="grid md:grid-cols-3 gap-6">
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Weight</CardDescription>
//                   <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].weight} kg` : 'N/A'}</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <Chart options={chartOptions(categories, weightData).options} series={chartOptions(categories, weightData).series} type="line" height={250} />
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>BMI & Interpretation</CardDescription>
//                   <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].bmi} (${healthData[healthData.length - 1].bmi_interpretation})` : 'N/A'}</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <Chart options={chartOptions(categories, bmiData).options} series={chartOptions(categories, bmiData).series} type="line" height={250} />
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Glucose</CardDescription>
//                   <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].glucose} mg/dL` : 'N/A'}</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <Chart options={chartOptions(categories, glucoseData).options} series={chartOptions(categories, glucoseData).series} type="line" height={250} />
//                 </CardContent>
//               </Card>
//             </div> */}
//             <div className="grid md:grid-cols-3 gap-6">
//             {!hasClinicalHistory && user?.role === 'patient' && <ProfileCompleteBanner className="profile-complete-banner" />}
//             {/* {hasClinicalHistory && <ClinicalHistoryComplete />} */}
//             <Card>
//                 <CardHeader>
//                   <CardTitle variant="h6">Patient Overview</CardTitle>
//                   <Typography variant="subtitle1">Key information about the patient's health status.</Typography>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid grid-cols-2 gap-4 p-4">
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Name</Typography>
//                       <Typography variant="h6">{profile.firstName} {profile.lastName}</Typography>
//                       <Typography variant="body2">Gender</Typography>
//                       <Typography variant="h6">{clinicalHistory?.genderAtBirth ? clinicalHistory.genderAtBirth.charAt(0).toUpperCase() + clinicalHistory.genderAtBirth.slice(1).toLowerCase() : 'N/A'}</Typography>
//                       {/* <Typography variant="h6">{clinicalHistory?.genderAtBirth || 'N/A'}</Typography> */}
//                     </div>
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Age</Typography>
//                       <Typography variant="h6">{clinicalHistory ? new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear() : 'N/A'}</Typography>
//                       <Typography variant="body2">Condition</Typography>
//                       <Typography variant="h6">{clinicalHistory?.medicalConditions?.join(', ') || 'N/A'}</Typography>
//                       {/* <Typography variant="h6">{clinicalHistory?.medicalConditions?.length > 0 ? clinicalHistory.medicalConditions.join(', '): 'N/A'}</Typography> */}
//                     </div>
//                   </div>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Weight</CardDescription>
//                   <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].weight} kg` : 'N/A'}</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <Chart options={chartOptions(categories, weightData).options} series={chartOptions(categories, weightData).series} type="line" height={250} />
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Glucose</CardDescription>
//                   <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].glucose} mg/dL` : 'N/A'}</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <Chart options={chartOptions(categories, glucoseData).options} series={chartOptions(categories, glucoseData).series} type="line" height={250} />
//                 </CardContent>
//               </Card>
              
//             </div>

//             <div className="grid md:grid-cols-3 gap-6">
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Treatments</CardDescription>
//                   <CardTitle>Medication, Physical Therapy</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <table className="min-w-full">
//                     <thead>
//                       <tr>
//                         <th className="px-4 py-2">Treatment</th>
//                         <th className="px-4 py-2">Start Date</th>
//                         <th className="px-4 py-2">End Date</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td className="border px-4 py-2">Medication</td>
//                         <td className="border px-4 py-2">2023-06-01</td>
//                         <td className="border px-4 py-2">2023-12-31</td>
//                       </tr>
//                       <tr>
//                         <td className="border px-4 py-2">Physical Therapy</td>
//                         <td className="border px-4 py-2">2023-07-15</td>
//                         <td className="border px-4 py-2">2023-09-30</td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Appointments</CardDescription>
//                   <CardTitle>Next Appointment</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid gap-2">
//                     {appointments.map((appointment) => (
//                       <div key={appointment.id} className="flex items-center justify-between">
//                         <div>
//                           <div className="font-medium">{appointment.doctor_name}</div>
//                           <div className="text-sm text-muted-foreground">{new Date(appointment.appointment_date).toLocaleString()}</div>
//                         </div>
//                         <Button variant="outline" size="sm" onClick={() => handleDetailClick(appointment)}>
//                           View Details
//                         </Button>
//                       </div>
//                     ))}
//                   </div>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Vital Signs</CardDescription>
//                   <CardTitle>Recent Measurements</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid gap-2">
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Temperature</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.bodyTemperatureC}°C</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Oxygen Saturation</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.oxygenSaturation}%</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Blood Pressure</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.bloodPressureSystolic}/{clinicalHistory?.anthropometry.bloodPressureDiastolic} mmHg</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Height</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.heightCm} cm</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Waist Circumference</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.waistCircumference} cm</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                     <Separator />
//                     <div className="flex items-center justify-between">
//                       <div>
//                         <div className="font-medium">Hip Circumference</div>
//                         <div className="text-sm text-muted-foreground">{clinicalHistory?.anthropometry.hipCircumference} cm</div>
//                       </div>
//                       <div className="text-sm text-muted-foreground">{formatDate(clinicalHistory?.created_at)}</div>
//                     </div>
//                   </div>
//                 </CardContent>
//               </Card>
//             </div>
//             <div className="grid md:grid-cols-3 gap-6">
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Add Health Data</CardDescription>
//                   <CardTitle>Update Health Data</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid gap-2">
//                     <TextField
//                       margin="dense"
//                       label="Weight (kg)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.weight}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, weight: e.target.value })}
//                     />
//                     <TextField
//                       margin="dense"
//                       label="Glucose (mg/dL)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.glucose}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, glucose: e.target.value })}
//                     />
//                     <TextField
//                       margin="dense"
//                       label="Waist Circumference (cm)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.waist_circumference}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, waist_circumference: e.target.value })}
//                     />
//                     <TextField
//                       margin="dense"
//                       label="Hip Circumference (cm)"
//                       fullWidth
//                       variant="standard"
//                       value={newHealthData.hip_circumference}
//                       onChange={(e) => setNewHealthData({ ...newHealthData, hip_circumference: e.target.value })}
//                     />
//                     <Button variant="contained" color="primary" onClick={handleAddHealthData}>
//                       Update
//                     </Button>
//                   </div>
//                 </CardContent>
//               </Card>
//               <Card>
//                 <CardHeader>
//                   <CardDescription>Edit Health Data</CardDescription>
//                   <CardTitle>Edit Selected Health Data</CardTitle>
//                 </CardHeader>
//                 <CardContent>
//                   {editHealthData ? (
//                     <div className="grid gap-2">
//                       <TextField
//                         margin="dense"
//                         label="Weight (kg)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.weight}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, weight: e.target.value })}
//                       />
//                       <TextField
//                         margin="dense"
//                         label="Glucose (mg/dL)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.glucose}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, glucose: e.target.value })}
//                       />
//                       <TextField
//                         margin="dense"
//                         label="Waist Circumference (cm)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.waist_circumference}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, waist_circumference: e.target.value })}
//                       />
//                       <TextField
//                         margin="dense"
//                         label="Hip Circumference (cm)"
//                         fullWidth
//                         variant="standard"
//                         value={editHealthData.hip_circumference}
//                         onChange={(e) => setEditHealthData({ ...editHealthData, hip_circumference: e.target.value })}
//                       />
//                       <Button variant="contained" color="primary" onClick={handleUpdateHealthData}>
//                         Update Health Data
//                       </Button>
//                       <Button variant="contained" color="secondary" onClick={() => setEditHealthData(null)}>
//                         Cancel
//                       </Button>
//                     </div>
//                   ) : (
//                     <Typography variant="body1">Select a health data entry to edit.</Typography>
//                   )}
//                 </CardContent>
//               </Card>
//             </div>
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// }

// export default PatientDashboard;

// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext'; // Ajuste de la ruta de importación
// import axiosInstance from '../../utils/axiosInstance'; // Ajuste de la ruta de importación
// import axiosAppointments from '../../utils/axiosAppointments'; // Ajuste de la ruta de importación
// import axiosPatient from '../../utils/axiosPatient'; // Ajuste de la ruta de importación
// import RefreshToken from '../../utils/RefreshToken'; // Ajuste de la ruta de importación
// import Alert from '../Alert'; // Ajuste de la ruta de importación
// import DataAnalysis from '../DataAnalysis'; // Ajuste de la ruta de importación
// import PropertyCard from '../PropertyCard'; // Ajuste de la ruta de importación
// import ScheduleAppointment from '../ScheduleAppointment'; // Ajuste de la ruta de importación
// import ProfileCompleteBanner from '../ProfileCompleteBanner'; // Ajuste de la ruta de importación
// import ClinicalHistoryComplete from '../ClinicalHistoryComplete'; // Ajuste de la ruta de importación
// import HealthDataChart from '../HealthDataChart'; // Ajuste de la ruta de importación
// import {
//   Input,
//   Button,
//   Tabs,
//   Tab,
//   Box,
//   Card,
//   CardHeader,
//   CardContent,
//   CardActions,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from '@mui/material';
// import { Link } from 'react-router-dom'; // Reemplazar 'next/link' por 'react-router-dom'

// const TabPanel = ({ children, value, index }) => (
//   <div role="tabpanel" hidden={value !== index}>
//     {value === index && <Box>{children}</Box>}
//   </div>
// );

// function Dashboard() {
//   const { user } = useAuth();
//   const [username, setUsername] = useState("");
//   const [isLoggedIn, setLoggedIn] = useState(false);
//   const [showAlert, setShowAlert] = useState(true);
//   const [message, setMessage] = useState("");
//   const [appointments, setAppointments] = useState([]);
//   const [isProfileComplete, setIsProfileComplete] = useState(false);
//   const [hasClinicalHistory, setHasClinicalHistory] = useState(false);
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
//   const [detailDialogOpen, setDetailDialogOpen] = useState(false);
//   const [currentAppointment, setCurrentAppointment] = useState(null);
//   const [value, setValue] = useState(0);
//   const [clinicalHistory, setClinicalHistory] = useState(null);
//   const [healthData, setHealthData] = useState([]);
//   const [newHealthData, setNewHealthData] = useState({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
//   const [editHealthData, setEditHealthData] = useState(null);

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const response = await axiosInstance.get('/user/');
//         setUsername(response.data.username);
//         setMessage(`Welcome back, ${response.data.username}!`);
//         setLoggedIn(true);
//         setIsProfileComplete(response.data.isProfileComplete);
//       } catch (error) {
//         setMessage("Session expired. Please log in again.");
//         setLoggedIn(false);
//       }
//     };

//     const fetchAppointments = async () => {
//       try {
//         const response = await axiosAppointments.get('/appointments/');
//         setAppointments(response.data);
//       } catch (error) {
//         console.error("Error fetching appointments:", error);
//       }
//     };

//     const checkClinicalHistory = async () => {
//       try {
//         const response = await axiosPatient.get('/clinical_history/exists');
//         setHasClinicalHistory(response.data.exists);
//       } catch (error) {
//         console.error("Error checking clinical history:", error);
//       }
//     };

//     const fetchClinicalHistory = async () => {
//       try {
//         const response = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//         setClinicalHistory(response.data);
//       } catch (error) {
//         console.error('Error fetching clinical history:', error);
//       }
//     };

//     const fetchHealthData = async () => {
//       try {
//         const response = await axiosPatient.get('/health_data');
//         setHealthData(response.data);
//       } catch (error) {
//         console.error('Error fetching health data:', error);
//       }
//     };

//     fetchUserData();
//     fetchAppointments();
//     checkClinicalHistory();

//     if (user?.role === 'patient' && user?.user_id) {
//       fetchClinicalHistory();
//       fetchHealthData();
//     }

//     const refreshInterval = setInterval(async () => {
//       const token = await RefreshToken();
//       if (!token) {
//         setMessage("Session expired. Please log in again.");
//         setLoggedIn(false);
//         clearInterval(refreshInterval);
//       }
//     }, 60 * 1000);

//     const timer = setTimeout(() => setShowAlert(false), 30000);

//     return () => {
//       clearInterval(refreshInterval);
//       clearTimeout(timer);
//     };
//   }, [user]);

//   const handleCloseAlert = () => setShowAlert(false);
//   const handleAppointmentAdded = (newAppointment) => setAppointments([...appointments, newAppointment]);
//   const handleEditClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setEditDialogOpen(true);
//   };
//   const handleDeleteClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setDeleteDialogOpen(true);
//   };
//   const handleDetailClick = (appointment) => {
//     setCurrentAppointment(appointment);
//     setDetailDialogOpen(true);
//   };
//   const handleEditSubmit = async () => {
//     try {
//       const response = await axiosAppointments.put(`/appointments/${currentAppointment.id}`, currentAppointment);
//       setAppointments(appointments.map(appt => (appt.id === currentAppointment.id ? response.data : appt)));
//       setEditDialogOpen(false);
//       setCurrentAppointment(null);
//     } catch (error) {
//       console.error("Error updating appointment:", error);
//     }
//   };
//   const handleDeleteSubmit = async () => {
//     try {
//       await axiosAppointments.delete(`/appointments/${currentAppointment.id}`);
//       setAppointments(appointments.filter(appt => appt.id !== currentAppointment.id));
//       setDeleteDialogOpen(false);
//       setCurrentAppointment(null);
//     } catch (error) {
//       console.error("Error deleting appointment:", error);
//     }
//   };
//   const handleChange = (event, newValue) => setValue(newValue);

//   const handleDownloadPDF = async () => {
//     try {
//       const response = await axiosPatient.get('/clinical_history/download', { responseType: 'blob' });
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'clinical_history.pdf');
//       document.body.appendChild(link);
//       link.click();
//       link.remove();
//     } catch (error) {
//       console.error('Error downloading PDF:', error);
//     }
//   };

//   const chartOptions = (categories, series) => ({
//     options: { chart: { id: 'health-chart' }, xaxis: { categories } },
//     series: [{ name: 'Value', data: series }]
//   });

//   const weightData = healthData.map(data => data.weight);
//   const bmiData = healthData.map(data => data.bmi);
//   const bmiInterpretation = healthData.map(data => data.bmi_interpretation);
//   const glucoseData = healthData.map(data => data.glucose);
//   const categories = healthData.map(data => new Date(data.created_at).toLocaleDateString());

//   const caloriesData = healthData.length > 0 ? healthData[healthData.length - 1].estimated_calories : null;
//   const waistHipRatio = healthData.length > 0 ? healthData[healthData.length - 1].whr : null;
//   const waistHipInterpretation = healthData.length > 0 ? healthData[healthData.length - 1].whr_interpretation : null;

//   const formatDate = (dateString) => {
//     const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
//     return new Date(dateString).toLocaleDateString('en-US', options);
//   };

//   const handleAddHealthData = async () => {
//     try {
//       const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//       const clinicalHistory = clinicalHistoryResponse.data;

//       const newHealthDataWithClinicalInfo = {
//         ...newHealthData,
//         height_cm: clinicalHistory.anthropometry.heightCm,
//         gender: clinicalHistory.genderAtBirth,
//         age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
//         activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
//       };

//       const response = await axiosPatient.post(`/health_data`, newHealthDataWithClinicalInfo);
//       setHealthData([...healthData, response.data]);
//       setNewHealthData({ weight: '', glucose: '', waist_circumference: '', hip_circumference: '' });
//     } catch (error) {
//       console.error("Error adding health data:", error);
//     }
//   };

//   const handleUpdateHealthData = async () => {
//     try {
//       const clinicalHistoryResponse = await axiosPatient.get(`/clinical_history/${user.user_id}`);
//       const clinicalHistory = clinicalHistoryResponse.data;

//       const updatedHealthDataWithClinicalInfo = {
//         ...editHealthData,
//         height_cm: clinicalHistory.anthropometry.heightCm,
//         gender: clinicalHistory.genderAtBirth,
//         age: new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear(),
//         activity_level: clinicalHistory.lifestyle.exercises ? clinicalHistory.lifestyle.exerciseType[0] : 'sedentary'
//       };

//       const response = await axiosPatient.put(`/health_data/${editHealthData.id}`, updatedHealthDataWithClinicalInfo);
//       setHealthData(healthData.map(data => (data.id === editHealthData.id ? response.data : data)));
//       setEditHealthData(null);
//     } catch (error) {
//       console.error("Error updating health data:", error);
//     }
//   };

//   const handleDeleteHealthData = async (id) => {
//     try {
//       await axiosPatient.delete(`/health_data/${id}`);
//       setHealthData(healthData.filter(data => data.id !== id));
//     } catch (error) {
//       console.error("Error deleting health data:", error);
//     }
//   };

//   return (
//     <div className="flex min-h-screen w-full flex-col bg-muted/40">
//       <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
//         <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
//           <Tabs value={value} onChange={handleChange}>
//             <Tab label="Overview" />
//             <Tab label="Vitals" />
//             <Tab label="Treatment" />
//             <TabPanel value={value} index={0}>
//               <Card>
//                 <CardHeader>
//                   <Typography variant="h6">Patient Overview</Typography>
//                   <Typography variant="subtitle1">Key information about the patient's health status.</Typography>
//                 </CardHeader>
//                 <CardContent className="grid grid-cols-2 gap-4">
//                   <div className="grid gap-2">
//                     <Typography variant="body2">Name</Typography>
//                     <Typography variant="h6">{username}</Typography>
//                   </div>
//                   <div className="grid gap-2">
//                     <Typography variant="body2">Age</Typography>
//                     <Typography variant="h6">{clinicalHistory ? new Date().getFullYear() - new Date(clinicalHistory.birthDate).getFullYear() : 'N/A'}</Typography>
//                   </div>
//                   <div className="grid gap-2">
//                     <Typography variant="body2">Gender</Typography>
//                     <Typography variant="h6">{clinicalHistory?.genderAtBirth || 'N/A'}</Typography>
//                   </div>
//                   <div className="grid gap-2">
//                     <Typography variant="body2">Condition</Typography>
//                     <Typography variant="h6">{clinicalHistory?.medicalConditions?.join(', ') || 'N/A'}</Typography>
//                   </div>
//                 </CardContent>
//               </Card>
//             </TabPanel>
//             <TabPanel value={value} index={1}>
//               <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
//                 <Card>
//                   <CardHeader>
//                     <Typography variant="h6">Weight</Typography>
//                   </CardHeader>
//                   <CardContent>
//                     <Typography variant="h3">{healthData.length > 0 ? `${healthData[healthData.length - 1].weight} kg` : 'N/A'}</Typography>
//                     <Typography variant="body2">{healthData.length > 0 ? `+2.5 kg from last month` : 'N/A'}</Typography>
//                   </CardContent>
//                 </Card>
//                 <Card>
//                   <CardHeader>
//                     <Typography variant="h6">Height</Typography>
//                   </CardHeader>
//                   <CardContent>
//                     <Typography variant="h3">{clinicalHistory?.anthropometry?.heightCm || 'N/A'} cm</Typography>
//                     <Typography variant="body2">No change from last month</Typography>
//                   </CardContent>
//                 </Card>
//                 <Card>
//                   <CardHeader>
//                     <Typography variant="h6">Blood Pressure</Typography>
//                   </CardHeader>
//                   <CardContent>
//                     <Typography variant="h3">{clinicalHistory?.anthropometry?.bloodPressureSystolic}/{clinicalHistory?.anthropometry?.bloodPressureDiastolic} mmHg</Typography>
//                     <Typography variant="body2">Stable from last month</Typography>
//                   </CardContent>
//                 </Card>
//                 <Card>
//                   <CardHeader>
//                     <Typography variant="h6">Blood Sugar</Typography>
//                   </CardHeader>
//                   <CardContent>
//                     <Typography variant="h3">{healthData.length > 0 ? `${healthData[healthData.length - 1].glucose} mg/dL` : 'N/A'}</Typography>
//                     <Typography variant="body2">-10 mg/dL from last month</Typography>
//                   </CardContent>
//                 </Card>
//               </div>
//             </TabPanel>
//             <TabPanel value={value} index={2}>
//               <Card>
//                 <CardHeader>
//                   <Typography variant="h6">Treatment Plan</Typography>
//                   <Typography variant="subtitle1">Details of the patient's current treatment plan.</Typography>
//                 </CardHeader>
//                 <CardContent>
//                   <div className="grid gap-4">
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Medication</Typography>
//                       <Typography variant="h6">Metformin 500mg, twice daily</Typography>
//                     </div>
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Dosage</Typography>
//                       <Typography variant="h6">2 tablets, 2 times per day</Typography>
//                     </div>
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Next Appointment</Typography>
//                       <Typography variant="h6">June 15, 2024</Typography>
//                     </div>
//                     <div className="grid gap-2">
//                       <Typography variant="body2">Notes</Typography>
//                       <Typography variant="h6">Monitor blood sugar levels closely and report any changes.</Typography>
//                     </div>
//                   </div>
//                 </CardContent>
//               </Card>
//             </TabPanel>
//           </Tabs>
//         </div>
//         <div>
//           <Card>
//             <CardHeader>
//               <Typography variant="h6">Recent Appointments</Typography>
//               <Typography variant="subtitle1">A summary of the patient's recent telemedicine appointments.</Typography>
//             </CardHeader>
//             <CardContent>
//               <TableContainer>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>Date</TableCell>
//                       <TableCell>Reason</TableCell>
//                       <TableCell>Provider</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {appointments.map(appointment => (
//                       <TableRow key={appointment.id}>
//                         <TableCell>{formatDate(appointment.date)}</TableCell>
//                         <TableCell>{appointment.reason}</TableCell>
//                         <TableCell>{appointment.provider}</TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </CardContent>
//             <CardActions className="flex items-center justify-end gap-2">
//               <Button size="small" variant="outlined">
//                 View All
//               </Button>
//             </CardActions>
//           </Card>
//         </div>
//       </main>
//     </div>
//   );
// }

// export default Dashboard;
