import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './Banner.css';

const Banner = ({ title, image }) => {
  const { t } = useTranslation();

  return (
    <div className="banner-header">
      <LazyLoadImage
        alt={t(title)}
        effect="blur"
        src={image}
        className="banner-image"
        wrapperClassName="banner-image-wrapper"
      />
      <h1 className="banner-title">{t(title)}</h1>
    </div>
  );
};

export default Banner;
