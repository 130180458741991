// // export default Login;
// import React, { useState } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import './login.css';
// import { useAuth } from '../context/AuthContext'; 
// import Alert from './Alert';
// import axiosInstance from '../utils/axiosInstance';

// function Login() {
//     const navigate = useNavigate(); 
//     const { setUser } = useAuth();
//     const { t } = useTranslation();

//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [isLoading, setIsLoading] = useState(false);
//     const [successMessage, setSuccessMessage] = useState(null);
//     const [errorMessage, setErrorMessage] = useState(null);

//     const handleLoginSubmit = async (e) => {
//         e.preventDefault();
//         if (isLoading) return;
//         setIsLoading(true);

//         const formData = { email, password };
//         try {
//             const response = await axiosInstance.post('/login/', formData);
//             if (response.data.tokens && response.data.tokens.access) {
//                 localStorage.setItem('accessToken', response.data.tokens.access);
//                 localStorage.setItem('refreshToken', response.data.tokens.refresh);
//                 setUser({ token: response.data.tokens.access });
//                 navigate('/dashboard');
//             } else {
//                 throw new Error('No tokens received');
//             }
//         } 
//         catch (error) {
//             console.error('Login failed', error.response || error);
//             if (error.response && error.response.data) {
//                 const errorMessages = Object.values(error.response.data).flat();
//                 setErrorMessage(errorMessages[0]);
//             } else {
//                 setErrorMessage(t('loginError'));
//             }
//             setSuccessMessage(null);
//         }
//         finally {
//             setIsLoading(false);
//         }
//     };

//     const handleAlertClose = () => {
//         setErrorMessage(null);
//         setSuccessMessage(null);
//     };

//     return (
//         <div className="login-container">
//             <form onSubmit={handleLoginSubmit} className="login-form">
//                 {errorMessage && <Alert message={errorMessage} onClose={handleAlertClose} type="danger" />}
//                 {successMessage && <Alert message={successMessage} onClose={handleAlertClose} type="success" />}
//                 <h2>{t('loginTitle')}</h2>
//                 <div className="form-group">
//                     <label htmlFor="email">{t('emailLabel')}</label>
//                     <input
//                         type="text"
//                         id="email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div className="form-group">
//                     <label htmlFor="password">{t('passwordLabel')}</label>
//                     <input
//                         type="password"
//                         id="password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <button type="submit" className="login-button" disabled={isLoading} onClick={handleLoginSubmit}>{t('loginButton')}</button>
//                 <div className="login-links">
//                     <Link to="/register">{t('createAccount')}</Link>
//                     <Link to="/forgot-password">{t('forgotPassword')}</Link>
//                 </div>
//             </form>
//         </div>
//     );
// }

// export default Login;
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './login.css';
import { useAuth } from '../context/AuthContext'; 
import Alert from './Alert';
import axiosInstance from '../utils/axiosInstance';

function Login() {
    const navigate = useNavigate(); 
    const { setUser } = useAuth();
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return;
        setIsLoading(true);

        const formData = { email, password };
        try {
            const response = await axiosInstance.post('/login/', formData);
            if (response.data.tokens && response.data.tokens.access && response.data.role) {
                localStorage.setItem('accessToken', response.data.tokens.access);
                localStorage.setItem('refreshToken', response.data.tokens.refresh);
                localStorage.setItem('role', response.data.role);  // Store the role
                setUser({ 
                    token: response.data.tokens.access, 
                    role: response.data.role 
                });
                navigate('/dashboard');
            } else {
                throw new Error('No tokens or role received');
            }
        } 
        catch (error) {
            console.error('Login failed', error.response || error);
            if (error.response && error.response.data) {
                const errorMessages = Object.values(error.response.data).flat();
                setErrorMessage(errorMessages[0]);
            } else {
                setErrorMessage(t('loginError'));
            }
            setSuccessMessage(null);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleAlertClose = () => {
        setErrorMessage(null);
        setSuccessMessage(null);
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLoginSubmit} className="login-form">
                {errorMessage && <Alert message={errorMessage} onClose={handleAlertClose} type="danger" />}
                {successMessage && <Alert message={successMessage} onClose={handleAlertClose} type="success" />}
                <h2>{t('loginTitle')}</h2>
                <div className="form-group">
                    <label htmlFor="email">{t('emailLabel')}</label>
                    <input
                        type="text"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">{t('passwordLabel')}</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="login-button" disabled={isLoading}>{t('loginButton')}</button>
                <div className="login-links">
                    <Link to="/register">{t('createAccount')}</Link>
                    <Link to="/forgot-password">{t('forgotPassword')}</Link>
                </div>
            </form>
        </div>
    );
}

export default Login;
