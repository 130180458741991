import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { Helmet } from 'react-helmet';

// Array of available error images
const errorImages = [
//   '/images/404_e.webp',
//   '/images/404_background.webp',
  '/images/error_404.webp',
];

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #edf2f4;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  background-color: #1a1a1d;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
`;

const Card = styled.div`
  background-color: rgba(26, 26, 29, 0.8); /* Semi-transparent background */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  width: 100%;
`;

const ErrorTitle = styled.h1`
  font-size: 4.5rem;
  margin: 0;
  color: #e63946;
  font-family: 'Sanfire', sans-serif;
`;

const OopsText = styled.h2`
  font-size: 3.5rem;
  margin: 1rem 0;
  color: #f1faee;
  font-family: 'Sanfire', sans-serif;
`;

const UniverseMessage = styled.p`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #edf2f4;
`;

const FunnyMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #edf2f4;
  font-style: italic;
`;

const SupportText = styled.p`
  font-size: 1rem;
  margin-top: 3rem;
  color: #457b9d;
`;

const EmailLink = styled.a`
  color: #6200EE;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ReturnButton = styled(Link)`
  font-size: 1.5rem;
  color: #6200EE;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  margin-top: 2rem;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    background-color: #e63946;
  }
  50% {
    transform: scale(1.5);
    background-color: #6200EE;
  }
  100% {
    transform: scale(1);
    background-color: #e63946;
  }
`;

const MultiverseAnimation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: ${pulse} 1.5s infinite ease-in-out;
  }
`;

const Error404 = () => {
  const { t } = useTranslation();
  const [randomImage, setRandomImage] = useState('');

  useEffect(() => {
    // Select a random image on component mount
    const randomIndex = Math.floor(Math.random() * errorImages.length);
    setRandomImage(errorImages[randomIndex]);
  }, []);

  return (
    <>
      <Helmet>
        <title>Error 404 - Page Not Found | DoctorElec Telemedicine</title>
        <meta name="description" content="Oops! It seems this page is lost in the digital ether. Return to the homepage or contact our support team." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container backgroundImage={randomImage}>
        <Card>
          <ErrorTitle>ERROR</ErrorTitle>
          <OopsText>Oops...</OopsText>
          <UniverseMessage>{t('error404.universeMessage', 'In this reality, this page doesn’t exist, maybe in another multiverse...')}</UniverseMessage>
          <FunnyMessage>{t('error404.funnyMessage', 'It seems like our interdimensional portal is having some issues...')}</FunnyMessage>
          <ReturnButton to="/">{t('error404.goHome', 'Return to Reality (Home)')}</ReturnButton>
          <SupportText>
            {t('error404.supportMessage', 'If you keep getting lost in the multiverse, contact our support team at ')}
            <EmailLink href="mailto:support@doctorelec.com">support@doctorelec.com</EmailLink>
          </SupportText>
          <MultiverseAnimation>
            <div />
          </MultiverseAnimation>
        </Card>
      </Container>
    </>
  );
};

export default Error404;

