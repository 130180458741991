import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import axiosProfile from '../../utils/axiosProfile';
import axiosPayment from '../../utils/axiosPayment';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DoctorsList.css';

const DoctorCard = ({ doctor, onSelect }) => (
    <div className="card mb-4 doctor-card shadow-sm">
      <div className="card-header bg-white text-primary">
        <h5 className="card-title">Dr. {doctor.first_name} {doctor.last_name}</h5>
      </div>
      <div className="text-center mt-3">
        <img 
          src={doctor.photo ? `http://localhost:5003/uploads/${doctor.photo}` : 'default-image.png'} 
          className="card-img-top rounded-circle doctor-photo mx-auto" 
          alt={`${doctor.first_name} ${doctor.last_name}`} 
          onError={(e) => { e.target.onerror = null; e.target.src = 'default-image.png'; }} 
        />
      </div>
      <div className="card-body text-dark">
        <p className="card-text"><strong>Specialty:</strong> {doctor.medical_speciality}</p>
        {/* <p className="card-text"><strong>Phone:</strong> {doctor.phone}</p> */}
        <p className="card-text"><strong>Address:</strong> {doctor.address}, {doctor.city}, {doctor.state}, {doctor.zipcode}</p>
        <p className="card-text"><strong>Bio:</strong> {doctor.bio}</p>
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-purple text-white" onClick={() => onSelect(doctor)}>Select Doctor</button>
        </div>
      </div>
    </div>
  );

const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [queue, setQueue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();  // Asegúrate de que el usuario está autenticado
  const navigate = useNavigate();

  // Lógica para seleccionar un doctor y redirigir al paciente
  const handleSelectDoctor = async (doctor) => {
    console.log('Selected Doctor:', doctor);  // Monitorizar la información del doctor seleccionado

    if (!user || !user.user_id) {
      setError('User is not authenticated');
      return;
    }

    try {
      const response = await axiosPayment.get(`/get_cards/${user.user_id}`, {
        headers: { Authorization: `Bearer ${user.token}` }
      });

      if (response.data.length > 0) {
        navigate('/paynow', { state: { doctor } });
      } else {
        navigate('/payment', { state: { doctor } });
      }
    } catch (err) {
      console.error('Error checking payment methods:', err);
      setError('Error retrieving payment methods.');
    }
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      if (!user || !user.token) {
        setLoading(false);
        setError('User Doctor is not authenticated');
        return;
      }

      try {
        const response = await axiosProfile.get('/profiles/', {
          params: { role: 'doctor' },
          headers: { Authorization: `Bearer ${user.token}` }
        });

        const activeDoctors = response.data.filter(doctor => doctor.status);

        if (activeDoctors.length === 0) {
          setQueue(prevQueue => [...prevQueue, user.user_id]);
        } else {
          setDoctors(activeDoctors);
        }
        setLoading(false);
      } catch (err) {
        setError('Error fetching doctors');
        setLoading(false);
      }
    };

    fetchDoctors();
  }, [user]);

  useEffect(() => {
    if (queue.length > 0 && doctors.length > 0) {
      const nextPatientId = queue[0]; // Obtener el primer paciente en la cola
      console.log(`Assigning doctor to patient ID: ${nextPatientId}`);
      
      // Notificar al paciente (suponiendo que nextPatientId corresponde al user_id del paciente)
      if (nextPatientId === user.user_id) {
        alert('A doctor is now available for your consultation. You will be redirected.');
        // Redirigir al paciente a la página de selección de doctor o consulta
        navigate('/selectdoctor');
      }
      
      setQueue(prevQueue => prevQueue.slice(1)); // Remover al paciente de la cola
    }
  }, [doctors, queue, user, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="doctors-list container mt-4">
      <div className="row">
        {doctors.length > 0 ? (
          doctors.map(doctor => (
            <div className="col-md-4" key={doctor.id}>
              <DoctorCard doctor={doctor} onSelect={handleSelectDoctor} />
            </div>
          ))
        ) : (
          <div className="col-12">
            <div className="alert alert-warning text-center">
              No doctors are currently available. Please wait or try again later.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DoctorsList;


// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import axiosProfile from '../../utils/axiosProfile';
// import axiosPayment from '../../utils/axiosPayment';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './DoctorsList.css';
// import { useNavigate } from 'react-router-dom';

// const DoctorCard = ({ doctor, onSelect }) => (
//   <div className="card mb-4 doctor-card shadow-sm">
//     <div className="card-header bg-white text-primary">
//       <h5 className="card-title">Dr. {doctor.first_name} {doctor.last_name}</h5>
//     </div>
//     <div className="text-center mt-3">
//       <img 
//         src={doctor.photo ? `http://localhost:5003/uploads/${doctor.photo}` : 'default-image.png'} 
//         className="card-img-top rounded-circle doctor-photo mx-auto" 
//         alt={`${doctor.first_name} ${doctor.last_name}`} 
//         onError={(e) => { e.target.onerror = null; e.target.src = 'default-image.png'; }} 
//       />
//     </div>
//     <div className="card-body text-dark">
//       <p className="card-text"><strong>Specialty:</strong> {doctor.medical_speciality}</p>
//       <p className="card-text"><strong>Phone:</strong> {doctor.phone}</p>
//       <p className="card-text"><strong>Address:</strong> {doctor.address}, {doctor.city}, {doctor.state}, {doctor.zipcode}</p>
//       <p className="card-text"><strong>Bio:</strong> {doctor.bio}</p>
//       <div className="d-flex justify-content-center mt-3">
//         <button className="btn btn-purple text-white" onClick={() => onSelect(doctor)}>Select Doctor</button>
//       </div>
//     </div>
//   </div>
// );

// const DoctorsList = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [queue, setQueue] = useState([]); // ##### NUEVO: Cola de espera para pacientes
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { user } = useAuth();  // Asegúrate de que el usuario está autenticado
//   const navigate = useNavigate();

//   const handleSelectDoctor = async (doctor) => {
//     console.log('Selected Doctor:', doctor);  // Monitorizar la información del doctor seleccionado

//     if (!user || !user.user_id) {
//       setError('User is not authenticated');
//       return;
//     }

//     try {
//       const response = await axiosPayment.get(`/get_cards/${user.user_id}`, {
//         headers: { Authorization: `Bearer ${user.token}` }
//       });

//       if (response.data.length > 0) {
//         navigate('/paynow', { state: { doctor } });
//       } else {
//         navigate('/payment', { state: { doctor } });
//       }
//     } catch (err) {
//       console.error('Error checking payment methods:', err);
//       setError('Error retrieving payment methods.');
//     }
//   };

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       if (!user || !user.token) {
//         setLoading(false);
//         setError('User Doctor is not authenticated');
//         return;
//       }

//       try {
//         const response = await axiosProfile.get('/profiles/', {
//           params: { role: 'doctor' },
//           headers: { Authorization: `Bearer ${user.token}` }
//         });

//         //##### NUEVO: Filtrar doctores que estén activos (status === true)
//         const activeDoctors = response.data.filter(doctor => doctor.status);

//         if (activeDoctors.length === 0) {
//           // Si no hay doctores disponibles, agregar al paciente a la cola
//           setQueue(prevQueue => [...prevQueue, user.user_id]); // ##### NUEVO: Agregar paciente a la cola
//         } else {
//           setDoctors(activeDoctors); // Almacenar solo los doctores activos
//         }
//         setLoading(false);
//       } catch (err) {
//         setError('Error fetching doctors');
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [user]);

//   useEffect(() => {
//     if (queue.length > 0 && doctors.length > 0) {
//       const nextPatientId = queue[0]; // Obtener el primer paciente en la cola
//       console.log(`Assigning doctor to patient ID: ${nextPatientId}`);
//       // Aquí iría la lógica para notificar al paciente o redirigirlo
//       setQueue(prevQueue => prevQueue.slice(1)); // Remover al paciente de la cola
//     }
//   }, [doctors, queue]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="doctors-list container mt-4">
//       <div className="row">
//         {doctors.length > 0 ? (
//           doctors.map(doctor => (
//             <div className="col-md-4" key={doctor.id}>
//               <DoctorCard doctor={doctor} onSelect={handleSelectDoctor} />
//             </div>
//           ))
//         ) : (
//           <div className="col-12">
//             <div className="alert alert-warning text-center">
//               No doctors are currently available. Please wait or try again later.
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default DoctorsList;


// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import axiosProfile from '../../utils/axiosProfile';
// import axiosPayment from '../../utils/axiosPayment';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './DoctorsList.css';
// import { useNavigate } from 'react-router-dom';

// const DoctorCard = ({ doctor, onSelect }) => (
//   <div className="card mb-4 doctor-card shadow-sm">
//     <div className="card-header bg-white text-primary">
//       <h5 className="card-title">Dr. {doctor.first_name} {doctor.last_name}</h5>
//     </div>
//     <div className="text-center mt-3">
//       <img 
//         src={doctor.photo ? `http://localhost:5003/uploads/${doctor.photo}` : 'default-image.png'} 
//         className="card-img-top rounded-circle doctor-photo mx-auto" 
//         alt={`${doctor.first_name} ${doctor.last_name}`} 
//         onError={(e) => { e.target.onerror = null; e.target.src = 'default-image.png'; }} 
//       />
//     </div>
//     <div className="card-body text-dark">
//       <p className="card-text"><strong>Specialty:</strong> {doctor.medical_speciality}</p>
//       <p className="card-text"><strong>Phone:</strong> {doctor.phone}</p>
//       <p className="card-text"><strong>Address:</strong> {doctor.address}, {doctor.city}, {doctor.state}, {doctor.zipcode}</p>
//       <p className="card-text"><strong>Bio:</strong> {doctor.bio}</p>
//       <div className="d-flex justify-content-center mt-3">
//         <button className="btn btn-purple text-white" onClick={() => onSelect(doctor)}>Select Doctor</button>
//       </div>
//     </div>
//   </div>
// );

// const DoctorsList = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { user } = useAuth();  // Asegúrate de que el usuario está autenticado
//   const navigate = useNavigate();

//   const handleSelectDoctor = async (doctor) => {
//     console.log('Selected Doctor:', doctor);  // Monitorizar la información del doctor seleccionado

//     if (!user || !user.user_id) {
//       setError('User is not authenticated');
//       return;
//     }

//     try {
//       const response = await axiosPayment.get(`/get_cards/${user.user_id}`, {
//         headers: { Authorization: `Bearer ${user.token}` }
//       });

//       if (response.data.length > 0) {
//         navigate('/paynow', { state: { doctor } });
//       } else {
//         navigate('/payment', { state: { doctor } });
//       }
//     } catch (err) {
//       console.error('Error checking payment methods:', err);
//       setError('Error retrieving payment methods.');
//     }
//   };

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       if (!user || !user.token) {
//         setLoading(false);
//         setError('User Doctor is not authenticated');
//         return;
//       }

//       try {
//         const response = await axiosProfile.get('/profiles/', {
//           params: { role: 'doctor' },
//           headers: { Authorization: `Bearer ${user.token}` }
//         });

//         //##### NUEVO: Filtrar doctores que estén activos (status === true)
//         const activeDoctors = response.data.filter(doctor => doctor.status);

//         setDoctors(activeDoctors); // Almacenar solo los doctores activos
//         setLoading(false);
//       } catch (err) {
//         setError('Error fetching doctors');
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [user]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="doctors-list container mt-4">
//       <div className="row">
//         {doctors.map(doctor => (
//           <div className="col-md-4" key={doctor.id}>
//             <DoctorCard doctor={doctor} onSelect={handleSelectDoctor} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default DoctorsList;

// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import axiosProfile from '../../utils/axiosProfile';
// import axiosPayment from '../../utils/axiosPayment';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './DoctorsList.css';
// import { useNavigate } from 'react-router-dom';

// const DoctorCard = ({ doctor, onSelect }) => (
//   <div className="card mb-4 doctor-card shadow-sm">
//     <div className="card-header bg-white text-primary">
//       <h5 className="card-title">Dr. {doctor.first_name} {doctor.last_name}</h5>
//     </div>
//     <div className="text-center mt-3">
//       <img 
//         src={doctor.photo ? `http://localhost:5003/uploads/${doctor.photo}` : 'default-image.png'} 
//         className="card-img-top rounded-circle doctor-photo mx-auto" 
//         alt={`${doctor.first_name} ${doctor.last_name}`} 
//         onError={(e) => { e.target.onerror = null; e.target.src = 'default-image.png'; }} 
//       />
//     </div>
//     <div className="card-body text-dark">
//       <p className="card-text"><strong>Specialty:</strong> {doctor.medical_speciality}</p>
//       <p className="card-text"><strong>Phone:</strong> {doctor.phone}</p>
//       <p className="card-text"><strong>Address:</strong> {doctor.address}, {doctor.city}, {doctor.state}, {doctor.zipcode}</p>
//       <p className="card-text"><strong>Bio:</strong> {doctor.bio}</p>
//       <div className="d-flex justify-content-center mt-3">
//         <button className="btn btn-purple text-white" onClick={() => onSelect(doctor)}>Select Doctor</button>
//       </div>
//     </div>
//   </div>
// );

// const DoctorsList = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { user } = useAuth();  // Asegúrate de que el usuario está autenticado
//   const navigate = useNavigate();

//   const handleSelectDoctor = async (doctor) => {
//     console.log('Selected Doctor:', doctor);  // Monitorizar la información del doctor seleccionado

//     if (!user || !user.user_id) {
//       setError('User is not authenticated');
//       return;
//     }

//     try {
//       const response = await axiosPayment.get(`/get_cards/${user.user_id}`, {
//         headers: { Authorization: `Bearer ${user.token}` }
//       });

//       if (response.data.length > 0) {
//         navigate('/paynow', { state: { doctor } });
//       } else {
//         navigate('/payment', { state: { doctor } });
//       }
//     } catch (err) {
//       console.error('Error checking payment methods:', err);
//       setError('Error retrieving payment methods.');
//     }
//   };

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       if (!user || !user.token) {
//         setLoading(false);
//         setError('User Doctor is not authenticated');
//         return;
//       }

//       try {
//         const response = await axiosProfile.get('/profiles/', {
//           params: { role: 'doctor' },
//           headers: { Authorization: `Bearer ${user.token}` }
//         });
//         setDoctors(response.data);
//         setLoading(false);
//       } catch (err) {
//         setError('Error fetching doctors');
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [user]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="doctors-list container mt-4">
//       <div className="row">
//         {doctors.map(doctor => (
//           <div className="col-md-4" key={doctor.id}>
//             <DoctorCard doctor={doctor} onSelect={handleSelectDoctor} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default DoctorsList;

// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import axiosProfile from '../../utils/axiosProfile';  // Asegúrate de que este archivo esté configurado correctamente
// import axiosPayment from '../../utils/axiosPayment';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './DoctorsList.css';  // Crea este archivo para tus estilos personalizados
// import { useNavigate } from 'react-router-dom';

// const DoctorCard = ({ doctor, onSelect }) => (
//   <div className="card mb-4 doctor-card shadow-sm">
//     <div className="card-header bg-white text-primary">
//       <h5 className="card-title">Dr. {doctor.first_name} {doctor.last_name}</h5>
//     </div>
//     <div className="text-center mt-3">
//       <img 
//         src={doctor.photo ? `http://localhost:5003/uploads/${doctor.photo}` : 'default-image.png'} 
//         className="card-img-top rounded-circle doctor-photo mx-auto" 
//         alt={`${doctor.first_name} ${doctor.last_name}`} 
//         onError={(e) => { e.target.onerror = null; e.target.src = 'default-image.png'; }}  // Manejo de error en la carga de imagen
//       />
//     </div>
//     <div className="card-body text-dark">
//       <p className="card-text"><strong>Specialty:</strong> {doctor.medical_speciality}</p>
//       <p className="card-text"><strong>Phone:</strong> {doctor.phone}</p>
//       <p className="card-text"><strong>Address:</strong> {doctor.address}, {doctor.city}, {doctor.state}, {doctor.zipcode}</p>
//       <p className="card-text"><strong>Bio:</strong> {doctor.bio}</p>
//       <div className="d-flex justify-content-center mt-3">
//         <button className="btn btn-purple text-white" onClick={() => onSelect(doctor)}>Select Doctor</button>
//       </div>
//     </div>
//   </div>
// );

// const DoctorsList = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const { user } = useAuth();
//   const navigate = useNavigate();

//   const handleSelectDoctor = async (doctor) => {
//     console.log('Selected Doctor:', doctor);  // Monitorizar la información del doctor seleccionado

//     try {
//       const response = await axiosPayment.get(`/get_cards/${user.user_id}`, {
//         headers: { Authorization: `Bearer ${user.token}` }
//       });

//       if (response.data.length > 0) {
//         navigate('/paynow', { state: { doctor } });
//       } else {
//         navigate('/payment', { state: { doctor } });
//       }
//     } catch (err) {
//       console.error('Error checking payment methods:', err);
//       setError('Error retrieving payment methods.');
//     }
//   };

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         const response = await axiosProfile.get('/profiles/', {
//           params: { role: 'doctor' },
//           headers: { Authorization: `Bearer ${user.token}` }
//         });
//         setDoctors(response.data);
//         setLoading(false);
//       } catch (err) {
//         setError('Error fetching doctors');
//         setLoading(false);
//       }
//     };

//     if (user && user.token) {
//       fetchDoctors();
//     } else {
//       setLoading(false);
//       setError('User is not authenticated');
//     }
//   }, [user]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="doctors-list container mt-4">
//       <div className="row">
//         {doctors.map(doctor => (
//           <div className="col-md-4" key={doctor.id}>
//             <DoctorCard doctor={doctor} onSelect={handleSelectDoctor} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default DoctorsList;

