const services = [
  {
    path: "/therapy",
    title: "Therapy",
    image: "/images/therapy.webp",
    description: "Therapy is a treatment intended to relieve or heal a disorder. It can be beneficial for mental health issues, emotional difficulties, and a wide range of other conditions.",
    keywords: "therapy, mental health, emotional difficulties, mental well-being",
    content: "Therapy is a treatment intended to relieve or heal a disorder. It can be beneficial for mental health issues, emotional difficulties, and a wide range of other conditions. Through various therapeutic approaches, individuals can gain insights, develop coping strategies, and improve their overall mental well-being.",
    sections: [
      {
        title: "Why Choose Therapy?",
        content: "Therapy can help you deal with various mental health issues such as anxiety, depression, and stress. It provides a safe space to talk about your feelings and develop coping strategies. Therapists are trained to help you understand the root causes of your issues and work with you to find effective solutions. Choosing therapy can lead to significant improvements in your emotional and psychological well-being.",
        image: "/images/why-therapy.webp"
      },
      {
        title: "Types of Therapy",
        content: "There are various types of therapy available, including cognitive-behavioral therapy (CBT), psychodynamic therapy, and humanistic therapy. Each type of therapy has its own unique approach and can be tailored to meet your individual needs. For example, CBT focuses on changing negative thought patterns, while psychodynamic therapy explores unconscious motivations. Humanistic therapy emphasizes personal growth and self-fulfillment.",
        image: "/images/types-therapy.webp"
      }
    ]
  },
  {
    path: "/chiropractic",
    title: "Chiropractic",
    image: "/images/chiropractic.webp",
    description: "Chiropractic care is primarily used as a pain relief alternative for muscles, joints, bones, and connective tissue. It involves manual adjustments to correct alignment and improve overall function.",
    keywords: "chiropractic, pain relief, muscles, joints, bones, connective tissue",
    content: "Chiropractic care is primarily used as a pain relief alternative for muscles, joints, bones, and connective tissue. It involves manual adjustments to correct alignment and improve overall function. Chiropractic care can help alleviate chronic pain, enhance mobility, and promote overall wellness.",
    sections: [
      {
        title: "Benefits of Chiropractic",
        content: "Chiropractic care can help relieve pain from conditions such as back pain, neck pain, and headaches. It can also improve mobility and support overall health. Regular chiropractic adjustments can enhance the function of your nervous system, improve posture, and boost your immune system. Many people find that chiropractic care provides effective relief without the need for medication.",
        image: "/images/benefits-chiropractic.webp"
      },
      {
        title: "Chiropractic Techniques",
        content: "Different techniques are used in chiropractic care, including spinal manipulation, soft tissue therapy, and therapeutic exercises. Each technique is designed to address specific issues and promote healing. Spinal manipulation involves applying controlled force to joints to improve alignment, while soft tissue therapy targets muscles and tendons. Therapeutic exercises help strengthen and stabilize the affected areas.",
        image: "/images/techniques-chiropractic.webp"
      }
    ]
  },
  {
    path: "/psychology",
    title: "Psychology",
    image: "/images/psychology.webp",
    description: "Psychology is the scientific study of the mind and behavior. It aims to understand how people think, feel, and behave in various situations.",
    keywords: "psychology, mind, behavior, mental health",
    content: "Psychology is the scientific study of the mind and behavior. It aims to understand how people think, feel, and behave in various situations. Psychological research can provide valuable insights into human nature and help develop interventions to improve mental health and well-being.",
    sections: [
      {
        title: "Understanding Psychology",
        content: "Psychology explores human behavior and mental processes. It helps us understand why we act the way we do and how we can change our behavior to improve our lives. Psychological theories and research can explain a wide range of phenomena, from cognitive development to social interactions. By studying psychology, we can learn how to better manage stress, improve relationships, and make healthier choices.",
        image: "/images/understanding-psychology.webp"
      },
      {
        title: "Branches of Psychology",
        content: "There are many branches of psychology including clinical psychology, cognitive psychology, and developmental psychology. Each branch focuses on different aspects of human behavior and mental processes. Clinical psychology involves diagnosing and treating mental health disorders, while cognitive psychology studies mental functions like memory and perception. Developmental psychology examines how people grow and change over the course of their lives.",
        image: "/images/branches-psychology.webp"
      }
    ]
  },
  {
    path: "/counseling",
    title: "Counseling",
    image: "/images/counseling.webp",
    description: "Counseling provides guidance and support to resolve personal or psychological problems. It helps individuals improve their mental health and well-being.",
    keywords: "counseling, mental health, support, guidance, psychological problems",
    content: "Counseling provides guidance and support to resolve personal or psychological problems. It helps individuals improve their mental health and well-being. Through counseling, you can explore your thoughts and feelings, gain new perspectives, and develop strategies for dealing with life's challenges.",
    sections: [
      {
        title: "Benefits of Counseling",
        content: "Counseling can help you improve mental health and well-being by providing a safe space to discuss your issues. It can help you develop coping strategies and improve your relationships. Counseling can also assist in managing stress, resolving conflicts, and making important life decisions. The support and understanding of a counselor can be invaluable in times of difficulty.",
        image: "/images/benefits-counseling.webp"
      },
      {
        title: "Types of Counseling",
        content: "There are various types of counseling such as individual counseling, group counseling, and family counseling. Each type of counseling is designed to address specific needs and issues. Individual counseling focuses on one-on-one sessions, group counseling involves multiple participants sharing their experiences, and family counseling addresses issues within the family dynamic. Each approach offers unique benefits and can be tailored to your situation.",
        image: "/images/types-counseling.webp"
      }
    ]
  },
  {
    path: "/general-practice",
    title: "General Practice",
    image: "/images/general-practice.webp",
    description: "General practice involves comprehensive medical care for individuals and families. It covers a wide range of health issues and provides preventive care.",
    keywords: "general practice, medical care, preventive care, health issues",
    content: "General practice involves comprehensive medical care for individuals and families. It covers a wide range of health issues and provides preventive care. General practitioners (GPs) are often the first point of contact for patients and play a key role in the healthcare system.",
    sections: [
      {
        title: "Role of General Practitioners",
        content: "General practitioners provide ongoing health care and are often the first point of contact for patients. They diagnose and treat various conditions, manage chronic diseases, and provide preventive care. GPs also coordinate with specialists and other healthcare providers to ensure comprehensive care. They play a vital role in early detection of illnesses and health education.",
        image: "/images/role-general-practitioners.webp"
      },
      {
        title: "Services in General Practice",
        content: "Services in general practice include preventive care, diagnosis, and treatment of various conditions. General practitioners also provide health education and counseling to promote healthy lifestyles. Preventive services may include vaccinations, health screenings, and lifestyle advice. GPs manage a variety of conditions, from acute illnesses to long-term health issues, ensuring patients receive continuous and holistic care.",
        image: "/images/services-general-practice.webp"
      }
    ]
  },
  {
    path: "/physical-therapy",
    title: "Physical Therapy",
    image: "/images/physical-therapy.webp",
    description: "Physical therapy helps improve movement and manage pain through physical techniques. It is often used to recover from injuries and manage chronic conditions.",
    keywords: "physical therapy, movement, pain management, chronic conditions, injuries",
    content: "Physical therapy helps improve movement and manage pain through physical techniques. It is often used to recover from injuries and manage chronic conditions. Physical therapists work with patients to restore function, improve mobility, and prevent disability.",
    sections: [
      {
        title: "Why Physical Therapy?",
        content: "Physical therapy can help recover from injuries, manage chronic conditions, and improve overall physical function. It involves exercises and techniques designed to restore movement and reduce pain. Physical therapists develop personalized treatment plans to address specific needs and goals. Physical therapy can also enhance recovery after surgery and help prevent future injuries.",
        image: "/images/why-physical-therapy.webp"
      },
      {
        title: "Physical Therapy Techniques",
        content: "Techniques in physical therapy include exercises, manual therapy, and modalities such as heat and cold therapy. These techniques are used to improve strength, flexibility, and function. Manual therapy involves hands-on techniques to manipulate muscles and joints, while therapeutic exercises aim to strengthen and stabilize affected areas. Modalities like heat, ice, and electrical stimulation can help reduce pain and inflammation.",
        image: "/images/importance-occupational-therapy.webp"
      }
    ]
  },
  {
    path: "/health-coach",
    title: "Health Coach",
    image: "/images/health-coach.webp",
    description: "Health coaching focuses on helping individuals achieve their health and wellness goals. A health coach provides personalized guidance and support.",
    keywords: "health coach, wellness, health goals, personalized guidance, support",
    content: "Health coaching focuses on helping individuals achieve their health and wellness goals. A health coach provides personalized guidance and support. Health coaching can address various aspects of well-being, including nutrition, exercise, stress management, and lifestyle changes.",
    sections: [
      {
        title: "What is Health Coaching?",
        content: "Health coaching involves personalized guidance to improve lifestyle habits. A health coach works with you to set goals, develop a plan, and provide support to help you achieve better health. Health coaches use motivational techniques and evidence-based strategies to help you make sustainable changes. They can assist with weight management, chronic disease prevention, and overall wellness improvement.",
        image: "/images/health-coaching.webp"
      },
      {
        title: "Benefits of a Health Coach",
        content: "A health coach can provide support, accountability, and strategies for better health. They can help you make sustainable changes to improve your overall well-being. Health coaches empower you to take control of your health, offering encouragement and practical advice. By working with a health coach, you can develop healthier habits, improve your physical fitness, and enhance your quality of life.",
        image: "/images/nutrition.webp"
      }
    ]
  },
  {
    path: "/occupational-therapy",
    title: "Occupational Therapy",
    image: "/images/occupational-therapy.webp",
    description: "Occupational therapy helps individuals participate in daily activities through therapeutic interventions. It focuses on improving physical, cognitive, and emotional skills.",
    keywords: "occupational therapy, daily activities, therapeutic interventions, skills improvement",
    content: "Occupational therapy helps individuals participate in daily activities through therapeutic interventions. It focuses on improving physical, cognitive, and emotional skills. Occupational therapists work with people of all ages to promote independence and enhance quality of life.",
    sections: [
      {
        title: "Importance of Occupational Therapy",
        content: "Occupational therapy helps people regain independence in daily activities. It can be particularly beneficial for individuals with physical disabilities, developmental disorders, or mental health conditions. Occupational therapists assess each person's unique needs and develop customized intervention plans. These plans may include exercises, adaptive equipment, and modifications to the environment.",
        image: "/images/importance-occupational-therapy.webp"
      },
      {
        title: "Occupational Therapy Methods",
        content: "Methods in occupational therapy include adaptive techniques, exercises, and environmental modifications. These methods are used to enhance the ability to perform everyday tasks and improve quality of life. Occupational therapists may teach patients new ways to perform activities, provide assistive devices, and recommend changes to the home or workplace. The goal is to help individuals achieve their fullest potential and lead fulfilling lives.",
        image: "/images/methods-occupational-therapy.webp"
      }
    ]
  },
  {
    path: "/coaching",
    title: "Coaching",
    image: "/images/coaching.webp",
    description: "Coaching helps individuals achieve personal and professional goals through guidance and support. It involves working with a coach to identify and achieve specific objectives.",
    keywords: "coaching, personal goals, professional goals, guidance, support",
    content: "Coaching helps individuals achieve personal and professional goals through guidance and support. It involves working with a coach to identify and achieve specific objectives. Coaching can help you enhance your skills, overcome obstacles, and reach your full potential.",
    sections: [
      {
        title: "What is Coaching?",
        content: "Coaching involves working with a coach to identify and achieve specific goals. It can be used for personal development, career advancement, and improving performance in various areas. Coaches provide feedback, support, and accountability, helping you stay focused and motivated. Whether you're looking to improve your leadership skills, achieve work-life balance, or pursue a new career path, coaching can help you get there.",
        image: "/images/health-coaching.webp"
      },
      {
        title: "Types of Coaching",
        content: "Types of coaching include life coaching, career coaching, and executive coaching. Each type of coaching is designed to address specific needs and help individuals achieve their goals. Life coaching focuses on personal development and well-being, career coaching helps with job search and career transitions, and executive coaching enhances leadership skills and organizational performance. Each approach offers unique benefits and can be tailored to your situation.",
        image: "/images/types-coaching.webp"
      }
    ]
  },
  {
    path: "/mental-health",
    title: "Mental Health",
    image: "/images/mental-health.webp",
    description: "Mental health includes emotional, psychological, and social well-being. It affects how we think, feel, and act, and is important at every stage of life.",
    keywords: "mental health, emotional well-being, psychological well-being, social well-being",
    content: "Mental health includes emotional, psychological, and social well-being. It affects how we think, feel, and act, and is important at every stage of life. Maintaining good mental health is crucial for overall health and well-being.",
    sections: [
      {
        title: "Understanding Mental Health",
        content: "Mental health affects how we think, feel, and act. It also helps determine how we handle stress, relate to others, and make choices. Good mental health is essential for overall well-being. Understanding mental health involves recognizing the signs of mental health issues, seeking help when needed, and adopting healthy habits to support emotional and psychological well-being.",
        image: "/images/understanding-mental-health.webp"
      },
      {
        title: "Improving Mental Health",
        content: "Strategies for improving mental health include therapy, medication, and lifestyle changes. It's important to seek help if you are experiencing mental health issues and to take steps to maintain good mental health. Regular physical activity, a healthy diet, sufficient sleep, and social connections can all contribute to better mental health. Practicing mindfulness, managing stress, and setting realistic goals are also effective strategies.",
        image: "/images/improving-mental-health.webp"
      }
    ]
  },
  {
    path: "/nursing",
    title: "Nursing",
    image: "/images/nursing.webp",
    description: "Nursing involves providing care and support to individuals to maintain or recover health. Nurses play a crucial role in the healthcare system, offering compassionate care and medical expertise.",
    keywords: "nursing, healthcare, patient care, medical support, compassionate care",
    content: "Nursing involves providing care and support to individuals to maintain or recover health. Nurses play a crucial role in the healthcare system, offering compassionate care and medical expertise.",
    sections: [
      {
        title: "Role of Nurses",
        content: "Nurses provide essential care and support to patients. They monitor patient health, administer medications, and collaborate with other healthcare professionals to ensure optimal care. Nurses also educate patients and their families about health conditions and care plans. They are advocates for their patients, working tirelessly to improve health outcomes and enhance quality of life.",
        image: "/images/role-nurses.webp"
      },
      {
        title: "Types of Nursing",
        content: "Types of nursing include registered nursing, licensed practical nursing, and advanced practice nursing. Each type of nursing involves different levels of education and responsibilities. Registered nurses (RNs) provide direct patient care, licensed practical nurses (LPNs) assist with basic nursing tasks, and advanced practice nurses (APNs) have specialized training to provide advanced care. Each role is vital to the healthcare system.",
        image: "/images/types-nursing.webp"
      }
    ]
  },
  {
    path: "/psychiatry",
    title: "Psychiatry",
    image: "/images/psychiatry.webp",
    description: "Psychiatry focuses on the diagnosis, treatment, and prevention of mental, emotional, and behavioral disorders.",
    keywords: "psychiatry, mental health, diagnosis, treatment, prevention",
    content: "Psychiatry focuses on the diagnosis, treatment, and prevention of mental, emotional, and behavioral disorders. Psychiatrists are medical doctors who specialize in mental health, providing comprehensive care for individuals with mental health conditions.",
    sections: [
      {
        title: "What is Psychiatry?",
        content: "Psychiatry involves medical treatment for mental health conditions. Psychiatrists can prescribe medication and provide psychotherapy to help manage mental health issues. They work with patients to develop individualized treatment plans that may include medication management, therapy, and lifestyle changes. Psychiatrists also collaborate with other healthcare professionals to provide holistic care.",
        image: "/images/what-psychiatry.webp"
      },
      {
        title: "Psychiatric Treatments",
        content: "Treatments in psychiatry include medication, psychotherapy, and other interventions. These treatments are designed to help manage symptoms and improve quality of life for individuals with mental health conditions. Common psychiatric treatments include antidepressants, antipsychotics, and mood stabilizers, as well as cognitive-behavioral therapy (CBT) and other forms of psychotherapy.",
        image: "/images/treatments-psychiatry.webp"
      }
    ]
  },
  {
    path: "/personal-training",
    title: "Personal Training",
    image: "/images/personal-training.webp",
    description: "Personal training provides customized fitness programs to help individuals achieve their health and fitness goals.",
    keywords: "personal training, fitness, health goals, customized programs, motivation",
    content: "Personal training provides customized fitness programs to help individuals achieve their health and fitness goals. A personal trainer offers guidance, support, and motivation to help you reach your potential.",
    sections: [
      {
        title: "Benefits of Personal Training",
        content: "Personal training offers personalized exercise programs and motivation. A personal trainer can help you set goals, stay accountable, and achieve better fitness results. They provide expert advice on exercise techniques, nutrition, and lifestyle changes. Personal training can also help you stay motivated and overcome barriers to achieving your fitness goals.",
        image: "/images/benefits-personal-training.webp"
      },
      {
        title: "Choosing a Personal Trainer",
        content: "Consider experience, certifications, and personal compatibility when choosing a trainer. A good personal trainer will provide the support and guidance you need to reach your fitness goals. Look for a trainer who understands your needs, has relevant expertise, and can create a safe and effective exercise plan. Building a positive relationship with your trainer can enhance your fitness journey and help you achieve lasting results.",
        image: "/images/choosing-personal-trainer.webp"
      }
    ]
  },
  {
    path: "/nutrition",
    title: "Nutrition Software",
    image: "/images/nutrition.webp",
    description: "Nutrition involves the study of nutrients and how they affect the body. Proper nutrition is essential for maintaining health and preventing disease.",
    keywords: "nutrition, nutrients, health, disease prevention, balanced diet",
    content: "Nutrition involves the study of nutrients and how they affect the body. Proper nutrition is essential for maintaining health and preventing disease. A balanced diet provides the nutrients your body needs to function properly.",
    sections: [
      {
        title: "Importance of Nutrition",
        content: "Proper nutrition is essential for maintaining health and preventing disease. A balanced diet provides the nutrients your body needs to function properly. Eating a variety of nutrient-rich foods can support overall health, boost energy levels, and reduce the risk of chronic diseases. Key components of a healthy diet include fruits, vegetables, whole grains, lean proteins, and healthy fats.",
        image: "/images/importance.webp"
      },
      {
        title: "Nutritional Guidelines",
        content: "Guidelines include balanced diet, portion control, and nutrient-rich foods. Following these guidelines can help you maintain a healthy weight, reduce the risk of chronic diseases, and promote overall health. It's important to focus on the quality of your food choices and to eat a variety of foods to ensure you get a wide range of nutrients. Staying hydrated and limiting processed foods, added sugars, and unhealthy fats are also important aspects of good nutrition.",
        image: "/images/guidelines-nutrition.webp"
      }
    ]
  },
  {
    path: "/speech-therapy",
    title: "Speech Therapy",
    image: "/images/speech-therapy.webp",
    description: "Speech therapy helps individuals improve communication skills through various techniques.",
    keywords: "speech therapy, communication skills, speech disorders, language disorders",
    content: "Speech therapy helps individuals improve communication skills through various techniques. It can be beneficial for those with speech, language, and communication disorders. Speech therapy can enhance the ability to speak clearly, understand language, and interact effectively with others.",
    sections: [
      {
        title: "Benefits of Speech Therapy",
        content: "Speech therapy can help with speech, language, and communication disorders. It can improve articulation, fluency, voice quality, and comprehension skills. Speech therapists work with individuals to address issues such as stuttering, voice disorders, and language delays. The goal is to enhance communication abilities and improve overall quality of life.",
        image: "/images/benefits-speech-therapy.webp"
      },
      {
        title: "Techniques in Speech Therapy",
        content: "Techniques include exercises, activities, and strategies to improve communication. Speech therapists use a variety of methods to address individual needs and goals. Techniques may involve practicing sounds and words, using visual aids, and engaging in interactive activities. Therapy is tailored to the specific needs of each individual, ensuring personalized and effective care.",
        image: "/images/techniques-speech-therapy.webp"
      }
    ]
  },
  {
    path: "/medical",
    title: "Medical",
    image: "/images/medical.webp",
    description: "Medical services include the diagnosis, treatment, and prevention of diseases and conditions. Medical professionals provide comprehensive care to promote health and well-being.",
    keywords: "medical services, diagnosis, treatment, disease prevention, health care",
    content: "Medical services include the diagnosis, treatment, and prevention of diseases and conditions. Medical professionals provide comprehensive care to promote health and well-being.",
    sections: [
      {
        title: "Medical Services Overview",
        content: "Medical services encompass a wide range of healthcare options, including primary care, specialty care, and emergency services. These services are designed to address various health needs and ensure optimal care. Medical professionals work together to provide coordinated and comprehensive care, focusing on prevention, diagnosis, treatment, and management of health conditions.",
        image: "/images/overview-medical.webp"
      },
      {
        title: "Finding Medical Care",
        content: "Consider factors such as location, specialties, and reviews when choosing medical care. It's important to find a healthcare provider that meets your specific needs and provides quality care. Researching and selecting the right medical care can lead to better health outcomes and greater satisfaction with your healthcare experience. Look for providers who are experienced, have a good reputation, and can offer the services you need.",
        image: "/images/finding-medical.webp"
      }
    ]
  }
];
module.exports = services;
// export default services;
