import React, { useState, useEffect } from 'react';
import { Card, Button, Alert, Spinner, Form } from 'react-bootstrap';
import VideoCall from './VideoCall';
import DoctorOverview from './DoctorOverview';
import PatientOverview from './PatientOverview'; 
import ClinicalHistoryComplete from '../ClinicalHistoryComplete';
import { useAuth } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import PrescriptionForm from '../Doctor/PrescriptionForm'; 
import axiosConsultation from '../../utils/axiosConsultation';
import axiosProfile from '../../utils/axiosProfile';
import { FaVideo, FaCheckCircle, FaDownload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Consultation.css';
import ReactStars from 'react-rating-stars-component';
import { FaStar } from 'react-icons/fa';


const Consultation = () => {
  const { consultationId } = useParams();
  const { user, isDoctor, isPatient } = useAuth();
  const [diagnosticCode, setDiagnosticCode] = useState('');
  const [diagnosticDescription, setDiagnosticDescription] = useState('');
  const [diagnostics, setDiagnostics] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [consultation, setConsultation] = useState(null);
  const [doctor, setDoctor] = useState(null);
  const [patient, setPatient] = useState(null);
  const [consultationState, setConsultationState] = useState('scheduled');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [treatmentNotes, setTreatmentNotes] = useState('');
  const [showRatingForm, setShowRatingForm] = useState(false);
  const [rating, setRating] = useState(0);
  const [testimonial, setTestimonial] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const navigate = useNavigate(); 


  const fetchDiagnostics = async () => {
    try {
        const response = await axiosConsultation.get(`/consultations/${consultationId}/diagnostics`, {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        setDiagnostics(response.data);
    } catch (err) {
        console.error('Error fetching diagnostics:', err);
        setError('Error fetching diagnostics. Please try again later.');
    } finally {
        setLoading(false);
    }
   };

  useEffect(() => {
    const fetchConsultation = async () => {
      try {
        console.log('Fetching consultation details with ID:', consultationId);
        const response = await axiosConsultation.get(`/consultations/${consultationId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        const { data } = response;
        if (!data) {
          setError('Consultation data is not available.');
          setLoading(false);
          return;
        }

        console.log('Consultation data received:', data);
        setConsultation(data);
        setConsultationState(data.status || 'scheduled');
        setTreatmentNotes(data.notes || '');

        // Fetch doctor details
        fetchDoctor(data.doctor_id);
         // Fetch patient details
        fetchPatient(data.patient_id);

        if (data.status === 'completed' && isPatient()){
          setShowRatingForm(true);
        }
        

      } catch (err) {
        console.error('Error fetching consultation:', err.response?.data || err.message);
        setError('Error fetching consultation details. Please try again later.');
        setLoading(false);
      }
    };

    const fetchDoctor = async (doctorId) => {
      try {
        const response = await axiosProfile.get(`/profiles/${doctorId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        const { data } = response;
        if (!data) {
          setError('Doctor data is not available.');
          setLoading(false);
          return;
        }

        console.log('Doctor data received:', data);
        setDoctor(data);
      } catch (err) {
        console.error('Error fetching doctor data:', err.response?.data || err.message);
        setError('Error fetching doctor details. Please try again later.');
        // setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    const fetchPatient = async (patientId) => {  // Nueva función para obtener la información del paciente
      try {
        const response = await axiosProfile.get(`/profiles/${patientId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        const { data } = response;
        if (!data) {
          setError('Patient data is not available.');
          setLoading(false);
          return;
        }

        console.log('Patient data received:', data);
        setPatient(data);
      } catch (err) {
        console.error('Error fetching patient data:', err.response?.data || err.message);
        setError('Error fetching patient details. Please try again later.');
        setLoading(false);
      }
    };

    if (user && consultationId) {
      fetchConsultation();
      fetchDiagnostics(); 
    } else {
      setLoading(false);
    }
  // }, [user, consultationId]);
   
    // Intervalo para chequear si el estado de la consulta cambia a "in progress"
    const intervalId = setInterval(async () => {
      try {
        const response = await axiosConsultation.get(`/consultations/${consultationId}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        const { data } = response;
        if (data.status === 'in progress' && consultationState !== 'in progress') {
          setConsultationState('in progress');
        }
      } catch (err) {
        console.error('Error checking consultation status:', err);
      }
    }, 5000); // Chequear cada 5 segundos

    return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente

  }, [user, consultationId, consultationState]);

  const setAccessTokenCookie = (token) => {
    document.cookie = `accessToken=${token}; Path=/;`;
    //document.cookie = `accessToken=${token}; Secure; HttpOnly; SameSite=Strict; Path=/;`;
    console.log("AccessToken set in cookie:", document.cookie); 
  };

  const handleStartVideoCall = () => {
    console.log('Starting video call...');
    console.log('User Token:', user.token);

    axiosConsultation
      .put(
        `/consultations/${consultationId}`,
        { status: 'in progress' },
        { headers: { Authorization: `Bearer ${user.token}` } }
      )
      .then(() => {
        console.log('Consultation status updated to in progress');

        setConsultationState('in progress');

        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          console.log('Setting access token in cookie:', accessToken);

          setAccessTokenCookie(accessToken); // Mover el token a una cookie

          console.log("All Cookies after setting accessToken:", document.cookie); // Verifica si el accessToken está en las cookies


        }else {
          console.error('Access token not found in localStorage');
        }

        const roomId = `${consultation.id}`;
        const videoCallUrl = `http://localhost:3001/room/${roomId}`;

        // Verificar que solo el doctor y el paciente puedan acceder
        if ((isDoctor() && doctor) || (isPatient() && patient)) {
          window.location.href = videoCallUrl; // Redirige al microfrontend en localhost:3001
        } else {
          setError('You are not authorized to join this video call.');
        }
      })
      .catch((err) => console.error('Error updating consultation status:', err));
  };

  const handleJoinVideoCall = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setAccessTokenCookie(accessToken); // Mover el token a una cookie
    }

    const roomId = `${consultation.id}`;
    const videoCallUrl = `http://localhost:3001/room/${roomId}`;
    window.location.href = videoCallUrl; // Redirige al microfrontend en localhost:3001
  };



  const handleSaveTreatmentNotes = () => {
    axiosConsultation
      .put(
        `/consultations/${consultationId}`,
        {
          notes: treatmentNotes,
          // Cambiar el estado solo si el usuario es un doctor
          status: isDoctor ? 'pending prescription' : consultationState,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      )
      .then(() => {
        if (isDoctor) {
          setConsultationState('pending prescription');
        }
        setAlertMessage('Treatment notes saved successfully.'); // NEW
        setAlertVariant('success'); // NEW
      })
      .catch((err) => console.error('Error saving treatment notes:', err));
      setAlertMessage('Failed to save treatment notes.'); // NEW
      setAlertVariant('danger'); // NEW
  };
  const handleDownloadClinicalHistory = async () => {
    try {
        const response = await axiosConsultation.get(`/consultations/${consultationId}/clinical_history`, {
            responseType: 'blob',
            headers: { Authorization: `Bearer ${user.token}` },  // Asegúrate de enviar el token JWT
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clinical_history.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading clinical history:', error);
        setAlertMessage('Failed to download clinical history.');
        setAlertVariant('danger');
    }
};

  // const handleDownloadClinicalHistory = async () => {
  //   try {
  //     const response = await axiosConsultation.get(`/consultations/${consultationId}/clinical_history`, {
  //       responseType: 'blob',
  //       headers: { Authorization: `Bearer ${user.token}` },
  //     });
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'clinical_history.pdf');
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();
  //   } catch (error) {
  //     console.error('Error downloading clinical history:', error);
  //     setAlertMessage('Failed to download clinical history.'); // NEW
  //     setAlertVariant('danger'); // NEW
  //   }
  // };

  const handleDownloadPrescription = () => {
    // Lógica para descargar la receta
    console.log('Downloading prescription...');
  };
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };
  // console.log("isDoctor:", isDoctor()); // Debe imprimir true si el usuario es doctor, false si no lo es.
  // console.log("isPatient:", isPatient()); // Debe imprimir true si el usuario es paciente, false si no lo es.
  const handleSaveRating = async () => {
    try {
      await axiosConsultation.post(`/consultations/${consultationId}/rating`, {
        rating,
        testimonial,
        doctor_username: doctor.username,  // Enviar el username del doctor
        patient_username: patient.username // Enviar el username del paciente
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setAlertMessage('Your rating has been submitted successfully.'); // NEW
      setAlertVariant('success'); // NEW
      setShowRatingForm(false);
    } catch (err) {
      setAlertMessage('Failed to submit your rating. Please try again later.'); // NEW
      setAlertVariant('danger'); // NEW
    }
  };

  const handleSearchDiagnostic = async (query) => {
    if (!query) {
      // Si el campo de búsqueda está vacío, limpiamos los resultados de búsqueda y retornamos
      setSearchResults([]);
      return;
  }
    try {
        const response = await axiosConsultation.get(`/diagnostics/search`, {
            params: { query },
            headers: { Authorization: `Bearer ${user.token}` },
        });
        console.log('Search results:', response.data); // Asegúrate de que los resultados sean los esperados
        setSearchResults(response.data);
    } catch (err) {
        console.error('Error searching diagnostics:', err);
        setError('Error searching diagnostics. Please try again later.');
    }
};

    const handleAddDiagnostic = async () => {
      try {
          console.log('Diagnostic Code:', diagnosticCode); // Verificar el código de diagnóstico
          console.log('Diagnostic Description:', diagnosticDescription); // Verificar la descripción del diagnóstico

          const response = await axiosConsultation.post(
              `/consultations/${consultationId}/diagnostics`,
              { code: diagnosticCode, description: diagnosticDescription },
              { headers: { Authorization: `Bearer ${user.token}` } }
          );
          setDiagnostics([...diagnostics, response.data]);
          setDiagnosticCode('');
          setDiagnosticDescription('');
          setSearchResults([]);
          fetchDiagnostics();
     } catch (err) {
          console.error('Error adding diagnostic:', err);
          setError('Failed to add diagnostic. Please ensure the ICD-10 code and description are correct.');
      }
    };

    const handleDeleteDiagnostic = async (diagnosticId) => {
      if (!diagnostics.find(diag => diag.id === diagnosticId)) {
          console.error(`Diagnostic with ID ${diagnosticId} not found in state.`);
          return;
      }
  
      // Aquí podría haber una solicitud concurrente o duplicada de eliminación
  
      try {
          await axiosConsultation.delete(
              `/consultations/${consultationId}/diagnostics/${diagnosticId}`,
              { headers: { Authorization: `Bearer ${user.token}` } }
          );
  
          // Si la primera solicitud ya eliminó el diagnóstico, una segunda solicitud fallará aquí
          setDiagnostics(diagnostics.filter(diag => diag.id !== diagnosticId));
          console.log(`Diagnostic with ID: ${diagnosticId} deleted successfully`);
      } catch (err) {
          // Captura el 404 que ocurre cuando el diagnóstico ya no existe
          if (err.response && err.response.status === 404) {
              console.error(`Diagnostic with ID ${diagnosticId} not found. It may have been deleted already.`);
              setDiagnostics(diagnostics.filter(diag => diag.id !== diagnosticId));
          } else {
              console.error('Error deleting diagnostic:', err);
              setError('Failed to delete diagnostic. Please try again later.');
          }
      }
  };
  
  return (
    <div className="consultation-page container mt-5">
      {alertMessage && (
        <Alert variant={alertVariant} onClose={() => setAlertMessage('')} dismissible>
          {alertMessage}
        </Alert>
      )}

     {isPatient() && (
      <h1 className="text-center mb-4 doctor-card-title">
        Consultation with Dr. {doctor?.first_name || 'N/A'} {doctor?.last_name || ''}
      </h1>
     )}
     {isDoctor() && (
      <h1 className="text-center mb-4 doctor-card-title">
        Consultation with patient {patient?.first_name || 'N/A'} {patient?.last_name || ''}
      </h1>
     )}

      <div className="consultation-card m-4 p-4">
      
        {isPatient() && doctor && <DoctorOverview doctor={doctor} />}
        {isDoctor() && patient && <PatientOverview patient={patient} />} 
        {/* {doctor && <DoctorOverview doctor={doctor} />} */}

        <Alert variant="info" className="mt-3">
          Your consultation is currently in the <strong>{consultationState}</strong> state.
        </Alert>

          <div className="video-call-section d-flex flex-column align-items-center mt-4">
            {consultationState === 'scheduled' && (
                <Button variant="primary video-call-button d-flex align-items-center" onClick={handleStartVideoCall}>
                <FaVideo className="video-icon" />
                <span className="video-call-text">Start Video Call</span>
                </Button>
            )}

            {consultationState === 'in progress' && (
                <Button variant="success video-call-button d-flex align-items-center" onClick={handleJoinVideoCall}>
                <FaVideo className="video-icon" />
                <span className="video-call-text">Join Video Call</span>
                </Button>
            )}
          </div>


          {consultationState === 'in progress' && (
            <div>
              <VideoCall doctor={doctor} patient={user} />
            </div>
          )}
          
        {consultationState === 'pending prescription' && (
          <Alert variant="success" className="d-flex align-items-center">
            <FaCheckCircle className="me-2" />
            Consultation with Dr@ {doctor?.first_name} {doctor?.last_name}  is pending for prescription medication.
          </Alert>
        )}


      {consultationState === 'completed' && (
          <Alert variant="success" className="d-flex align-items-center">
            <FaCheckCircle className="me-2" />
            Consultation with Dr@ {doctor?.first_name} {doctor?.last_name}  is completed.
          </Alert>
        )}

        {consultationState === 'completed' && isPatient() && showRatingForm && (
          <div className="consultation-section mt-4">
            <h3>Rate Your Experience</h3>
            <p>We hope your consultation was helpful. Please let us know how it went by rating below.</p>
            <br></br>
            <hr></hr>
            <Form>
              <Form.Group controlId="rating">
                <Form.Label>Review your Dr@ {doctor.first_name} {doctor.last_name}</Form.Label>
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={50}
                  activeColor="gold"
                  color="#9D8DF1" 
                 
                  char="★" 
                  classNames="react-stars-animation" 
                />
              </Form.Group>
     
              <Form.Group controlId="testimonial">
                <Form.Label>Testimonial</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={testimonial}
                  onChange={(e) => setTestimonial(e.target.value)}
                />
              </Form.Group>
              <Button className="mt-3 btn-purple" onClick={handleSaveRating}>
                Submit Review
              </Button>
            </Form>
          </div>
        )}

   {isDoctor() && (
    <div className="consultation-section mt-5 p-4 shadow rounded bg-light">
        <h3 className="mb-4 text-center">Add Diagnostic</h3>
        
        {/* Caja de búsqueda */}
        {consultationState !== 'completed' && (
        <div className="input-group mb-3">
            <Form.Control
                type="text"
                value={diagnosticCode}
                onChange={(e) => {
                    setDiagnosticCode(e.target.value);
                    handleSearchDiagnostic(e.target.value);
                }}
                placeholder="Search diagnostic by code or description"
                className="form-control"
                style={{ fontSize: '1.1rem', padding: '10px' }}
            />
            <Button
                onClick={handleAddDiagnostic}
                className="btn btn-success ms-2"
                style={{ fontSize: '1.1rem', padding: '10px 20px' }}
            >
                Add
            </Button>
        </div>
)}
        {/* Dropdown de resultados */}
        {searchResults.length > 0 && (
            <ul className="list-group mt-2">
                {searchResults.map((result, index) => (
                    <li
                        key={index}
                        className="list-group-item list-group-item-action"
                        style={{ cursor: 'pointer', fontSize: '1rem' }}
                        onClick={() => {
                            setDiagnosticCode(result.code);
                            setDiagnosticDescription(result.description);
                            setSearchResults([]);
                        }}
                    >
                        <strong>{result.code}</strong>: {result.description}
                    </li>
                ))}
            </ul>
        )}

        {/* Lista de diagnósticos actuales */}
        <div className="diagnostics-list mt-4">
            <h4 className="mb-3">Current Diagnostics</h4>
            {diagnostics.length > 0 ? (
                diagnostics.map((diag, index) => (
                    <div
                        key={index}
                        className="alert alert-info d-flex justify-content-between align-items-center"
                        style={{ padding: '10px 15px', fontSize: '1.1rem' }}
                    >
                        <p className="mb-0">
                            <strong>{diag.code}</strong>: {diag.description}
                        </p>
                        {(consultationState === 'scheduled' || consultationState === 'in progress') && (
                          <Button variant="danger" size="sm" onClick={() => handleDeleteDiagnostic(diag.id)}>
                              Delete
                          </Button>
                      )}
                    </div>
                ))
            ) : (
                <p>No diagnostics added yet.</p>
            )}
        </div>
    </div>
)}
       
        {isDoctor() && (
          <div className="consultation-section mt-4">
            <h3>Treatment Notes</h3>
            <Form>
              <Form.Control
                as="textarea"
                rows={3}
                value={treatmentNotes}
                onChange={(e) => setTreatmentNotes(e.target.value)}
                placeholder="Enter treatment notes here..."
                disabled={consultationState === 'pending prescription'}
              />
              {consultationState !== 'pending prescription' && (
                <Button
                  variant="success mt-3"
                  onClick={handleSaveTreatmentNotes}
                >
                  Save Treatment Notes
                </Button>
              )}
            </Form>
          </div>
        )}

        {/* Clinical History Download Section */}
        {isDoctor() && (
          <div className="consultation-section mt-4">
            <h3>Clinical History</h3>
            <Button className="download-button" onClick={handleDownloadClinicalHistory}>
              <FaDownload className="me-2" />
              Download Clinical History
            </Button>
            {/* <ClinicalHistoryComplete /> */}
          </div>
        )}

     
        {isDoctor() && (
          <div className="consultation-section mt-4">
            <h3>Prescription</h3>
            {consultationState === 'pending prescription' && (
              <>
                <Button className="download-button" onClick={handleDownloadPrescription}>
                  <FaDownload className="me-2" />
                  Create Prescription
                </Button>
                <PrescriptionForm />
              </>
            )}
          </div>
        )}
 
      </div>
    </div>
  );
};

export default Consultation;

