import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './EditProfile.css';
import './LightMode.css';
import './DarkMode.css';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/AuthContext';
import axiosProfile from '../utils/axiosProfile';
import { Modal, Button, Card } from 'react-bootstrap';

function EditProfile() {
    const { user, isPatient, isDoctor } = useAuth();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        bio: '',
        photo: null,
        address: '',
        city: '',
        state: '',
        zipcode: '',
        occupation: '',
        medical_speciality: '',
        license_number: '',
        issue_date: '',
        license_type: '',
        certification: null,
        accreditation: '',
        x_username: '',
        linkedin_username: '',
        facebook_username: '',
        instagram_username: '',
    });
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const { darkMode } = useTheme();
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axiosProfile.get('/profiles/', {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                    },
                });
                const profileData = response.data;

                const socialLinks = profileData.social_links || {};
                setFormData({
                    ...profileData,
                    x_username: socialLinks.x || '',
                    linkedin_username: socialLinks.linkedin || '',
                    facebook_username: socialLinks.facebook || '',
                    instagram_username: socialLinks.instagram || '',
                });
                
                if (profileData.photo) {
                    setProfilePhoto(`http://127.0.0.1:5003/uploads/${profileData.photo}`);
                }
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };
        if (user) {
            fetchProfile();
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value || '',
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: file,
            }));
            if (e.target.name === 'photo') {
                const previewUrl = URL.createObjectURL(file);
                setProfilePhoto(previewUrl);
            }
        } else {
            alert("Please select a valid image file (png or jpg).");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (formData[key] !== null && formData[key] !== '' && formData[key] !== undefined) {
                if (key === 'issue_date' && formData[key]) {
                    const date = new Date(formData[key]);
                    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                    data.append(key, formattedDate);
                } else {
                    data.append(key, formData[key]);
                }
            }
        });
        const socialLinks = [
            { platform: 'x', username: formData.x_username },
            { platform: 'linkedin', username: formData.linkedin_username },
            { platform: 'facebook', username: formData.facebook_username },
            { platform: 'instagram', username: formData.instagram_username },
        ];

        socialLinks.forEach(link => {
            if (link.username) {
                // new: agregar doctor_username en lugar de profile_id
                data.append('social_links', JSON.stringify({ ...link, doctor_username: user.username })); // new
            }
        });
    
        // socialLinks.forEach(link => {
        //     if (link.username) {
        //         data.append('social_links', JSON.stringify(link));
        //     }
        // });

        if (user) {
            data.append('user_id', user.user_id);
            data.append('username', user.username);
            data.append('role', user.role);
        } else {
            console.error('User data is not available');
        }

        try {
            const response = await axiosProfile.put('/profiles/', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`,
                },
            });
            console.log(response.data);
            setIsEditing(false);
            navigate('/dashboard');
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };

    const handleDelete = async () => {
        try {
            await axiosProfile.delete('/profiles/', {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
            });
            console.log("Profile deleted");
            navigate('/signup');
        } catch (error) {
            console.error("Error deleting profile:", error);
        }
        setShowModal(false);
    };

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handlePhotoClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className={`container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className={`card mx-auto ${darkMode ? 'dark-mode' : 'light-mode'}`} style={{ maxWidth: '600px' }}>
                <div className="card-body">
                    <h2 className={`card-title text-center ${darkMode ? 'dark-mode' : 'light-mode'}`}>Settings</h2>
                    {profilePhoto && (
                        <div className="text-center mb-3">
                            <img src={profilePhoto} alt="Profile" className="profile-photo-edit rounded-circle" onClick={handlePhotoClick} style={{ cursor: 'pointer' }} />
                            <input
                                type="file"
                                className="d-none"
                                ref={fileInputRef}
                                id="photo"
                                name="photo"
                                accept="image/*"
                                onChange={handleFileChange}
                            />


                            <h3 className="mt-2">@{user.username}</h3>
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <Card className="mb-3 bg-white text-purple">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h3>Personal Information</h3>
                            </Card.Header>
                            <Card.Body>
                                {isEditing ? (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="first_name" className="form-label">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="first_name"
                                                name="first_name"
                                                value={formData.first_name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="last_name" className="form-label">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="last_name"
                                                name="last_name"
                                                value={formData.last_name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="bio" className="form-label">Bio</label>
                                            <textarea
                                                className="form-control"
                                                id="bio"
                                                name="bio"
                                                value={formData.bio}
                                                onChange={handleChange}
                                                rows="3"
                                                required
                                            ></textarea>
                                        </div>
                                        {isPatient() && (
                                            <div className="mb-3">
                                                <label htmlFor="occupation" className="form-label">Occupation</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="occupation"
                                                    name="occupation"
                                                    value={formData.occupation}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <p><strong>First Name: </strong>{formData.first_name}</p>
                                        <p><strong>Last Name: </strong>{formData.last_name}</p>
                                        <p><strong>Bio: </strong>{formData.bio}</p>
                                        {isPatient() && (
                                            <p><strong>Occupation: </strong>{formData.occupation}</p>
                                        )}
                                    </>
                                )}
                            </Card.Body>
                        </Card>

                        <Card className="mb-3 bg-white text-purple">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h3>Contact Information</h3>
                            </Card.Header>
                            <Card.Body>
                                {isEditing ? (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                name="address"
                                                value={formData.address}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="city"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="state" className="form-label">State</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="state"
                                                name="state"
                                                value={formData.state}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="zipcode" className="form-label">Zip Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="zipcode"
                                                name="zipcode"
                                                value={formData.zipcode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p><strong>Address: </strong>{formData.address}</p>
                                        <p><strong>City: </strong>{formData.city}</p>
                                        <p><strong>State: </strong>{formData.state}</p>
                                        <p><strong>Zip Code: </strong>{formData.zipcode}</p>
                                    </>
                                )}
                            </Card.Body>
                        </Card>

                        {isDoctor() && (
                            <Card className="mb-3 bg-white text-purple">
                                <Card.Header className="d-flex justify-content-between align-items-center">
                                    <h3>Professional Information</h3>
                                </Card.Header>
                                <Card.Body>
                                    {isEditing ? (
                                        <>
                                            <div className="mb-3">
                                                <label htmlFor="medical_speciality" className="form-label">Medical Speciality</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="medical_speciality"
                                                    name="medical_speciality"
                                                    value={formData.medical_speciality}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="license_number" className="form-label">License Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="license_number"
                                                    name="license_number"
                                                    value={formData.license_number}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="issue_date" className="form-label">Issue Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="issue_date"
                                                    name="issue_date"
                                                    value={formData.issue_date}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="license_type" className="form-label">License Type</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="license_type"
                                                    name="license_type"
                                                    value={formData.license_type}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="certification" className="form-label">Certification</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="certification"
                                                    name="certification"
                                                    accept="image/*"
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="accreditation" className="form-label">Accreditation</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="accreditation"
                                                    name="accreditation"
                                                    value={formData.accreditation}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <p><strong>Medical Speciality: </strong>{formData.medical_speciality}</p>
                                            <p><strong>License Number: </strong>{formData.license_number}</p>
                                            <p><strong>Issue Date: </strong>{formData.issue_date}</p>
                                            <p><strong>License Type: </strong>{formData.license_type}</p>
                                            <p><strong>Certification: </strong>{formData.certification}</p>
                                            <p><strong>Accreditation: </strong>{formData.accreditation}</p>
                                        </>
                                    )}
                                </Card.Body>
                            </Card>
                        )}

                        {isDoctor() && (
                            <Card className="mb-3 bg-white text-purple">
                                <Card.Header className="d-flex justify-content-between align-items-center">
                                    <h3>Social Media</h3>
                                </Card.Header>
                                <Card.Body>
                                    {isEditing ? (
                                        <>
                                            <div className="mb-3">
                                                <label htmlFor="x_username" className="form-label">X Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="x_username"
                                                    name="x_username"
                                                    value={formData.x_username}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="linkedin_username" className="form-label">LinkedIn Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="linkedin_username"
                                                    name="linkedin_username"
                                                    value={formData.linkedin_username}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="facebook_username" className="form-label">Facebook Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="facebook_username"
                                                    name="facebook_username"
                                                    value={formData.facebook_username}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="instagram_username" className="form-label">Instagram Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="instagram_username"
                                                    name="instagram_username"
                                                    value={formData.instagram_username}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <p><strong>X: </strong>{formData.x_username}</p>
                                            <p><strong>LinkedIn: </strong>{formData.linkedin_username}</p>
                                            <p><strong>Facebook: </strong>{formData.facebook_username}</p>
                                            <p><strong>Instagram: </strong>{formData.instagram_username}</p>
                                        </>
                                    )}
                                </Card.Body>
                            </Card>
                        )}

                        <div className="card-footer d-flex justify-content-between align-items-center">
                            <Button variant="danger" onClick={() => setShowModal(true)}>Delete Profile</Button>
                            <div>
                                <Button variant="light" onClick={toggleEdit}>{isEditing ? 'Cancel' : 'Edit'}</Button>
                                {isEditing && <Button variant="purple" type="submit" className="ml-2">Save Changes</Button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete your profile? This action cannot be undone.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default EditProfile;

// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './EditProfile.css';
// import './LightMode.css';
// import './DarkMode.css';
// import { useTheme } from '../context/ThemeContext';
// import { useAuth } from '../context/AuthContext';
// import axiosProfile from '../utils/axiosProfile';
// import { Modal, Button, Card } from 'react-bootstrap';

// function EditProfile() {
//     const { user, isPatient, isDoctor } = useAuth();
//     const [formData, setFormData] = useState({
//         first_name: '',
//         last_name: '',
//         bio: '',
//         photo: null,
//         address: '',
//         city: '',
//         state: '',
//         zipcode: '',
//         occupation: '',
//         medical_speciality: '',
//         license_number: '',
//         issue_date: '',
//         license_type: '',
//         certification: null,
//         accreditation: '',
//         twitter_username: '',
//         linkedin_username: '',
//         facebook_username: '',
//         instagram_username: '',
//     });
//     const [profilePhoto, setProfilePhoto] = useState(null);
//     const [isEditing, setIsEditing] = useState(false);
//     const [showModal, setShowModal] = useState(false);
//     const navigate = useNavigate();
//     const { darkMode } = useTheme();
//     const fileInputRef = useRef(null); // Crear la referencia al input de archivo

//     useEffect(() => {
//         const fetchProfile = async () => {
//             try {
//                 const response = await axiosProfile.get('/profiles/', {
//                     headers: {
//                         'Authorization': `Bearer ${user.token}`,
//                     },
//                 });
//                 const profileData = response.data;
//                 // setFormData(profileData);
//                 setFormData({
//                     ...profileData,
//                     twitter_username: profileData.twitter_username || '',
//                     linkedin_username: profileData.linkedin_username || '',
//                     facebook_username: profileData.facebook_username || '',
//                     instagram_username: profileData.instagram_username || '',
//                 });

//                 if (profileData.photo) {
//                     setProfilePhoto(`http://127.0.0.1:5003/uploads/${profileData.photo}`);
//                 }
//             } catch (error) {
//                 console.error("Error fetching profile:", error);
//             }
//         };
//         if (user) {
//             fetchProfile();
//         }
//     }, [user]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value || '',  // Asegurarse de que no se pase null
//         }));
//     };

//     const handleFileChange = (e) => {
//         const file = e.target.files[0];
//         if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
//             setFormData((prevData) => ({
//                 ...prevData,
//                 [e.target.name]: file,
//             }));
//             if (e.target.name === 'photo') {
//                 const previewUrl = URL.createObjectURL(file);
//                 setProfilePhoto(previewUrl);
//             }
//         } else {
//             alert("Please select a valid image file (png or jpg).");
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const data = new FormData();
//         Object.keys(formData).forEach((key) => {
//             if (formData[key] !== null && formData[key] !== '' && formData[key] !== undefined) {
//                 if (key === 'issue_date' && formData[key]) {
//                     const date = new Date(formData[key]);
//                     const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
//                     data.append(key, formattedDate);
//                 } else {
//                     data.append(key, formData[key]);
//                 }
//             }
//         });

//         if (user) {
//             data.append('user_id', user.user_id);
//             data.append('username', user.username);
//             data.append('role', user.role);
//         } else {
//             console.error('User data is not available');
//         }

//         try {
//             const response = await axiosProfile.put('/profiles/', data, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                     'Authorization': `Bearer ${user.token}`,
//                 },
//             });
//             console.log(response.data);
//             setIsEditing(false);
//             navigate('/dashboard');
//         } catch (error) {
//             console.error("Error updating profile:", error);
//         }
//     };

//     const handleDelete = async () => {
//         try {
//             await axiosProfile.delete('/profiles/', {
//                 headers: {
//                     'Authorization': `Bearer ${user.token}`,
//                 },
//             });
//             console.log("Profile deleted");
//             navigate('/signup');
//         } catch (error) {
//             console.error("Error deleting profile:", error);
//         }
//         setShowModal(false);
//     };

//     const toggleEdit = () => {
//         setIsEditing(!isEditing);
//     };

//     const handlePhotoClick = () => {
//         fileInputRef.current?.click(); // Usar optional chaining para manejar el clic de manera segura
//     };

//     return (
//         <div className={`container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//             <div className={`card mx-auto ${darkMode ? 'dark-mode' : 'light-mode'}`} style={{ maxWidth: '600px' }}>
//                 <div className="card-body">
//                     <h2 className={`card-title text-center ${darkMode ? 'dark-mode' : 'light-mode'}`}>Settings</h2>
//                     {profilePhoto && (
//                         <div className="text-center mb-3">
//                             <img src={profilePhoto} alt="Profile" className="profile-photo-edit rounded-circle" onClick={handlePhotoClick} style={{ cursor: 'pointer' }} />
//                             <input
//                                 type="file"
//                                 className="d-none"
//                                 ref={fileInputRef} // Referenciar el input de archivo
//                                 id="photo"
//                                 name="photo"
//                                 accept="image/*"
//                                 onChange={handleFileChange}
//                             />
//                         </div>
//                     )}
//                     <form onSubmit={handleSubmit}>
//                         <Card className="mb-3 bg-white text-purple">
//                             <Card.Header className="d-flex justify-content-between align-items-center">
//                                 <h3>Personal Information</h3>
//                             </Card.Header>
//                             <Card.Body>
//                                 {isEditing ? (
//                                     <>
//                                         <div className="mb-3">
//                                             <label htmlFor="first_name" className="form-label">First Name</label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="first_name"
//                                                 name="first_name"
//                                                 value={formData.first_name}
//                                                 onChange={handleChange}
//                                                 required
//                                             />
//                                         </div>
//                                         <div className="mb-3">
//                                             <label htmlFor="last_name" className="form-label">Last Name</label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="last_name"
//                                                 name="last_name"
//                                                 value={formData.last_name}
//                                                 onChange={handleChange}
//                                                 required
//                                             />
//                                         </div>
//                                         <div className="mb-3">
//                                             <label htmlFor="bio" className="form-label">Bio</label>
//                                             <textarea
//                                                 className="form-control"
//                                                 id="bio"
//                                                 name="bio"
//                                                 value={formData.bio}
//                                                 onChange={handleChange}
//                                                 rows="3"
//                                                 required
//                                             ></textarea>
//                                         </div>
//                                         {isPatient() && (
//                                             <div className="mb-3">
//                                                 <label htmlFor="occupation" className="form-label">Occupation</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="occupation"
//                                                     name="occupation"
//                                                     value={formData.occupation}
//                                                     onChange={handleChange}
//                                                     required
//                                                 />
//                                             </div>
//                                         )}
//                                     </>
//                                 ) : (
//                                     <>
//                                         <p><strong>First Name: </strong>{formData.first_name}</p>
//                                         <p><strong>Last Name: </strong>{formData.last_name}</p>
//                                         <p><strong>Bio: </strong>{formData.bio}</p>
//                                         {isPatient() && (
//                                             <p><strong>Occupation: </strong>{formData.occupation}</p>
//                                         )}
//                                     </>
//                                 )}
//                             </Card.Body>
//                         </Card>

//                         <Card className="mb-3 bg-white text-purple">
//                             <Card.Header className="d-flex justify-content-between align-items-center">
//                                 <h3>Contact Information</h3>
//                             </Card.Header>
//                             <Card.Body>
//                                 {isEditing ? (
//                                     <>
//                                         <div className="mb-3">
//                                             <label htmlFor="address" className="form-label">Address</label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="address"
//                                                 name="address"
//                                                 value={formData.address}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                         <div className="mb-3">
//                                             <label htmlFor="city" className="form-label">City</label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="city"
//                                                 name="city"
//                                                 value={formData.city}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                         <div className="mb-3">
//                                             <label htmlFor="state" className="form-label">State</label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="state"
//                                                 name="state"
//                                                 value={formData.state}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                         <div className="mb-3">
//                                             <label htmlFor="zipcode" className="form-label">Zip Code</label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="zipcode"
//                                                 name="zipcode"
//                                                 value={formData.zipcode}
//                                                 onChange={handleChange}
//                                             />
//                                         </div>
//                                     </>
//                                 ) : (
//                                     <>
//                                         <p><strong>Address: </strong>{formData.address}</p>
//                                         <p><strong>City: </strong>{formData.city}</p>
//                                         <p><strong>State: </strong>{formData.state}</p>
//                                         <p><strong>Zip Code: </strong>{formData.zipcode}</p>
//                                     </>
//                                 )}
//                             </Card.Body>
//                         </Card>

//                         {isDoctor() && (
//                             <Card className="mb-3 bg-white text-purple">
//                                 <Card.Header className="d-flex justify-content-between align-items-center">
//                                     <h3>Professional Information</h3>
//                                 </Card.Header>
//                                 <Card.Body>
//                                     {isEditing ? (
//                                         <>
//                                             <div className="mb-3">
//                                                 <label htmlFor="medical_speciality" className="form-label">Medical Speciality</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="medical_speciality"
//                                                     name="medical_speciality"
//                                                     value={formData.medical_speciality}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="mb-3">
//                                                 <label htmlFor="license_number" className="form-label">License Number</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="license_number"
//                                                     name="license_number"
//                                                     value={formData.license_number}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="mb-3">
//                                                 <label htmlFor="issue_date" className="form-label">Issue Date</label>
//                                                 <input
//                                                     type="date"
//                                                     className="form-control"
//                                                     id="issue_date"
//                                                     name="issue_date"
//                                                     value={formData.issue_date}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="mb-3">
//                                                 <label htmlFor="license_type" className="form-label">License Type</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="license_type"
//                                                     name="license_type"
//                                                     value={formData.license_type}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="mb-3">
//                                                 <label htmlFor="certification" className="form-label">Certification</label>
//                                                 <input
//                                                     type="file"
//                                                     className="form-control"
//                                                     id="certification"
//                                                     name="certification"
//                                                     accept="image/*"
//                                                     onChange={handleFileChange}
//                                                 />
//                                             </div>
//                                             <div className="mb-3">
//                                                 <label htmlFor="accreditation" className="form-label">Accreditation</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="accreditation"
//                                                     name="accreditation"
//                                                     value={formData.accreditation}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                         </>
//                                     ) : (
//                                         <>
//                                             <p><strong>Medical Speciality: </strong>{formData.medical_speciality}</p>
//                                             <p><strong>License Number: </strong>{formData.license_number}</p>
//                                             <p><strong>Issue Date: </strong>{formData.issue_date}</p>
//                                             <p><strong>License Type: </strong>{formData.license_type}</p>
//                                             <p><strong>Certification: </strong>{formData.certification}</p>
//                                             <p><strong>Accreditation: </strong>{formData.accreditation}</p>
//                                         </>
//                                     )}
//                                 </Card.Body>
//                             </Card>
                            
//                         )}
//                         {isDoctor() && (
//                             <>
//                                 <Card className="mb-3 bg-white text-purple">
//                                     <Card.Header className="d-flex justify-content-between align-items-center">
//                                         <h3>Social Media</h3>
//                                     </Card.Header>
//                                     <Card.Body>
//                                         {isEditing ? (
//                                             <>
//                                                 <div className="mb-3">
//                                                     <label htmlFor="twitter_username" className="form-label">Twitter Username</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         id="twitter_username"
//                                                         name="twitter_username"
//                                                         value={formData.twitter_username}
//                                                         onChange={handleChange}
//                                                     />
//                                                 </div>
//                                                 <div className="mb-3">
//                                                     <label htmlFor="linkedin_username" className="form-label">LinkedIn Username</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         id="linkedin_username"
//                                                         name="linkedin_username"
//                                                         value={formData.linkedin_username}
//                                                         onChange={handleChange}
//                                                     />
//                                                 </div>
//                                                 <div className="mb-3">
//                                                     <label htmlFor="facebook_username" className="form-label">Facebook Username</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         id="facebook_username"
//                                                         name="facebook_username"
//                                                         value={formData.facebook_username}
//                                                         onChange={handleChange}
//                                                     />
//                                                 </div>
//                                                 <div className="mb-3">
//                                                     <label htmlFor="instagram_username" className="form-label">Instagram Username</label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         id="instagram_username"
//                                                         name="instagram_username"
//                                                         value={formData.instagram_username}
//                                                         onChange={handleChange}
//                                                     />
//                                                 </div>
//                                             </>
//                                         ) : (
//                                             <>
//                                                 <p><strong>Twitter: </strong>{formData.twitter_username}</p>
//                                                 <p><strong>LinkedIn: </strong>{formData.linkedin_username}</p>
//                                                 <p><strong>Facebook: </strong>{formData.facebook_username}</p>
//                                                 <p><strong>Instagram: </strong>{formData.instagram_username}</p>
//                                             </>
//                                         )}
//                                     </Card.Body>
//                                 </Card>
//                          )};
//                         <div className="card-footer d-flex justify-content-between align-items-center">
//                             <Button variant="danger" onClick={() => setShowModal(true)}>Delete Profile</Button>
//                             <div>
//                                 <Button variant="light" onClick={toggleEdit}>{isEditing ? 'Cancel' : 'Edit'}</Button>
//                                 {isEditing && <Button variant="purple" type="submit" className="ml-2">Save Changes</Button>}
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>

//             <Modal show={showModal} onHide={() => setShowModal(false)}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Confirm Deletion</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>Are you sure you want to delete your profile? This action cannot be undone.</Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
//                     <Button variant="danger" onClick={handleDelete}>Delete</Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// }

// export default EditProfile;

