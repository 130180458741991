import React from 'react';
import { useTranslation } from 'react-i18next';
import './Features.css';
import { useTheme } from '../context/ThemeContext';
import DoctorElecSVG from '../components/circulo.svg'; // Ajusta la ruta al SVG
import SEO from './SEO';

function Features() {
  const { darkMode } = useTheme();
  const { t } = useTranslation();

  return (
    <div id="features" className={`features-section ${darkMode ? 'dark-mode' : 'light-mode'}`}>
{/*    
      <SEO path="/features" /> */}
      <SEO />
      <div className="container">
        <div className="section-title">
          <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('featuresTitle')}</h2>
          <hr className="divider"/>
         
          <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('featuresSubtitle')}</p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon" style={{ position: 'relative' }}>
                <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
                <span style={{ position: 'relative', zIndex: '1' }}>📹</span>
              </div>
              <h4><a href="#">{t('realTimeConsultations')}</a></h4>
              <p>{t('realTimeConsultationsContent')}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon" style={{ position: 'relative' }}>
                <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
                <span style={{ position: 'relative', zIndex: '1' }}>🤖</span>
              </div>
              <h4><a href="#">{t('aiDiagnostics')}</a></h4>
              <p>{t('aiDiagnosticsContent')}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon" style={{ position: 'relative' }}>
                <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
                <span style={{ position: 'relative', zIndex: '1' }}>📄</span>
              </div>
              <h4><a href="#">{t('downloadPrescription')}</a></h4>
              <p>{t('downloadPrescriptionContent')}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon" style={{ position: 'relative' }}>
                <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
                <span style={{ position: 'relative', zIndex: '1' }}>🔍</span>
              </div>
              <h4><a href="#">{t('diseasePrevention')}</a></h4>
              <p>{t('diseasePreventionContent')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;

// import React from 'react';
// import './Features.css';
// import { useTheme } from '../context/ThemeContext';
// import DoctorElecSVG from '../components/circulo.svg'; // Ajusta la ruta al SVG

// function Features() {
//   const { darkMode } = useTheme();

//   return (
//     <div id="features" className={`features-section ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      
//       <div className="container">
//         <div className="section-title">
//           <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>Features</h2>
//           <hr className="divider"/>
         
//           <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>Discover the powerful features of our telemedicine platform that help you manage your health efficiently.</p>
//         </div>
//         <div className="row">
//           <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
//             <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="icon" style={{ position: 'relative' }}>
//                 <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
//                 <span style={{ position: 'relative', zIndex: '1' }}>📹</span>
//               </div>
//               <h4><a href="#">Real-time Medical Consultations</a></h4>
//               <p>Connect with your doctor from home in real-time</p>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
//             <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="icon" style={{ position: 'relative' }}>
//                 <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
//                 <span style={{ position: 'relative', zIndex: '1' }}>🤖</span>
//               </div>
//               <h4><a href="#">AI-Assisted Diagnostics</a></h4>
//               <p>Get accurate diagnoses with the help of AI</p>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
//             <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="icon" style={{ position: 'relative' }}>
//                 <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
//                 <span style={{ position: 'relative', zIndex: '1' }}>📄</span>
//               </div>
//               <h4><a href="#">Download Your Prescription</a></h4>
//               <p>Receive and download your prescriptions online</p>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
//             <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="icon" style={{ position: 'relative' }}>
//                 <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
//                 <span style={{ position: 'relative', zIndex: '1' }}>🔍</span>
//               </div>
//               <h4><a href="#">Disease Prevention and Monitoring</a></h4>
//               <p>Monitor and manage your health to prevent diseases</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Features;
