import styled, { keyframes, css } from 'styled-components';

// if applied to a persistent component, make sure to remove when animation is not imminent
// to prevent taking up too many browser resources with will-change

const shouldForwardProp = (prop) => !['darkMode', 'duration', 'absolute', 'noMarginBottom'].includes(prop);

export const promoteLayer = css`
  will-change: transform;
`;

const getDropdownRootKeyFrame = ({ animatingOut }) => {
  return keyframes`
    from {
      transform: ${animatingOut ? "translateY(0)" : "translateY(-10px)"};
      opacity: ${animatingOut ? 1 : 0};
    }
    to {
      transform: ${animatingOut ? "translateY(-10px)" : "translateY(0)"};
      opacity: ${animatingOut ? 0 : 1};
    }
  `;
};

export const DropdownRoot = styled.div`
  transform-origin: top;
  ${promoteLayer}
  animation-name: ${getDropdownRootKeyFrame};
  animation-duration: ${props => props.duration}ms;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -20px;
`;

export const Caret = styled.div`
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--white);
  z-index: 1;
  position: relative;
  top: 1px;
`;

export const DropdownBackground = styled.div`
  transform-origin: top;
  background-color: var(--white);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1);
  ${promoteLayer}
`;

export const AltBackground = styled.div`
  background-color: var(--grey);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top;
  z-index: 0;
  transition: opacity ${props => props.duration}ms;
`;

export const InvertedDiv = styled.div`
  ${promoteLayer}
  position: relative;
  top: 0;
  left: 0;
  &:first-of-type {
    z-index: 1;
  }
  &:not(:first-of-type) {
    z-index: -1;
  }
`;

export const Heading = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0;
  margin-bottom: ${({ $noMarginBottom }) => ($noMarginBottom ? 0 : '1rem')};
  color: ${({ color }) => (color ? `var(--${color})` : "var(--blue)")};
`;

export const HeadingLink = styled.li`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 0;
  margin-bottom: ${props => (props.noMarginBottom ? 0 : "1rem")};
  color: ${({ color }) => (color ? `var(--${color})` : "var(--blue)")};
`;

export const LinkList = styled.ul`
  li {
    margin-bottom: 1rem;
  }

  li:last-of-type {
    margin-bottom: 0;
  }
  
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)};
`;

export const Icon = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 13px;
  background-color: var(--blue);
  opacity: 0.8;
  display: inline-block;
`;

export const DropdownSection = styled.div`
  padding: var(--spacer);
  position: relative;
  z-index: 1;
`;
