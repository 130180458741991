// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import Slider from 'react-slick';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import './MainContent.css';
// import Hero from './Hero';
// import Features from './Features';
// import { useTheme } from '../context/ThemeContext';
// import Image0 from '../assets/images/rx.png';
// import Image1 from '../assets/images/medical-computer.png';
// import Image2 from '../assets/images/medical-glass.png';
// import WhyUs from './Whyus';
// import DoctorElecSVG from '../components/circulo.svg';
// import SEO from './SEO';

// function MainContent() {
//   const { darkMode } = useTheme();
//   const { t } = useTranslation();

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//   };

//   return (
//     <div className={`main-content ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//       <SEO path="/" />
//       <Hero />
//       <div className="text-center">
//         <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('title')}</h2>
//         <hr className="divider"/>
//         <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('subtitle')}</p>
//       </div>
//       <div className="card-container">
//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <LazyLoadImage 
//               src={Image1} 
//               alt="Telemedicine" 
//               effect="blur"
//             />
//             <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card1Title')}</h2>
//             <p>{t('card1Content')}</p>
//           </div>
//         </div>

//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <LazyLoadImage 
//               src={Image2} 
//               alt="Predictive Analytics" 
//               effect="blur"
//             />
//             <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card2Title')}</h2>
//             <p>{t('card2Content')}</p>
//           </div>
//         </div>

//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <LazyLoadImage 
//               src={Image0} 
//               alt="Personalized Care" 
//               effect="blur"
//             />
//             <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card3Title')}</h2>
//             <p>{t('card3Content')}</p>
//           </div>
//         </div>
//       </div>
//       {/* Slider Section */}
//       <div className="text-center">
//         <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('sliderTitle')}</h2>
//         <hr className="divider"/>
//         <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('sliderSubtitle')}</p>
//       </div>
//       <div className="slider-container">
//         <Slider {...sliderSettings}>
//           <div className="slider-item">
//             <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="card-content">
//                 <LazyLoadImage 
//                   src={Image0} 
//                   alt="Telemedicine" 
//                   effect="blur"
//                 />
//                 <h3>{t('sliderCard1Title')}</h3>
//                 <p>{t('sliderCard1Content')}</p>
//               </div>
//             </div>
//           </div>
//           <div className="slider-item">
//             <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="card-content">
//                 <LazyLoadImage 
//                   src={Image1} 
//                   alt="Predictive Analytics" 
//                   effect="blur"
//                 />
//                 <h3>{t('sliderCard2Title')}</h3>
//                 <p>{t('sliderCard2Content')}</p>
//               </div>
//             </div>
//           </div>
//           <div className="slider-item">
//             <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="card-content">
//                 <LazyLoadImage 
//                   src={Image2} 
//                   alt="Personalized Care" 
//                   effect="blur"
//                 />
//                 <h3>{t('sliderCard3Title')}</h3>
//                 <p>{t('sliderCard3Content')}</p>
//               </div>
//             </div>
//           </div>
//           <div className="slider-item">
//             <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//               <div className="card-content">
//                 <LazyLoadImage 
//                   src={Image2} 
//                   alt="Continuous Support" 
//                   effect="blur"
//                 />
//                 <h3>{t('sliderCard4Title')}</h3>
//                 <p>{t('sliderCard4Content')}</p>
//               </div>
//             </div>
//           </div>
//         </Slider>
//       </div>

//       <Features />

//       {/* Contact Section */}
//       <section className="contact">
//         <div className="text-center">
//           <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('feedbackTitle')}</h2>
//           <hr className="divider"/>
//           <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('feedbackContent')}</p>
//         </div>
//         <div className="contact-container">
//           <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//             <div className="icon" style={{ position: 'relative' }}>
//               <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
//               <span style={{ position: 'relative', zIndex: '1' }}>📞</span>
//             </div>
//             <h4><a href="/feedback">{t('feedbackButton')}</a></h4>
//             <p>{t('feedbackDescription')}</p>
//           </div>
//         </div>
//       </section>
//       <div className="text-center">
//         <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('digitalHealthTitle')}</h2>
//         <hr className="divider"/>
//         <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('digitalHealthSubtitle')}</p>
//       </div>

//       <WhyUs />

//     </div>
//   );
// }

// export default MainContent;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './MainContent.css';
import Hero from './Hero';
import Features from './Features';
import { useTheme } from '../context/ThemeContext';
import Image0 from '../assets/images/rx.png';
import Image1 from '../assets/images/medical-computer.png';
import Image2 from '../assets/images/medical-glass.png';
import WhyUs from './Whyus';
import DoctorElecSVG from '../components/circulo.svg';
import SEO from './SEO';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function MainContent() {
  const { darkMode } = useTheme();
  const { t } = useTranslation();

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className={`main-content ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <SEO path="/" />
      <Hero />
      <div className="text-center">
        <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('title')}</h2>
        <hr className="divider"/>
        <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('subtitle')}</p>
      </div>
      <div className="card-container">
        <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
          <div className="card-content">
            <LazyLoadImage 
              src={Image1} 
              alt="Telemedicine" 
              effect="blur"
            />
            <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card1Title')}</h2>
            <p>{t('card1Content')}</p>
          </div>
        </div>
        <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
          <div className="card-content">
            <LazyLoadImage 
              src={Image2} 
              alt="Predictive Analytics" 
              effect="blur"
            />
            <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card2Title')}</h2>
            <p>{t('card2Content')}</p>
          </div>
        </div>
        <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
          <div className="card-content">
            <LazyLoadImage 
              src={Image0} 
              alt="Personalized Care" 
              effect="blur"
            />
            <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card3Title')}</h2>
            <p>{t('card3Content')}</p>
          </div>
        </div>
      </div>
      <div className="text-center">
        <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('sliderTitle')}</h2>
        <hr className="divider"/>
        <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('sliderSubtitle')}</p>
      </div>
      <div className="slider-container">
        <Slider {...sliderSettings}>
          <div className="slider-item">
            <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="card-content">
                <LazyLoadImage 
                  src={Image0} 
                  alt="Telemedicine" 
                  effect="blur"
                />
                <h3>{t('sliderCard1Title')}</h3>
                <p>{t('sliderCard1Content')}</p>
              </div>
            </div>
          </div>
          <div className="slider-item">
            <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="card-content">
                <LazyLoadImage 
                  src={Image1} 
                  alt="Predictive Analytics" 
                  effect="blur"
                />
                <h3>{t('sliderCard2Title')}</h3>
                <p>{t('sliderCard2Content')}</p>
              </div>
            </div>
          </div>
          <div className="slider-item">
            <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="card-content">
                <LazyLoadImage 
                  src={Image2} 
                  alt="Personalized Care" 
                  effect="blur"
                />
                <h3>{t('sliderCard3Title')}</h3>
                <p>{t('sliderCard3Content')}</p>
              </div>
            </div>
          </div>
          <div className="slider-item">
            <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="card-content">
                <LazyLoadImage 
                  src={Image2} 
                  alt="Continuous Support" 
                  effect="blur"
                />
                <h3>{t('sliderCard4Title')}</h3>
                <p>{t('sliderCard4Content')}</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <Features />
      <section className="contact">
        <div className="text-center">
          <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('feedbackTitle')}</h2>
          <hr className="divider"/>
          <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('feedbackContent')}</p>
        </div>
        <div className="contact-container">
          <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className="icon" style={{ position: 'relative' }}>
              <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
              <span style={{ position: 'relative', zIndex: '1' }}>📞</span>
            </div>
            <h4><a href="/feedback">{t('feedbackButton')}</a></h4>
            <p>{t('feedbackDescription')}</p>
          </div>
        </div>
      </section>
      <div className="text-center">
        <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('digitalHealthTitle')}</h2>
        <hr className="divider"/>
        <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('digitalHealthSubtitle')}</p>
      </div>
      <WhyUs />
    </div>
  );
}

export default MainContent;

// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { Container, Row, Col, Button } from 'react-bootstrap'; 
// import './MainContent.css'; // Asegúrate de crear este archivo para tus estilos
// import Slider from 'react-slick'; // Importa Slider
// import 'slick-carousel/slick/slick.css'; // Estilos básicos
// import 'slick-carousel/slick/slick-theme.css'; // Tema adicional (opcional)
// import Hero from './Hero';
// import Features from './Features';
// import { useTheme } from '../context/ThemeContext';
// import Image0 from '../assets/images/rx.png'; 
// import Image1 from '../assets/images/medical-computer.png'; 
// import Image2 from '../assets/images/medical-glass.png'; 
// import WhyUs from './Whyus';
// import DoctorElecSVG from '../components/circulo.svg'; 
// import SEO from './SEO';

// function MainContent() {
//   const { darkMode } = useTheme();
//   const { t } = useTranslation();

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 1000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//   };

//   return (
    
//     <div className={`main-content ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//       {/* <SEO path="/" /> */}
//       <SEO />
//       <Hero />
//       <div className="text-center">
//         <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('title')}</h2>
//         <hr className="divider"/>
//         <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('subtitle')}</p>
//       </div>
//       <div className="card-container">
//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <img src={Image1} alt="Telemedicine" />
//             <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card1Title')}</h2>
//             <p>{t('card1Content')}</p>
//           </div>
//         </div>

//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <img src={Image2} alt="Predictive Analytics" />
//             <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card2Title')}</h2>
//             <p>{t('card2Content')}</p>
//           </div>
//         </div>

//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <img src={Image0} alt="Personalized Care" />
//             <h2 className={`title ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('card3Title')}</h2>
//             <p>{t('card3Content')}</p>
//           </div>
//         </div>
//       </div>
//       {/* Slider Section */}
//       <div className="text-center">
//         <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('sliderTitle')}</h2>
//         <hr className="divider"/>
//         <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('sliderSubtitle')}</p>
//       </div>
//       <Slider {...sliderSettings}>
//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <img src={Image0} alt="Telemedicine" />
//             <h3>{t('sliderCard1Title')}</h3>
//             <p>{t('sliderCard1Content')}</p>
//           </div>
//         </div>
//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <img src={Image1} alt="Predictive Analytics" />
//             <h3>{t('sliderCard2Title')}</h3>
//             <p>{t('sliderCard2Content')}</p>
//           </div>
//         </div>
//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <img src={Image2} alt="Personalized Care" />
//             <h3>{t('sliderCard3Title')}</h3>
//             <p>{t('sliderCard3Content')}</p>
//           </div>
//         </div>
//         <div className={`card ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//           <div className="card-content">
//             <img src={Image2} alt="Continuous Support" />
//             <h3>{t('sliderCard4Title')}</h3>
//             <p>{t('sliderCard4Content')}</p>
//           </div>
//         </div>
//       </Slider>

//       <Features />

//       {/* Contact Section */}
//       <section className="contact">
//         <div className="text-center">
//           <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('feedbackTitle')}</h2>
//           <hr className="divider"/>
//           <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('feedbackContent')}</p>
//         </div>
//         <div className="contact-container">
//           <div className={`icon-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//             <div className="icon" style={{ position: 'relative' }}>
//               <img src={DoctorElecSVG} alt="DoctorElec Logo" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '140%', height: '140%', zIndex: '-1' }} />
//               <span style={{ position: 'relative', zIndex: '1' }}>📞</span>
//             </div>
//             <h4><a href="/feedback">{t('feedbackButton')}</a></h4>
//             <p>{t('feedbackDescription')}</p>
//           </div>
//         </div>
//       </section>
//       <div className="text-center">
//         <h2 className={`title-cat font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('digitalHealthTitle')}</h2>
//         <hr className="divider"/>
//         <p className={`p-landing ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('digitalHealthSubtitle')}</p>
//       </div>

//       <WhyUs />

//     </div>
//   );
// }

// export default MainContent;



