import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './TestimonialItem.css';


const TestimonialItem = ({ image, title, text }) => {
  const { t } = useTranslation();

  return (
    <div className="testimonial-item">
      <LazyLoadImage
        alt={t(text)}
        effect="blur"
        src={image}
        className="testimonial-image"
      />
      <div className="testimonial-content">
        <h3>{t(title)}</h3>
        <p>{t(text)}</p>
      </div>
     
    </div>
    
  );
};

export default TestimonialItem;
