import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
`;

const ErrorCode = styled.h1`
  font-size: 6rem;
  margin: 0;
  color: #dc3545;
`;

const ErrorMessage = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #6c757d;
`;

const StyledLink = styled(Link)`
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Error500 = () => {
  return (
    <Container>
      <ErrorCode>500</ErrorCode>
      <ErrorMessage>Error interno del servidor</ErrorMessage>
      <StyledLink to="/">Volver al Inicio</StyledLink>
    </Container>
  );
};

export default Error500;
