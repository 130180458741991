import axios from 'axios';

const axiosBlog = axios.create({
    baseURL: 'http://127.0.0.1:5009/api',  // Asegúrate de que el backend corra en este puerto
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

export default axiosBlog;
