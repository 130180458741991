// src/components/ui/Card.jsx
import React from 'react';

export function Card({ children }) {
  return <div className="card bg-white shadow-md rounded-lg p-4 text-dark">{children}</div>;
}

export function CardHeader({ children }) {
  return <div className="card-header">{children}</div>;
}

export function CardDescription({ children }) {
  return <div className="card-description">{children}</div>;
}

export function CardTitle({ children }) {
  return <div className="card-title">{children}</div>;
}

export function CardContent({ children }) {
  return <div className="card-content">{children}</div>;
}
