// export default ResetPassword
import React, { useState } from 'react';
// import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './ResetPassword.css';
import axiosInstance from '../utils/axiosInstance';
import Alert from './Alert';

function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const { uidb64, token } = useParams();
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Password must contain at least one uppercase letter");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Password must contain at least one lowercase letter");
        }
        if (!/[0-9]/.test(password)) {
            errors.push("Password must contain at least one digit");
        }
        if (!/[^A-Za-z0-9]/.test(password)) {
            errors.push("Password must contain at least one special character !@#$%^&*()");
        }
        return errors;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validatePassword(newPassword);
        if (errors.length > 0) {
            setMessage(`Error: ${errors.join(", ")}`);
            setAlertType('danger'); 
            return;
        }

        try {
            const response = await axiosInstance.patch('/password-reset-complete/', 
                { uidb64, token, password: newPassword },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            setMessage(response.data.success);
            setAlertType('success');
            navigate('/login');

        } catch (error) {
            if (error.response && error.response.data) {
                setMessage(`Error: ${JSON.stringify(error.response.data)}`);
            } else {
                setMessage('Error resetting password');
            }
            setAlertType('danger');
        }
    };

    const handleAlertClose = () => {
        setMessage('');
        setAlertType('');
    };

    return (
        <div className="reset-password">
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <button className="Reset_Button" type="submit">Reset Password</button>
            </form>
            {message && (
                <Alert 
                    message={message} 
                    onClose={handleAlertClose} 
                    type={alertType} 
                />
            )}
        </div>
    );
}

export default ResetPassword;
