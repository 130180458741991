import React from 'react';
import './Pricing.css';
import { useTheme } from '../context/ThemeContext';
import SEO from './SEO';
import { useTranslation } from 'react-i18next';


function Pricing() {
  const { darkMode } = useTheme();
  const { t } = useTranslation();

  return (
    <div id="pricing" className={`pricing-section ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <SEO />
      <div className="container">
        <div className="section-title">
          <h2 className={`font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>{t('Pricing')}</h2>
          <p className={darkMode ? 'dark-mode' : 'light-mode'}>Flexible pricing plans to suit your business needs. Get started for free and upgrade as you grow.</p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className={`pricing-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon">🔹</div>
              <h4>Basic</h4>
              <p>$50/month</p>
              <p>Basic access to the platform and patients</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className={`pricing-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon">🔷</div>
              <h4>Pro</h4>
              <p>$100/month</p>
              <p>Advanced tools for professional practice</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className={`pricing-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon">💎</div>
              <h4>Premium</h4>
              <p>$200/month</p>
              <p>All features for large practices</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className={`pricing-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon">🌟</div>
              <h4>Custom</h4>
              <p>Contact us</p>
              <p>Custom solutions tailored to your needs</p>
            </div>
          </div>
        </div>
        <div className="section-title mt-5">
          <h2 className={`font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>Consultation Pricing</h2>
          <p className={darkMode ? 'dark-mode' : 'light-mode'}>Pay-per-consultation pricing for patients</p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className={`pricing-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon">🩺</div>
              <h4>General Consultation</h4>
              <p>$30/consultation</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div className={`pricing-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon">👨‍⚕️</div>
              <h4>Specialist Consultation</h4>
              <p>$50/consultation</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
            <div className={`pricing-box ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="icon">🚑</div>
              <h4>Urgent Consultation</h4>
              <p>$70/consultation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;

