import axios from 'axios';

const axiosAppointments = axios.create({
    baseURL: 'http://127.0.0.1:5001/api',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

axiosAppointments.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            //console.log('Access Token:', token);  // Verificar que el token se está pasando
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosAppointments;
