import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosBlog from '../../utils/axiosBlog';

const RelatedArticles = ({ articleSlug }) => {
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    axiosBlog.get(`/articles/${articleSlug}/related`)
      .then(response => setRelatedArticles(response.data))
      .catch(error => console.error('Error fetching related articles:', error));
  }, [articleSlug]);

  if (!relatedArticles.length) return null;

  return (
    <div className="related-articles mt-10">
      <h3 className="text-2xl font-semibold mb-4">Related Articles</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {relatedArticles.map(article => (
          <div key={article.slug} className="block p-4 border rounded-lg hover:shadow-lg transition duration-300">
            {/* Imagen del artículo */}
            <Link to={`/article/${article.slug}`} className="block mb-4">
              <img 
                src={article.image} 
                alt={article.title} 
                className="w-full h-40 object-cover rounded-md"
              />
            </Link>

            {/* Título del artículo */}
            <h4 className="font-bold text-lg">
              <Link to={`/article/${article.slug}`} className="hover:text-blue-500 transition">
                {article.title}
              </Link>
            </h4>

            {/* Resumen del artículo */}
            <p className="text-sm text-gray-600 mt-2">
              {article.summary}
            </p>

            {/* Botón para leer más */}
            <div className="mt-4">
              <Link to={`/article/${article.slug}`} className="inline-block bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition">
                Read More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedArticles;
