// import React, { useState } from 'react';
// import axios from 'axios';

// const MedicationSearch = ({ selectedMedications, setSelectedMedications }) => {
//   const [searchResults, setSearchResults] = useState([]);

//   const handleSearch = async (e) => {
//     const searchTerm = e.target.value;
//     try {
//       const response = await axios.post('/search_medications', { searchTerm });
//       setSearchResults(response.data.medications);
//     } catch (error) {
//       console.error('Error searching medications:', error);
//     }
//   };

//   const addMedication = (medication) => {
//     setSelectedMedications([...selectedMedications, { ...medication, frequency: 1 }]);
//   };

//   return (
//     <div>
//       <label>Search Medications</label>
//       <input type="text" onChange={handleSearch} />
//       <ul>
//         {searchResults.map((med) => (
//           <li key={med.id}>
//             {med.drug_name} - {med.strength}
//             <button type="button" onClick={() => addMedication(med)}>Add</button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default MedicationSearch;
import React, { useState } from 'react';
import axios from 'axios';
import { Table, Button, FormControl } from 'react-bootstrap';

const MedicationSearch = ({ selectedMedications, setSelectedMedications }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length > 2) {
      try {
        const response = await axios.post('/search_medications', { searchTerm: e.target.value });
        setSearchResults(response.data.medications);
      } catch (error) {
        console.error('Error searching medications:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const addMedication = (medication) => {
    setSelectedMedications([...selectedMedications, { ...medication, frequency: '' }]);
    setSearchResults([]);
    setSearchTerm('');
  };

  return (
    <div className="medication-search mt-3">
      <FormControl
        type="text"
        placeholder="Search Medications"
        value={searchTerm}
        onChange={handleSearch}
      />
      {searchResults.length > 0 && (
        <Table striped bordered hover size="sm" className="mt-3">
          <thead>
            <tr>
              <th>Name</th>
              <th>Strength</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((med) => (
              <tr key={med.id}>
                <td>{med.drugName}</td>
                <td>{med.strength}</td>
                <td>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addMedication(med)}
                  >
                    Add
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default MedicationSearch;

