import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, ProgressBar, Card, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axiosPatient from '../utils/axiosPatient';
import optionsList from '../assets/medicalConditions.json';
import './ClinicalHistoryForm.css';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/AuthContext';

const ClinicalHistoryForm = () => {
  const [formData, setFormData] = useState({
    birthDate: '',
    genderAtBirth: '',
    hasMedicalConditions: false,
    medicalConditions: [],
    familyHistory: [{ condition: '', relationship: '' }],
    immunization: [],
    lifestyle: {
      smokes: false,
      drinksAlcohol: false,
      exercises: false,
      smokeFrequency: '',
      smokePerYears: '',
      wantToQuitSmoking: false,
      alcoholFrequency: '',
      alcoholQuantity: '',
      exerciseFrequency: '',
      exerciseIntensity: '',
      exerciseType: ''
    },
    medication: '',
    otherCondition: '',
    allergies: '',
    surgeries: '',
    hospitalizations: '',
    psychological: {
      stressLevel: 5,
      mood: 5,
      socialActivityHours: ''
    },
    anthropometry: {
      weightKg: '',
      weightLbs: '',
      heightCm: '',
      heightIn: '',
      waistCircumference: '',
      hipCircumference: '',
      bloodPressureSystolic: '',
      bloodPressureDiastolic: '',
      bloodGlucose: '',
      oxygenSaturation: '',
      bodyTemperatureC: '',
      bodyTemperatureF: ''
    }
  });

  const { darkMode } = useTheme();
  const { user } = useAuth();
  const [currentPart, setCurrentPart] = useState(1);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      console.log('User data from useAuth:', user);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const keys = name.split('.');

    setFormData(prevState => {
      if (keys.length === 1) {
        return {
          ...prevState,
          [name]: type === 'checkbox' ? checked : value
        };
      } else if (keys.length === 2) {
        return {
          ...prevState,
          [keys[0]]: {
            ...prevState[keys[0]],
            [keys[1]]: type === 'checkbox' ? checked : value
          }
        };
      } else if (keys.length === 3) {
        return {
          ...prevState,
          [keys[0]]: {
            ...prevState[keys[0]],
            [keys[1]]: {
              ...prevState[keys[0]][keys[1]],
              [keys[2]]: type === 'checkbox' ? checked : value
            }
          }
        };
      }
    });
  };

  const handleMedicalConditionsChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      medicalConditions: checked
        ? [...prevState.medicalConditions, value]
        : prevState.medicalConditions.filter(cond => cond !== value)
    }));
  };

  const handleAddFamilyHistory = () => {
    setFormData(prevState => ({
      ...prevState,
      familyHistory: [...prevState.familyHistory, { condition: '', relationship: '' }]
    }));
  };

  const handleRemoveFamilyHistory = (index) => {
    setFormData(prevState => ({
      ...prevState,
      familyHistory: prevState.familyHistory.filter((_, i) => i !== index)
    }));
  };

  const handleImmunizationChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      immunization: checked
        ? [...prevState.immunization, value]
        : prevState.immunization.filter(imm => imm !== value)
    }));
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  const validateForm = () => {
    const newErrors = {};

    if (currentPart === 1) {
      if (!formData.birthDate) {
        newErrors.birthDate = 'Date of birth is required';
      } else if (calculateAge(formData.birthDate) < 13) {
        newErrors.birthDate = 'The minimum age to use this service is 13 years';
      }
      if (!formData.genderAtBirth) newErrors.genderAtBirth = 'Gender at birth is required';
    }

    if (currentPart === 2) {
      if (formData.hasMedicalConditions && formData.medicalConditions.length === 0) {
        newErrors.medicalConditions = 'At least one medical condition must be selected';
      }
    }

    if (currentPart === 3) {
      formData.familyHistory.forEach((history, index) => {
        if (!history.condition) newErrors[`familyHistory_${index}_condition`] = 'Condition is required';
        if (!history.relationship) newErrors[`familyHistory_${index}_relationship`] = 'Relationship is required';
      });
    }

    if (currentPart === 4) {
      if (formData.immunization.length === 0) newErrors.immunization = 'At least one immunization must be selected';
    }

    if (currentPart === 5) {
      if (formData.lifestyle.smokes && !formData.lifestyle.smokeFrequency) newErrors.smokeFrequency = 'Smoke frequency is required';
      if (formData.lifestyle.smokes && !formData.lifestyle.smokePerYears) newErrors.smokePerYears = 'Years smoking is required';
      if (formData.lifestyle.drinksAlcohol && !formData.lifestyle.alcoholFrequency) newErrors.alcoholFrequency = 'Alcohol frequency is required';
      if (formData.lifestyle.drinksAlcohol && !formData.lifestyle.alcoholQuantity) newErrors.alcoholQuantity = 'Alcohol quantity is required';
      if (formData.lifestyle.exercises && !formData.lifestyle.exerciseFrequency) newErrors.exerciseFrequency = 'Exercise frequency is required';
      if (formData.lifestyle.exercises && !formData.lifestyle.exerciseIntensity) newErrors.exerciseIntensity = 'Exercise intensity is required';
      if (formData.lifestyle.exercises && !formData.lifestyle.exerciseType) newErrors.exerciseType = 'Exercise type is required';
    }

    if (currentPart === 6) {
      if (!formData.medication) newErrors.medication = 'Medication is required, is do not apply please type "N/A"';
      if (!formData.allergies) newErrors.allergies = 'Allergies are required, is do not apply please type "N/A"';
      if (!formData.surgeries) newErrors.surgeries = 'Previous surgeries are required, is do not apply please type "N/A"';
      if (!formData.hospitalizations) newErrors.hospitalizations = 'Previous hospitalizations are required, is do not apply please type "N/A"';
    }

    if (currentPart === 7) {
      if (!formData.psychological.socialActivityHours) newErrors.socialActivityHours = 'Social activity hours are required';
    }

    if (currentPart === 8) {
      if (!formData.anthropometry.weightKg && !formData.anthropometry.weightLbs) newErrors.weight = 'Weight is required';
      if (!formData.anthropometry.heightCm && !formData.anthropometry.heightIn) newErrors.height = 'Height is required';
      if (formData.anthropometry.weightKg && (formData.anthropometry.weightKg < 10 || formData.anthropometry.weightKg > 650)) newErrors.weight = 'Weight must be in kilograms (valid range: 10-650 kg)';
      if (formData.anthropometry.weightLbs && (formData.anthropometry.weightLbs < 22 || formData.anthropometry.weightLbs > 970)) newErrors.weight = 'Weight must be in pounds (valid range: 22-970 lbs)';
      
      if (formData.anthropometry.heightCm && (formData.anthropometry.heightCm < 50 || formData.anthropometry.heightCm > 300)) newErrors.height = 'Height must be in centimeters (valid range: 50-300 cm)';
      if (formData.anthropometry.heightIn && (formData.anthropometry.heightIn < 20 || formData.anthropometry.heightIn > 118)) newErrors.height = 'Height must be in inches (valid range: 20-118 inches)';
      
      if (formData.anthropometry.waistCircumference && (formData.anthropometry.waistCircumference < 20 || formData.anthropometry.waistCircumference > 200)) newErrors.waistCircumference = 'Waist circumference must be in centimeters (valid range: 20-200 cm) \n **Leave blank if you do not have that information.**';
      if (formData.anthropometry.hipCircumference && (formData.anthropometry.hipCircumference < 30 || formData.anthropometry.hipCircumference > 250)) newErrors.hipCircumference = 'Hip circumference must be in centimeters (valid range: 30-250 cm) \n **Leave blank if you do not have that information.**';

      if (formData.anthropometry.bloodPressureSystolic && (formData.anthropometry.bloodPressureSystolic < 50 || formData.anthropometry.bloodPressureSystolic > 250)) newErrors.bloodPressureSystolic = 'Systolic blood pressure must be in valid range (50-250 mmHg) \n **Leave blank if you do not have that information.**';
      if (formData.anthropometry.bloodPressureDiastolic && (formData.anthropometry.bloodPressureDiastolic < 30 || formData.anthropometry.bloodPressureDiastolic > 150)) newErrors.bloodPressureDiastolic = 'Diastolic blood pressure must be in valid range (30-150 mmHg) \n **Leave blank if you do not have that information.**';

      if (formData.anthropometry.bloodGlucose && (formData.anthropometry.bloodGlucose < 30 || formData.anthropometry.bloodGlucose > 600)) newErrors.bloodGlucose = 'Blood glucose level must be in valid range (30-600 mg/dL) \n  **Leave blank if you do not have that information.**';

      if (formData.anthropometry.oxygenSaturation && (formData.anthropometry.oxygenSaturation < 70 || formData.anthropometry.oxygenSaturation > 100)) newErrors.oxygenSaturation = 'Oxygen saturation must be between 70 and 100% \n  **Leave blank if you do not have that information.**';

      if (formData.anthropometry.bodyTemperatureC && (formData.anthropometry.bodyTemperatureC < 30 || formData.anthropometry.bodyTemperatureC > 45)) newErrors.bodyTemperature = 'Body temperature must be in valid range (30-45 °C) \n **Leave blank if you do not have that information.**';
      if (formData.anthropometry.bodyTemperatureF && (formData.anthropometry.bodyTemperatureF < 86 || formData.anthropometry.bodyTemperatureF > 113)) newErrors.bodyTemperature = 'Body temperature must be in valid range (86-113 °F) \n  **Leave blank if you do not have that information.**';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Final Form Data:', formData); // Verificar datos finales

    if (validateForm()) {
      console.log('Form Data:', formData);

      // Agregar los datos del usuario al formData
      if (user) {
        console.log('User data from useAuth:', user);
        const finalFormData = {
          ...formData,
          user_id: user.user_id,  // Asegúrate de usar user.user_id
          username: user.username,
          role: user.role,
        };

        try {
          const response = await axiosPatient.post('/clinical_history', finalFormData, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`,
            }
          });
          console.log(response.data);
          navigate('/dashboard');  // O la ruta que desees después de enviar el formulario exitosamente
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      } else {
        console.error('User data is not available');
      }
    }
  };

  const nextPart = () => {
    if (validateForm()) {
      if (currentPart < totalParts) {
        setCurrentPart(prevPart => prevPart + 1);
      }
    }
  };

  const prevPart = () => {
    if (currentPart > 1) {
      setCurrentPart(prevPart => prevPart - 1);
    }
  };

  const handleStressLevelChange = (value) => {
    setFormData(prevState => ({
      ...prevState,
      psychological: {
        ...prevState.psychological,
        stressLevel: value
      }
    }));
  };

  const handleMoodChange = (value) => {
    setFormData(prevState => ({
      ...prevState,
      psychological: {
        ...prevState.psychological,
        mood: value
      }
    }));
  };

  const handleTooltip = (text) => (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id="tooltip-right">{text}</Tooltip>}
    >
      <span className="info-circle">?</span>
    </OverlayTrigger>
  );

  const totalParts = 8;
  const progress = Math.min(Math.floor((currentPart / totalParts) * 100), 100);

  return (
    <div className={`clinical-history-form container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <h1 className="title-cat">Clinical History</h1>
      <ProgressBar now={progress} label={`${progress}%`} className="mb-4 progress-bar-gradient" />
      <Card className={`p-4 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
        {Object.keys(errors).length > 0 && (
          <Alert variant="danger">
            {Object.values(errors).map((error, idx) => (
              <p key={idx}>{error}</p>
            ))}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          {currentPart === 1 && (
            <div id="part-1" className="form-part">
              <h3 className="icon-box h4">Personal Information</h3>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Date of Birth {handleTooltip('We need your date of birth to calculate your age and assess health risks associated with different age groups.')}</Form.Label>
                <Form.Control
                  type="date"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleChange}
                  className="form-control-lg"
                  isInvalid={!!errors.birthDate}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Gender at Birth {handleTooltip('We need to know your gender at birth to provide accurate health recommendations and screenings.')}</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    name="genderAtBirth"
                    value={formData.genderAtBirth}
                    onChange={handleChange}
                    className="form-select-lg"
                    isInvalid={!!errors.genderAtBirth}
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                </div>
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button type="button" className="btn btn-primary" onClick={nextPart}>Continue</Button>
              </div>
            </div>
          )}

          {currentPart === 2 && (
            <div id="part-2" className="form-part">
              <h3 className="icon-box h4">Personal Medical History</h3>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="9" className="form-label-lg">Do you have any medical conditions? {handleTooltip('Knowing your medical conditions helps us to tailor health advice and treatment plans to your specific needs.')}</Form.Label>
                <Col sm="3">
                  <Form.Check
                    type="checkbox"
                    name="hasMedicalConditions"
                    checked={formData.hasMedicalConditions}
                    onChange={handleChange}
                    className="form-check-lg"
                  />
                </Col>
              </Form.Group>

              {formData.hasMedicalConditions && (
                <Form.Group className="mb-3">
                  <Form.Label className="form-label-lg">Medical Conditions {handleTooltip('Please select your medical conditions so that we can better understand your health needs.')}</Form.Label>
                  <Row>
                    <Col md={6}>
                      {optionsList.medicalConditions.slice(0, Math.ceil(optionsList.medicalConditions.length / 2)).map((condition, index) => (
                        <Form.Check
                          key={index}
                          type="checkbox"
                          label={condition}
                          value={condition}
                          checked={formData.medicalConditions.includes(condition)}
                          onChange={handleMedicalConditionsChange}
                          className="form-check-lg"
                        />
                      ))}
                    </Col>
                    <Col md={6}>
                      {optionsList.medicalConditions.slice(Math.ceil(optionsList.medicalConditions.length / 2)).map((condition, index) => (
                        <Form.Check
                          key={index}
                          type="checkbox"
                          label={condition}
                          value={condition}
                          checked={formData.medicalConditions.includes(condition)}
                          onChange={handleMedicalConditionsChange}
                          className="form-check-lg"
                        />
                      ))}
                      <Form.Check
                        type="checkbox"
                        label="Other"
                        value="Other"
                        checked={formData.medicalConditions.includes('Other')}
                        onChange={handleMedicalConditionsChange}
                        className="form-check-lg"
                      />
                      {formData.medicalConditions.includes('Other') && (
                        <Form.Control
                          type="text"
                          name="otherCondition"
                          value={formData.otherCondition}
                          onChange={handleChange}
                          placeholder="Please specify"
                          className="mt-2"
                        />
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              )}
              <div className="d-flex justify-content-end">
                <Button type="button" className="btn btn-primary" onClick={nextPart}>Continue</Button>
              </div>
            </div>
          )}

          {currentPart === 3 && (
            <div id="part-3" className="form-part">
              <h3 className="icon-box h4">Family History</h3>
              {formData.familyHistory.map((history, index) => (
                <div key={index} className="mb-3">
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="form-label-lg">Condition</Form.Label>
                        <div className="select-wrapper">
                          <Form.Control
                            as="select"
                            name={`familyHistory_${index}_condition`}
                            value={history.condition}
                            onChange={handleChange}
                            className="form-select-lg"
                            isInvalid={!!errors[`familyHistory_${index}_condition`]}
                          >
                            <option value="">Select condition</option>
                            {optionsList.medicalConditions.map((condition, idx) => (
                              <option key={idx} value={condition}>{condition}</option>
                            ))}
                          </Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="form-label-lg">Relationship</Form.Label>
                        <div className="select-wrapper">
                          <Form.Control
                            as="select"
                            name={`familyHistory_${index}_relationship`}
                            value={history.relationship}
                            onChange={handleChange}
                            className="form-select-lg"
                            isInvalid={!!errors[`familyHistory_${index}_relationship`]}
                          >
                            <option value="">Select relationship</option>
                            {optionsList.relationships.map((relationship, idx) => (
                              <option key={idx} value={relationship}>{relationship}</option>
                            ))}
                          </Form.Control>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs="auto" className="d-flex align-items-center">
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveFamilyHistory(index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Button variant="secondary" onClick={handleAddFamilyHistory}>Add Another Family History</Button>
              <div className="d-flex justify-content-end">
                <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
                <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
              </div>
            </div>
          )}

          {currentPart === 4 && (
            <div id="part-4" className="form-part">
              <h3 className="icon-box h4">Immunization</h3>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Immunization Records {handleTooltip('Knowing your immunization history helps us ensure that you are up to date with vaccines and protected against preventable diseases.')}</Form.Label>
                {optionsList.immunizations.map((immunization, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={immunization}
                    value={immunization}
                    checked={formData.immunization.includes(immunization)}
                    onChange={handleImmunizationChange}
                    className="form-check-lg"
                  />
                ))}
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
                <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
              </div>
            </div>
          )}

          {currentPart === 5 && (
            <div id="part-5" className="form-part">
              <h3 className="icon-box h4">Lifestyle</h3>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="9" className="form-label-lg">Do you smoke? {handleTooltip('We ask about your smoking habits to assess your risk for various health conditions and to offer appropriate support if you want to quit.')}</Form.Label>
                <Col sm="3">
                  <Form.Check
                    type="checkbox"
                    name="lifestyle.smokes"
                    checked={formData.lifestyle.smokes}
                    onChange={handleChange}
                    className="form-check-lg"
                  />
                </Col>
              </Form.Group>

              {formData.lifestyle.smokes && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-lg">Smoke Frequency {handleTooltip('Please select how frequently you smoke to better understand your smoking habits.')}</Form.Label>
                    <div className="select-wrapper">
                      <Form.Control
                        as="select"
                        name="lifestyle.smokeFrequency"
                        value={formData.lifestyle.smokeFrequency}
                        onChange={handleChange}
                        className="form-select-lg"
                        isInvalid={!!errors.smokeFrequency}
                      >
                        <option value="">Select frequency</option>
                        {optionsList.smokingFrequency.map((frequency, idx) => (
                          <option key={idx} value={frequency}>{frequency}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-lg">Years Smoking {handleTooltip('Please select how many years you have been smoking to understand the duration of your habit.')}</Form.Label>
                    <div className="select-wrapper">
                      <Form.Control
                        as="select"
                        name="lifestyle.smokePerYears"
                        value={formData.lifestyle.smokePerYears}
                        onChange={handleChange}
                        className="form-select-lg"
                        isInvalid={!!errors.smokePerYears}
                      >
                        <option value="">Select years</option>
                        {optionsList.years.map((year, idx) => (
                          <option key={idx} value={year}>{year}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </>
              )}

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="9" className="form-label-lg">Do you drink alcohol? {handleTooltip('We ask about your alcohol consumption to understand your lifestyle and to provide appropriate health advice.')}</Form.Label>
                <Col sm="3">
                  <Form.Check
                    type="checkbox"
                    name="lifestyle.drinksAlcohol"
                    checked={formData.lifestyle.drinksAlcohol}
                    onChange={handleChange}
                    className="form-check-lg"
                  />
                </Col>
              </Form.Group>

              {formData.lifestyle.drinksAlcohol && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-lg">Alcohol Frequency {handleTooltip('Please select how frequently you drink alcohol to understand your drinking habits.')}</Form.Label>
                    <div className="select-wrapper">
                      <Form.Control
                        as="select"
                        name="lifestyle.alcoholFrequency"
                        value={formData.lifestyle.alcoholFrequency}
                        onChange={handleChange}
                        className="form-select-lg"
                        isInvalid={!!errors.alcoholFrequency}
                      >
                        <option value="">Select frequency</option>
                        {optionsList.alcoholFrequency.map((frequency, idx) => (
                          <option key={idx} value={frequency}>{frequency}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-lg">Alcohol Quantity {handleTooltip('Please select how many drinks you usually have to better understand your alcohol consumption.')}</Form.Label>
                    <div className="select-wrapper">
                      <Form.Control
                        as="select"
                        name="lifestyle.alcoholQuantity"
                        value={formData.lifestyle.alcoholQuantity}
                        onChange={handleChange}
                        className="form-select-lg"
                        isInvalid={!!errors.alcoholQuantity}
                      >
                        <option value="">Select quantity</option>
                        {optionsList.alcoholQuantity.map((quantity, idx) => (
                          <option key={idx} value={quantity}>{quantity}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </>
              )}

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="9" className="form-label-lg">Do you exercise? {handleTooltip('We ask about your exercise habits to provide personalized fitness and health advice.')}</Form.Label>
                <Col sm="3">
                  <Form.Check
                    type="checkbox"
                    name="lifestyle.exercises"
                    checked={formData.lifestyle.exercises}
                    onChange={handleChange}
                    className="form-check-lg"
                  />
                </Col>
              </Form.Group>

              {formData.lifestyle.exercises && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-lg">Exercise Frequency {handleTooltip('Please select how frequently you exercise to understand your fitness routine.')}</Form.Label>
                    <div className="select-wrapper">
                      <Form.Control
                        as="select"
                        name="lifestyle.exerciseFrequency"
                        value={formData.lifestyle.exerciseFrequency}
                        onChange={handleChange}
                        className="form-select-lg"
                        isInvalid={!!errors.exerciseFrequency}
                      >
                        <option value="">Select frequency</option>
                        {optionsList.exerciseFrequency.map((frequency, idx) => (
                          <option key={idx} value={frequency}>{frequency}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-lg">Exercise Intensity {handleTooltip('Please select the intensity of your exercises to better tailor fitness advice.')}</Form.Label>
                    <div className="select-wrapper">
                      <Form.Control
                        as="select"
                        name="lifestyle.exerciseIntensity"
                        value={formData.lifestyle.exerciseIntensity}
                        onChange={handleChange}
                        className="form-select-lg"
                        isInvalid={!!errors.exerciseIntensity}
                      >
                        <option value="">Select intensity</option>
                        {optionsList.exerciseIntensity.map((intensity, idx) => (
                          <option key={idx} value={intensity}>{intensity}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="form-label-lg">Exercise Type {handleTooltip('Please select the type of exercises you usually do to understand your fitness preferences.')}</Form.Label>
                    <div className="select-wrapper">
                      <Form.Control
                        as="select"
                        name="lifestyle.exerciseType"
                        value={formData.lifestyle.exerciseType}
                        onChange={handleChange}
                        className="form-select-lg"
                        isInvalid={!!errors.exerciseType}
                      >
                        <option value="">Select type</option>
                        {optionsList.exerciseType.map((type, idx) => (
                          <option key={idx} value={type}>{type}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </>
              )}
              <div className="d-flex justify-content-end">
                <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
                <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
              </div>
            </div>
          )}

          {currentPart === 6 && (
            <div id="part-6" className="form-part">
              <h3 className="icon-box h4">Medication and Allergies</h3>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Current Medication {handleTooltip('Please list your current medications to ensure we can account for them in your treatment plan if not please type N/A.')}</Form.Label>
                <Form.Control
                  type="text"
                  name="medication"
                  value={formData.medication}
                  onChange={handleChange}
                  placeholder="Enter medications, separated by commas"
                  className="form-control-lg"
                  isInvalid={!!errors.medication}
                  style={{ fontSize: '18px' }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Allergies {handleTooltip('Please list your allergies so that we can avoid recommending treatments that might cause an adverse reaction if not please type N/A.')}</Form.Label>
                <Form.Control
                  type="text"
                  name="allergies"
                  value={formData.allergies}
                  onChange={handleChange}
                  placeholder="Enter allergies, separated by commas"
                  className="form-control-lg"
                  isInvalid={!!errors.allergies}
                  style={{ fontSize: '18px' }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Previous Surgeries {handleTooltip('Please list any previous surgeries to provide a comprehensive view of your medical history if not please type N/A. ')}</Form.Label>
                <Form.Control
                  type="text"
                  name="surgeries"
                  value={formData.surgeries}
                  onChange={handleChange}
                  placeholder="Enter previous surgeries, separated by commas"
                  className="form-control-lg"
                  isInvalid={!!errors.surgeries}
                  style={{ fontSize: '18px' }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Previous Hospitalizations {handleTooltip('Please list any previous hospitalizations to help us understand your medical background if not please type N/A. ')}</Form.Label>
                <Form.Control
                  type="text"
                  name="hospitalizations"
                  value={formData.hospitalizations}
                  onChange={handleChange}
                  placeholder="Enter previous hospitalizations, separated by commas"
                  className="form-control-lg"
                  isInvalid={!!errors.hospitalizations}
                  style={{ fontSize: '18px' }}
                />
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
                <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
              </div>
            </div>
          )}

          {currentPart === 7 && (
            <div id="part-7" className="form-part">
              <h3 className="icon-box h4">Psychological State</h3>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Stress Level {handleTooltip('Please rate your current stress level to help us provide appropriate support.')}</Form.Label>
                <div className="d-flex align-items-center">
                  <span role="img" aria-label="stressed">😱 Very Stress</span>
                  {Array.from({ length: 5 }, (_, i) => (
                    <Button
                      key={i + 1}
                      variant={formData.psychological.stressLevel === i + 1 ? 'primary' : 'outline-primary'}
                      onClick={() => handleStressLevelChange(i + 1)}
                      className="mx-1"
                    >
                      {i + 1}
                    </Button>
                  ))}
                  <span role="img" aria-label="calm">😌 Calm</span>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">General Mood {handleTooltip('Please rate your general mood to help us understand your mental well-being.')}</Form.Label>
                <div className="d-flex align-items-center">
                  <span role="img" aria-label="sad">😢 Sadness</span>
                  {Array.from({ length: 5 }, (_, i) => (
                    <Button
                      key={i + 1}
                      variant={formData.psychological.mood === i + 1 ? 'primary' : 'outline-primary'}
                      onClick={() => handleMoodChange(i + 1)}
                      className="mx-1"
                    >
                      {i + 1}
                    </Button>
                  ))}
                  <span role="img" aria-label="happy">😄 Happyness</span>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">Hours of Social Activity {handleTooltip('Please enter the average number of hours you spend on social activities per week to help us understand your social engagement.')}</Form.Label>
                <Form.Control
                  type="number"
                  name="psychological.socialActivityHours"
                  value={formData.psychological.socialActivityHours}
                  onChange={handleChange}
                  className="form-control-lg"
                  isInvalid={!!errors.socialActivityHours}
                />
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
                <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
              </div>
            </div>
          )}

          {currentPart === 8 && (
            <div id="part-8" className="form-part">
              <h3 className="icon-box h4">Health Data</h3>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="form-label-lg">
                  Weight {handleTooltip('Please enter your weight to calculate your body mass index (BMI) and assess health risks associated with weight. For this reason, it is not possible to combine the metric system with the English system.')}
                </Form.Label>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.weightKg"
                    value={formData.anthropometry.weightKg}
                    onChange={handleChange}
                    placeholder="kg"
                    disabled={!!formData.anthropometry.weightLbs || !!formData.anthropometry.heightIn}
                    className="form-control-lg"
                    isInvalid={!!errors.weight}
                  />
                  <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
                </Col>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.weightLbs"
                    value={formData.anthropometry.weightLbs}
                    onChange={handleChange}
                    placeholder="lbs"
                    disabled={!!formData.anthropometry.weightKg || !!formData.anthropometry.heightCm}
                    className="form-control-lg"
                    isInvalid={!!errors.weight}
                  />
                  <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="form-label-lg">
                  Height {handleTooltip('Please enter your height to calculate your BMI and assess growth and development. For this reason, it is not possible to combine the metric system with the English system.')}
                </Form.Label>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.heightCm"
                    value={formData.anthropometry.heightCm}
                    onChange={handleChange}
                    placeholder="cm"
                    disabled={!!formData.anthropometry.heightIn || !!formData.anthropometry.weightLbs}
                    className="form-control-lg"
                    isInvalid={!!errors.height}
                  />
                  <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
                </Col>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.heightIn"
                    value={formData.anthropometry.heightIn}
                    onChange={handleChange}
                    placeholder="inches"
                    disabled={!!formData.anthropometry.heightCm || !!formData.anthropometry.weightKg}
                    className="form-control-lg"
                    isInvalid={!!errors.height}
                  />
                  <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">
                  Waist Circumference {handleTooltip('Please enter your waist circumference to assess health risks related to abdominal fat, this input is not mandatory. **Leave blank if you do not have that information.')}
                </Form.Label>
                <Form.Control
                  type="number"
                  name="anthropometry.waistCircumference"
                  value={formData.anthropometry.waistCircumference}
                  onChange={handleChange}
                  className="form-control-lg"
                  placeholder="cm"
                  isInvalid={!!errors.waistCircumference}
                />
                <Form.Control.Feedback type="invalid">{errors.waistCircumference}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">
                  Hip Circumference {handleTooltip('Please enter your hip circumference to calculate your waist-to-hip ratio, which can indicate health risks, this input is not mandatory. **Leave blank if you do not have that information.')}
                </Form.Label>
                <Form.Control
                  type="number"
                  name="anthropometry.hipCircumference"
                  value={formData.anthropometry.hipCircumference}
                  onChange={handleChange}
                  className="form-control-lg"
                  placeholder="cm"
                  isInvalid={!!errors.hipCircumference}
                />
                <Form.Control.Feedback type="invalid">{errors.hipCircumference}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="form-label-lg">
                  Blood Pressure {handleTooltip('Please enter your blood pressure to help monitor your cardiovascular health, you need a digital pressure machine this input is not mandatory. **Leave blank if you do not have that information.')}
                </Form.Label>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.bloodPressureSystolic"
                    value={formData.anthropometry.bloodPressureSystolic}
                    onChange={handleChange}
                    placeholder="systolic"
                    className="form-control-lg"
                    isInvalid={!!errors.bloodPressureSystolic}
                  />
                  <Form.Control.Feedback type="invalid">{errors.bloodPressureSystolic}</Form.Control.Feedback>
                </Col>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.bloodPressureDiastolic"
                    value={formData.anthropometry.bloodPressureDiastolic}
                    onChange={handleChange}
                    placeholder="diastolic"
                    className="form-control-lg"
                    isInvalid={!!errors.bloodPressureDiastolic}
                  />
                  <Form.Control.Feedback type="invalid">{errors.bloodPressureDiastolic}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">
                  Blood Glucose {handleTooltip('Please enter your blood glucose level to monitor your risk for diabetes and other metabolic conditions, you need a glucometer this input is not mandatory. **Leave blank if you do not have that information.')}
                </Form.Label>
                <Form.Control
                  type="number"
                  name="anthropometry.bloodGlucose"
                  value={formData.anthropometry.bloodGlucose}
                  onChange={handleChange}
                  className="form-control-lg"
                  placeholder="mg/dL"
                  isInvalid={!!errors.bloodGlucose}
                />
                <Form.Control.Feedback type="invalid">{errors.bloodGlucose}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-lg">
                  Oxygen Saturation {handleTooltip('Please enter your oxygen saturation level to monitor your respiratory health. **Leave blank if you do not have that information.')}
                </Form.Label>
                <Form.Control
                  type="number"
                  name="anthropometry.oxygenSaturation"
                  value={formData.anthropometry.oxygenSaturation}
                  onChange={handleChange}
                  className="form-control-lg"
                  placeholder="%"
                  isInvalid={!!errors.oxygenSaturation}
                />
                <Form.Control.Feedback type="invalid">{errors.oxygenSaturation}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3" className="form-label-lg">
                  Body Temperature {handleTooltip('Please enter your body temperature to monitor for fever or hypothermia. **Leave blank if you do not have that information.')}
                </Form.Label>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.bodyTemperatureC"
                    value={formData.anthropometry.bodyTemperatureC}
                    onChange={handleChange}
                    placeholder="°C"
                    disabled={!!formData.anthropometry.bodyTemperatureF}
                    className="form-control-lg"
                    isInvalid={!!errors.bodyTemperature}
                  />
                  <Form.Control.Feedback type="invalid">{errors.bodyTemperature}</Form.Control.Feedback>
                </Col>
                <Col sm="4">
                  <Form.Control
                    type="number"
                    name="anthropometry.bodyTemperatureF"
                    value={formData.anthropometry.bodyTemperatureF}
                    onChange={handleChange}
                    placeholder="°F"
                    disabled={!!formData.anthropometry.bodyTemperatureC}
                    className="form-control-lg"
                    isInvalid={!!errors.bodyTemperature}
                  />
                  <Form.Control.Feedback type="invalid">{errors.bodyTemperature}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
                <Button type="submit" className="btn btn-primary ml-2">Submit</Button>
              </div>
            </div>
          )}
        </Form>
      </Card>
    </div>
  );
}

export default ClinicalHistoryForm;

// import React, { useState, useEffect } from 'react';
// import { Form, Button, Col, Row, ProgressBar, Card, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import axiosPatient from '../utils/axiosPatient';
// import optionsList from '../assets/medicalConditions.json';
// import './ClinicalHistoryForm.css';
// import { useTheme } from '../context/ThemeContext';
// import { useAuth } from '../context/AuthContext';

// const ClinicalHistoryForm = () => {
//   const [formData, setFormData] = useState({
//     birthDate: '',
//     genderAtBirth: '',
//     hasMedicalConditions: false,
//     medicalConditions: [],
//     familyHistory: [{ condition: '', relationship: '' }],
//     immunization: [],
//     lifestyle: {
//       smokes: false,
//       drinksAlcohol: false,
//       exercises: false,
//       smokeFrequency: '',
//       smokePerYears: '',
//       wantToQuitSmoking: false,
//       alcoholFrequency: '',
//       alcoholQuantity: '',
//       exerciseFrequency: '',
//       exerciseIntensity: '',
//       exerciseType: ''
//     },
//     medication: '',
//     otherCondition: '',
//     allergies: '',
//     surgeries: '',
//     hospitalizations: '',
//     psychological: {
//       stressLevel: 5,
//       mood: 5,
//       socialActivityHours: ''
//     },
//     anthropometry: {
//       weightKg: '',
//       weightLbs: '',
//       heightCm: '',
//       heightIn: '',
//       waistCircumference: '',
//       hipCircumference: '',
//       bloodPressureSystolic: '',
//       bloodPressureDiastolic: '',
//       bloodGlucose: '',
//       oxygenSaturation: '',
//       bodyTemperatureC: '',
//       bodyTemperatureF: ''
//     }
//   });

//   const { darkMode } = useTheme();
//   const { user } = useAuth();
//   const [currentPart, setCurrentPart] = useState(1);
//   const [errors, setErrors] = useState({});
//   const navigate = useNavigate();



//   useEffect(() => {
//     console.log('User data from useAuth:', user);
// }, [user]);


//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     const keys = name.split('.');

//     if (name in formData.lifestyle) {
//       setFormData(prevState => ({
//         ...prevState,
//         lifestyle: {
//           ...prevState.lifestyle,
//           [name]: type === 'checkbox' ? checked : value
//         }
//       }));
//     } else if (name.startsWith('familyHistory')) {
//       const index = parseInt(name.split('_')[1]);
//       const key = name.split('_')[2];
//       const updatedFamilyHistory = [...formData.familyHistory];
//       updatedFamilyHistory[index][key] = value;
//       setFormData(prevState => ({
//         ...prevState,
//         familyHistory: updatedFamilyHistory
//       }));
//     } else if (keys.length === 2) {
//       setFormData(prevState => ({
//         ...prevState,
//         [keys[0]]: {
//           ...prevState[keys[0]],
//           [keys[1]]: type === 'checkbox' ? checked : value
//         }
//       }));
//     } else if (keys.length === 3) {
//       setFormData(prevState => ({
//         ...prevState,
//         [keys[0]]: {
//           ...prevState[keys[0]],
//           [keys[1]]: {
//             ...prevState[keys[0]][keys[1]],
//             [keys[2]]: type === 'checkbox' ? checked : value
//           }
//         }
//       }));
//     } else {
//       setFormData(prevState => ({
//         ...prevState,
//         [name]: type === 'checkbox' ? checked : value
//       }));
//     }
//   };

//   const handleMedicalConditionsChange = (e) => {
//     const { value, checked } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       medicalConditions: checked
//         ? [...prevState.medicalConditions, value]
//         : prevState.medicalConditions.filter(cond => cond !== value)
//     }));
//   };

//   const handleAddFamilyHistory = () => {
//     setFormData(prevState => ({
//       ...prevState,
//       familyHistory: [...prevState.familyHistory, { condition: '', relationship: '' }]
//     }));
//   };

//   const handleRemoveFamilyHistory = (index) => {
//     setFormData(prevState => ({
//       ...prevState,
//       familyHistory: prevState.familyHistory.filter((_, i) => i !== index)
//     }));
//   };

//   const handleImmunizationChange = (e) => {
//     const { value, checked } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       immunization: checked
//         ? [...prevState.immunization, value]
//         : prevState.immunization.filter(imm => imm !== value)
//     }));
//   };
//   const calculateAge = (birthDate) => {
//     const today = new Date();
//     const birthDateObj = new Date(birthDate);
//     let age = today.getFullYear() - birthDateObj.getFullYear();
//     const monthDifference = today.getMonth() - birthDateObj.getMonth();

//     if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
//       age--;
//     }
//     return age;
//   };

//   const validateForm = () => {
//     const newErrors = {};
  
//       if (currentPart === 1) {
//         if (!formData.birthDate) {
//           newErrors.birthDate = 'Date of birth is required';
//         } else if (calculateAge(formData.birthDate) < 13) {
//           newErrors.birthDate = 'The minimum age to use this service is 13 years';
//         }
//         if (!formData.genderAtBirth) newErrors.genderAtBirth = 'Gender at birth is required';
//       }

//     if (currentPart === 2) {
//       if (formData.hasMedicalConditions && formData.medicalConditions.length === 0) {
//         newErrors.medicalConditions = 'At least one medical condition must be selected';
//       }
//     }

//     if (currentPart === 3) {
//       formData.familyHistory.forEach((history, index) => {
//         if (!history.condition) newErrors[`familyHistory_${index}_condition`] = 'Condition is required';
//         if (!history.relationship) newErrors[`familyHistory_${index}_relationship`] = 'Relationship is required';
//       });
//     }

//     if (currentPart === 4) {
//       if (formData.immunization.length === 0) newErrors.immunization = 'At least one immunization must be selected';
//     }

//     if (currentPart === 5) {
//       if (formData.lifestyle.smokes && !formData.lifestyle.smokeFrequency) newErrors.smokeFrequency = 'Smoke frequency is required';
//       if (formData.lifestyle.smokes && !formData.lifestyle.smokePerYears) newErrors.smokePerYears = 'Years smoking is required';
//       if (formData.lifestyle.drinksAlcohol && !formData.lifestyle.alcoholFrequency) newErrors.alcoholFrequency = 'Alcohol frequency is required';
//       if (formData.lifestyle.drinksAlcohol && !formData.lifestyle.alcoholQuantity) newErrors.alcoholQuantity = 'Alcohol quantity is required';
//       if (formData.lifestyle.exercises && !formData.lifestyle.exerciseFrequency) newErrors.exerciseFrequency = 'Exercise frequency is required';
//       if (formData.lifestyle.exercises && !formData.lifestyle.exerciseIntensity) newErrors.exerciseIntensity = 'Exercise intensity is required';
//       if (formData.lifestyle.exercises && !formData.lifestyle.exerciseType) newErrors.exerciseType = 'Exercise type is required';
//     }

//     if (currentPart === 6) {
//       if (!formData.medication) newErrors.medication = 'Medication is required, is do not apply please type "N/A"';
//       if (!formData.allergies) newErrors.allergies = 'Allergies are requiredm, is do not apply please type "N/A"';
//       if (!formData.surgeries) newErrors.surgeries = 'Previous surgeries are required, is do not apply please type "N/A"';
//       if (!formData.hospitalizations) newErrors.hospitalizations = 'Previous hospitalizations are required, is do not apply please type "N/A"';
//     }

//     if (currentPart === 7) {
//       if (!formData.psychological.socialActivityHours) newErrors.socialActivityHours = 'Social activity hours are required';
//     }

//     if (currentPart === 8) {
//       if (!formData.anthropometry.weightKg && !formData.anthropometry.weightLbs) newErrors.weight = 'Weight is required';
//       if (!formData.anthropometry.heightCm && !formData.anthropometry.heightIn) newErrors.height = 'Height is required';
//       if (formData.anthropometry.weightKg && (formData.anthropometry.weightKg < 10 || formData.anthropometry.weightKg > 650)) newErrors.weight = 'Weight must be in kilograms (valid range: 10-650 kg)';
//       if (formData.anthropometry.weightLbs && (formData.anthropometry.weightLbs < 22 || formData.anthropometry.weightLbs > 970)) newErrors.weight = 'Weight must be in pounds (valid range: 22-970 lbs)';
    
//       // Validaciones de altura
//       if (formData.anthropometry.heightCm && (formData.anthropometry.heightCm < 50 || formData.anthropometry.heightCm > 300)) newErrors.height = 'Height must be in centimeters (valid range: 50-300 cm)';
//       if (formData.anthropometry.heightIn && (formData.anthropometry.heightIn < 20 || formData.anthropometry.heightIn > 118)) newErrors.height = 'Height must be in inches (valid range: 20-118 inches)';
    
//       // Validaciones de circunferencia de cintura y cadera
//       if (formData.anthropometry.waistCircumference && (formData.anthropometry.waistCircumference < 20 || formData.anthropometry.waistCircumference > 200)) newErrors.waistCircumference = 'Waist circumference must be in centimeters (valid range: 20-200 cm) \n **Leaved blank if you did not have that information.**';
//       if (formData.anthropometry.hipCircumference && (formData.anthropometry.hipCircumference < 30 || formData.anthropometry.hipCircumference > 250)) newErrors.hipCircumference = 'Hip circumference must be in centimeters (valid range: 30-250 cm) \n **Leaved blank if you did not have that information.**';
    
//       if (formData.anthropometry.weightKg && formData.anthropometry.weightKg < 1) newErrors.weight = 'Weight must be greater than 0 kg';
//       if (formData.anthropometry.weightLbs && formData.anthropometry.weightLbs < 1) newErrors.weight = 'Weight must be greater than 0 lbs';
//       // if (formData.anthropometry.weightLbs && (formData.anthropometry.weightLbs < 50 || formData.anthropometry.weightLbs > 300)) newErrors.weight = 'Weight must be in pounds (valid range: 10-350 Pounds)';
        
//       if (formData.anthropometry.heightCm && formData.anthropometry.heightCm < 1) newErrors.height = 'Height must be greater than 0 cm';
//       if (formData.anthropometry.heightIn && formData.anthropometry.heightIn < 1) newErrors.height = 'Height must be greater than 0 inches';
//       // Validaciones de circunferencia de cintura y cadera
//       // if (formData.anthropometry.waistCircumference && (formData.anthropometry.waistCircumference < 20 || formData.anthropometry.waistCircumference > 200)) newErrors.waistCircumference = 'Waist circumference must be in centimeters (valid range: 20-200 cm)  **Leaved blank if you did not have that information.**';
//       // if (formData.anthropometry.hipCircumference && (formData.anthropometry.hipCircumference < 30 || formData.anthropometry.hipCircumference > 250)) newErrors.hipCircumference = 'Hip circumference must be in centimeters (valid range: 30-250 cm)  **Leaved blank if you did not have that information.**';

//       // Validaciones de presión arterial (opcional pero con rango válido)
//       if (formData.anthropometry.bloodPressureSystolic && (formData.anthropometry.bloodPressureSystolic < 50 || formData.anthropometry.bloodPressureSystolic > 250)) newErrors.bloodPressureSystolic = 'Systolic blood pressure must be in valid range (50-250 mmHg) \n **Leaved blank if you did not have that information.**';
//       if (formData.anthropometry.bloodPressureDiastolic && (formData.anthropometry.bloodPressureDiastolic < 30 || formData.anthropometry.bloodPressureDiastolic > 150)) newErrors.bloodPressureDiastolic = 'Diastolic blood pressure must be in valid range (30-150 mmHg) \n **Leaved blank if you did not have that information.**';

//       // Validaciones de glucosa en sangre
//       if (formData.anthropometry.bloodGlucose && (formData.anthropometry.bloodGlucose < 30 || formData.anthropometry.bloodGlucose > 600)) newErrors.bloodGlucose = 'Blood glucose level must be in valid range (30-600 mg/dL) \n  **Leaved blank if you did not have that information.**';

//       // Validaciones de saturación de oxígeno
//       if (formData.anthropometry.oxygenSaturation && (formData.anthropometry.oxygenSaturation < 70 || formData.anthropometry.oxygenSaturation > 100)) newErrors.oxygenSaturation = 'Oxygen saturation must be between 70 and 100% \n  **Leaved blank if you did not have that information.**';

//       // Validaciones de temperatura corporal
//       if (formData.anthropometry.bodyTemperatureC && (formData.anthropometry.bodyTemperatureC < 30 || formData.anthropometry.bodyTemperatureC > 45)) newErrors.bodyTemperature = 'Body temperature must be in valid range (30-45 °C) \n **Leaved blank if you did not have that information.**';
//       if (formData.anthropometry.bodyTemperatureF && (formData.anthropometry.bodyTemperatureF < 86 || formData.anthropometry.bodyTemperatureF > 113)) newErrors.bodyTemperature = 'Body temperature must be in valid range (86-113 °F) \n  **Leaved blank if you did not have that information.**';
//     }
    
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   if (validateForm()) {
//   //     console.log('Form Data:', formData);
  
//   //     try {
//   //       const response = await axiosProfile.get('/profiles/check');
//   //       if (response.data.isProfileComplete) {
//   //         navigate('/dashboard');
//   //       } else {
//   //         navigate('/create-profile');
//   //       }
//   //     } catch (error) {
//   //       console.error('Error checking profile status:', error);
//   //     }
//   //   }
//   // };



//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   console.log('Final Form Data:', formData); // Verificar datos finales
//   //   const data = new FormData();
//   //   Object.keys(formData).forEach((key) => {
//   //       if (formData[key] !== null && formData[key] !== '' && formData[key] !== undefined) {
//   //           if (key === 'issue_date' && formData[key]) {
//   //               const date = new Date(formData[key]);
//   //               const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
//   //               data.append(key, formattedDate);
//   //           } else {
//   //               data.append(key, formData[key]);
//   //           }
//   //       }
//   //   });



//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log('Final Form Data:', formData); // Verificar datos finales

//     if (validateForm()) {
//         console.log('Form Data:', formData);

//         // Agregar los datos del usuario al formData
//         if (user) {
//             console.log('User data from useAuth:', user);
//             formData.user_id = user.user_id;  // Asegúrate de usar user.user_id
//             formData.username = user.username;
//             formData.role = user.role;
//         } else {

//             console.error('User data is not available');
//         }

//         try {
//             const response = await axiosPatient.post('/clinical_history', formData, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${user.token}`,
//                 }
//             });
//             console.log(response.data);
//             navigate('/dashboard');  // O la ruta que desees después de enviar el formulario exitosamente
//         } catch (error) {
//             console.error('Error submitting form:', error);
//         }
//     }
// };


//   const nextPart = () => {
//     if (validateForm()) {
//       if (currentPart < totalParts) {
//         setCurrentPart(prevPart => prevPart + 1);
//       }
//     }
//   };

//   const prevPart = () => {
//     if (currentPart > 1) {
//       setCurrentPart(prevPart => prevPart - 1);
//     }
//   };

//   const handleStressLevelChange = (value) => {
//     setFormData(prevState => ({
//       ...prevState,
//       psychological: {
//         ...prevState.psychological,
//         stressLevel: value
//       }
//     }));
//   };

//   const handleMoodChange = (value) => {
//     setFormData(prevState => ({
//       ...prevState,
//       psychological: {
//         ...prevState.psychological,
//         mood: value
//       }
//     }));
//   };

//   const handleTooltip = (text) => (
//     <OverlayTrigger
//       placement="right"
//       overlay={<Tooltip id="tooltip-right">{text}</Tooltip>}
//     >
//       <span className="info-circle">?</span>
//     </OverlayTrigger>
//   );

//   const totalParts = 8;
//   const progress = Math.min(Math.floor((currentPart / totalParts) * 100), 100);

//   return (
//     <div className={`clinical-history-form container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//       <h1 className="title-cat">Clinical History</h1>
//       <ProgressBar now={progress} label={`${progress}%`} className="mb-4 progress-bar-gradient" />
//       <Card className={`p-4 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//         {Object.keys(errors).length > 0 && (
//           <Alert variant="danger">
//             {Object.values(errors).map((error, idx) => (
//               <p key={idx}>{error}</p>
//             ))}
//           </Alert>
//         )}
//         <Form onSubmit={handleSubmit}>
//           {currentPart === 1 && (
//             <div id="part-1" className="form-part">
//               <h3 className="icon-box h4">Personal Information</h3>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Date of Birth {handleTooltip('We need your date of birth to calculate your age and assess health risks associated with different age groups.')}</Form.Label>
//                 <Form.Control
//                   type="date"
//                   name="birthDate"
//                   value={formData.birthDate}
//                   onChange={handleChange}
//                   className="form-control-lg"
//                   isInvalid={!!errors.birthDate}
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Gender at Birth {handleTooltip('We need to know your gender at birth to provide accurate health recommendations and screenings.')}</Form.Label>
//                 <div className="select-wrapper">
//                   <Form.Control
//                     as="select"
//                     name="genderAtBirth"
//                     value={formData.genderAtBirth}
//                     onChange={handleChange}
//                     className="form-select-lg"
//                     isInvalid={!!errors.genderAtBirth}
//                   >
//                     <option value="">Select gender</option>
//                     <option value="male">Male</option>
//                     <option value="female">Female</option>
//                   </Form.Control>
//                 </div>
//               </Form.Group>
//               <div className="d-flex justify-content-end">
//                 <Button type="button" className="btn btn-primary" onClick={nextPart}>Continue</Button>
//               </div>
//             </div>
//           )}

//           {currentPart === 2 && (
//             <div id="part-2" className="form-part">
//               <h3 className="icon-box h4">Personal Medical History</h3>
//               <Form.Group as={Row} className="mb-3">
//                 <Form.Label column sm="9" className="form-label-lg">Do you have any medical conditions? {handleTooltip('Knowing your medical conditions helps us to tailor health advice and treatment plans to your specific needs.')}</Form.Label>
//                 <Col sm="3">
//                   <Form.Check
//                     type="checkbox"
//                     name="hasMedicalConditions"
//                     checked={formData.hasMedicalConditions}
//                     onChange={handleChange}
//                     className="form-check-lg"
//                   />
//                 </Col>
//               </Form.Group>

//               {formData.hasMedicalConditions && (
//                 <Form.Group className="mb-3">
//                   <Form.Label className="form-label-lg">Medical Conditions {handleTooltip('Please select your medical conditions so that we can better understand your health needs.')}</Form.Label>
//                   <Row>
//                     <Col md={6}>
//                       {optionsList.medicalConditions.slice(0, Math.ceil(optionsList.medicalConditions.length / 2)).map((condition, index) => (
//                         <Form.Check
//                           key={index}
//                           type="checkbox"
//                           label={condition}
//                           value={condition}
//                           checked={formData.medicalConditions.includes(condition)}
//                           onChange={handleMedicalConditionsChange}
//                           className="form-check-lg"
//                         />
//                       ))}
//                     </Col>
//                     <Col md={6}>
//                       {optionsList.medicalConditions.slice(Math.ceil(optionsList.medicalConditions.length / 2)).map((condition, index) => (
//                         <Form.Check
//                           key={index}
//                           type="checkbox"
//                           label={condition}
//                           value={condition}
//                           checked={formData.medicalConditions.includes(condition)}
//                           onChange={handleMedicalConditionsChange}
//                           className="form-check-lg"
//                         />
//                       ))}
//                       <Form.Check
//                         type="checkbox"
//                         label="Other"
//                         value="Other"
//                         checked={formData.medicalConditions.includes('Other')}
//                         onChange={handleMedicalConditionsChange}
//                         className="form-check-lg"
//                       />
//                       {formData.medicalConditions.includes('Other') && (
//                         <Form.Control
//                           type="text"
//                           name="otherCondition"
//                           value={formData.otherCondition}
//                           onChange={handleChange}
//                           placeholder="Please specify"
//                           className="mt-2"
//                         />
//                       )}
//                     </Col>
//                   </Row>
//                 </Form.Group>
//               )}
//               <div className="d-flex justify-content-end">
//                 <Button type="button" className="btn btn-primary" onClick={nextPart}>Continue</Button>
//               </div>
//             </div>
//           )}

//           {currentPart === 3 && (
//             <div id="part-3" className="form-part">
//               <h3 className="icon-box h4">Family History</h3>
//               {formData.familyHistory.map((history, index) => (
//                 <div key={index} className="mb-3">
//                   <Row>
//                     <Col>
//                       <Form.Group>
//                         <Form.Label className="form-label-lg">Condition</Form.Label>
//                         <div className="select-wrapper">
//                           <Form.Control
//                             as="select"
//                             name={`familyHistory_${index}_condition`}
//                             value={history.condition}
//                             onChange={handleChange}
//                             className="form-select-lg"
//                             isInvalid={!!errors[`familyHistory_${index}_condition`]}
//                           >
//                             <option value="">Select condition</option>
//                             {optionsList.medicalConditions.map((condition, idx) => (
//                               <option key={idx} value={condition}>{condition}</option>
//                             ))}
//                           </Form.Control>
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col>
//                       <Form.Group>
//                         <Form.Label className="form-label-lg">Relationship</Form.Label>
//                         <div className="select-wrapper">
//                           <Form.Control
//                             as="select"
//                             name={`familyHistory_${index}_relationship`}
//                             value={history.relationship}
//                             onChange={handleChange}
//                             className="form-select-lg"
//                             isInvalid={!!errors[`familyHistory_${index}_relationship`]}
//                           >
//                             <option value="">Select relationship</option>
//                             {optionsList.relationships.map((relationship, idx) => (
//                               <option key={idx} value={relationship}>{relationship}</option>
//                             ))}
//                           </Form.Control>
//                         </div>
//                       </Form.Group>
//                     </Col>
//                     <Col xs="auto" className="d-flex align-items-center">
//                       <Button
//                         variant="danger"
//                         onClick={() => handleRemoveFamilyHistory(index)}
//                       >
//                         Remove
//                       </Button>
//                     </Col>
//                   </Row>
//                 </div>
//               ))}
//               <Button variant="secondary" onClick={handleAddFamilyHistory}>Add Another Family History</Button>
//               <div className="d-flex justify-content-end">
//                 <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
//                 <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
//               </div>
//             </div>
//           )}

//           {currentPart === 4 && (
//             <div id="part-4" className="form-part">
//               <h3 className="icon-box h4">Immunization</h3>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Immunization Records {handleTooltip('Knowing your immunization history helps us ensure that you are up to date with vaccines and protected against preventable diseases.')}</Form.Label>
//                 {optionsList.immunizations.map((immunization, index) => (
//                   <Form.Check
//                     key={index}
//                     type="checkbox"
//                     label={immunization}
//                     value={immunization}
//                     checked={formData.immunization.includes(immunization)}
//                     onChange={handleImmunizationChange}
//                     className="form-check-lg"
//                   />
//                 ))}
//               </Form.Group>
//               <div className="d-flex justify-content-end">
//                 <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
//                 <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
//               </div>
//             </div>
//           )}

//           {currentPart === 5 && (
//             <div id="part-5" className="form-part">
//               <h3 className="icon-box h4">Lifestyle</h3>
//               <Form.Group as={Row} className="mb-3">
//                 <Form.Label column sm="9" className="form-label-lg">Do you smoke? {handleTooltip('We ask about your smoking habits to assess your risk for various health conditions and to offer appropriate support if you want to quit.')}</Form.Label>
//                 <Col sm="3">
//                   <Form.Check
//                     type="checkbox"
//                     name="lifestyle.smokes"
//                     checked={formData.lifestyle.smokes}
//                     onChange={handleChange}
//                     className="form-check-lg"
//                   />
//                 </Col>
//               </Form.Group>

//               {formData.lifestyle.smokes && (
//                 <>
//                   <Form.Group className="mb-3">
//                     <Form.Label className="form-label-lg">Smoke Frequency {handleTooltip('Please select how frequently you smoke to better understand your smoking habits.')}</Form.Label>
//                     <div className="select-wrapper">
//                       <Form.Control
//                         as="select"
//                         name="lifestyle.smokeFrequency"
//                         value={formData.lifestyle.smokeFrequency}
//                         onChange={handleChange}
//                         className="form-select-lg"
//                         isInvalid={!!errors.smokeFrequency}
//                       >
//                         <option value="">Select frequency</option>
//                         {optionsList.smokingFrequency.map((frequency, idx) => (
//                           <option key={idx} value={frequency}>{frequency}</option>
//                         ))}
//                       </Form.Control>
//                     </div>
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label className="form-label-lg">Years Smoking {handleTooltip('Please select how many years you have been smoking to understand the duration of your habit.')}</Form.Label>
//                     <div className="select-wrapper">
//                       <Form.Control
//                         as="select"
//                         name="lifestyle.smokePerYears"
//                         value={formData.lifestyle.smokePerYears}
//                         onChange={handleChange}
//                         className="form-select-lg"
//                         isInvalid={!!errors.smokePerYears}
//                       >
//                         <option value="">Select years</option>
//                         {optionsList.years.map((year, idx) => (
//                           <option key={idx} value={year}>{year}</option>
//                         ))}
//                       </Form.Control>
//                     </div>
//                   </Form.Group>
//                 </>
//               )}

//               <Form.Group as={Row} className="mb-3">
//                 <Form.Label column sm="9" className="form-label-lg">Do you drink alcohol? {handleTooltip('We ask about your alcohol consumption to understand your lifestyle and to provide appropriate health advice.')}</Form.Label>
//                 <Col sm="3">
//                   <Form.Check
//                     type="checkbox"
//                     name="lifestyle.drinksAlcohol"
//                     checked={formData.lifestyle.drinksAlcohol}
//                     onChange={handleChange}
//                     className="form-check-lg"
//                   />
//                 </Col>
//               </Form.Group>

//               {formData.lifestyle.drinksAlcohol && (
//                 <>
//                   <Form.Group className="mb-3">
//                     <Form.Label className="form-label-lg">Alcohol Frequency {handleTooltip('Please select how frequently you drink alcohol to understand your drinking habits.')}</Form.Label>
//                     <div className="select-wrapper">
//                       <Form.Control
//                         as="select"
//                         name="lifestyle.alcoholFrequency"
//                         value={formData.lifestyle.alcoholFrequency}
//                         onChange={handleChange}
//                         className="form-select-lg"
//                         isInvalid={!!errors.alcoholFrequency}
//                       >
//                         <option value="">Select frequency</option>
//                         {optionsList.alcoholFrequency.map((frequency, idx) => (
//                           <option key={idx} value={frequency}>{frequency}</option>
//                         ))}
//                       </Form.Control>
//                     </div>
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label className="form-label-lg">Alcohol Quantity {handleTooltip('Please select how many drinks you usually have to better understand your alcohol consumption.')}</Form.Label>
//                     <div className="select-wrapper">
//                       <Form.Control
//                         as="select"
//                         name="lifestyle.alcoholQuantity"
//                         value={formData.lifestyle.alcoholQuantity}
//                         onChange={handleChange}
//                         className="form-select-lg"
//                         isInvalid={!!errors.alcoholQuantity}
//                       >
//                         <option value="">Select quantity</option>
//                         {optionsList.alcoholQuantity.map((quantity, idx) => (
//                           <option key={idx} value={quantity}>{quantity}</option>
//                         ))}
//                       </Form.Control>
//                     </div>
//                   </Form.Group>
//                 </>
//               )}

//               <Form.Group as={Row} className="mb-3">
//                 <Form.Label column sm="9" className="form-label-lg">Do you exercise? {handleTooltip('We ask about your exercise habits to provide personalized fitness and health advice.')}</Form.Label>
//                 <Col sm="3">
//                   <Form.Check
//                     type="checkbox"
//                     name="lifestyle.exercises"
//                     checked={formData.lifestyle.exercises}
//                     onChange={handleChange}
//                     className="form-check-lg"
//                   />
//                 </Col>
//               </Form.Group>

//               {formData.lifestyle.exercises && (
//                 <>
//                   <Form.Group className="mb-3">
//                     <Form.Label className="form-label-lg">Exercise Frequency {handleTooltip('Please select how frequently you exercise to understand your fitness routine.')}</Form.Label>
//                     <div className="select-wrapper">
//                       <Form.Control
//                         as="select"
//                         name="lifestyle.exerciseFrequency"
//                         value={formData.lifestyle.exerciseFrequency}
//                         onChange={handleChange}
//                         className="form-select-lg"
//                         isInvalid={!!errors.exerciseFrequency}
//                       >
//                         <option value="">Select frequency</option>
//                         {optionsList.exerciseFrequency.map((frequency, idx) => (
//                           <option key={idx} value={frequency}>{frequency}</option>
//                         ))}
//                       </Form.Control>
//                     </div>
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label className="form-label-lg">Exercise Intensity {handleTooltip('Please select the intensity of your exercises to better tailor fitness advice.')}</Form.Label>
//                     <div className="select-wrapper">
//                       <Form.Control
//                         as="select"
//                         name="lifestyle.exerciseIntensity"
//                         value={formData.lifestyle.exerciseIntensity}
//                         onChange={handleChange}
//                         className="form-select-lg"
//                         isInvalid={!!errors.exerciseIntensity}
//                       >
//                         <option value="">Select intensity</option>
//                         {optionsList.exerciseIntensity.map((intensity, idx) => (
//                           <option key={idx} value={intensity}>{intensity}</option>
//                         ))}
//                       </Form.Control>
//                     </div>
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label className="form-label-lg">Exercise Type {handleTooltip('Please select the type of exercises you usually do to understand your fitness preferences.')}</Form.Label>
//                     <div className="select-wrapper">
//                       <Form.Control
//                         as="select"
//                         name="lifestyle.exerciseType"
//                         value={formData.lifestyle.exerciseType}
//                         onChange={handleChange}
//                         className="form-select-lg"
//                         isInvalid={!!errors.exerciseType}
//                       >
//                         <option value="">Select type</option>
//                         {optionsList.exerciseType.map((type, idx) => (
//                           <option key={idx} value={type}>{type}</option>
//                         ))}
//                       </Form.Control>
//                     </div>
//                   </Form.Group>
//                 </>
//               )}
//               <div className="d-flex justify-content-end">
//                 <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
//                 <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
//               </div>
//             </div>
//           )}

//           {currentPart === 6 && (
//             <div id="part-6" className="form-part">
//               <h3 className="icon-box h4">Medication and Allergies</h3>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Current Medication {handleTooltip('Please list your current medications to ensure we can account for them in your treatment plan if not please type N/A.')}</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="medication"
//                   value={formData.medication}
//                   onChange={handleChange}
//                   placeholder="Enter medications, separated by commas"
//                   className="form-control-lg"
//                   isInvalid={!!errors.medication}
//                   style={{ fontSize: '18px' }}
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Allergies {handleTooltip('Please list your allergies so that we can avoid recommending treatments that might cause an adverse reaction if not please type N/A.')}</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="allergies"
//                   value={formData.allergies}
//                   onChange={handleChange}
//                   placeholder="Enter allergies, separated by commas"
//                   className="form-control-lg"
//                   isInvalid={!!errors.allergies}
//                   style={{ fontSize: '18px' }}
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Previous Surgeries {handleTooltip('Please list any previous surgeries to provide a comprehensive view of your medical history if not please type N/A. ')}</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="surgeries"
//                   value={formData.surgeries}
//                   onChange={handleChange}
//                   placeholder="Enter previous surgeries, separated by commas"
//                   className="form-control-lg"
//                   isInvalid={!!errors.surgeries}
//                   style={{ fontSize: '18px' }}
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Previous Hospitalizations {handleTooltip('Please list any previous hospitalizations to help us understand your medical background if not please type N/A. ')}</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="hospitalizations"
//                   value={formData.hospitalizations}
//                   onChange={handleChange}
//                   placeholder="Enter previous hospitalizations, separated by commas"
//                   className="form-control-lg"
//                   isInvalid={!!errors.hospitalizations}
//                   style={{ fontSize: '18px' }}
//                 />
//               </Form.Group>
//               <div className="d-flex justify-content-end">
//                 <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
//                 <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
//               </div>
//             </div>
//           )}

//           {currentPart === 7 && (
//             <div id="part-7" className="form-part">
//               <h3 className="icon-box h4">Psychological State</h3>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Stress Level {handleTooltip('Please rate your current stress level to help us provide appropriate support.')}</Form.Label>
//                 <div className="d-flex align-items-center">
//                   <span role="img" aria-label="stressed">😱 Very Stress</span>
//                   {Array.from({ length: 5 }, (_, i) => (
//                     <Button
//                       key={i + 1}
//                       variant={formData.psychological.stressLevel === i + 1 ? 'primary' : 'outline-primary'}
//                       onClick={() => handleStressLevelChange(i + 1)}
//                       className="mx-1"
//                     >
//                       {i + 1}
//                     </Button>
//                   ))}
//                   <span role="img" aria-label="calm">😌 Calm</span>
//                 </div>
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">General Mood {handleTooltip('Please rate your general mood to help us understand your mental well-being.')}</Form.Label>
//                 <div className="d-flex align-items-center">
//                   <span role="img" aria-label="sad">😢 Sadness</span>
//                   {Array.from({ length: 5 }, (_, i) => (
//                     <Button
//                       key={i + 1}
//                       variant={formData.psychological.mood === i + 1 ? 'primary' : 'outline-primary'}
//                       onClick={() => handleMoodChange(i + 1)}
//                       className="mx-1"
//                     >
//                       {i + 1}
//                     </Button>
//                   ))}
//                   <span role="img" aria-label="happy">😄 Happyness</span>
//                 </div>
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label className="form-label-lg">Hours of Social Activity {handleTooltip('Please enter the average number of hours you spend on social activities per week to help us understand your social engagement.')}</Form.Label>
//                 <Form.Control
//                   type="number"
//                   name="psychological.socialActivityHours"
//                   value={formData.psychological.socialActivityHours}
//                   onChange={handleChange}
//                   className="form-control-lg"
//                   isInvalid={!!errors.socialActivityHours}
//                 />
//               </Form.Group>
//               <div className="d-flex justify-content-end">
//                 <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
//                 <Button type="button" className="btn btn-primary ml-2" onClick={nextPart}>Continue</Button>
//               </div>
//             </div>
//           )}

//     {currentPart === 8 && (
//       <div id="part-8" className="form-part">
//         <h3 className="icon-box h4">Health Data</h3>
//         <Form.Group as={Row} className="mb-3">
//           <Form.Label column sm="3" className="form-label-lg">
//             Weight {handleTooltip('Please enter your weight to calculate your body mass index (BMI) and assess health risks associated with weight. For this reason, it is not possible to combine the metric system with the English system.')}
//           </Form.Label>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.weightKg"
//               value={formData.anthropometry.weightKg}
//               onChange={handleChange}
//               placeholder="kg"
//               disabled={!!formData.anthropometry.weightLbs || !!formData.anthropometry.heightIn}
//               className="form-control-lg"
//               isInvalid={!!errors.weight}
//             />
//             <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
//           </Col>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.weightLbs"
//               value={formData.anthropometry.weightLbs}
//               onChange={handleChange}
//               placeholder="lbs"
//               disabled={!!formData.anthropometry.weightKg || !!formData.anthropometry.heightCm}
//               className="form-control-lg"
//               isInvalid={!!errors.weight}
//             />
//             <Form.Control.Feedback type="invalid">{errors.weight}</Form.Control.Feedback>
//           </Col>
//         </Form.Group>
//         <Form.Group as={Row} className="mb-3">
//           <Form.Label column sm="3" className="form-label-lg">
//             Height {handleTooltip('Please enter your height to calculate your BMI and assess growth and development. For this reason, it is not possible to combine the metric system with the English system.')}
//           </Form.Label>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.heightCm"
//               value={formData.anthropometry.heightCm}
//               onChange={handleChange}
//               placeholder="cm"
//               disabled={!!formData.anthropometry.heightIn || !!formData.anthropometry.weightLbs}
//               className="form-control-lg"
//               isInvalid={!!errors.height}
//             />
//             <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
//           </Col>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.heightIn"
//               value={formData.anthropometry.heightIn}
//               onChange={handleChange}
//               placeholder="inches"
//               disabled={!!formData.anthropometry.heightCm || !!formData.anthropometry.weightKg}
//               className="form-control-lg"
//               isInvalid={!!errors.height}
//             />
//             <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
//           </Col>
//         </Form.Group>
//         <Form.Group className="mb-3">
//           <Form.Label className="form-label-lg">
//             Waist Circumference {handleTooltip('Please enter your waist circumference to assess health risks related to abdominal fat, this input is not mandatory. **Leaved blank if you did not have that information.')}
//           </Form.Label>
//           <Form.Control
//             type="number"
//             name="anthropometry.waistCircumference"
//             value={formData.anthropometry.waistCircumference}
//             onChange={handleChange}
//             className="form-control-lg"
//             placeholder="cm"
//             isInvalid={!!errors.waistCircumference}
//           />
//           <Form.Control.Feedback type="invalid">{errors.waistCircumference}</Form.Control.Feedback>
//         </Form.Group>
//         <Form.Group className="mb-3">
//           <Form.Label className="form-label-lg">
//             Hip Circumference {handleTooltip('Please enter your hip circumference to calculate your waist-to-hip ratio, which can indicate health risks, this input is not mandatory. **Leaved blank if you did not have that information.')}
//           </Form.Label>
//           <Form.Control
//             type="number"
//             name="anthropometry.hipCircumference"
//             value={formData.anthropometry.hipCircumference}
//             onChange={handleChange}
//             className="form-control-lg"
//             placeholder="cm"
//             isInvalid={!!errors.hipCircumference}
//           />
//           <Form.Control.Feedback type="invalid">{errors.hipCircumference}</Form.Control.Feedback>
//         </Form.Group>
//         <Form.Group as={Row} className="mb-3">
//           <Form.Label column sm="3" className="form-label-lg">
//             Blood Pressure {handleTooltip('Please enter your blood pressure to help monitor your cardiovascular health, you need a digital pressure machine this input is not mandatory. **Leaved blank if you did not have that information.')}
//           </Form.Label>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.bloodPressureSystolic"
//               value={formData.anthropometry.bloodPressureSystolic}
//               onChange={handleChange}
//               placeholder="systolic"
//               className="form-control-lg"
//               isInvalid={!!errors.bloodPressureSystolic}
//             />
//             <Form.Control.Feedback type="invalid">{errors.bloodPressureSystolic}</Form.Control.Feedback>
//           </Col>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.bloodPressureDiastolic"
//               value={formData.anthropometry.bloodPressureDiastolic}
//               onChange={handleChange}
//               placeholder="diastolic"
//               className="form-control-lg"
//               isInvalid={!!errors.bloodPressureDiastolic}
//             />
//             <Form.Control.Feedback type="invalid">{errors.bloodPressureDiastolic}</Form.Control.Feedback>
//           </Col>
//         </Form.Group>
//         <Form.Group className="mb-3">
//           <Form.Label className="form-label-lg">
//             Blood Glucose {handleTooltip('Please enter your blood glucose level to monitor your risk for diabetes and other metabolic conditions, you need a glucometer this input is not mandatory. **Leaved blank if you did not have that information.')}
//           </Form.Label>
//           <Form.Control
//             type="number"
//             name="anthropometry.bloodGlucose"
//             value={formData.anthropometry.bloodGlucose}
//             onChange={handleChange}
//             className="form-control-lg"
//             placeholder="mg/dL"
//             isInvalid={!!errors.bloodGlucose}
//           />
//           <Form.Control.Feedback type="invalid">{errors.bloodGlucose}</Form.Control.Feedback>
//         </Form.Group>
//         <Form.Group className="mb-3">
//           <Form.Label className="form-label-lg">
//             Oxygen Saturation {handleTooltip('Please enter your oxygen saturation level to monitor your respiratory health. **Leaved blank if you did not have that information.')}
//           </Form.Label>
//           <Form.Control
//             type="number"
//             name="anthropometry.oxygenSaturation"
//             value={formData.anthropometry.oxygenSaturation}
//             onChange={handleChange}
//             className="form-control-lg"
//             placeholder="%"
//             isInvalid={!!errors.oxygenSaturation}
//           />
//           <Form.Control.Feedback type="invalid">{errors.oxygenSaturation}</Form.Control.Feedback>
//         </Form.Group>
//         <Form.Group as={Row} className="mb-3">
//           <Form.Label column sm="3" className="form-label-lg">
//             Body Temperature {handleTooltip('Please enter your body temperature to monitor for fever or hypothermia. **Leaved blank if you did not have that information.')}
//           </Form.Label>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.bodyTemperatureC"
//               value={formData.anthropometry.bodyTemperatureC}
//               onChange={handleChange}
//               placeholder="°C"
//               disabled={!!formData.anthropometry.bodyTemperatureF}
//               className="form-control-lg"
//               isInvalid={!!errors.bodyTemperature}
//             />
//             <Form.Control.Feedback type="invalid">{errors.bodyTemperature}</Form.Control.Feedback>
//           </Col>
//           <Col sm="4">
//             <Form.Control
//               type="number"
//               name="anthropometry.bodyTemperatureF"
//               value={formData.anthropometry.bodyTemperatureF}
//               onChange={handleChange}
//               placeholder="°F"
//               disabled={!!formData.anthropometry.bodyTemperatureC}
//               className="form-control-lg"
//               isInvalid={!!errors.bodyTemperature}
//             />
//             <Form.Control.Feedback type="invalid">{errors.bodyTemperature}</Form.Control.Feedback>
//           </Col>
//         </Form.Group>
//         <div className="d-flex justify-content-end">
//           <Button variant="link" className="text-purple" onClick={prevPart}><i className="bi bi-arrow-left"></i> Back</Button>
//           <Button type="submit" className="btn btn-primary ml-2">Submit</Button>
//         </div>
//       </div>
//     )}

//      </Form>
//       </Card>
//     </div>
//   );
// }

// export default ClinicalHistoryForm;


