import React from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; 
import { Helmet } from 'react-helmet'; // Importa react-helmet para los metadatos

const ArticlePreview = ({ article }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
      <Helmet>
        <title>{article.title} - Blog</title>
        <meta name="description" content={article.summary} />
        <meta name="keywords" content={`salud, tecnología, ${article.category}, ${article.title}`} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.summary} />
        <meta property="og:image" content={article.image} />
      </Helmet>

      <Link to={`/article/${article.slug}`} className="block">
        <img 
          className="w-full h-48 object-cover" 
          src={article.image} 
          alt={article.title} 
        />
        <div className="p-4">
          <h2 className="text-xl font-semibold text-gray-800 hover:text-blue-500 transition">{article.title}</h2>

          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              p: ({ node, ...props }) => (
                <p className="mb-4 text-gray-700" {...props} />
              ),
              h1: ({ node, ...props }) => (
                <h1 className="text-2xl font-bold text-blue-600 mb-4" {...props} />
              ),
              h2: ({ node, ...props }) => (
                <h2 className="text-xl font-semibold text-blue-500 mb-3" {...props} />
              ),
              a: ({ node, ...props }) => (
                <a className="text-blue-600 hover:underline" {...props} />
              ),
            }}
          >
            {article.summary}
          </ReactMarkdown>

          <button className="mt-4 bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 transition duration-200">
            Read More
          </button>
        </div>
      </Link>
    </div>
  );
};

export default ArticlePreview;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import ReactMarkdown from 'react-markdown';

// const ArticlePreview = ({ article }) => {
//   return (
//     <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
//       <Link to={`/article/${article.slug}`} className="block">
//         <img 
//           className="w-full h-48 object-cover" 
//           src={article.image} 
//           alt={article.title} 
//         />
//         <div className="p-4">
//           <h2 className="text-xl font-semibold text-gray-800 hover:text-blue-500 transition">{article.title}</h2>

//           {/* Usar ReactMarkdown para procesar el resumen */}
//           <ReactMarkdown className="mt-2 text-gray-600">
//             {article.summary}
//           </ReactMarkdown>

//           <button className="mt-4 bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 transition duration-200">
//             Read More
//           </button>
//         </div>
//       </Link>
//     </div>
//   );
// };

// export default ArticlePreview;
