// src/components/Alert.jsx
import React from 'react';
import './Alert.css'; // Asegúrate de que tienes los estilos necesarios

function Alert({ message, onClose, type }) {
  return (
    <div className={`alert alert-${type} alert-dismissible fade show`} role="alert">
      {message}
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={onClose}>X</button>
    </div>
  );
}

export default Alert;
