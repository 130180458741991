import React from 'react';

const SelectedMedications = ({ selectedMedications, onFrequencyChange }) => {
  return (
    <div>
      <h5>Selected Medications</h5>
      <ul>
        {selectedMedications.map((medication) => (
          <li key={medication.id}>
            {medication.drugName} ({medication.strength})
            <select onChange={(e) => onFrequencyChange(medication.id, e.target.value)}>
              <option value="">Select Frequency</option>
              <option value="daily">Daily</option>
              <option value="twice_a_day">Twice a Day</option>
              <option value="three_times_a_day">Three Times a Day</option>
              <option value="as_needed">As Needed</option>
            </select>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedMedications;

