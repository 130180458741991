import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './profileCompleteBanner.css';
import './LightMode.css'; // Importar estilos del modo claro
import './DarkMode.css'; // Importar estilos del modo oscuro
import { useTheme } from '../context/ThemeContext'; // Importar el useTheme
import bannerImage from '../assets/images/telehealth.webp'; // Asegúrate de tener una imagen en esta ruta

function ProfileCompleteBanner() {
    const navigate = useNavigate();
    const { darkMode } = useTheme(); // Obtener el valor de darkMode

    return (
        <div className={`container my-5 p-4 bg-gradient rounded shadow-lg d-flex flex-wrap align-items-center justify-content-between ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className="col-md-8 mb-4 mb-md-0">
                <h2 className={`display-5 fw-bold mb-3 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
                    Complete Your Medical History
                </h2>
                <p className={`lead mb-4 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
                    To provide you with the best care, please complete your profile and fill out your medical history questionnaire.
                </p>
                <button
                    className={`btn btn-purple btn-lg ${darkMode ? 'dark-mode' : 'light-mode'}`}
                    onClick={() => navigate('/complete-profile')}
                >
                    Complete Profile
                </button>
            </div>
            <div className="col-md-4 text-center">
                <img src={bannerImage} alt="Complete Profile" className="img-fluid rounded shadow-lg" />
            </div>
        </div>
    );
}

export default ProfileCompleteBanner;
