import React from 'react';
import { Icon, DropdownSection, Heading } from '../styles';
import './ProductsDropdown.css';
import { t } from 'i18next';

const ProductsDropdown = () => {
  return (
    <div className="products-dropdown">
      <Heading>{t('medicalServices')}</Heading>
      <div className="columns-container">
        <DropdownSection>
          <ul className="services-list">
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/chiropractic">{t('Chiropractic')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/coaching">{t('Coaching')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/counseling">{t('Counseling')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/general-practice">{t('General Practice')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/health-coach">{t('Health Coach')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/medical">{t('Medical')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/mental-health">{t('Mental Health')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/nursing">{t('Nursing')}</a>
            </li>
          </ul>
        </DropdownSection>
        <DropdownSection>
          <ul className="services-list">
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/nutrition">{t('Nutrition')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/occupational-therapy">{t('Occupational Therapy')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/personal-training">{t('Personal Training')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/physical-therapy">{t('Physical Therapy')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/psychiatry">{t('Psychiatry')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/psychology">{t('Psychology')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/speech-therapy">{t('Speech Therapy')}</a>
            </li>
            <li>
              <div className="circle-icon">
                <Icon>
                  <svg width="16" height="16">
                    <circle cx="8" cy="8" r="8" fill="white" />
                  </svg>
                </Icon>
              </div>
              <a href="/therapy">{t('Therapy')}</a>
            </li>
          </ul>
        </DropdownSection>
      </div>
    </div>
  );
};

export default ProductsDropdown;
