import React from 'react';
import { Container, Paper, Typography, Box, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axiosPatient from '../utils/axiosPatient';

const ClinicalHistoryComplete = () => {
    const handleDownloadPdf = async () => {
        try {
            const response = await axiosPatient.get('/clinical_history/download', {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ClinicalHistory.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                <CheckCircleIcon style={{ fontSize: '50px', color: 'green' }} />
                <Typography variant="h5" gutterBottom>
                    Your clinical history is complete!
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You can access your clinical history at any time.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleDownloadPdf}>
                    Download PDF
                </Button>
            </Paper>
        </Container>
    );
};

export default ClinicalHistoryComplete;
