// import React, { useRef } from 'react';

// const DoctorSignature = ({ updateSignatureData }) => {
//   const canvasRef = useRef(null);

//   const clearCanvas = () => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');
//     ctx.clearRect(0, 0, canvas.width, canvas.height);
//   };

//   const handleMouseDown = () => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');
//     ctx.beginPath();
//     ctx.moveTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop);
//     canvas.onmousemove = handleMouseMove;
//   };

//   const handleMouseMove = () => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');
//     ctx.lineTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop);
//     ctx.stroke();
//   };

//   const handleMouseUp = () => {
//     const canvas = canvasRef.current;
//     canvas.onmousemove = null;
//     updateSignatureData(canvas.toDataURL());
//   };

//   return (
//     <div>
//       <h3>Doctor Signature</h3>
//       <canvas
//         ref={canvasRef}
//         width="500"
//         height="200"
//         style={{ border: '1px solid black' }}
//         onMouseDown={handleMouseDown}
//         onMouseUp={handleMouseUp}
//       ></canvas>
//       <button type="button" onClick={clearCanvas}>Clear</button>
//     </div>
//   );
// };

// export default DoctorSignature;
// src/components/Doctor/DoctorSignature.jsx
// src/components/Doctor/DoctorSignature.jsx

import React, { useRef, useState } from 'react';

const DoctorSignature = ({ saveSignature }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const startDrawing = (event) => {
    setIsDrawing(true);
    const ctx = canvasRef.current.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(
      event.nativeEvent.offsetX,
      event.nativeEvent.offsetY
    );
  };

  const draw = (event) => {
    if (!isDrawing) return;
    const ctx = canvasRef.current.getContext('2d');
    ctx.lineTo(
      event.nativeEvent.offsetX,
      event.nativeEvent.offsetY
    );
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    saveSignature(canvas.toDataURL());
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        onMouseDown={(event) => startDrawing(event)}
        onMouseMove={(event) => draw(event)}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
        width="600"
        height="200"
        style={{ border: '1px solid black' }}
      ></canvas>
      <button onClick={clearCanvas} className="btn btn-danger mt-2">Clear Signature</button>
    </div>
  );
};

export default DoctorSignature;
