import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { DropdownRoot, Caret, DropdownBackground, AltBackground, InvertedDiv } from './styles';
import FadeContents from './FadeContents';

const DropdownContainer = ({ children, duration }) => {
  const nodeRef = useRef(null);

  const [currentDropdown, prevDropdown] = React.Children.toArray(children);

  return (
    <DropdownRoot duration={duration}>
      <TransitionGroup>
        {currentDropdown && (
          <CSSTransition
            key="currentDropdown"
            timeout={duration}
            classNames="fade"
            nodeRef={nodeRef}
            unmountOnExit
          >
            <DropdownBackground ref={nodeRef}>
              <InvertedDiv>
                <AltBackground duration={duration} />
                <FadeContents duration={duration}>
                  {currentDropdown}
                </FadeContents>
              </InvertedDiv>
            </DropdownBackground>
          </CSSTransition>
        )}
        {prevDropdown && (
          <CSSTransition
            key="prevDropdown"
            timeout={duration}
            classNames="fade"
            nodeRef={nodeRef}
            unmountOnExit
          >
            <InvertedDiv absolute ref={nodeRef}>
              <FadeContents animatingOut duration={duration}>
                {prevDropdown}
              </FadeContents>
            </InvertedDiv>
          </CSSTransition>
        )}
      </TransitionGroup>
      <Caret />
    </DropdownRoot>
  );
};

DropdownContainer.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
};

export default DropdownContainer;

// import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Flipped } from 'react-flip-toolkit';
// import { DropdownRoot, Caret, DropdownBackground, AltBackground, InvertedDiv } from './styles';
// import FadeContents from './FadeContents';

// const getFirstDropdownSectionHeight = (el) => {
//   if (!el || !el.querySelector || !el.querySelector('*[data-first-dropdown-section]')) return 0;
//   return el.querySelector('*[data-first-dropdown-section]').offsetHeight;
// };

// const updateAltBackground = ({ altBackground, prevDropdown, currentDropdown }) => {
//   if (!altBackground || !prevDropdown || !currentDropdown) return;

//   const prevHeight = getFirstDropdownSectionHeight(prevDropdown);
//   const currentHeight = getFirstDropdownSectionHeight(currentDropdown);

//   const immediateSetTranslateY = (el, translateY) => {
//     if (!el) return;
//     el.style.transform = `translateY(${translateY}px)`;
//     el.style.transition = 'transform 0s';
//     requestAnimationFrame(() => (el.style.transitionDuration = ''));
//   };

//   if (prevHeight) {
//     immediateSetTranslateY(altBackground, prevHeight);
//     requestAnimationFrame(() => {
//       if (altBackground) altBackground.style.transform = `translateY(${currentHeight}px)`;
//     });
//   } else {
//     immediateSetTranslateY(altBackground, currentHeight);
//   }
// };

// const DropdownContainer = ({ children, duration }) => {
//   const currentDropdownEl = useRef(null);
//   const prevDropdownEl = useRef(null);
//   const altBackgroundEl = useRef(null);

//   useEffect(() => {
//     updateAltBackground({
//       altBackground: altBackgroundEl.current,
//       prevDropdown: prevDropdownEl.current,
//       currentDropdown: currentDropdownEl.current,
//       duration: duration,
//     });
//   }, [children, duration]);

//   const [currentDropdown, prevDropdown] = React.Children.toArray(children);

//   return (
//     <DropdownRoot duration={duration}>
//       <Flipped flipId="dropdown-caret">
//         <Caret />
//       </Flipped>
//       <Flipped flipId="dropdown">
//         <DropdownBackground>
//           <Flipped inverseFlipId="dropdown">
//             <InvertedDiv>
//               <AltBackground ref={altBackgroundEl} duration={duration} />
//               <FadeContents duration={duration} ref={currentDropdownEl}>
//                 {currentDropdown}
//               </FadeContents>
//             </InvertedDiv>
//           </Flipped>
//           <Flipped inverseFlipId="dropdown" scale>
//             <InvertedDiv absolute>
//               {prevDropdown && (
//                 <FadeContents animatingOut duration={duration} ref={prevDropdownEl}>
//                   {prevDropdown}
//                 </FadeContents>
//               )}
//             </InvertedDiv>
//           </Flipped>
//         </DropdownBackground>
//       </Flipped>
//     </DropdownRoot>
//   );
// };

// DropdownContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   duration: PropTypes.number,
// };

// export default DropdownContainer;

// import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Flipped } from 'react-flip-toolkit';
// import { DropdownRoot, Caret, DropdownBackground, AltBackground, InvertedDiv } from './styles';
// import FadeContents from './FadeContents';

// const getFirstDropdownSectionHeight = (el) => {
//   if (!el || !el.querySelector || !el.querySelector('*[data-first-dropdown-section]')) return 0;
//   return el.querySelector('*[data-first-dropdown-section]').offsetHeight;
// };

// const updateAltBackground = ({ altBackground, prevDropdown, currentDropdown }) => {
//   if (!altBackground || !prevDropdown || !currentDropdown) return;

//   const prevHeight = getFirstDropdownSectionHeight(prevDropdown);
//   const currentHeight = getFirstDropdownSectionHeight(currentDropdown);

//   const immediateSetTranslateY = (el, translateY) => {
//     if (!el) return;
//     el.style.transform = `translateY(${translateY}px)`;
//     el.style.transition = 'transform 0s';
//     requestAnimationFrame(() => (el.style.transitionDuration = ''));
//   };

//   if (prevHeight) {
//     immediateSetTranslateY(altBackground, prevHeight);
//     requestAnimationFrame(() => {
//       if (altBackground) altBackground.style.transform = `translateY(${currentHeight}px)`;
//     });
//   } else {
//     immediateSetTranslateY(altBackground, currentHeight);
//   }
// };

// const DropdownContainer = ({ children, duration }) => {
//   const currentDropdownEl = useRef(null);
//   const prevDropdownEl = useRef(null);
//   const altBackgroundEl = useRef(null);

//   useEffect(() => {
//     updateAltBackground({
//       altBackground: altBackgroundEl.current,
//       prevDropdown: prevDropdownEl.current,
//       currentDropdown: currentDropdownEl.current,
//       duration: duration,
//     });
//   }, [children, duration]);

//   const [currentDropdown, prevDropdown] = React.Children.toArray(children);

//   return (
//     <DropdownRoot duration={duration}>
//       <Flipped flipId="dropdown-caret">
//         <Caret />
//       </Flipped>
//       <Flipped flipId="dropdown">
//         <DropdownBackground>
//           <Flipped inverseFlipId="dropdown">
//             <InvertedDiv>
//               <AltBackground ref={altBackgroundEl} duration={duration} />
//               <FadeContents duration={duration} ref={currentDropdownEl}>
//                 {currentDropdown}
//               </FadeContents>
//             </InvertedDiv>
//           </Flipped>

//           <Flipped inverseFlipId="dropdown" scale>
//             <InvertedDiv absolute>
//               {prevDropdown && (
//                 <FadeContents animatingOut duration={duration} ref={prevDropdownEl}>
//                   {prevDropdown}
//                 </FadeContents>
//               )}
//             </InvertedDiv>
//           </Flipped>
//         </DropdownBackground>
//       </Flipped>
//     </DropdownRoot>
//   );
// };

// DropdownContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   duration: PropTypes.number,
// };

// export default DropdownContainer;

// // export default DropdownContainer;
// import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Flipped } from 'react-flip-toolkit';
// import { DropdownRoot, Caret, DropdownBackground, AltBackground, InvertedDiv } from './styles';
// import FadeContents from './FadeContents';

// const getFirstDropdownSectionHeight = (el) => {
//   if (!el || !el.querySelector || !el.querySelector('*[data-first-dropdown-section]')) return 0;
//   return el.querySelector('*[data-first-dropdown-section]').offsetHeight;
// };

// const updateAltBackground = ({ altBackground, prevDropdown, currentDropdown }) => {
//   const prevHeight = getFirstDropdownSectionHeight(prevDropdown);
//   const currentHeight = getFirstDropdownSectionHeight(currentDropdown);

//   const immediateSetTranslateY = (el, translateY) => {
//     el.style.transform = `translateY(${translateY}px)`;
//     el.style.transition = 'transform 0s';
//     requestAnimationFrame(() => (el.style.transitionDuration = ''));
//   };

//   if (prevHeight) {
//     immediateSetTranslateY(altBackground, prevHeight);
//     requestAnimationFrame(() => {
//       altBackground.style.transform = `translateY(${currentHeight}px)`;
//     });
//   } else {
//     immediateSetTranslateY(altBackground, currentHeight);
//   }
// };

// const DropdownContainer = ({ children, duration }) => {
//   const currentDropdownEl = useRef(null);
//   const prevDropdownEl = useRef(null);
//   const altBackgroundEl = useRef(null);

//   useEffect(() => {
//     updateAltBackground({
//       altBackground: altBackgroundEl.current,
//       prevDropdown: prevDropdownEl.current,
//       currentDropdown: currentDropdownEl.current,
//       duration: duration,
//     });
//   }, [children, duration]);

//   const [currentDropdown, prevDropdown] = React.Children.toArray(children);

//   return (
//     <DropdownRoot duration={duration}>
//       <Flipped flipId="dropdown-caret">
//         <Caret />
//       </Flipped>
//       <Flipped flipId="dropdown">
//         <DropdownBackground>
//           <Flipped inverseFlipId="dropdown">
//             <InvertedDiv>
//               <AltBackground ref={altBackgroundEl} duration={duration} />
//               <FadeContents duration={duration} ref={currentDropdownEl}>
//                 {currentDropdown}
//               </FadeContents>
//             </InvertedDiv>
//           </Flipped>

//           <Flipped inverseFlipId="dropdown" scale>
//             <InvertedDiv absolute>
//               {prevDropdown && (
//                 <FadeContents animatingOut duration={duration} ref={prevDropdownEl}>
//                   {prevDropdown}
//                 </FadeContents>
//               )}
//             </InvertedDiv>
//           </Flipped>
//         </DropdownBackground>
//       </Flipped>
//     </DropdownRoot>
//   );
// };

// DropdownContainer.propTypes = {
//   children: PropTypes.node.isRequired,
//   duration: PropTypes.number,
// };

// export default DropdownContainer;
