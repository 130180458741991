import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import {
  Card, CardHeader, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tabs, Tab
} from '@mui/material';
import { 
  HeartPulseIcon,
  HomeIcon,
  CalendarIcon,
  AppleIcon,
  PillIcon,
  CreditCardIcon,
  ChartIcon,
  ClipboardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../Icons';

function Treatments() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [treatments, setTreatments] = useState([]);
  const [newTreatmentDialogOpen, setNewTreatmentDialogOpen] = useState(false);
  const [newTreatment, setNewTreatment] = useState({ name: '', description: '', startDate: '', endDate: '' });

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const { data } = await axios.get(`/api/treatments/${user.user_id}`);
        setTreatments(data);
      } catch (error) {
        console.error('Error fetching treatments:', error);
      }
    };

    fetchTreatments();
  }, [user.user_id]);

  const handleOpenNewTreatmentDialog = () => {
    setNewTreatmentDialogOpen(true);
  };

  const handleCloseNewTreatmentDialog = () => {
    setNewTreatmentDialogOpen(false);
  };

  const handleInputChange = (e) => {
    setNewTreatment({ ...newTreatment, [e.target.name]: e.target.value });
  };

  const saveNewTreatment = async () => {
    try {
      await axios.post(`/api/treatments`, { ...newTreatment, userId: user.user_id });
      setTreatments([...treatments, newTreatment]);
      handleCloseNewTreatmentDialog();
    } catch (error) {
      console.error('Error adding new treatment:', error);
    }
  };

  return (
    <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex-1 overflow-auto py-2">
            <nav className="grid items-start px-4 text-sm font-medium">
            <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
              <HeartPulseIcon className="h-6 w-6" />
                Start Consultation
              </Link>
              <Link to="/dashboard" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <HomeIcon className="h-6 w-6" />
                Dashboard
              </Link>
              <Link to="/wellness" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <AppleIcon className="h-6 w-6" />
                Wellness
              </Link>
              <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <CalendarIcon className="h-6 w-6" />
                Appointments
              </Link>
              <Link to="/treatments" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
              <PillIcon className="h-6 w-6" />
                Treatments
              </Link>
              <Link to="/payment" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <CreditCardIcon className="h-6 w-6" />
                Payment
              </Link>
              <Link to="/reports" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <ChartIcon className="h-6 w-6" />
                Reports
              </Link>
              <Link to="/logs" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
              <ClipboardIcon className="h-6 w-6" />
                Logs
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-4">
        <Tabs centered>
          <Tab label="Medical Prescriptions" />
          <Tab label="Meal Plans" />
          <Tab label="Psicology Reports" />
        </Tabs>
        <div>
          <Button color="primary" onClick={handleOpenNewTreatmentDialog}>
            Add New Treatment
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {treatments.map((treatment) => (
              <TableRow key={treatment.id}>
                <TableCell>{treatment.name}</TableCell>
                <TableCell>{treatment.description}</TableCell>
                <TableCell>{treatment.startDate}</TableCell>
                <TableCell>{treatment.endDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Dialog open={newTreatmentDialogOpen} onClose={handleCloseNewTreatmentDialog}>
          <DialogTitle>Add New Treatment</DialogTitle>
          <DialogContent>
            <TextField autoFocus margin="dense" name="name" label="Treatment Name" fullWidth variant="standard" onChange={handleInputChange} />
            <TextField margin="dense" name="description" label="Description" fullWidth variant="standard" multiline onChange={handleInputChange} />
            <TextField margin="dense" name="startDate" label="Start Date" type="date" fullWidth variant="standard" InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
            <TextField margin="dense" name="endDate" label="End Date" type="date" fullWidth variant="standard" InputLabelProps={{ shrink: true }} onChange={handleInputChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewTreatmentDialog}>Cancel</Button>
            <Button onClick={saveNewTreatment}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}




export default Treatments;
// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { useAuth } from '../../context/AuthContext';
// import axios from 'axios';
// import {
//   Card, CardHeader, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Button,
//   Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tabs, Tab
// } from '@mui/material';

// function Treatments() {
//   const { user } = useAuth();
//   const navigate = useNavigate();
//   const [treatments, setTreatments] = useState([]);
//   const [newTreatmentDialogOpen, setNewTreatmentDialogOpen] = useState(false);
//   const [newTreatment, setNewTreatment] = useState({ name: '', description: '', startDate: '', endDate: '' });

//   useEffect(() => {
//     const fetchTreatments = async () => {
//       try {
//         const { data } = await axios.get(`/api/treatments/${user.user_id}`);
//         setTreatments(data);
//       } catch (error) {
//         console.error('Error fetching treatments:', error);
//       }
//     };

//     fetchTreatments();
//   }, [user.user_id]);

//   const handleOpenNewTreatmentDialog = () => {
//     if (user.role === 'doctor') {
//       setNewTreatmentDialogOpen(true);
//     } else {
//       alert('Only doctors can add treatments.');
//     }
//   };

//   const handleCloseNewTreatmentDialog = () => {
//     setNewTreatmentDialogOpen(false);
//   };

//   const handleInputChange = (e) => {
//     setNewTreatment({ ...newTreatment, [e.target.name]: e.target.value });
//   };

//   const saveNewTreatment = async () => {
//     if (user.role === 'doctor') {
//       try {
//         await axios.post(`/api/treatments`, { ...newTreatment, userId: user.user_id });
//         setTreatments([...treatments, newTreatment]);
//         handleCloseNewTreatmentDialog();
//       } catch (error) {
//         console.error('Error adding new treatment:', error);
//       }
//     } else {
//       alert('Only doctors can save treatments.');
//     }
//   };

//   return (
//     <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
//       <div className="hidden border-r bg-muted/40 lg:block">
//         <div className="flex h-full max-h-screen flex-col gap-2">
//           <div className="flex-1 overflow-auto py-2">
//             <nav className="grid items-start px-4 text-sm font-medium">
//               <Link to="/dashboard" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
//                 Start Consultation
//               </Link>
//               <Link to="/dashboard" className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
//                 Dashboard
//               </Link>
//               <Link to="/patients" className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
//                 Nutrition
//               </Link>
//               <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
//                 Appointments
//               </Link>
//               <Link to="/treatments" className="flex items-center gap-3 rounded-lg bg-muted px-3 py-2 text-primary transition-all hover:text-primary">
//                 Treatments
//               </Link>
//               <Link to="/reports" className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
//                 Reports
//               </Link>
//               <Link to="/reports" className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
//                 Logs
//               </Link>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col p-4">
//         <Tabs centered>
//           <Tab label="Overview" />
//           <Tab label="Vitals" />
//           <Tab label="Treatment" />
//         </Tabs>
//         <div>
//           {user.role === 'doctor' && (
//             <Button color="primary" onClick={handleOpenNewTreatmentDialog}>
//               Add New Treatment
//             </Button>
//           )}
//         </div>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Name</TableCell>
//               <TableCell>Description</TableCell>
//               <TableCell>Start Date</TableCell>
//               <TableCell>End Date</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {treatments.map((treatment) => (
//               <TableRow key={treatment.id}>
//                 <TableCell>{treatment.name}</TableCell>
//                 <TableCell>{treatment.description}</TableCell>
//                 <TableCell>{treatment.startDate}</TableCell>
//                 <TableCell>{treatment.endDate}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </div>
//     </div>
//   );
// }

// export default Treatments;

