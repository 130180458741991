import React, { useState } from 'react';



const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = () => {
    onSearch(query);
  };

  return (
    <div className="flex items-center justify-center mt-5">
      <input 
        type="text" 
        value={query}
        onChange={handleInputChange}
        className="w-full md:w-2/3 lg:w-1/2 p-3 border rounded-l-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Search articles..."
      />
      <button 
        onClick={handleSearch} 
        className="bg-blue-500 text-white px-4 py-3 rounded-r-md hover:bg-blue-600 transition duration-200">
        Search
      </button>
    </div>
  );
};

export default SearchBar;
