import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Importa react-helmet para los metadatos
import ArticlePreview from './ArticlePreview';
import SearchBar from './SearchBar';
import Category from './Category';
import axiosBlog from '../../utils/axiosBlog';

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);

  useEffect(() => {
    axiosBlog.get('/articles')
      .then(response => {
        setArticles(response.data);
        setFilteredArticles(response.data);
      })
      .catch(error => console.error("There was an error fetching the articles:", error));
  }, []);

  const handleSearch = (query) => {
    if (!query) {
      setFilteredArticles(articles);
    } else {
      const results = articles.filter(article => 
        article.title.toLowerCase().includes(query.toLowerCase()) ||
        article.content.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredArticles(results);
    }
  };

  return (
    <div className="container mx-auto p-5">
      <Helmet>
        <title>Blog - Artículos de Salud y Tecnología</title>
        <meta name="description" content="Explora una variedad de artículos relacionados con la salud y la tecnología. Encuentra recursos sobre temas como salud mental, nutrición, y más." />
        <meta name="keywords" content="salud, tecnología, nutrición, salud mental, blog, artículos" />
      </Helmet>

      <SearchBar onSearch={handleSearch} />
      <Category articles={articles} onFilter={setFilteredArticles} />
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
        {filteredArticles.map(article => (
          <ArticlePreview key={article.id} article={article} />
        ))}
      </div>
    </div>
  );
};

export default Blog;


// import React, { useState, useEffect } from 'react';
// import ArticlePreview from './ArticlePreview';
// import SearchBar from './SearchBar';
// import Category from './Category';
// import axiosBlog from '../../utils/axiosBlog';  // Asegúrate de importar axiosBlog
// import './Blog.css';  // Importa el archivo de estilos CSS

// const Blog = () => {
//   const [articles, setArticles] = useState([]);
//   const [filteredArticles, setFilteredArticles] = useState([]);

//   useEffect(() => {
//     axiosBlog.get('/articles')
//       .then(response => {
//         setArticles(response.data);
//         setFilteredArticles(response.data);
//       })
//       .catch(error => console.error("There was an error fetching the articles:", error));
//   }, []);

//   const handleSearch = (query) => {
//     if (!query) {
//       setFilteredArticles(articles);
//     } else {
//       const results = articles.filter(article => 
//         article.title.toLowerCase().includes(query.toLowerCase()) ||
//         article.content.toLowerCase().includes(query.toLowerCase())
//       );
//       setFilteredArticles(results);
//     }
//   };

//   return (
//     <div className="blog-container">
//       <SearchBar onSearch={handleSearch} />
//       <Category articles={articles} onFilter={setFilteredArticles} />
//       <div className="articles-grid">
//         {filteredArticles.map(article => (
//           <ArticlePreview key={article.id} article={article} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Blog;
