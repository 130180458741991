import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importamos Helmet para SEO
import Author from './Author';
import CommentSection from './CommentSection';
import RelatedArticles from './RelatedArticles';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // Para permitir HTML en el markdown
import axiosBlog from '../../utils/axiosBlog';

const Article = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speechSynthesis, setSpeechSynthesis] = useState(window.speechSynthesis);
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    axiosBlog.get(`/articles/${slug}`)
      .then(response => setArticle(response.data))
      .catch(error => {
        console.error('Error fetching the article:', error);
        setError("Article not found or an error occurred.");
      });

    // Cargar todas las voces disponibles
    const loadVoices = () => {
      let availableVoices = speechSynthesis.getVoices();
      if (availableVoices.length === 0) {
        // Algunas veces las voces no se cargan inmediatamente, esperar y recargar
        speechSynthesis.onvoiceschanged = () => {
          availableVoices = speechSynthesis.getVoices();
          setVoices(availableVoices);
        };
      } else {
        setVoices(availableVoices);
      }
    };

    loadVoices();
  }, [slug, speechSynthesis]);

  if (error) return <div className="text-center mt-20 text-red-500">{error}</div>;
  if (!article) return <div className="text-center mt-20 text-gray-500">Loading...</div>;

  // Función para obtener el idioma del artículo
  const getArticleLanguage = () => {
    return article.language || 'es'; // Por defecto en español
  };

  // Función para seleccionar la mejor voz en función del idioma
  const getVoiceForLanguage = (language) => {
    const filteredVoices = voices.filter(voice => voice.lang.startsWith(language));
    return filteredVoices.length > 0 ? filteredVoices[0] : null;
  };

  // Función para leer el artículo en voz alta
  const readArticleAloud = () => {
    if (isSpeaking) {
      speechSynthesis.cancel(); // Detener la lectura si ya está en curso
      setIsSpeaking(false);
      return;
    }

    const content = document.createElement('div');
    content.innerHTML = article.content; // El contenido sin markdown
    const plainText = content.innerText;

    const speech = new SpeechSynthesisUtterance(plainText);

    // Configurar el idioma según el artículo
    const lang = getArticleLanguage() === 'en' ? 'en-US' : 'es-ES';
    const voice = getVoiceForLanguage(lang);

    if (voice) {
      speech.voice = voice;
      speech.lang = voice.lang;
    } else {
      speech.lang = lang; // Fallback a 'es-ES' o 'en-US' si no se encuentra la voz específica
    }

    speech.onend = () => {
      setIsSpeaking(false);
    };

    speech.onerror = (event) => {
      console.error('Error during speech synthesis:', event);
      setIsSpeaking(false);
    };

    speechSynthesis.speak(speech);
    setIsSpeaking(true);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8 bg-white">
      {/* SEO Metadatos */}
      <Helmet>
        <title>{article.title} - Blog</title>
        <meta name="description" content={article.summary || 'Explora este artículo sobre salud y tecnología.'} />
        <meta name="keywords" content={`salud, tecnología, blog, ${article.category}, ${article.title}`} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.summary || article.title} />
        <meta property="og:image" content={article.image} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.summary || article.title} />
        <meta name="twitter:image" content={article.image} />
      </Helmet>

      {/* Título */}
      <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
        {article.title}
      </h1>

      {/* Imagen */}
      <div className="flex justify-center mb-6">
        <img 
          src={article.image} 
          alt={article.title} 
          className="rounded-lg shadow-lg object-cover h-96 w-full max-w-3xl"
        />
      </div>

      {/* Botón para leer el artículo */}
      <div className="flex justify-center mb-4">
        <button
          onClick={readArticleAloud}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md focus:outline-none"
        >
          {isSpeaking ? "Detener lectura" : "Leer artículo"}
        </button>
      </div>

      {/* Autor */}
      <Author author={article.author} />

      {/* Contenido usando react-markdown con HTML habilitado */}
      <div className="prose lg:prose-xl max-w-none mt-6 text-gray-700 leading-relaxed">
        <ReactMarkdown 
          rehypePlugins={[rehypeRaw]} 
          components={{
            p: ({ node, ...props }) => <p className="mb-6" {...props} />,
            h2: ({ node, ...props }) => <h2 className="text-3xl font-bold text-gray-800 mt-10 mb-4" {...props} />,
            h3: ({ node, ...props }) => <h3 className="text-2xl font-semibold text-gray-700 mt-8 mb-3" {...props} />,
          }}
        >
          {article.content}
        </ReactMarkdown>
      </div>

      {/* Referencia */}
      {article.reference && (
        <div className="mt-6 text-right text-sm italic text-gray-500">
          <p>Reference: {article.reference}</p>
        </div>
      )}

      {/* Artículos relacionados */}
      <RelatedArticles articleSlug={slug} />

      {/* Sección de comentarios */}
      <CommentSection articleId={article.id} />
    </div>
  );
};

export default Article;





// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { Helmet } from 'react-helmet'; // Importamos Helmet para SEO
// import Author from './Author';
// import CommentSection from './CommentSection';
// import RelatedArticles from './RelatedArticles';
// import ReactMarkdown from 'react-markdown';
// import rehypeRaw from 'rehype-raw'; // Para permitir HTML en el markdown
// import axiosBlog from '../../utils/axiosBlog';

// const Article = () => {
//   const { slug } = useParams();
//   const [article, setArticle] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     axiosBlog.get(`/articles/${slug}`)
//       .then(response => setArticle(response.data))
//       .catch(error => {
//         console.error('Error fetching the article:', error);
//         setError("Article not found or an error occurred.");
//       });
//   }, [slug]);

//   if (error) return <div className="text-center mt-20 text-red-500">{error}</div>;
//   if (!article) return <div className="text-center mt-20 text-gray-500">Loading...</div>;

//   return (
//     <div className="max-w-4xl mx-auto px-4 py-8">
//       {/* SEO Metadatos */}
//       <Helmet>
//         <title>{article.title} - Blog</title>
//         <meta name="description" content={article.summary || 'Explora este artículo sobre salud y tecnología.'} />
//         <meta name="keywords" content={`salud, tecnología, blog, ${article.category}, ${article.title}`} />
//         <meta property="og:title" content={article.title} />
//         <meta property="og:description" content={article.summary || article.title} />
//         <meta property="og:image" content={article.image} />
//         <meta property="og:type" content="article" />
//         <meta property="og:url" content={window.location.href} />
//         <meta name="twitter:card" content="summary_large_image" />
//         <meta name="twitter:title" content={article.title} />
//         <meta name="twitter:description" content={article.summary || article.title} />
//         <meta name="twitter:image" content={article.image} />
//       </Helmet>

//       {/* Título */}
//       <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
//         {article.title}
//       </h1>

//       {/* Imagen */}
//       <div className="flex justify-center mb-6">
//         <img 
//           src={article.image} 
//           alt={article.title} 
//           className="rounded-lg shadow-lg object-cover h-96 w-full max-w-3xl"
//         />
//       </div>

//       {/* Autor */}
//       <Author author={article.author} />

//       {/* Contenido usando react-markdown con HTML habilitado */}
//       <div className="prose lg:prose-xl max-w-none mt-6 text-gray-700 leading-relaxed">
//         <ReactMarkdown 
//           rehypePlugins={[rehypeRaw]} 
//           components={{
//             // Personalización de encabezados y párrafos
//             p: ({ node, ...props }) => <p className="mb-6" {...props} />,
//             h2: ({ node, ...props }) => <h2 className="text-3xl font-bold text-gray-800 mt-10 mb-4" {...props} />,
//             h3: ({ node, ...props }) => <h3 className="text-2xl font-semibold text-gray-700 mt-8 mb-3" {...props} />,
//           }}
//         >
//           {article.content}
//         </ReactMarkdown>
//       </div>

//       {/* Referencia */}
//       {/* {article.refenrece && (
//         <div className="mt-6 text-right text-sm italic text-gray-500">
//           <p>Reference: {article.refenrece}</p>
//         </div>
//       )} */}
//       {/* Referencia */}
//       {article.reference && (
//         <div className="mt-6 text-right text-sm italic text-gray-500">
//           <p>Reference: {article.reference}</p>
//         </div>
//       )}


//       {/* Artículos relacionados */}
//       <RelatedArticles articleSlug={slug} />

//       {/* Sección de comentarios */}
//       <CommentSection articleId={article.id} />
//     </div>
//   );
// };

// export default Article;

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import Author from './Author';
// import CommentSection from './CommentSection';
// import RelatedArticles from './RelatedArticles';
// import ReactMarkdown from 'react-markdown'; 
// import rehypeRaw from 'rehype-raw'; // Importamos rehype-raw para procesar HTML en markdown
// import axiosBlog from '../../utils/axiosBlog';

// const Article = () => {
//   const { slug } = useParams();
//   const [article, setArticle] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     axiosBlog.get(`/articles/${slug}`)
//       .then(response => setArticle(response.data))
//       .catch(error => {
//         console.error('Error fetching the article:', error);
//         setError("Article not found or an error occurred.");
//       });
//   }, [slug]);

//   if (error) return <div className="text-center mt-20 text-red-500">{error}</div>;
//   if (!article) return <div className="text-center mt-20 text-gray-500">Loading...</div>;

//   return (
//     <div className="max-w-4xl mx-auto px-4 py-8">
//       {/* Título */}
//       <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
//         {article.title}
//       </h1>

//       {/* Imagen */}
//       <div className="flex justify-center mb-6">
//         <img 
//           src={article.image} 
//           alt={article.title} 
//           className="rounded-lg shadow-lg object-cover h-96 w-full max-w-3xl"
//         />
//       </div>

//       {/* Autor */}
//       <Author author={article.author} />

//       {/* Contenido usando react-markdown con HTML habilitado */}
//       <div className="prose lg:prose-xl max-w-none mt-6 text-gray-700 leading-relaxed">
//         <ReactMarkdown rehypePlugins={[rehypeRaw]}>
//           {article.content}
//         </ReactMarkdown>
//       </div>

//       {/* Referencia */}
//       {article.refenrece && (
//         <div className="mt-6 text-right text-sm italic text-gray-500">
//           <p>Reference: {article.refenrece}</p>
//         </div>
//       )}

//       {/* Artículos relacionados */}
//       <RelatedArticles articleSlug={slug} />

//       {/* Sección de comentarios */}
//       <CommentSection articleId={article.id} />
//     </div>
//   );
// };

// export default Article;



// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import Author from './Author';
// import CommentSection from './CommentSection';
// import RelatedArticles from './RelatedArticles';
// import axiosBlog from '../../utils/axiosBlog';

// const Article = () => {
//   const { slug } = useParams();
//   const [article, setArticle] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     axiosBlog.get(`/articles/${slug}`)
//       .then(response => setArticle(response.data))
//       .catch(error => {
//         console.error('Error fetching the article:', error);
//         setError("Article not found or an error occurred.");
//       });
//   }, [slug]);

//   if (error) return <div className="text-center mt-20 text-red-500">{error}</div>;
//   if (!article) return <div className="text-center mt-20 text-gray-500">Loading...</div>;

//   return (
//     <div className="max-w-4xl mx-auto px-4 py-8">
//       {/* Título */}
//       <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
//         {article.title}
//       </h1>

//       {/* Imagen */}
//       <div className="flex justify-center mb-6">
//         <img 
//           src={article.image} 
//           alt={article.title} 
//           className="rounded-lg shadow-lg object-cover h-96 w-full max-w-3xl"
//         />
//       </div>

//       {/* Autor */}
//       <Author author={article.author} />

//       {/* Contenido */}
//       <div className="prose lg:prose-xl max-w-none mt-6 text-gray-700 leading-relaxed">
//         <div dangerouslySetInnerHTML={{ __html: article.content }} />
//       </div>

//       {/* Referencia */}
//       {article.refenrece && (
//         <div className="mt-6 text-right text-sm italic text-gray-500">
//           <p>Reference: {article.refenrece}</p>
//         </div>
//       )}

//       {/* Artículos relacionados */}
//       <RelatedArticles articleSlug={slug} />

//       {/* Sección de comentarios */}
//       <CommentSection articleId={article.id} />
//     </div>
//   );
// };

// export default Article;

// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import Author from './Author';
// import CommentSection from './CommentSection';
// import RelatedArticles from './RelatedArticles';
// import axiosBlog from '../../utils/axiosBlog';

// const Article = () => {
//   const { slug } = useParams();
//   const [article, setArticle] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     axiosBlog.get(`/articles/${slug}`)
//       .then(response => setArticle(response.data))
//       .catch(error => {
//         console.error('Error fetching the article:', error);
//         setError("Article not found or an error occurred.");
//       });
//   }, [slug]);

//   if (error) return <div className="text-center mt-20 text-red-500">{error}</div>;
//   if (!article) return <div className="text-center mt-20 text-gray-500">Loading...</div>;

//   return (
//     <div className="max-w-4xl mx-auto px-4 py-8">
//       {/* Título */}
//       <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
//         {article.title}
//       </h1>

//       {/* Imagen */}
//       <div className="flex justify-center mb-6">
//         <img 
//           src={article.image} 
//           alt={article.title} 
//           className="rounded-lg shadow-lg object-cover h-96 w-full max-w-3xl"
//         />
//       </div>

//       {/* Autor */}
//       <Author author={article.author} />

//       {/* Contenido */}
//       <div className="prose lg:prose-xl max-w-none mt-6 text-gray-700 leading-relaxed">
//         <div dangerouslySetInnerHTML={{ __html: article.content }} />
//       </div>

//       {/* Artículos relacionados */}
//       <RelatedArticles articleSlug={slug} />

//       {/* Sección de comentarios */}
//       <CommentSection articleId={article.id} />
//     </div>
//   );
// };

// export default Article;
