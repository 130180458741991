import React from 'react';
import Slider from 'react-slick';
import TestimonialItem from './TestimonialItem';
import './BannerCarousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BannerCarousel = ({ testimonials }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768, // Define el punto de quiebre para pantallas móviles
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Desactiva el modo centrado en pantallas móviles
        },
      },
    ],
  };

  return (
    <div className="banner-carousel">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-slide">
            <TestimonialItem
              image={testimonial.image}
              title={testimonial.title}
              text={testimonial.text}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerCarousel;
