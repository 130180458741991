
// export default ServicePage;
import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
import Section from './Section';
import BannerCarousel from './BannerCarousel';
import './ServicePage.css';
import SEO from '../SEO';
import Canvas from '../canvas';

const ServicePage = ({ title, image, content, sections }) => {
  const { t } = useTranslation();
  const testimonials = [
    { image: 'images/2.webp', title: 'testimonials.zoe_more.title', text: 'testimonials.zoe_more.text' },
    { image: 'images/2.webp', title: 'testimonials.david_marquez.title', text: 'testimonials.david_marquez.text' },
    { image: 'images/2.webp', title: 'testimonials.frank_smith.title', text: 'testimonials.frank_smith.text' },
    { image: 'images/2.webp', title: 'testimonials.william_moyer.title', text: 'testimonials.william_moyer.text' },
    { image: 'images/2.webp', title: 'testimonials.daniel_marz.title', text: 'testimonials.daniel_marz.text' },
    { image: 'images/2.webp', title: 'testimonials.elizabeth_zaragoza.title', text: 'testimonials.elizabeth_zaragoza.text' },
  ];

  return (
    <div className="service-page">
      <SEO />
      <Canvas />
      <Banner title={title} image={image} />
      <div className="service-content">
        <p>{t(content)}</p>
        {sections.slice(0, 1).map((section, index) => (
          <Section key={index} title={section.title} content={section.content} image={section.image} />
        ))}
        <BannerCarousel testimonials={testimonials} />
        {sections.slice(1).map((section, index) => (
          <Section key={index} title={section.title} content={section.content} image={section.image} />
        ))}
      </div>
    </div>
  );
};

export default ServicePage;
