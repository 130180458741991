import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './canvas.css';

const Canvas = () => {
  const bannerRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const banner = bannerRef.current;
    const canvas = canvasRef.current;
    if (!banner || !canvas) return;

    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.width = banner.offsetWidth;
      canvas.height = banner.offsetHeight;
    };

    resizeCanvas();

    const arrayColors = ['#fff', '#6200ee', '#9d8df1', '#007BFF'];
    let dots = [];

    const createDots = () => {
      dots = [];
      for (let index = 0; index < 40; index++) { // Reduced the number of dots
        dots.push({
          x: Math.floor(Math.random() * canvas.width),
          y: Math.floor(Math.random() * canvas.height),
          size: Math.random() * 3 + 5,
          color: arrayColors[Math.floor(Math.random() * arrayColors.length)],
        });
      }
    };

    const drawDots = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      dots.forEach(dot => {
        ctx.fillStyle = dot.color;
        ctx.beginPath();
        ctx.arc(dot.x, dot.y, dot.size, 0, Math.PI * 2);
        ctx.fill();
      });
    };

    createDots();
    drawDots();

    const handleMouseMove = (event) => {
      const rect = canvas.getBoundingClientRect();
      const mouse = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };
      drawDots();
      dots.forEach(dot => {
        const distance = Math.sqrt((mouse.x - dot.x) ** 2 + (mouse.y - dot.y) ** 2);
        if (distance < 150) { // Reduced the distance for connection
          ctx.strokeStyle = dot.color;
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(dot.x, dot.y);
          ctx.lineTo(mouse.x, mouse.y);
          ctx.stroke();
        }
      });
    };

    banner.addEventListener('mousemove', handleMouseMove);

    window.addEventListener('resize', () => {
      resizeCanvas();
      createDots();
      drawDots();
    });

    banner.addEventListener('mouseout', drawDots);

    return () => {
      banner.removeEventListener('mousemove', handleMouseMove);
      banner.removeEventListener('mouseout', drawDots);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  const handleClick = () => {
    navigate('/register');
  };

  const titles = [
    'DoctorElec is Connection with Health',
    'DoctorElec is Prevention',
    'DoctorElec is Health and Benefits',
    'DoctorElec is Telehealth',
    'DoctorElec is Wellness Anywhere',
    'DoctorElec is Savings',
    'Doctorelec is Connection with Your Health Care',
    'DoctorElec is Telehealth at Your Fingertips',
    'DoctorElec is Your Health Partner',
    'DoctorElec is Saving You Time and Money',
    'DoctorElec is Health and Well-being'
  ];

  const randomTitle = titles[Math.floor(Math.random() * titles.length)];

  return (
    <main className="canvas-container">
      <div className="banner" ref={bannerRef}>
        <h1>{randomTitle}</h1>
        <h2>
          Stay updated with DoctorElec for the latest in telehealth.
          <br />Enhance your health and well-being with us.
        </h2>
        <button onClick={handleClick}>Get Started &#8599;</button>
        <canvas id="dotsCanvas" ref={canvasRef}></canvas>
      </div>
    </main>
  );
};

export default Canvas;

