import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'boxicons/css/boxicons.min.css';
import './MainContent.css';
import { useAuth } from '../context/AuthContext';

function WhyUs() {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <section id="why-us" className="why-us doctors">
      <Container>
        <Row>
          <Col lg={4} className="d-flex align-items-stretch">
            <div className="content">
              <h3 className="text-white">{t('whyUsTitle')}</h3>
              <p>
                {t('whyUsContent')}
              </p>
              {!user && (
                <div className="text-center">
                  <Button href="/login" className="button_style btn btn-purple">
                    {t('login')} <i className="bx bx-chevron-right"></i>
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col lg={8} className="d-flex align-items-stretch">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <Row>
                <Col xl={4} className="d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-video"></i>
                    <h4 className="font_color_style_small">{t('directVideoCalls')}</h4>
                    <p>{t('directVideoCallsContent')}</p>
                  </div>
                </Col>
                <Col xl={4} className="d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-shield"></i>
                    <h4 className="font_color_style_small">{t('advancedSecurity')}</h4>
                    <p>{t('advancedSecurityContent')}</p>
                  </div>
                </Col>
                <Col xl={4} className="d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <i className="bx bx-support"></i>
                    <h4 className="font_color_style_small">{t('continuousSupport')}</h4>
                    <p>{t('continuousSupportContent')}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WhyUs;
