// import React, { useState, useEffect } from 'react';
// import { Button, Alert } from 'react-bootstrap';

// const VideoCall = ({ doctor, patient }) => {
//     const [connected, setConnected] = useState(false);

//     useEffect(() => {
//         // Logic to initialize the video call using WebRTC or similar technology
//         // For now, just simulate a connection
//         setConnected(true);
//     }, []);

//     const handleJoinCall = () => {
//         // Logic to join the call
//         setConnected(true);
//     };

//     return (
//         <div className="video-call-container">
//             {!connected ? (
//                 <Button onClick={handleJoinCall} variant="primary">
//                     Join Call with Dr. {doctor.first_name}
//                 </Button>
//             ) : (
//                 <div>
//                     <h3>Connected to Dr. {doctor.first_name}'s Video Call</h3>
//                     <Alert variant="info">Consultation is now in progress.</Alert>
//                     {/* Placeholder for the video component */}
//                     <div className="video-stream-placeholder">Video Stream Here</div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default VideoCall;
import React, { useState, useEffect, useRef } from 'react';
import { Button, Alert } from 'react-bootstrap';

const VideoCall = ({ socket, participants, roomId }) => {
    const [connected, setConnected] = useState(false);
    const [localStream, setLocalStream] = useState(null);
    const [remoteStreams, setRemoteStreams] = useState([]);
    const localVideoRef = useRef();
    const remoteVideoRefs = useRef([]);
    const peerConnections = useRef({});
    
    useEffect(() => {
        if (!socket || !roomId) {
            console.error("Socket or Room ID is undefined.");
            return;
        }
        console.log('socket:', socket);
        console.log('roomId:', roomId);


        socket.on('connect', () => {
            console.log("Socket connected, starting local stream...");
            setConnected(true);
            startLocalStream();
        });

        socket.on('offer', handleReceiveOffer);
        socket.on('answer', handleReceiveAnswer);
        socket.on('ice-candidate', handleNewICECandidateMsg);
        socket.on('user-disconnected', handleUserDisconnected);
        // } else {
        //     console.error("Socket or Room ID is undefined.");
        // }

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [socket, roomId]);

    const startLocalStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setLocalStream(stream);
            localVideoRef.current.srcObject = stream;

            console.log("Local stream started, emitting 'ready' signal with roomId:", roomId);
            socket.emit('ready', { roomId: roomId });
        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };

    const handleReceiveOffer = async ({ offer, socketId }) => {
        console.log("Received offer from socketId:", socketId);
        const peerConnection = createPeerConnection(socketId);
        await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);
        socket.emit('answer', { answer, to: socketId });
        console.log("Sent answer to socketId:", socketId);
    };

    const handleReceiveAnswer = async ({ answer, socketId }) => {
        console.log("Received answer from socketId:", socketId);
        const peerConnection = peerConnections.current[socketId];
        await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
    };

    const handleNewICECandidateMsg = async ({ candidate, socketId }) => {
        console.log("Received ICE candidate from socketId:", socketId);
        const peerConnection = peerConnections.current[socketId];
        await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    };

    const handleUserDisconnected = (socketId) => {
        console.log("User disconnected with socketId:", socketId);
        if (peerConnections.current[socketId]) {
            peerConnections.current[socketId].close();
            delete peerConnections.current[socketId];
            setRemoteStreams((prevStreams) => prevStreams.filter((stream) => stream.id !== socketId));
        }
    };

    const createPeerConnection = (socketId) => {
        console.log("Creating peer connection for socketId:", socketId);
        const peerConnection = new RTCPeerConnection({
            iceServers: [
                {
                    urls: 'stun:stun.l.google.com:19302',
                },
            ],
        });

        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                console.log("Sending ICE candidate to socketId:", socketId);
                socket.emit('ice-candidate', { candidate: event.candidate, to: socketId });
            }
        };

        peerConnection.ontrack = (event) => {
            console.log("Received remote track from socketId:", socketId);
            setRemoteStreams((prevStreams) => [
                ...prevStreams,
                { id: socketId, stream: event.streams[0] },
            ]);
            remoteVideoRefs.current[socketId].srcObject = event.streams[0];
        };

        localStream.getTracks().forEach((track) => peerConnection.addTrack(track, localStream));

        peerConnections.current[socketId] = peerConnection;
        return peerConnection;
    };

    return (
        <div className="video-call-container">
            {!connected ? (
                <Alert variant="warning">Connecting to the video call...</Alert>
            ) : (
                <div>
                    <h3>Connected to the video call</h3>
                    <Alert variant="info">Consultation is now in progress.</Alert>
                    <div className="video-stream-placeholder">
                        <video ref={localVideoRef} autoPlay muted className="local-video" />
                        {remoteStreams.map((stream, index) => (
                            <video
                                key={stream.id}
                                ref={(el) => (remoteVideoRefs.current[stream.id] = el)}
                                autoPlay
                                className="remote-video"
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoCall;
