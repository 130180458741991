import React, { useState, useEffect } from 'react';
import './Contact.css';
import { useTheme } from '../context/ThemeContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import SEO from './SEO';

function Feedback() {
  const { darkMode } = useTheme();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_query: '',
    user_message: '',
    captcha: ''
  });
  const [captchaValue, setCaptchaValue] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [captchaError, setCaptchaError] = useState('');

  const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const length = Math.floor(Math.random() * 3) + 3; // Random length between 3 and 5
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const generateCaptcha = () => {
    const type = Math.random() > 0.5 ? 'number' : 'text';
    if (type === 'number') {
      const num1 = Math.floor(Math.random() * 90) + 10; // Random number between 10 and 99
      const num2 = Math.floor(Math.random() * 90) + 10;
      setCaptchaValue(`${num1} + ${num2} = ?`);
      return num1 + num2;
    } else {
      const str1 = generateRandomString();
      const str2 = generateRandomString();
      setCaptchaValue(`${str1} + ${str2} = ?`);
      return str1 + str2;
    }
  };

  useEffect(() => {
    setCaptchaAnswer(generateCaptcha());
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.captcha === captchaAnswer.toString()) {
      console.log('Form data:', formData);
      alert('Your feedback has been submitted!');
      setCaptchaError('');
      setFormData({
        user_name: '',
        user_email: '',
        user_query: '',
        user_message: '',
        captcha: ''
      });
      setCaptchaAnswer(generateCaptcha());
    } else {
      setCaptchaError('Incorrect captcha answer. Please try again.');
    }
  };

  return (
    <div id="feedback" className={`contact-section ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <SEO />
      <div className="container">
        <div className="section-title">
          <h2 className={`font_color_style ${darkMode ? 'dark-mode' : 'light-mode'}`}>Support</h2>
          <p className={darkMode ? 'dark-mode' : 'light-mode'}>
            Have any questions or issues? Submit your feedback and a customer service representative will assist you promptly.
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <form onSubmit={handleSubmit} className={`ticket-form ${darkMode ? 'dark-mode' : 'light-mode'}`}>
              <div className="form-group">
                <label htmlFor="user_name">Name</label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleChange}
                  className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="user_email">Email</label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleChange}
                  className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="user_query">Query</label>
                <input
                  type="text"
                  id="user_query"
                  name="user_query"
                  value={formData.user_query}
                  onChange={handleChange}
                  className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="user_message">Message</label>
                <textarea
                  id="user_message"
                  name="user_message"
                  rows="5"
                  value={formData.user_message}
                  onChange={handleChange}
                  className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="captcha">Please solve: {captchaValue}</label>
                <input
                  type="text"
                  id="captcha"
                  name="captcha"
                  value={formData.captcha}
                  onChange={handleChange}
                  className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                  required
                />
                {captchaError && <small className="text-danger">{captchaError}</small>}
              </div>
              <button type="submit" className="btn btn-primary btn-custom">Submit Feedback 🎟️</button>
            </form>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default Feedback;

