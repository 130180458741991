import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const DoctorOverview = ({ doctor }) => {
  const isVerified = doctor.is_verified; // Assuming this property exists
  const isActive = doctor.status; // Assuming this property exists

  // Calcular los años de experiencia
  const calculateExperience = (issueDate) => {
    if (!issueDate) return null;
    const currentDate = new Date();
    const issueYear = new Date(issueDate).getFullYear();
    const years = currentDate.getFullYear() - issueYear;
    return years >= 1 ? years : null; // Only show if >= 1 year
  };

  const yearsOfExperience = calculateExperience(doctor.issue_date);

  return (
    <Card className="doctor-overview shadow-sm">
      <Card.Body className="text-center d-flex flex-column align-items-center">
        <Image
          src={doctor.photo ? `http://localhost:5003/uploads/${doctor.photo}` : 'default-image.png'}
          roundedCircle
          className="doctor-photo"
          alt={`${doctor.first_name || 'Doctor'} ${doctor.last_name || ''}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'default-image.png';
          }}
        />
        <h3 className="doctor-card-title mt-3">
          Dr. {doctor.first_name || 'N/A'} {doctor.last_name || ''}
        </h3>
        <Card.Text className="mt-3">
          <strong>Specialty:</strong> {doctor.medical_speciality || 'Not available'}
          <br />
          <strong>License Number:</strong> {doctor.license_number || 'Not available'}
          <br />
          {yearsOfExperience !== null && (
            <>
              <strong>Experience:</strong> {yearsOfExperience} years
              <br />
            </>
          )}
          <strong>Bio:</strong> {doctor.bio || 'No bio available'}
          <br />
        </Card.Text>
        <Card.Text className="status-info mt-2">
          <strong>Status:</strong>{' '}
          {isActive ? (
            <span className="doctor-status">
              Active <FaCheckCircle color="green" />
            </span>
          ) : (
            <span className="doctor-status">
              Inactive <FaTimesCircle color="red" />
            </span>
          )}
          <br />
          {/* <strong>Verification:</strong>{' '}
          {isVerified ? (
            <span className="doctor-status">
              Verified <FaCheckCircle color="green" />
            </span>
          ) : (
            <span className="doctor-status">
              Not Verified <FaTimesCircle color="red" />
            </span>
          )} */}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default DoctorOverview;

