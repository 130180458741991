import React, { useState, useEffect } from 'react';
import { Button, Spinner, Alert, Table, Pagination, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axiosConsultation from '../../utils/axiosConsultation';
import axiosProfile from '../../utils/axiosProfile';
import {
  HeartPulseIcon,
  HomeIcon,
  CalendarIcon,
  PillIcon,
  CreditCardIcon,
  ChartIcon,
  ClipboardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '../Icons'; // Asegúrate de que la ruta sea correcta
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción

const LogsDoctor = () => {
  const { t } = useTranslation(); // Inicializa el hook de traducción
  const navigate = useNavigate();
  const { user } = useAuth();
  const [logs, setLogs] = useState([]);
  const [patientProfiles, setPatientProfiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Variables para paginación
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 10;

  const pages = [
    { path: '/dashboard', label: t('Dashboard') },
    { path: '/wellness', label: t('Wellness') },
    { path: '/appointments', label: t('Appointments') },
    { path: '/treatments', label: t('Treatments') },
    { path: '/billing', label: t('Billing') },
    { path: '/statistics', label: t('Statistics') },
    { path: '/logs-doctor', label: t('Doctor Logs') }, // Updated label
  ];

  useEffect(() => {
    const fetchLogsAndPatients = async () => {
      try {
        const response = await axiosConsultation.get('/logs-doctor', {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        // Ordenar los logs por fecha de creación en orden descendente
        const sortedLogs = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setLogs(sortedLogs);

        const patientIds = sortedLogs.map((log) => log.patient_id);
        fetchPatientProfiles(patientIds);
      } catch (error) {
        setError(error.response?.data?.message || t('Failed to fetch logs'));
      } finally {
        setLoading(false);
      }
    };

    const fetchPatientProfiles = async (patientIds) => {
      try {
        const uniquePatientIds = [...new Set(patientIds)];
        const queryString = uniquePatientIds.map((id) => `ids[]=${id}`).join('&');
        const profilesResponse = await axiosProfile.get(`/profiles/?role=patient&${queryString}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const profilesMap = profilesResponse.data.reduce((acc, profile) => {
          acc[profile.user_id] = profile;
          return acc;
        }, {});
        setPatientProfiles(profilesMap);
      } catch (error) {
        setError(error.response?.data?.message || t('Failed to fetch patient profiles'));
      }
    };

    if (user) {
      fetchLogsAndPatients();
    }
  }, [user]);

  const handlePrevious = () => {
    const newIndex = currentPage > 1 ? currentPage - 1 : pages.length;
    setCurrentPage(newIndex);
    navigate(pages[newIndex].path);
  };

  const handleNext = () => {
    const newIndex = currentPage < pages.length ? currentPage + 1 : 1;
    setCurrentPage(newIndex);
    navigate(pages[newIndex].path);
  };

  const getStatusClassName = (status) => {
    switch (status.toLowerCase()) {
      case 'scheduled':
        return 'badge bg-info text-dark'; // Azul: Programado
      case 'in progress':
        return 'badge bg-primary text-light'; // Azul oscuro: En progreso
      case 'pending prescription':
        return 'badge bg-warning text-dark'; // Amarillo: Pendiente de prescripción
      case 'completed':
        return 'badge bg-success text-light'; // Verde: Completado
      case 'canceled':
        return 'badge bg-danger text-light'; // Rojo: Cancelado
      default:
        return 'badge bg-secondary text-light'; // Gris: Desconocido
    }
  };
  
  
  

  // Obtener los logs de la página actual
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex-1 overflow-auto py-2">
            <nav className="grid items-start px-4 text-sm font-medium">
              <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
                <HeartPulseIcon className="h-6 w-6" />
                {t('Start Consultation')}
              </Link>
              <Link to="/dashboard" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <HomeIcon className="h-6 w-6" />
                {t('Dashboard')}
              </Link>
              <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <CalendarIcon className="h-6 w-6" />
                {t('Appointments')}
              </Link>
              <Link to="/treatments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <PillIcon className="h-6 w-6" />
                {t('Treatments')}
              </Link>
              <Link to="/billing" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <CreditCardIcon className="h-6 w-6" />
                {t('Billing')}
              </Link>
              <Link to="/statistics" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <ChartIcon className="h-6 w-6" />
                {t('Statistics')}
              </Link>
              <Link to="/logs-doctor" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
                <ClipboardIcon className="h-6 w-6" />
                {t('Doctor Logs')}
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <main className="flex-col gap-4 p-4 md:gap-8 md:p-6">
          <div className="flex items-center gap-4">
            <h1 className="font-semibold text-lg md:text-xl">{pages[currentPage - 1].label}</h1>
            <div className="ml-auto flex items-center gap-2">
              <Button variant="outline" size="icon" onClick={handlePrevious}>
                <ChevronLeftIcon className="h-4 w-4" />
                <span className="sr-only">{t('Previous')}</span>
              </Button>
              <Button variant="outline" size="icon" onClick={handleNext}>
                <ChevronRightIcon className="h-4 w-4" />
                <span className="sr-only">{t('Next')}</span>
              </Button>
            </div>
          </div>
          <br />
          <h2 className="font-semibold text-lg md:text-xl">{t("Doctor's Patient Logs")}</h2>
          <hr />
          <br />
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr className="text-center fw-bold">
                    <th>{t('Date')}</th>
                    <th>{t('Patient')}</th>
                    <th>{t('Status')}</th>
                    <th>{t('Notes')}</th>
                    <th>{t('Details')}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentLogs.map((log) => (
                    <tr key={log.id} className="text-center align-middle">
                      <td>{new Date(log.created_at).toLocaleString()}</td>
                      <td>
                        {patientProfiles[log.patient_id] ? (
                          <div className="d-flex align-items-center">
                            <img
                              src={`http://127.0.0.1:5003/uploads/${patientProfiles[log.patient_id].photo}`} // Cambia la URL si es necesario
                              alt={t('Patient')}
                              className="rounded-circle me-2"
                              style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                            />
                            <span>{patientProfiles[log.patient_id].first_name} {patientProfiles[log.patient_id].last_name}</span>
                          </div>
                        ) : (
                          t('Unknown Patient')
                        )}
                      </td>
                      <td>
                        <span className={getStatusClassName(log.status)}>
                          {t(log.status)}
                        </span>
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{log.notes}</Tooltip>}
                        >
                          <div className="text-truncate" style={{ maxWidth: '150px' }}>
                            {log.notes}
                          </div>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <Link to={`/consultation/${log.id}`} className="btn btn-sm btn-purple w-100">
                          {t('View Details')}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination>
                {[...Array(Math.ceil(logs.length / logsPerPage)).keys()].map((page) => (
                  <Pagination.Item
                    key={page + 1}
                    active={page + 1 === currentPage}
                    onClick={() => paginate(page + 1)}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default LogsDoctor;

