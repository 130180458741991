// import React, { useState, useEffect } from 'react';
// import './Hero.css';
// import Logo from './Logo';
// import { useAuth } from '../context/AuthContext';
// import Login from './Login';

// function Hero() {
//   const { user } = useAuth();
//   const [firstName, setFirstName] = useState('');
//   const [backgroundImage, setBackgroundImage] = useState('');

//   useEffect(() => {
//     if (user && user.first_name) {
//       setFirstName(user.first_name);
//     } else {
//       setFirstName('');
//     }

//     const images = [
//       require('../assets/images/hero1.webp'),
//       require('../assets/images/hero2.webp'),
//       require('../assets/images/hero3.webp'),
//       require('../assets/images/hero4.webp')
//     ];

//     const randomImage = images[Math.floor(Math.random() * images.length)];
//     setBackgroundImage(randomImage);
//   }, [user]);

//   return (
    // <section className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
    //   <div className="hero-content">
    //     <div className="hero-left">
    //       <Logo />
    //       <h1 className="hero-text">Welcome to DoctorElec {firstName ? firstName : '😊'}</h1>
    //       <p className="hero-text">Connecting you with top healthcare professionals through our innovative telehealth platform.</p>
    //     </div>
    //     {!user && (
    //       <div className="hero-right">
    //         <Login />
    //       </div>
    //     )}
    //   </div>
    // </section>
//   );
// }

// export default Hero;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Hero.css';
import Logo from './Logo';
import { useAuth } from '../context/AuthContext';
import Login from './Login';

function Hero() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    if (user && user.first_name) {
      setFirstName(user.first_name);
    } else {
      setFirstName('');
    }

    const images = [
      require('../assets/images/hero1.webp'),
      require('../assets/images/hero2.webp'),
      require('../assets/images/hero3.webp'),
      require('../assets/images/hero4.webp')
    ];

    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, [user]);

  return (
    <section className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="hero-content">
        <div className="hero-left">
          <Logo />
          <h1 className="hero-text">{t('welcome')} {firstName ? firstName : '😊'}</h1>
          <p className="hero-text">{t('heroSubtitle')}</p>
        </div>
        {!user && (
          <div className="hero-right">
            <Login />
          </div>
        )}
      </div>
    </section>
    
    
  );
}

export default Hero;


