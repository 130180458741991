// src/components/Doctor/Icons.jsx

import React from "react";

export function HomeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  );
}




export function CreditCardIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
      <line x1="1" y1="10" x2="23" y2="10" />
      <line x1="6" y1="16" x2="6" y2="16" />
      <line x1="10" y1="16" x2="14" y2="16" />
    </svg>
  );
}

export function ChevronLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  );
}

export function ChevronRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
}

export function PillIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m10.5 20.5 10-10a4.95 4.95 0 1 0-7-7l-10 10a4.95 4.95 0 1 0 7 7Z" />
      <path d="m8.5 8.5 7 7" />
    </svg>
  );
}

 
  export function HeartPulseIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
        <path d="M3.22 12H9.5l.5-1 2 4.5 2-7 1.5 3.5h5.27" />
      </svg>
    );
  }
  
  
  export function InfoIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M12 16v-4" />
        <path d="M12 8h.01" />
      </svg>
    );
  }
  
  export function SearchIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="11" cy="11" r="8" />
        <path d="m21 21-4.3-4.3" />
      </svg>
    );
  }
  
  export function UserIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    );
  }
  
  
  export function CalendarIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
        <line x1="16" y1="2" x2="16" y2="6" />
        <line x1="8" y1="2" x2="8" y2="6" />
        <line x1="3" y1="10" x2="21" y2="10" />
      </svg>
    );
  }
  
  export function ClipboardIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19 4h-4a2 2 0 0 0-4 0H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />
        <line x1="12" y1="11" x2="16" y2="11" />
        <line x1="12" y1="16" x2="16" y2="16" />
        <line x1="8" y1="11" x2="8" y2="11" />
        <line x1="8" y1="16" x2="8" y2="16" />
      </svg>
    );
  }
  
  export function ChartIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="12" y1="20" x2="12" y2="10" />
        <line x1="18" y1="20" x2="18" y2="4" />
        <line x1="6" y1="20" x2="6" y2="16" />
      </svg>
    );
  }
  export function DumbbellIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="1" y="5" width="22" height="4" rx="2" ry="2" />
        <rect x="1" y="15" width="22" height="4" rx="2" ry="2" />
      </svg>
    );
  }
  
  export function AppleIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16.362 1.639c.044 1.143-.414 2.254-1.139 3.067-.715.8-1.85 1.477-3.004 1.377-.049-1.141.416-2.265 1.141-3.068.719-.798 1.848-1.48 3.002-1.376zM12.002 5.527c.8 0 1.785-.48 2.665-1.308.71-.656 1.262-1.497 1.262-2.429 0-.143-.008-.287-.023-.431-.013-.125-.028-.253-.048-.383.627-.05 1.252.207 1.635.713.393.518.624 1.24.659 2.015.034.77-.128 1.513-.453 2.107-.323.59-.83 1.147-1.511 1.564.15.189.288.389.409.605.349.63.553 1.383.553 2.178 0 .795-.204 1.548-.553 2.178-.149.272-.31.522-.482.748-.455.596-1.013 1.111-1.676 1.529-.664.418-1.423.728-2.293.728-.87 0-1.629-.31-2.293-.728-.663-.418-1.221-.933-1.676-1.529-.172-.226-.333-.476-.482-.748-.349-.63-.553-1.383-.553-2.178 0-.795.204-1.548.553-2.178.121-.216.259-.416.409-.605-.681-.417-1.188-.974-1.511-1.564-.325-.594-.487-1.337-.453-2.107.035-.776.266-1.497.659-2.015.383-.506 1.008-.763 1.635-.713-.02.13-.035.258-.048.383-.015.144-.023.288-.023.431 0 .932.552 1.773 1.262 2.429.88.828 1.865 1.308 2.665 1.308z" />
      </svg>
    );
  }

  export function VisaIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 48 32"
            fill="none"
            stroke="none"
        >
            <rect width="48" height="32" rx="4" fill="#1A1F71" />
            <path fill="#fff" d="M16.41 21.6H14.33L15.68 9.6h2.07L16.41 21.6zM24.6 9.89c-.4-.15-1.02-.29-1.79-.29-1.98 0-3.38 1.05-3.39 2.55 0 1.11 1 1.73 1.76 2.1.78.39 1.04.65 1.04 1.01-.005.54-.63.78-1.22.78-.81 0-1.24-.12-1.9-.39l-.27-.12-.28 1.76c.46.2 1.31.38 2.19.4 2.06 0 3.41-1.03 3.42-2.63 0-.88-.52-1.56-1.65-2.12-.69-.35-1.11-.58-1.11-.94.008-.32.36-.65 1.14-.65.65-.01 1.11.14 1.48.3l.18.08.28-1.7M28.67 17.74c.16-.43.78-2.16.78-2.16-.008.015.16-.43.26-.7l.14.64s.37 1.81.45 2.22h-1.63zm2.42-8.14h-1.51c-.47 0-.82.13-1.03.61l-2.9 7.1h2.04s.33-.93.4-1.13h2.45c.06.27.23 1.13.23 1.13h1.8l-1.48-7.71zM33.5 15.41l.92-2.52.1-.25c.27-.7.9-1.36 1.69-1.69l1.61-.55.37 1.83-.5.16c-.43.14-.92.37-1.02.91l-1.95 7.66h-2.04l1.1-5.55M12.05 9.61L9.55 18.82l-.23-1.17c-.4-1.34-1.63-2.79-3.01-3.52l1.91 7.42h2.25L14.3 9.61h-2.25" />
        </svg>
    );
}
