const Category = ({ articles, onFilter }) => {
  const categories = [...new Set(articles.map(article => article.category))];

  const handleCategoryClick = (category) => {
    const filtered = articles.filter(article => article.category === category);
    onFilter(filtered);
  };

  return (
    <div className="flex justify-center space-x-4 mt-6">
      <button
        onClick={() => onFilter(articles)}
        className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-full text-sm text-gray-700 transition">
        All
      </button>
      {categories.map(category => (
        <button
          key={category}
          onClick={() => handleCategoryClick(category)}
          className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-full text-sm text-white transition">
          {category}
        </button>
      ))}
    </div>
  );
};

export default Category;

