
// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// const PrivateRoute = () => {
//     const { user, isLoading } = useAuth();
//     if (isLoading) {
//         return <div>Loading...</div>; // O un componente de carga más elaborado
//     }
//     return user ? <Outlet /> : <Navigate to="/login" />;
// };

// export default PrivateRoute;
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ allowedRoles }) => {
    const { user, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return allowedRoles.includes(user?.role) ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default PrivateRoute;
