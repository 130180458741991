import React, { useState, useEffect } from 'react';
import axiosPayment from '../../utils/axiosPayment';
import axiosConsultation from '../../utils/axiosConsultation';
import { useAuth } from '../../context/AuthContext';
import { Card, Spinner, Alert } from 'react-bootstrap';
import { FaDollarSign, FaMoneyBillWave } from 'react-icons/fa';
import './Billing.css'; // Archivo CSS para estilos personalizados

const Billing = () => {
  const { user } = useAuth();
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalConsultations, setTotalConsultations] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBillingData = async () => {
      try {
        const paymentsResponse = await axiosPayment.get(`/payments/${user.user_id}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        const consultationsResponse = await axiosConsultation.get('/consultations', {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        // Calcular el total de ganancias a partir de los pagos
        const total = paymentsResponse.data.reduce((acc, payment) => acc + payment.amount, 0);
        setTotalEarnings(total / 100); // Convertir centavos a dólares

        // Calcular el número total de consultas
        const consultationsCount = consultationsResponse.data.length;
        setTotalConsultations(consultationsCount);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching billing data:', err.response?.data || err.message);
        setError('Failed to fetch billing data');
        setLoading(false);
      }
    };

    if (user && user.token) {
      fetchBillingData();
    }
  }, [user]);

  return (
    <div className="billing-container">
      {loading ? (
        <Spinner animation="border" />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Card className="billing-card">
          <Card.Body>
            <Card.Title className="text-center mb-4">Billing Overview</Card.Title>
            <div className="billing-summary">
              <div className="billing-item">
                <FaDollarSign className="billing-icon text-success" />
                <h5>Total Earnings</h5>
                <p className="amount">${totalEarnings.toFixed(2)}</p>
              </div>
              <div className="billing-item">
                <FaMoneyBillWave className="billing-icon text-info" />
                <h5>Total Consultations</h5>
                <p className="amount">{totalConsultations}</p>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Billing;
