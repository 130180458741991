// import React, { useState, useEffect } from 'react';
// import axiosAppointments from '../utils/axiosAppointments';
// import axiosInstance from '../utils/axiosInstance'; 
// import { Button, Modal, Form, Card } from 'react-bootstrap';
// import DatePicker, { registerLocale } from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import enUS from 'date-fns/locale/en-US';
// import moment from 'moment-timezone';
// import './ScheduleAppointment.css';  // Importar archivo de estilos personalizados
// import './LightMode.css'; // Importar estilos del modo claro
// import './DarkMode.css'; // Importar estilos del modo oscuro
// import { useTheme } from '../context/ThemeContext'; // Importar el useTheme

// registerLocale('en-US', enUS);

// const ScheduleAppointment = ({ onAppointmentAdded }) => {
//     const [show, setShow] = useState(false);
//     const [formData, setFormData] = useState({
//         doctor_name: '',
//         appointment_date: '',
//         reason: ''
//     });
//     const [username, setUsername] = useState('');
//     const [appointments, setAppointments] = useState([]);
//     const [occupiedDates, setOccupiedDates] = useState([]);
//     const { darkMode } = useTheme(); // Obtener el valor de darkMode

//     const fetchAvailableDates = async (doctorName) => {
//         try {
//             const response = await axiosAppointments.get('/available-dates/', {
//                 params: { doctor_name: doctorName }
//             });
//             setOccupiedDates(response.data.map(date => new Date(date)));
//         } catch (error) {
//             console.error('Error fetching available dates:', error);
//         }
//     };

//     useEffect(() => {
//         const fetchAppointments = async () => {
//             try {
//                 const token = localStorage.getItem('accessToken');
//                 if (token) {
//                     console.log('Token:', token);  // Verifica que el token contiene el `username`
//                 }
                
//                 if (!token) {
//                     console.error('No token found');
//                     return;
//                 }

//                 const response = await axiosAppointments.get('/appointments/', {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 });
//                 setAppointments(response.data);
//             } catch (error) {
//                 console.error('Error fetching appointments:', error);
//             }
//         };

//         const fetchUsername = async () => {
//             try {
//                 const response = await axiosInstance.get('/user/');
//                 console.log('User data:', response.data);
//                 setUsername(response.data.username);
//             } catch (error) {
//                 console.error('Error fetching username:', error);
//             }
//         };

//         fetchAppointments();
//         fetchUsername();
//     }, []);

//     useEffect(() => {
//         if (formData.doctor_name) {
//             fetchAvailableDates(formData.doctor_name);
//         }
//     }, [formData.doctor_name]);
    
//     const handleShow = () => setShow(true);
//     const handleClose = () => setShow(false);

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const handleDateChange = (date) => {
//         setFormData({
//             ...formData,
//             appointment_date: date
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const formattedDate = moment(formData.appointment_date).format();  // Formatea la fecha con la zona horaria local
//             const token = localStorage.getItem('accessToken');
    
//             console.log('Token:', token);  // Verifica que el token contiene el `username`
//             console.log('FormData:', {
//                 ...formData,
//                 patient_name: username,
//                 appointment_date: formattedDate
//             });  // Verifica que los datos enviados son correctos
    
//             const response = await axiosAppointments.post('/appointments/', 
//                 {
//                     ...formData,
//                     patient_name: username,
//                     appointment_date: formattedDate
//                 },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 }
//             );
//             onAppointmentAdded(response.data);
//             setFormData({
//                 doctor_name: '',
//                 appointment_date: '',
//                 reason: ''
//             });
//             handleClose();
//         } catch (error) {
//             console.error('Error creating appointment:', error);
//         }
//     };

//     return (
//         <div className={`container mt-4 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//             <Card className={`d-flex flex-row align-items-center p-3 mb-4 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
//                 <div className="mr-auto d-flex align-items-center">
//                     <img 
//                         src="images/what_is_telehealth.webp" 
//                         alt="profile" 
//                         className="rounded-circle mr-3" 
//                         style={{ width: '50px', height: '50px' }} 
//                     />
//                     <div>
//                         <h5 className={`mt-1 click ${darkMode ? 'dark-mode' : 'light-mode'}`}>Doctor Appointment</h5>
//                         <p  className={`mb-0 mt-4 click ${darkMode ? 'dark-mode' : 'light-mode'}`}>Click Now..</p>
//                     </div>
//                 </div>
//                 <button className="btn btn-purple ml-auto" onClick={handleShow}>
//                    Schedule Now
//                 </button>
//             </Card>

//             <Modal show={show} onHide={handleClose} className={darkMode ? 'dark-mode' : 'light-mode'}>
//                 <Modal.Header closeButton className={darkMode ? 'dark-mode' : 'light-mode'}>
//                     <Modal.Title className={darkMode ? 'dark-mode' : 'light-mode'}>Schedule Appointment</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body className={darkMode ? 'dark-mode' : 'light-mode'}>
//                     <Form onSubmit={handleSubmit}>
//                         <Form.Group controlId="formDoctorName">
//                             <Form.Label>Doctor's Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="doctor_name"
//                                 value={formData.doctor_name}
//                                 onChange={handleChange}
//                                 className={darkMode ? 'dark-mode' : 'light-mode'}
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formAppointmentDate">
//                             <Form.Label>Appointment Date & Time</Form.Label>
//                             <DatePicker
//                                 selected={formData.appointment_date}
//                                 onChange={handleDateChange}
//                                 showTimeSelect
//                                 timeFormat="HH:mm"
//                                 timeIntervals={30}
//                                 dateFormat="Pp"
//                                 minDate={new Date()}
//                                 className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
//                                 locale="en-US"
//                                 excludeDates={occupiedDates}  // Excluir fechas ocupadas
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formReason">
//                             <Form.Label>Reason of Consultation</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="reason"
//                                 value={formData.reason}
//                                 onChange={handleChange}
//                                 className={darkMode ? 'dark-mode' : 'light-mode'}
//                             />
//                         </Form.Group>
//                         <Modal.Footer className={darkMode ? 'dark-mode' : 'light-mode'}>
//                             <Button variant="secondary" onClick={handleClose}>
//                                 Cancel
//                             </Button>
//                             <button className="btn btn-purple" type="submit">
//                                 Save
//                             </button>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal.Body>
//             </Modal>
//         </div>
//     );
// };

// export default ScheduleAppointment;

import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axiosAppointments from '../utils/axiosAppointments';
import axiosInstance from '../utils/axiosInstance'; 
import { Button, Modal, Form, Card } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enUS from 'date-fns/locale/en-US';
import './ScheduleAppointment.css';
import './LightMode.css';
import './DarkMode.css';
import { useTheme } from '../context/ThemeContext';

registerLocale('en-US', enUS);

const localizer = momentLocalizer(moment);

const ScheduleAppointment = ({ onAppointmentAdded }) => {
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        doctor_name: '',
        appointment_date: '',
        reason: ''
    });
    const [username, setUsername] = useState('');
    const [appointments, setAppointments] = useState([]);
    const { darkMode } = useTheme();

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    console.error('No token found');
                    return;
                }

                const response = await axiosAppointments.get('/appointments/', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const fetchedAppointments = response.data.map(app => ({
                    title: app.reason,
                    start: new Date(app.appointment_date),
                    end: new Date(moment(app.appointment_date).add(1, 'hours').toDate()),
                    doctor_name: app.doctor_name
                }));
                setAppointments(fetchedAppointments);
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        };

        const fetchUsername = async () => {
            try {
                const response = await axiosInstance.get('/user/');
                setUsername(response.data.username);
            } catch (error) {
                console.error('Error fetching username:', error);
            }
        };

        fetchAppointments();
        fetchUsername();
    }, []);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            appointment_date: date
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedDate = moment(formData.appointment_date).format();
            const token = localStorage.getItem('accessToken');

            const response = await axiosAppointments.post('/appointments/', 
                {
                    ...formData,
                    patient_name: username,
                    appointment_date: formattedDate
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            onAppointmentAdded(response.data);
            setFormData({
                doctor_name: '',
                appointment_date: '',
                reason: ''
            });
            handleClose();
        } catch (error) {
            console.error('Error creating appointment:', error);
        }
    };

    return (
        <div className={`container-fluid mt-4 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <Card className={`d-flex flex-row align-items-center p-3 mb-4 ${darkMode ? 'dark-mode' : 'light-mode'}`}>
                <div className="mr-auto d-flex align-items-center">
                    <img 
                        src="images/what_is_telehealth.webp" 
                        alt="profile" 
                        className="rounded-circle mr-3" 
                        style={{ width: '50px', height: '50px' }} 
                    />
                    <div>
                        <h5 className={`mt-1 click ${darkMode ? 'dark-mode' : 'light-mode'}`}>Doctor Appointment</h5>
                        <p  className={`mb-0 mt-4 click ${darkMode ? 'dark-mode' : 'light-mode'}`}>Click Now..</p>
                    </div>
                </div>
                <button className="btn btn-purple ml-auto" onClick={handleShow}>
                   Schedule Now
                </button>
            </Card>

            <Calendar
                localizer={localizer}
                events={appointments}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                selectable
                onSelectSlot={handleShow}
                className={darkMode ? 'dark-mode' : 'light-mode'}
            />

            <Modal show={show} onHide={handleClose} className={darkMode ? 'dark-mode' : 'light-mode'}>
                <Modal.Header closeButton className={darkMode ? 'dark-mode' : 'light-mode'}>
                    <Modal.Title className={darkMode ? 'dark-mode' : 'light-mode'}>Schedule Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body className={darkMode ? 'dark-mode' : 'light-mode'}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formDoctorName">
                            <Form.Label>Doctor's Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="doctor_name"
                                value={formData.doctor_name}
                                onChange={handleChange}
                                className={darkMode ? 'dark-mode' : 'light-mode'}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formAppointmentDate">
                            <Form.Label>Appointment Date & Time</Form.Label>
                            <DatePicker
                                selected={formData.appointment_date}
                                onChange={handleDateChange}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={30}
                                dateFormat="Pp"
                                minDate={new Date()}
                                className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                locale="en-US"
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formReason">
                            <Form.Label>Reason of Consultation</Form.Label>
                            <Form.Control
                                type="text"
                                name="reason"
                                value={formData.reason}
                                onChange={handleChange}
                                className={darkMode ? 'dark-mode' : 'light-mode'}
                            />
                        </Form.Group>
                        <Modal.Footer className={darkMode ? 'dark-mode' : 'light-mode'}>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <button className="btn btn-purple" type="submit">
                                Save
                            </button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ScheduleAppointment;


// import React, { useState, useEffect } from 'react';
// import axiosAppointments from '../utils/axiosAppointments';
// import axiosInstance from '../utils/axiosInstance'; 
// import { Button, Modal, Form, Card } from 'react-bootstrap';
// import DatePicker, { registerLocale } from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import enUS from 'date-fns/locale/en-US';
// import moment from 'moment-timezone';
// import './ScheduleAppointment.css';  // Importar archivo de estilos personalizados

// registerLocale('en-US', enUS);

// const ScheduleAppointment = ({ onAppointmentAdded }) => {
//     const [show, setShow] = useState(false);
//     const [formData, setFormData] = useState({
//         doctor_name: '',
//         appointment_date: '',
//         reason: ''
//     });
//     const [username, setUsername] = useState('');
//     const [appointments, setAppointments] = useState([]);
//     const [occupiedDates, setOccupiedDates] = useState([]);

//     const fetchAvailableDates = async (doctorName) => {
//         try {
//             const response = await axiosAppointments.get('/available-dates/', {
//                 params: { doctor_name: doctorName }
//             });
//             setOccupiedDates(response.data.map(date => new Date(date)));
//         } catch (error) {
//             console.error('Error fetching available dates:', error);
//         }
//     };

//     useEffect(() => {
//         const fetchAppointments = async () => {
//             try {
//                 const token = localStorage.getItem('accessToken');
//                 if (!token) {
//                     console.error('No token found');
//                     return;
//                 }
    
//                 const response = await axiosAppointments.get('/appointments/', {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 });
//                 setAppointments(response.data);
//             } catch (error) {
//                 console.error('Error fetching appointments:', error);
//             }
//         };

//         const fetchUsername = async () => {
//             try {
//                 const response = await axiosInstance.get('/user/');
//                 setUsername(response.data.username);
//             } catch (error) {
//                 console.error('Error fetching username:', error);
//             }
//         };

//         fetchAppointments();
//         fetchUsername();
//     }, []);

//     useEffect(() => {
//         if (formData.doctor_name) {
//             fetchAvailableDates(formData.doctor_name);
//         }
//     }, [formData.doctor_name]);
    
//     const handleShow = () => setShow(true);
//     const handleClose = () => setShow(false);

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const handleDateChange = (date) => {
//         setFormData({
//             ...formData,
//             appointment_date: date
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const formattedDate = moment(formData.appointment_date).format();  // Formatea la fecha con la zona horaria local
//             const token = localStorage.getItem('accessToken');
//             const response = await axiosAppointments.post('/appointments/', 
//                 {
//                     ...formData,
//                     patient_name: username,
//                     appointment_date: formattedDate
//                 },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 }
//             );
//             onAppointmentAdded(response.data);
//             setFormData({
//                 doctor_name: '',
//                 appointment_date: '',
//                 reason: ''
//             });
//             handleClose();
//         } catch (error) {
//             console.error('Error creating appointment:', error);
//         }
//     };

//     return (
//         <div className="container mt-4">
//             <Card className="d-flex flex-row align-items-center p-3 mb-4">
//                 <div className="mr-auto d-flex align-items-center">
//                     <img 
//                         src="images/what_is_telehealth.webp" 
//                         alt="profile" 
//                         className="rounded-circle mr-3" 
//                         style={{ width: '50px', height: '50px' }} 
//                     />
//                     <div>
//                         <h5 className="mb-1">Doctor Appointment</h5>
//                         <p className="mb-0 text-muted">Click Now..</p>
//                     </div>
//                 </div>
//                 <Button variant="primary" onClick={handleShow} className="ml-auto">
//                    Schedule Now
//                 </Button>
//             </Card>

//             <Modal show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Schedule Appointment</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form onSubmit={handleSubmit}>
//                         <Form.Group controlId="formDoctorName">
//                             <Form.Label>Doctor's Name</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="doctor_name"
//                                 value={formData.doctor_name}
//                                 onChange={handleChange}
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formAppointmentDate">
//                             <Form.Label>Appointment Date & Time</Form.Label>
//                             <DatePicker
//                                 selected={formData.appointment_date}
//                                 onChange={handleDateChange}
//                                 showTimeSelect
//                                 timeFormat="HH:mm"
//                                 timeIntervals={30}
//                                 dateFormat="Pp"
//                                 minDate={new Date()}
//                                 excludeDates={occupiedDates}
//                                 className="form-control"
//                                 locale="en-US"
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formReason">
//                             <Form.Label>Reason</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="reason"
//                                 value={formData.reason}
//                                 onChange={handleChange}
//                             />
//                         </Form.Group>
//                         <Modal.Footer>
//                             <Button variant="secondary" onClick={handleClose}>
//                                 Cancel
//                             </Button>
//                             <Button variant="primary" type="submit">
//                                 Save
//                             </Button>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal.Body>
//             </Modal>
//         </div>
//     );
// };

// export default ScheduleAppointment;





// import React, { useState, useEffect } from 'react';
// import axiosAppointments from '../utils/axiosAppointments';
// import { Button, Modal, Form, Card } from 'react-bootstrap';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const ScheduleAppointment = ({ onAppointmentAdded }) => {
//     const [show, setShow] = useState(false);
//     const [formData, setFormData] = useState({
//         patient_name: '',
//         doctor_name: '',
//         appointment_date: '',
//         reason: ''
//     });
//     const [appointments, setAppointments] = useState([]);
//     const [availableDates, setAvailableDates] = useState([]);

//     useEffect(() => {
//         const fetchAppointments = async () => {
//             try {
//                 const token = localStorage.getItem('accessToken');  // Verifica el nombre del token
//                 if (!token) {
//                     console.error('No token found');
//                     return;
//                 }
    
//                 const response = await axiosAppointments.get('/appointments/', {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 });
//                 setAppointments(response.data);
//             } catch (error) {
//                 console.error('Error fetching appointments:', error);
//             }
//         };

//         const fetchAvailableDates = async () => {
//             try {
//                 const response = await axiosAppointments.get('/available-dates/'); // Ajusta el endpoint según tu API
//                 setAvailableDates(response.data.map(date => new Date(date)));
//             } catch (error) {
//                 console.error('Error fetching available dates:', error);
//             }
//         };

//         fetchAppointments();
//         fetchAvailableDates();
//     }, []);
    
//     const handleShow = () => setShow(true);
//     const handleClose = () => setShow(false);

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const handleDateChange = (date) => {
//         setFormData({
//             ...formData,
//             appointment_date: date
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const formattedDate = formData.appointment_date.toISOString();
//             const response = await axiosAppointments.post('/appointments/', 
//                 {
//                     ...formData,
//                     appointment_date: formattedDate
//                 }
//             );
//             onAppointmentAdded(response.data);
//             setFormData({
//                 patient_name: '',
//                 doctor_name: '',
//                 appointment_date: '',
//                 reason: ''
//             });
//             handleClose();
//         } catch (error) {
//             console.error('Error creating appointment:', error);
//         }
//     };

//     return (
//         <div className="container mt-4">
//          <Card className="d-flex flex-row align-items-center p-3 mb-4">
//                  <div className="mr-auto d-flex align-items-center">
//                      <img 
//                         src="images/what_is_telehealth.webp" 
//                         alt="profile" 
//                         className="rounded-circle mr-3" 
//                         style={{ width: '50px', height: '50px' }} 
//                     />
//                     <div>
//                         <h5 className="mb-1">Doctor Appointment</h5>
//                         <p className="mb-0 text-muted">Click Now..</p>
//                     </div>
//                 </div>
//                 <Button variant="primary" onClick={handleShow} className="ml-auto">
//                    Schedule Now
//                 </Button>
//             </Card>

//             <Modal show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Agendar Cita</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form onSubmit={handleSubmit}>
//                         <Form.Group controlId="formPatientName">
//                             <Form.Label>Nombre del Paciente</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="patient_name"
//                                 value={formData.patient_name}
//                                 onChange={handleChange}
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formDoctorName">
//                             <Form.Label>Nombre del Doctor</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="doctor_name"
//                                 value={formData.doctor_name}
//                                 onChange={handleChange}
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formAppointmentDate">
//                             <Form.Label>Fecha y Hora de la Cita</Form.Label>
//                             <DatePicker
//                                 selected={formData.appointment_date}
//                                 onChange={handleDateChange}
//                                 showTimeSelect
//                                 timeFormat="HH:mm"
//                                 timeIntervals={30}
//                                 dateFormat="Pp"
//                                 minDate={new Date()}
//                                 includeDates={availableDates}
//                                 className="form-control"
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group controlId="formReason">
//                             <Form.Label>Razón</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 name="reason"
//                                 value={formData.reason}
//                                 onChange={handleChange}
//                             />
//                         </Form.Group>
//                         <Modal.Footer>
//                             <Button variant="secondary" onClick={handleClose}>
//                                 Cancelar
//                             </Button>
//                             <Button variant="primary" type="submit">
//                                 Guardar
//                             </Button>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal.Body>
//             </Modal>
//             {/* <ul>
//                 {appointments.map((appointment) => (
//                     <li key={appointment.id}>{appointment.patient_name}</li>
//                 ))}
//             </ul> */}
//         </div>
//     );
// };

// export default ScheduleAppointment;
