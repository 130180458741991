import React, { createContext, useContext, useState, useEffect } from 'react';
import RefreshToken from '../utils/RefreshToken';
import {jwtDecode} from 'jwt-decode'; // Asegúrate de que este es el import correcto

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            setIsLoading(true);
            const storedToken = localStorage.getItem('accessToken');  // Asegúrate de que esto es correcto
            const storedRole = localStorage.getItem('role');

            if (storedToken) {
                const decodedToken = jwtDecode(storedToken);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    // El token ha expirado, intenta refrescarlo
                    const newToken = await RefreshToken();
                    if (newToken) {
                        const newDecodedToken = jwtDecode(newToken);
                        setUser({
                            user_id: newDecodedToken.user_id,
                            username: newDecodedToken.username,
                            token: newToken,
                            role: storedRole,
                        });
                        // setUser({
                        //     ...newDecodedToken,
                        //     token: newToken,
                        //     role: storedRole,
                        // });
                    } else {
                        setUser(null);
                    }
                } else {
                    // El token es todavía válido
                    setUser({
                        user_id: decodedToken.user_id,
                        username: decodedToken.username,
                        token: storedToken,
                        role: storedRole,
                    });
                    // setUser({
                    //     ...decodedToken,
                    //     token: storedToken,
                    //     role: storedRole,
                    // });
                }
            } else {
                setUser(null);
            }
            setIsLoading(false);
        };

        checkAuth();
    }, []);

    const isPatient = () => user?.role === 'patient';
    const isDoctor = () => user?.role === 'doctor';

    return (
        <AuthContext.Provider value={{ user, setUser, isLoading, isPatient, isDoctor }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
