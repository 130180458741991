import React, { useState, useEffect } from 'react';
import axiosBlog from '../../utils/axiosBlog';
import { useParams } from 'react-router-dom';

const CommentSection = ({ articleSlug }) => {
  const { slug } = useParams();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    axiosBlog.get(`/articles/${slug}/comments`)
      .then(response => setComments(response.data))
      .catch(error => console.error('Error fetching comments:', error));
  }, [slug]);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    const comment = { content: newComment, articleSlug }; // Cambiado articleId por articleSlug
    axiosBlog.post(`/articles/${slug}/comments`, comment)
      .then(response => {
        setComments([...comments, response.data]);
        setNewComment('');
      })
      .catch(error => console.error('Error posting comment:', error));
  };

  return (
    <div className="mt-10">
      <h3 className="text-2xl font-semibold mb-4">Comments</h3>

      {/* Listado de comentarios */}
      <div className="space-y-4 mb-6">
        {comments.length > 0 ? (
          comments.map(comment => (
            <div key={comment.id} className="p-4 bg-gray-50 rounded-lg shadow-sm">
              <p className="text-gray-700">{comment.content}</p>
              <span className="text-xs text-gray-500">{comment.createdAt}</span>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No comments yet. Be the first to comment!</p>
        )}
      </div>

      {/* Formulario de comentarios */}
      <form onSubmit={handleCommentSubmit} className="space-y-4">
        <textarea
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment..."
          rows="4"
          required
        />
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Post Comment
        </button>
      </form>
    </div>
  );
};

export default CommentSection;
