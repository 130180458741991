import React, { useState, useEffect } from 'react';
import axiosPatient from '../utils/axiosPatient';
import { Line } from 'react-chartjs-2';
import { Card, CardHeader, CardDescription, CardTitle, CardContent } from './ui/Card';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import 'chartjs-adapter-date-fns';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, TextField, Typography, Button
} from '@mui/material';
// Registrar las escalas y elementos que se van a utilizar
ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const HealthDataChart = () => {
  const [healthData, setHealthData] = useState([]);

  useEffect(() => {
    const fetchHealthData = async () => {
      try {
        const response = await axiosPatient.get('/health_data');
        setHealthData(response.data);
      } catch (error) {
        console.error('Error fetching health data:', error);
      }
    };

    fetchHealthData();
  }, []);

  const processData = (data, key) => {
    return data.map(entry => ({
      x: new Date(entry.created_at),
      y: entry[key]
    })).filter(entry => entry.y !== null && entry.y !== undefined);
  };

  const createChartData = (data, label, color) => ({
    labels: data.map(entry => entry.x),
    datasets: [
      {
        label,
        data: data.map(entry => entry.y),
        borderColor: color,
        backgroundColor: `${color}33`, // Adding some transparency
        fill: false,
        tension: 0.4, // Smoothing the line
        pointRadius: 3, // Adjust point size
        pointHoverRadius: 5, // Adjust point hover size
      }
    ]
  });

  const weightData = createChartData(processData(healthData, 'weight'), 'Weight', 'rgba(75, 192, 192, 1)');
  const bmiData = createChartData(processData(healthData, 'bmi'), 'BMI', 'rgba(255, 99, 132, 1)');
  const glucoseData = createChartData(processData(healthData, 'glucose'), 'Glucose', 'rgba(153, 102, 255, 1)');

  const caloriesData = healthData.length > 0 ? healthData[healthData.length - 1].estimated_calories : null;
  const waistHipRatio = healthData.length > 0 ? healthData[healthData.length - 1].whr : null;
  const waistHipInterpretation = healthData.length > 0 ? healthData[healthData.length - 1].whr_interpretation : null;

  const chartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'dd MMM yyyy',
          displayFormats: {
            day: 'dd MMM yyyy'
          }
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10, // Adjust the number of ticks on x-axis
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top'
      }
    },
    elements: {
      line: {
        tension: 0.4 // Smoothing the line
      }
    }
  };

  return (
    <div className="grid md:grid-cols-3 gap-6">
      <Card>
        <CardHeader>
          <CardDescription>Weight</CardDescription>
          <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].weight} kg` : 'N/A'}</CardTitle>
        </CardHeader>
        <CardContent>
          {weightData.datasets[0].data.length > 0 ? (
            <Line data={weightData} options={chartOptions} />
          ) : (
            <p>No data available</p>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardDescription>BMI & Interpretation</CardDescription>
          <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].bmi} (${healthData[healthData.length - 1].bmi_interpretation})` : 'N/A'}</CardTitle>
        </CardHeader>
        <CardContent>
          {bmiData.datasets[0].data.length > 0 ? (
            <Line data={bmiData} options={chartOptions} />
          ) : (
            <p>No data available</p>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardDescription>Glucose</CardDescription>
          <CardTitle>{healthData.length > 0 ? `${healthData[healthData.length - 1].glucose} mg/dL` : 'N/A'}</CardTitle>
        </CardHeader>
        <CardContent>
          {glucoseData.datasets[0].data.length > 0 ? (
            <Line data={glucoseData} options={chartOptions} />
          ) : (
            <p>No data available</p>
          )}
        </CardContent>
      </Card>
      <Card>
                <CardHeader>
                  <CardDescription>Calories</CardDescription>
                  <CardTitle>{caloriesData !== null ? `${caloriesData} kcal` : 'N/A'}</CardTitle>
                </CardHeader>
                <CardContent>
                  <Typography variant="body1">A healthy caloric intake varies based on individual factors such as age, gender, weight, height, and physical activity level.</Typography>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardDescription>Waist-Hip Ratio & Interpretation</CardDescription>
                  <CardTitle>{waistHipRatio !== null ? `${waistHipRatio} (${waistHipInterpretation})` : 'N/A'}</CardTitle>
                </CardHeader>
                <CardContent>
                  <Typography variant="body1">A high WHR indicates a greater risk of health issues such as cardiovascular disease and diabetes.</Typography>
                </CardContent>
              </Card>

    </div>
  );
};

export default HealthDataChart;



