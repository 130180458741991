import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axiosConsultation from "../../utils/axiosConsultation";
import { useAuth } from "../../context/AuthContext";
import { Button, Spinner, Alert, Tabs, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  HomeIcon,
  ChartIcon,
  ClipboardIcon,
  CreditCardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PillIcon,
  CalendarIcon,
  HeartPulseIcon,
} from "../Icons";
import {
  Card,
  CardHeader,
  CardDescription,
  CardTitle,
  CardContent,
} from "../ui/Card";

// Registrar los elementos y escalas en Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = () => {
  const { user } = useAuth();
  const [consultations, setConsultations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const navigate = useNavigate();

  const pages = [
    { path: "/dashboard", label: "Dashboard" },
    { path: "/wellness", label: "Wellness" },
    { path: "/appointments", label: "Appointments" },
    { path: "/treatments", label: "Treatments" },
    { path: "/billing", label: "Billing" },
    { path: "/statistics", label: "Statistics" },
    { path: "/logs-doctor", label: "Doctor Logs" },
    { path: "/statistics", label: "Statistics" }, // Updated label
  ];

  const [currentPageIndex, setCurrentPageIndex] = useState(pages.length - 1);

  useEffect(() => {
    const fetchConsultations = async () => {
      try {
        const response = await axiosConsultation.get("/logs-doctor", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        // Ordenar las consultas por fecha de creación (de la más reciente a la más antigua)
        const sortedConsultations = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setConsultations(sortedConsultations);

        // Generar datos del gráfico
        const updatedChartData = generateChartData(
          sortedConsultations,
          selectedTab
        );
        setChartData(updatedChartData);
      } catch (error) {
        setError(
          error.response?.data?.message || "Failed to fetch consultations"
        );
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchConsultations();
    }
  }, [user, selectedTab]);

  // Generar datos para el gráfico
  const generateChartData = (consultations, selectedTab) => {
    if (!Array.isArray(consultations) || consultations.length === 0) {
      return {
        labels: [],
        datasets: [],
      };
    }

    // Define los estados como un array, incluyendo "pending prescription"
    const statuses = [
      "scheduled",
      "in progress",
      "pending prescription",
      "completed",
      "canceled",
    ];

    // Asegúrate de que el índice del tab sea válido
    if (selectedTab < 0 || selectedTab >= statuses.length) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const expectedStatus = statuses[selectedTab];

    // Filtrar las consultas por el estado seleccionado
    const filteredData = consultations.filter((consultation) => {
      if (!consultation || !consultation.status) {
        return false;
      }

      return consultation.status.toLowerCase().trim() === expectedStatus;
    });

    // Crear una lista de etiquetas para los últimos 7 días
    const labels = [];
    const counts = []; // Para almacenar la cantidad de consultas por día
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    for (let i = 0; i < 7; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      labels.unshift(date.toLocaleDateString("en-US"));
      counts.unshift(0);
    }

    // Contar la cantidad de consultas para cada día
    filteredData.forEach((consultation) => {
      const date = new Date(consultation.created_at).toLocaleDateString(
        "en-US"
      );
      const index = labels.indexOf(date);
      if (index !== -1) {
        counts[index]++;
      }
    });

    return {
      labels,
      datasets: [
        {
          label: "Number of Patients",
          data: counts,
          backgroundColor: "rgba(54, 162, 235, 0.2)", // Azul claro
          borderColor: "rgba(54, 162, 235, 1)", // Azul
          borderWidth: 1,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Consultation Statistics Over Time",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time (Dates)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Patients",
        },
        beginAtZero: true,
      },
    },
  };

  const handleTabSelect = (key) => {
    setSelectedTab(key);
    const updatedChartData = generateChartData(consultations, key);
    setChartData(updatedChartData);
  };

  const handlePrevious = () => {
    const newIndex =
      currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };

  const handleNext = () => {
    const newIndex =
      currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };

  // Contadores de consultas
  const completedConsultations = consultations.filter(
    (consultation) => consultation.status.toLowerCase() === "completed"
  ).length;
  const inProgressConsultations = consultations.filter(
    (consultation) => consultation.status.toLowerCase() === "in progress"
  ).length;
  const pendingPrescriptionConsultations = consultations.filter(
    (consultation) => consultation.status.toLowerCase() === "pending prescription"
  ).length;
  const canceledConsultations = consultations.filter(
    (consultation) => consultation.status.toLowerCase() === "canceled"
  ).length;
  const scheduledConsultations = consultations.filter(
    (consultation) => consultation.status.toLowerCase() === "scheduled"
  ).length;

  return (
    <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex-1 overflow-auto py-2">
            <nav className="grid items-start px-4 text-sm font-medium">
              <Link
                to="/selectdoctor"
                className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <HeartPulseIcon className="h-6 w-6" />
                Start Consultation
              </Link>
              <Link
                to="/dashboard"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <HomeIcon className="h-6 w-6" />
                Dashboard
              </Link>
              <Link
                to="/appointments"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <CalendarIcon className="h-6 w-6" />
                Appointments
              </Link>
              <Link
                to="/treatments"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <PillIcon className="h-6 w-6" />
                Treatments
              </Link>
              <Link
                to="/billing"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <CreditCardIcon className="h-6 w-6" />
                Billing
              </Link>

              <Link
                to="/statistics"
                className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary"
              >
                <ChartIcon className="h-6 w-6" />
                Statistics
              </Link>
              <Link
                to="/logs-doctor"
                className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
              >
                <ClipboardIcon className="h-6 w-6" />
                Doctor Logs
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <main className="flex-col gap-4 p-4 md:gap-8 md:p-6">
          <div className="flex items-center gap-4">
            <h1 className="font-semibold text-lg md:text-xl">
              {pages[currentPageIndex].label}
            </h1>
            <div className="ml-auto flex items-center gap-2">
              <Button variant="outline" size="icon" onClick={handlePrevious}>
                <ChevronLeftIcon className="h-4 w-4" />
                <span className="sr-only">Previous</span>
              </Button>
              <Button variant="outline" size="icon" onClick={handleNext}>
                <ChevronRightIcon className="h-4 w-4" />
                <span className="sr-only">Next</span>
              </Button>
            </div>
          </div>
          <br />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card className="w-full mb-4 md:mb-0">
              <CardHeader>
                <CardTitle>Consultation Counts</CardTitle>
                <CardDescription>
                  Number of consultations per status
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0 md:space-x-16">
                  <div className="flex items-center space-x-4">
                    <span className="font-medium text-lg text-blue-600">
                      Scheduled:
                    </span>
                    <span className="text-2xl font-bold text-blue-600">
                      {scheduledConsultations}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="font-medium text-lg text-green-600">
                      In Progress:
                    </span>
                    <span className="text-2xl font-bold text-green-600">
                      {inProgressConsultations}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="font-medium text-lg text-orange-600">
                      Pending Prescription:
                    </span>
                    <span className="text-2xl font-bold text-orange-600">
                      {pendingPrescriptionConsultations}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="font-medium text-lg text-purple-600">
                      Completed:
                    </span>
                    <span className="text-2xl font-bold text-purple-600">
                      {completedConsultations}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="font-medium text-lg text-red-600">
                      Canceled:
                    </span>
                    <span className="text-2xl font-bold text-red-600">
                      {canceledConsultations}
                    </span>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Consultation Statistics</CardTitle>
                <CardDescription>
                  Overview of patient consultations
                </CardDescription>
              </CardHeader>
              <CardContent>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" />
                  </div>
                ) : error ? (
                  <Alert variant="danger">{error}</Alert>
                ) : (
                  <>
                    <Tabs
                      activeKey={selectedTab}
                      onSelect={handleTabSelect}
                      className="mb-3"
                    >
                      <Tab eventKey={0} title="Scheduled"></Tab>
                      <Tab eventKey={1} title="In Progress"></Tab>
                      <Tab eventKey={2} title="Pending Prescription"></Tab>
                      <Tab eventKey={3} title="Completed"></Tab>
                      <Tab eventKey={4} title="Canceled"></Tab>
                    </Tabs>
                    <Line data={chartData} options={chartOptions} />
                  </>
                )}
              </CardContent>
            </Card>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Statistics;

// import React, { useEffect, useState } from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import axiosConsultation from "../../utils/axiosConsultation";
// import { useAuth } from "../../context/AuthContext";
// import { Button, Spinner, Alert, Tabs, Tab } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   HomeIcon,
//   ChartIcon,
//   ClipboardIcon,
//   CreditCardIcon,
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   PillIcon,
//   CalendarIcon,
//   HeartPulseIcon,
// } from "../Icons";
// import {
//   Card,
//   CardHeader,
//   CardDescription,
//   CardTitle,
//   CardContent,
// } from "../ui/Card";

// // Registrar los elementos y escalas en Chart.js
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const Statistics = () => {
//   const { user } = useAuth();
//   const [consultations, setConsultations] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: [],
//   });
//   const navigate = useNavigate();

//   const pages = [
//     { path: "/dashboard", label: "Dashboard" },
//     { path: "/wellness", label: "Wellness" },
//     { path: "/appointments", label: "Appointments" },
//     { path: "/treatments", label: "Treatments" },
//     { path: "/billing", label: "Billing" },
//     { path: "/statistics", label: "Statistics" },
//     { path: "/logs-doctor", label: "Doctor Logs" },
//     { path: "/statistics", label: "Statistics" }, // Updated label
//   ];

//   const [currentPageIndex, setCurrentPageIndex] = useState(pages.length - 1);

//   useEffect(() => {
//     const fetchConsultations = async () => {
//       try {
//         const response = await axiosConsultation.get("/logs-doctor", {
//           headers: {
//             Authorization: `Bearer ${user.token}`,
//           },
//         });

//         // Ordenar las consultas por fecha de creación (de la más reciente a la más antigua)
//         const sortedConsultations = response.data.sort(
//           (a, b) => new Date(b.created_at) - new Date(a.created_at)
//         );

//         // console.log("Fetched and sorted consultations:", sortedConsultations);

//         setConsultations(sortedConsultations);

//         // Generar datos del gráfico
//         const updatedChartData = generateChartData(
//           sortedConsultations,
//           selectedTab
//         );
//         setChartData(updatedChartData);
//       } catch (error) {
//         setError(
//           error.response?.data?.message || "Failed to fetch consultations"
//         );
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (user) {
//       fetchConsultations();
//     }
//   }, [user, selectedTab]);

//   // Generar datos para el gráfico
//   const generateChartData = (consultations, selectedTab) => {
//     if (!Array.isArray(consultations) || consultations.length === 0) {
//     //   console.log("No consultations available.");
//       return {
//         labels: [],
//         datasets: [],
//       };
//     }

//     // Define los estados como un array
//     const statuses = ["scheduled", "in progress", "pending prescription", "completed", "canceled"];

//     // Asegúrate de que el índice del tab sea válido
//     if (selectedTab < 0 || selectedTab >= statuses.length) {
//     //   console.error("Selected tab index is out of range.");
//       return {
//         labels: [],
//         datasets: [],
//       };
//     }

//     const expectedStatus = statuses[selectedTab];
//     // console.log("Expected Status:", expectedStatus);

//     // Filtrar las consultas por el estado seleccionado
//     const filteredData = consultations.filter((consultation) => {
//       if (!consultation || !consultation.status) {
//         return false;
//       }

//       const isMatchingStatus =
//         consultation.status.toLowerCase().trim() === expectedStatus;

//     //   console.log(
//     //     `Consultation ${consultation.id} with status ${
//     //       consultation.status
//     //     } is matching: ${isMatchingStatus}`
//     //   );

//       return isMatchingStatus;
//     });

//     // console.log("Filtered Data:", filteredData);

//     // Crear una lista de etiquetas para los últimos 7 días
//     const labels = [];
//     const counts = []; // Para almacenar la cantidad de consultas por día
//     const currentDate = new Date();
//     currentDate.setHours(0, 0, 0, 0);

//     for (let i = 0; i < 7; i++) {
//       const date = new Date(currentDate);
//       date.setDate(currentDate.getDate() - i);
//       labels.unshift(date.toLocaleDateString("en-US"));
//       counts.unshift(0);
//     }

//     // console.log("Initial Labels:", labels);

//     // Contar la cantidad de consultas para cada día
//     filteredData.forEach((consultation) => {
//       const date = new Date(consultation.created_at).toLocaleDateString(
//         "en-US"
//       );
//       const index = labels.indexOf(date);
//       if (index !== -1) {
//         counts[index]++;
//         // console.log(`Incrementing count for date ${date} to ${counts[index]}`);
//       }
//     });

//     // console.log("Final counts:", counts);

//     return {
//       labels,
//       datasets: [
//         {
//           label: "Number of Patients",
//           data: counts,
//           backgroundColor: "rgba(54, 162, 235, 0.2)", // Azul claro
//           borderColor: "rgba(54, 162, 235, 1)", // Azul
//           borderWidth: 1,
//         },
//       ],
//     };
//   };

//   const chartOptions = {
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false,
//       },
//       title: {
//         display: true,
//         text: "Consultation Statistics Over Time",
//       },
//     },
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: "Time (Dates)",
//         },
//       },
//       y: {
//         title: {
//           display: true,
//           text: "Number of Patients",
//         },
//         beginAtZero: true,
//       },
//     },
//   };

//   const handleTabSelect = (key) => {
//     // console.log(`Tab changed to: ${key}`);
//     setSelectedTab(key);
//     const updatedChartData = generateChartData(consultations, key);
//     setChartData(updatedChartData);
//   };

//   const handlePrevious = () => {
//     const newIndex =
//       currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
//     setCurrentPageIndex(newIndex);
//     navigate(pages[newIndex].path);
//   };

//   const handleNext = () => {
//     const newIndex =
//       currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
//     setCurrentPageIndex(newIndex);
//     navigate(pages[newIndex].path);
//   };

//   // Contadores de consultas
//   const completedConsultations = consultations.filter(
//     (consultation) => consultation.status.toLowerCase() === "completed"
//   ).length;
//   const inProgressConsultations = consultations.filter(
//     (consultation) => consultation.status.toLowerCase() === "in progress"
//   ).length;
//   const pendingPrescriptionConsultations = consultations.filter(
//     (consultation) => consultation.status.toLowerCase() === "pending prescription"
//   ).length;
//   const canceledConsultations = consultations.filter(
//     (consultation) => consultation.status.toLowerCase() === "canceled"
//   ).length;
//   const scheduledConsultations = consultations.filter(
//     (consultation) => consultation.status.toLowerCase() === "scheduled"
//   ).length;

//   return (
//     <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
//       <div className="hidden border-r bg-muted/40 lg:block">
//         <div className="flex h-full max-h-screen flex-col gap-2">
//           <div className="flex-1 overflow-auto py-2">
//             <nav className="grid items-start px-4 text-sm font-medium">
//               <Link
//                 to="/selectdoctor"
//                 className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <HeartPulseIcon className="h-6 w-6" />
//                 Start Consultation
//               </Link>
//               <Link
//                 to="/dashboard"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <HomeIcon className="h-6 w-6" />
//                 Dashboard
//               </Link>
//               <Link
//                 to="/appointments"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <CalendarIcon className="h-6 w-6" />
//                 Appointments
//               </Link>
//               <Link
//                 to="/treatments"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <PillIcon className="h-6 w-6" />
//                 Treatments
//               </Link>
//               <Link
//                 to="/billing"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <CreditCardIcon className="h-6 w-6" />
//                 Billing
//               </Link>

//               <Link
//                 to="/statistics"
//                 className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary"
//               >
//                 <ChartIcon className="h-6 w-6" />
//                 Statistics
//               </Link>
//               <Link
//                 to="/logs-doctor"
//                 className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary"
//               >
//                 <ClipboardIcon className="h-6 w-6" />
//                 Doctor Logs
//               </Link>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-col flex-1">
//         <main className="flex-col gap-4 p-4 md:gap-8 md:p-6">
//           <div className="flex items-center gap-4">
//             <h1 className="font-semibold text-lg md:text-xl">
//               {pages[currentPageIndex].label}
//             </h1>
//             <div className="ml-auto flex items-center gap-2">
//               <Button variant="outline" size="icon" onClick={handlePrevious}>
//                 <ChevronLeftIcon className="h-4 w-4" />
//                 <span className="sr-only">Previous</span>
//               </Button>
//               <Button variant="outline" size="icon" onClick={handleNext}>
//                 <ChevronRightIcon className="h-4 w-4" />
//                 <span className="sr-only">Next</span>
//               </Button>
//             </div>
//           </div>
//           <br />
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> {/* Ajusta las columnas */}
//             <Card className="w-full mb-4 md:mb-0"> {/* Asegura que las tarjetas tengan el ancho completo en móvil */}
//               <CardHeader>
//                 <CardTitle>Consultation Counts</CardTitle>
//                 <CardDescription>
//                   Number of consultations per status
//                 </CardDescription>
//               </CardHeader>
//               <CardContent>
//                 <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0 md:space-x-16">
//                   <div className="flex items-center space-x-4">
//                     <span className="font-medium text-lg text-blue-600">
//                       Scheduled:
//                     </span>
//                     <span className="text-2xl font-bold text-blue-600">
//                       {scheduledConsultations}
//                     </span>
//                   </div>
//                   <div className="flex items-center space-x-4">
//                     <span className="font-medium text-lg text-green-600">
//                       In Progress:
//                     </span>
//                     <span className="text-2xl font-bold text-green-600">
//                       {inProgressConsultations}
//                     </span>
//                   </div>
//                   <div className="flex items-center space-x-4">
//                     <span className="font-medium text-lg text-orange-600">
//                       Pending Prescription:
//                     </span>
//                     <span className="text-2xl font-bold text-orange-600">
//                       {pendingPrescriptionConsultations}
//                     </span>
//                   </div>
//                   <div className="flex items-center space-x-4">
//                     <span className="font-medium text-lg text-purple-600">
//                       Completed:
//                     </span>
//                     <span className="text-2xl font-bold text-purple-600">
//                       {completedConsultations}
//                     </span>
//                   </div>
//                   <div className="flex items-center space-x-4">
//                     <span className="font-medium text-lg text-red-600">
//                       Canceled:
//                     </span>
//                     <span className="text-2xl font-bold text-red-600">
//                       {canceledConsultations}
//                     </span>
//                   </div>
//                 </div>
//               </CardContent>
//             </Card>
//             <Card className="w-full">
//               <CardHeader>
//                 <CardTitle>Consultation Statistics</CardTitle>
//                 <CardDescription>
//                   Overview of patient consultations
//                 </CardDescription>
//               </CardHeader>
//               <CardContent>
//                 {loading ? (
//                   <div className="d-flex justify-content-center">
//                     <Spinner animation="border" />
//                   </div>
//                 ) : error ? (
//                   <Alert variant="danger">{error}</Alert>
//                 ) : (
//                   <>
//                     <Tabs
//                       activeKey={selectedTab}
//                       onSelect={handleTabSelect}
//                       className="mb-3"
//                     >
//                       <Tab eventKey={0} title="Scheduled"></Tab>
//                       <Tab eventKey={1} title="In Progress"></Tab>
//                       <Tab eventKey={2} title="Pending Prescription"></Tab>
//                       <Tab eventKey={3} title="Completed"></Tab>
//                       <Tab eventKey={4} title="Canceled"></Tab>
//                     </Tabs>
//                     <Line data={chartData} options={chartOptions} />
//                   </>
//                 )}
//               </CardContent>
//             </Card>
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// };

// export default Statistics;

