// src/components/Terms.jsx
import React from 'react';
import './Terms.css'; // Asegúrate de tener este archivo para estilos personalizados
import SEO from './SEO';


function Terms() {
    return (
        <div className="terms-card">
            <SEO />
        <div className="terms-container">
            <h1>Terms and Conditions</h1>
            <p>Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern the relationship between <strong>powerlabs.ai</strong> and you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.</p>
            <h2>Use of the Site</h2>
            <p>This website may be used for legitimate purposes and in accordance with these terms and conditions.</p>
            <h2>Intellectual Property</h2>
            <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>
            <h2>Links to Other Websites</h2>
            <p>This website may include links to other websites of interest. These links are provided for your convenience and to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p>
            <h2>Modifications to Terms</h2>
            <p>We reserve the right to make changes to these terms at any time. You should check this page periodically to ensure that you are happy with any changes.</p>
            <h2>Contact</h2>
            <p>If you have any questions about these Terms and Conditions, please contact us at [contact email].</p>
        </div>
    </div>
    );
}

export default Terms;
