import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import axiosPatient from '../utils/axiosPatient';
import ClinicalHistoryComplete from './ClinicalHistoryComplete';
import { Link, useNavigate } from 'react-router-dom';

const Reports = () => {
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(5);
  const pages = [
    { path: '/dashboard', label: 'Dashboard' },
    { path: '/wellness', label: 'Wellness' },
    { path: '/appointments', label: 'Appointments' },
    { path: '/treatments', label: 'Treatments' },
    { path: '/payment', label: 'Payment' },
    { path: '/reports', label: 'Reports' },
    { path: '/logs', label: 'Logs' },
  ];

  const handleDownloadPDF = async () => {
    try {
      const response = await axiosPatient.get('/clinical_history/download', { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'clinical_history.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const handlePrevious = () => {
    const newIndex = currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };

  const handleNext = () => {
    const newIndex = currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
    setCurrentPageIndex(newIndex);
    navigate(pages[newIndex].path);
  };

  return (
    <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 lg:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex-1 overflow-auto py-2">
            <nav className="grid items-start px-4 text-sm font-medium">
              <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
                <HeartPulseIcon className="h-6 w-6" />
                Start Consultation
              </Link>
              <Link to="/dashboard" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <HomeIcon className="h-6 w-6" />
                Dashboard
              </Link>
              <Link to="/wellness" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <AppleIcon className="h-6 w-6" />
                Wellness
              </Link>
              <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <CalendarIcon className="h-6 w-6" />
                Appointments
              </Link>
              <Link to="/treatments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <PillIcon className="h-6 w-6" />
                Treatments
              </Link>
              <Link to="/payment" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <CreditCardIcon className="h-6 w-6" />
                Payment
              </Link>
              <Link to="/reports" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
                <ChartIcon className="h-6 w-6" />
                Reports
              </Link>
              <Link to="/logs" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                <ClipboardIcon className="h-6 w-6" />
                Logs
              </Link>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <main className="flex-col gap-4 p-4 md:gap-8 md:p-6">
          <div className="flex items-center gap-4">
            <h1 className="font-semibold text-lg md:text-xl">{pages[currentPageIndex].label}</h1>
            <div className="ml-auto flex items-center gap-2">
              <Button variant="outline" size="icon" onClick={handlePrevious}>
                <ChevronLeftIcon className="h-4 w-4" />
                <span className="sr-only">Previous</span>
              </Button>
              <Button variant="outline" size="icon" onClick={handleNext}>
                <ChevronRightIcon className="h-4 w-4" />
                <span className="sr-only">Next</span>
              </Button>
            </div>
          </div>
          <br></br>
          <h2 className="font-semibold text-lg md:text-xl">Health Reports</h2>
          <hr></hr>
          <br></br>
          <ClinicalHistoryComplete />
          {/* <Button onClick={handleDownloadPDF} variant="contained" color="primary">
            Download PDF
          </Button> */}
        </main>
      </div>
    </div>
  );
};

function CreditCardIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
      <line x1="1" y1="10" x2="23" y2="10" />
      <line x1="6" y1="16" x2="6" y2="16" />
      <line x1="10" y1="16" x2="14" y2="16" />
    </svg>
  );
}

function ChevronLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  );
}

function ChevronRightIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
}

function HeartPulseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
      <path d="M3.22 12H9.5l.5-1 2 4.5 2-7 1.5 3.5h5.27" />
    </svg>
  );
}

function HomeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  );
}

function InfoIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 16v-4" />
      <path d="M12 8h.01" />
    </svg>
  );
}

function PillIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m10.5 20.5 10-10a4.95 4.95 0 1 0-7-7l-10 10a4.95 4.95 0 1 0 7 7Z" />
      <path d="m8.5 8.5 7 7" />
    </svg>
  );
}

function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  );
}

function UserIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  );
}

function CalendarIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
      <line x1="16" y1="2" x2="16" y2="6" />
      <line x1="8" y1="2" x2="8" y2="6" />
      <line x1="3" y1="10" x2="21" y2="10" />
    </svg>
  );
}

function ClipboardIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 4h-4a2 2 0 0 0-4 0H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />
      <line x1="12" y1="11" x2="16" y2="11" />
      <line x1="12" y1="16" x2="16" y2="16" />
      <line x1="8" y1="11" x2="8" y2="11" />
      <line x1="8" y1="16" x2="8" y2="16" />
    </svg>
  );
}

function ChartIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="12" y1="20" x2="12" y2="10" />
      <line x1="18" y1="20" x2="18" y2="4" />
      <line x1="6" y1="20" x2="6" y2="16" />
    </svg>
  );
}

function DumbbellIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="1" y="5" width="22" height="4" rx="2" ry="2" />
      <rect x="1" y="15" width="22" height="4" rx="2" ry="2" />
    </svg>
  );
}

function AppleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16.362 1.639c.044 1.143-.414 2.254-1.139 3.067-.715.8-1.85 1.477-3.004 1.377-.049-1.141.416-2.265 1.141-3.068.719-.798 1.848-1.48 3.002-1.376zM12.002 5.527c.8 0 1.785-.48 2.665-1.308.71-.656 1.262-1.497 1.262-2.429 0-.143-.008-.287-.023-.431-.013-.125-.028-.253-.048-.383.627-.05 1.252.207 1.635.713.393.518.624 1.24.659 2.015.034.77-.128 1.513-.453 2.107-.323.59-.83 1.147-1.511 1.564.15.189.288.389.409.605.349.63.553 1.383.553 2.178 0 .795-.204 1.548-.553 2.178-.149.272-.31.522-.482.748-.455.596-1.013 1.111-1.676 1.529-.664.418-1.423.728-2.293.728-.87 0-1.629-.31-2.293-.728-.663-.418-1.221-.933-1.676-1.529-.172-.226-.333-.476-.482-.748-.349-.63-.553-1.383-.553-2.178 0-.795.204-1.548.553-2.178.121-.216.259-.416.409-.605-.681-.417-1.188-.974-1.511-1.564-.325-.594-.487-1.337-.453-2.107.035-.776.266-1.497.659-2.015.383-.506 1.008-.763 1.635-.713-.02.13-.035.258-.048.383-.015.144-.023.288-.023.431 0 .932.552 1.773 1.262 2.429.88.828 1.865 1.308 2.665 1.308z" />
    </svg>
  );
}

export default Reports;
