import React from 'react';
import { Heading, Icon, DropdownSection } from '../styles';
import './CompanyDropdown.css';

const CompanyDropdown = () => {
  return (
    <div className="company-dropdown">
      <DropdownSection data-first-dropdown-section>
        <ul>
          <li className="heading-link">
            <a href="/about">
              <Icon /> About Us
            </a>
          </li>
          <li className="heading-link">
            <a href="/customers">
              <Icon /> Our Patients
            </a>
          </li>
          <li className="heading-link">
            <a href="/careers">
              <Icon /> Careers
            </a>
          </li>
          <li className="heading-link">
            <a href="/sustainability">
              <Icon /> Sustainability
            </a>
          </li>
        </ul>
      </DropdownSection>
      <DropdownSection>
        <div>
          <Heading>
            <Icon /> From the Blog
          </Heading>
          <ul className="link-list">
            <li>
              <a href="/blog/telemedicine-trends">Telemedicine Trends &rsaquo;</a>
            </li>
            <li>
              <a href="/blog/health-tips">Health Tips &rsaquo;</a>
            </li>
        
            <li className="help-center-item">
              <span>
                <svg height="16" width="16" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" />
                  <text x="12" y="16" fontSize="12" textAnchor="middle" fill="white">?</text>
                </svg>
              </span>
              <a href="/support">Help Center &rsaquo;</a>
            </li>
            <li className="help-center-item">
              <span>
                <svg height="16" width="16" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" />
                  <text x="12" y="16" fontSize="12" textAnchor="middle" fill="white">T</text>
                </svg>
              </span>
              <a href="/terms">Terms and Conditions &rsaquo;</a>
            </li>
          </ul>
        </div>
      </DropdownSection>
    </div>
  );
};

export default CompanyDropdown;

// import React from 'react';
// import { Heading, HeadingLink, LinkList, DropdownSection, Icon } from '../styles';
// import './CompanyDropdown.css';

// const CompanyDropdown = () => {
//   return (
//     <div className="company-dropdown">
//       <DropdownSection data-first-dropdown-section>
//         <ul>
//           <li className="heading-link">
//             <a href="/">
//               <Icon /> About Stripe
//             </a>
//           </li>
//           <li className="heading-link">
//             <a href="/">
//               <Icon /> Customers
//             </a>
//           </li>
//           <li className="heading-link">
//             <a href="/">
//               <Icon /> Jobs
//             </a>
//           </li>
//           <li className="heading-link">
//             <a href="/">
//               <Icon /> Environment
//             </a>
//           </li>
//         </ul>
//       </DropdownSection>
//       <DropdownSection>
//         <div>
//           <Heading>
//             <Icon /> From the Blog
//           </Heading>
//           <ul className="link-list">
//             <li>
//               <a href="/">Stripe Atlas &rsaquo;</a>
//             </li>
//             <li>
//               <a href="/">Stripe Home &rsaquo;</a>
//             </li>
//             <li className="help-center-item">
//               <span>
//                 <svg height="16" width="16" viewBox="0 0 24 24">
//                   <circle cx="12" cy="12" r="12" />
//                   <text x="12" y="16" fontSize="12" textAnchor="middle" fill="white">?</text>
//                 </svg>
//               </span>
//               <a href="/support">Help Center &rsaquo;</a>
//             </li>
//           </ul>
//         </div>
//       </DropdownSection>
//     </div>
//   );
// };

// export default CompanyDropdown;
