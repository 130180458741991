import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Card, Button, Form, Alert, Table, Badge, DropdownButton, Dropdown, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Payment.css'; // Assuming you have a CSS file for custom styles
import axiosPayment from '../../utils/axiosPayment';
import { useAuth } from '../../context/AuthContext'; // Importar el contexto de autenticación
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDiscover, FaCcDinersClub, FaCcJcb } from 'react-icons/fa';
import {
    HeartPulseIcon,
    HomeIcon,
    AppleIcon,
    CalendarIcon,
    PillIcon,
    CreditCardIcon,
    ChartIcon,
    ClipboardIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  } from '../Icons';
const stripePromise = loadStripe('pk_test_51MSdBfGhB6Kn5glmmHb4SnF4hVP7xQ36a2MSmn2IEP265a7d8YTVzBKXoZTGCTvCqN0mb4xxC5mVaVLMuH80FnSr006wV7ACsM');

const AddCardForm = ({ fetchCards }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useAuth(); // Obtener el usuario autenticado del contexto
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        console.log('User data from useAuth:', user);
    }, [user]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            setLoading(false);
            setMessage('Stripe has not loaded yet.');
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
            setMessage(error.message);
            setLoading(false);
            return;
        }

        axiosPayment.post('/add_card', {
            user_id: user.user_id, 
            stripe_token: token.id
        },{
            headers: {
               'Authorization': `Bearer ${user.token}`
            }
        })
        .then(response => {
            if (response.status === 201) {
                setMessage('Card added successfully');
                fetchCards();
            } else {
                setMessage('Failed to add card');
            }
        })
        .catch(error => {
            console.error("Error adding card:", error.response?.data || error.message);
            setMessage(error.response?.data?.message || 'Failed to add card');
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <Card style={{ width: '100%', maxWidth: '500px', margin: '20px auto', padding: '20px', borderColor: '#6200ee' }} className="bg-light">
            <Card.Body>
                <Card.Title className="text-purple" style={{ color: '#6200ee' }}>Add Your Payment Method</Card.Title>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <div className="card-element-wrapper" style={{ borderColor: '#6200ee' }}>
                            <CardElement className="card-element" />
                        </div>
                    </Form.Group>
                    <Button 
                        variant="light" 
                        type="submit" 
                        disabled={!stripe || loading}
                        style={{ width: '100%', marginTop: '20px', backgroundColor: '#6200ee', color: '#fff' }}
                    >
                        {loading ? 'Processing...' : 'Add Card'}
                    </Button>
                    {message && <Alert variant={message.includes('success') ? 'success' : 'danger'} style={{ marginTop: '20px' }}>{message}</Alert>}
                </Form>
            </Card.Body>
        </Card>
    );
};

const Payment = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [currentPageIndex, setCurrentPageIndex] = useState(4);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [message, setMessage] = useState('');

    const pages = [
        { path: '/dashboard', label: 'Dashboard' },
        { path: '/wellness', label: 'Wellness' },
        { path: '/appointments', label: 'Appointments' },
        { path: '/treatments', label: 'Treatments' },
        { path: '/payment', label: 'Payment' },
        { path: '/reports', label: 'Reports' },
        { path: '/logs', label: 'Logs' },
    ];

    const fetchCards = () => {
        if (user) {
            setLoading(true);
            axiosPayment.get(`/get_cards/${user.user_id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            .then(response => {
                setCards(response.data);
            })
            .catch(error => {
                console.error("Error fetching cards:", error.response?.data || error.message);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        fetchCards();
    }, [user]);

    const handleDelete = (cardId) => {
        setLoading(true);
        axiosPayment.delete(`/delete_card/${cardId}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then(response => {
            fetchCards();
            setMessage('Card deleted successfully');
            // Después de eliminar, verificar si hay una tarjeta predeterminada
            setTimeout(() => {
                const defaultCard = cards.find(card => card.default);
                if (!defaultCard && cards.length > 0) {
                    handleSetDefault(cards[0].id); // Establecer la primera tarjeta como predeterminada
                }
            }, 1000); // Un pequeño retraso para asegurar que las tarjetas se hayan actualizado
        })
        .catch(error => {
            console.error("Error deleting card:", error.response?.data || error.message);
            setMessage('Failed to delete card');
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleSetDefault = (cardId) => {
        setLoading(true);
        axiosPayment.post('/set_default_card', { card_id: cardId }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
                fetchCards();
                setMessage('Default card updated successfully');
            } else {
                setMessage('Failed to update default card');
            }
        })
        .catch(error => {
            console.error("Error setting default card:", error.response?.data || error.message);
            setMessage('Failed to update default card');
        })
        .finally(() => {
            setLoading(false);
            setShowModal(false);
        });
    };

    const handlePrevious = () => {
        const newIndex = currentPageIndex > 0 ? currentPageIndex - 1 : pages.length - 1;
        setCurrentPageIndex(newIndex);
        navigate(pages[newIndex].path);
    };

    const handleNext = () => {
        const newIndex = currentPageIndex < pages.length - 1 ? currentPageIndex + 1 : 0;
        setCurrentPageIndex(newIndex);
        navigate(pages[newIndex].path);
    };

    const handleShowModal = (cardId) => {
        setSelectedCard(cardId);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCard(null);
    };

    function VisaIcon(props) {
        return <FaCcVisa {...props} />;
    }

    function MastercardIcon(props) {
        return <FaCcMastercard {...props} />;
    }

    function AmexIcon(props) {
        return <FaCcAmex {...props} />;
    }

    function DiscoverIcon(props) {
        return <FaCcDiscover {...props} />;
    }

    function DinersClubIcon(props) {
        return <FaCcDinersClub {...props} />;
    }

    function JcbIcon(props) {
        return <FaCcJcb {...props} />;
    }

    function getCardIcon(brand) {
        // console.log("Brand:", brand);
        switch (brand) {
            case 'visa':
                return <VisaIcon size={24} />;
            case 'mastercard':
                return <MastercardIcon size={24} />;
            case 'amex':
                return <AmexIcon size={24} />;
            case 'discover':
                return <DiscoverIcon size={24} />;
            case 'diners club':
                return <DinersClubIcon size={24} />;
            case 'jcb':
                return <JcbIcon size={24} />;
            default:
                return null; // You can return a default icon or null if brand is not recognized
        }
    }

    return (
        <div className="grid min-h-screen w-full lg:grid-cols-[280px_1fr]">
            <div className="hidden border-r bg-muted/40 lg:block">
                <div className="flex h-full max-h-screen flex-col gap-2">
                    <div className="flex-1 overflow-auto py-2">
                        <nav className="grid items-start px-4 text-sm font-medium">
                            <Link to="/selectdoctor" className="btn btn-primary text-white flex items-center gap-3 rounded-lg px-4 py-2 text-muted-foreground transition-all hover:text-primary">
                                <HeartPulseIcon className="h-6 w-6" />
                                Start Consultation
                            </Link>
                            <Link to="/dashboard" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                                <HomeIcon className="h-6 w-6" />
                                Dashboard
                            </Link>
                            <Link to="/wellness" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                                <AppleIcon className="h-6 w-6" />
                                Wellness
                            </Link>
                            <Link to="/appointments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                                <CalendarIcon className="h-6 w-6" />
                                Appointments
                            </Link>
                            <Link to="/treatments" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                                <PillIcon className="h-6 w-6" />
                                Treatments
                            </Link>
                            <Link to="/payment" className="flex items-center gap-3 rounded-lg bg-muted px-5 py-2 text-primary transition-all hover:text-primary">
                                <CreditCardIcon className="h-6 w-6" />
                                Payment
                            </Link>
                            <Link to="/reports" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                                <ChartIcon className="h-6 w-6" />
                                Reports
                            </Link>
                            <Link to="/logs" className="flex items-center gap-3 rounded-lg px-5 py-2 text-muted-foreground transition-all hover:text-primary">
                                <ClipboardIcon className="h-6 w-6" />
                                Logs
                            </Link>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <main className=" flex-col gap-4 p-4 md:gap-8 md:p-6">
                    <div className="flex items-center gap-4">
                        <h1 className="font-semibold text-lg md:text-xl">{pages[currentPageIndex].label}</h1>
                        <div className="ml-auto flex items-center gap-2">
                            <Button variant="outline" size="icon" onClick={handlePrevious}>
                                <ChevronLeftIcon className="h-4 w-4" />
                                <span className="sr-only">Previous</span>
                            </Button>
                            <Button variant="outline" size="icon" onClick={handleNext}>
                                <ChevronRightIcon className="h-4 w-4" />
                                <span className="sr-only">Next</span>
                            </Button>
                        </div>
                    </div>
                    <div className="grid gap-6">
                        <br></br>
                        <h2 className="font-semibold text-lg md:text-xl">Add Payment Method</h2>
                        <hr></hr>
                        <br></br>
                        <Elements stripe={stripePromise}>
                            <AddCardForm fetchCards={fetchCards} />
                        </Elements>
                        <br></br>
                        <h2 className="font-semibold text-lg md:text-xl">Payment methods list</h2>
                        <hr></hr>
                        <br></br>
                        <Card className="bg-light" style={{ borderColor: '#6200ee' }}>
                            <Card.Body>
                                <Card.Title className="text-purple" style={{ color: '#6200ee' }}>Saved Cards</Card.Title>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr className="text-center fw-bold">
                                                <th>Last 4 Digits</th>
                                                <th>Brand</th>
                                                <th>Default</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cards.map(card => (
                                                <tr key={card.id} className="text-center align-middle">
                                                    <td>xxxx-xxxx-xxxx-{card.last4}</td>
                                                    <td className="d-flex justify-content-center align-items-center">{getCardIcon(card.brand)}</td>
                                                    <td>
                                                        {card.default && (
                                                            <Badge pill bg="success">
                                                                Default
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <DropdownButton
                                                            className="text-white"
                                                            id="dropdown-basic-button"
                                                            title="Actions"
                                                            variant="success"
                                                        >
                                                            <Dropdown.Item onClick={() => handleShowModal(card.id)} className="text-success fw-bold">Set as Default</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => handleDelete(card.id)} className="text-danger fw-bold">Delete</Dropdown.Item>
                                                        </DropdownButton>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                                {message && <Alert variant={message.includes('success') ? 'success' : 'danger'} style={{ marginTop: '20px' }}>{message}</Alert>}
                            </Card.Body>
                        </Card>
                    </div>
                </main>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Card as Default</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to set this card as default?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={() => handleSetDefault(selectedCard)}>
                        Change
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};



export default Payment;