import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './footer.css';
import './LightMode.css';
import './DarkMode.css';
import { useTheme } from '../context/ThemeContext';
import Logo from './Logo';
import LanguageSelector from './LanguageSelector';

function Footer() {
  const { darkMode } = useTheme();
  const { t } = useTranslation();

  return (
    <footer className={`footer ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="footer-column">
        <Logo />
        <p className="footer-description">{t('footerDescription')}</p>
      </div>
      <div className="footer-column">
        <h3 className="footer-title">{t('links')}</h3>
        <hr className="footer-divider" />
        <Link to="/" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('home')}</Link>
        <Link to="/blog" className={darkMode ? 'dark-mode' : 'light-mode'}>Blog</Link>
        <Link to="/pricing" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('pricing')}</Link>
        <Link to="/features" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('features')}</Link>
        <Link to="/support" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('feedback')}</Link>
        <Link to="/terms" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('terms')}</Link>
      </div>
      <div className="footer-column">
        <h3 className="footer-title">{t('support')}</h3>
        <hr className="footer-divider" />
        <Link to="/support" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('support')}</Link>
        <Link to="/faq" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('faq')}</Link>
        <Link to="/pricing" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('pricing')}</Link>
        <Link to="/features" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('features')}</Link>
        <Link to="/terms" className={darkMode ? 'dark-mode' : 'light-mode'}>{t('terms')}</Link>
      </div>
      <div className="footer-column">
        <h3 className="footer-title">{t('contact')}</h3>
        <hr className="footer-divider" />
        <p>© 2024 {t('createdBy')} <a href="https://webcex.com" target="_blank" rel="noopener noreferrer" className={darkMode ? 'dark-mode' : 'light-mode'}>WebCex</a></p>
      </div>
      <div className="footer-column">
        <h3 className="footer-title">{t('changeLanguage')}</h3>
        <hr className="footer-divider" />
        <LanguageSelector />
      </div>
    </footer>
  );
}

export default Footer;

