
  const resources = [
    {
      path: "/guides/telemedicine",
      title: 'Telemedicine Guide',
      description: 'Comprehensive guides and manuals for effective online consultations. Learn the best practices and protocols to provide top-notch care remotely.',
      keywords: 'telemedicine, online consultations, healthcare, best practices, protocols, medical care',
     
      sections: [
        {
          title: "Best Practices",
          content: `
            <p>Telemedicine has become an essential tool in modern healthcare, allowing medical professionals to provide care to patients regardless of their location. To conduct effective online consultations, it is crucial to follow best practices that ensure quality care and patient satisfaction.</p>
            <p>Firstly, ensure that your technology setup is robust. A stable internet connection, a high-quality webcam, and a quiet, well-lit environment are fundamental. Test your equipment regularly to avoid technical issues during consultations.</p>
            <p>Secondly, maintain clear communication with your patients. Introduce yourself and explain how the telemedicine session will proceed. Make sure to speak slowly and clearly, and confirm that the patient can hear and see you well. Use visual aids when necessary to enhance understanding.</p>
            <p>Thirdly, privacy and confidentiality are paramount. Use secure telemedicine platforms that comply with regulations such as HIPAA in the United States. Inform patients about the steps taken to protect their privacy and ensure that their personal health information is secure.</p>
            <p>Fourthly, document the consultation thoroughly. Just as you would in a face-to-face visit, record the patient's history, symptoms, diagnosis, treatment plan, and any follow-up instructions. Proper documentation helps in continuity of care and legal protection.</p>
            <p>Lastly, provide clear follow-up instructions. Ensure that the patient knows the next steps, whether it involves scheduling another telemedicine appointment, visiting a clinic for further tests, or any home care instructions. Offer multiple ways for patients to contact you if they have questions or concerns after the session.</p>
            <p>Implementing these best practices will help you conduct telemedicine consultations effectively, providing high-quality care and ensuring patient satisfaction.</p>
          `,
          image: 'images/best-practices.webp'
        },
        {
          title: "Protocols",
          content: `
            <p>Understanding and adhering to established protocols for various medical conditions is crucial in telemedicine. Protocols provide a standardized approach to diagnosing and treating patients, ensuring consistency and quality of care.</p>
            <p>For common conditions such as respiratory infections, follow the specific protocol that outlines the symptoms to look for, the questions to ask, and the steps to take for diagnosis and treatment. This might include recommending over-the-counter medications, advising on home care, or determining if the patient needs to visit a healthcare facility for further evaluation.</p>
            <p>For chronic conditions like diabetes or hypertension, protocols help in monitoring and managing the condition remotely. Ensure that you have the patient's medical history and recent data such as blood sugar levels or blood pressure readings. Use this information to adjust treatment plans, provide dietary and lifestyle advice, and schedule regular follow-ups.</p>
            <p>Mental health protocols are also essential in telemedicine. Conduct thorough assessments using standardized questionnaires and engage in active listening to understand the patient's mental health status. Provide support, discuss treatment options, and, if necessary, refer the patient to a specialist.</p>
            <p>Additionally, emergency protocols should be in place. Know how to identify signs that require immediate attention and have a plan for directing the patient to emergency services if needed. Provide clear instructions and reassure the patient while coordinating with emergency responders.</p>
            <p>By following established protocols, you can deliver consistent, high-quality care through telemedicine, ensuring that patients receive the appropriate treatment and support for their conditions.</p>
          `,
          image: 'images/protocols.webp'
        }
      ]
    },
  
  
  {
    path: "/training-courses",
    title: 'Remote Care Guide',
    content: 'Online courses and webinars to improve your telemedicine skills. Stay updated with the latest trends and techniques in the field.',
    image: 'images/training-courses.webp',
    sections: [
      {
        title: "Online Courses",
        content: "Enroll in online courses to enhance your skills...",
        image: 'images/online-courses.webp'
      },
      {
        title: "Webinars",
        content: "Attend webinars on the latest trends in telemedicine...",
        image: 'images/webinars.webp'
      }
    ]
  },
  {
    path: "/guides/consultations",
    title: 'Effective Consultations',
    content: 'Guides and videos to help patients prepare for their online consultations. Ensure a smooth and efficient experience with our resources.',
    image: 'images/patient-info.webp',
    sections: [
      {
        title: "Preparation Guides",
        content: "Guides to help patients prepare for their online consultations...",
        image: 'images/preparation-guides.webp'
      },
      {
        title: "Video Tutorials",
        content: "Watch video tutorials on how to use our telemedicine platform...",
        image: 'images/video-tutorials.webp'
      }
    ]
  },
  {
    path: "/patient/preparing-consultation",
    title: 'Preparing for Consultation',
    content: 'Access to medical calculators, drug databases, and more. Enhance your clinical decision-making with these valuable resources.',
    image: 'images/clinical-tools.webp',
    sections: [
      {
        title: "Medical Calculators",
        content: "Use our medical calculators for accurate measurements...",
        image: 'images/medical-calculators.webp'
      },
      {
        title: "Drug Databases",
        content: "Access comprehensive drug databases for quick reference...",
        image: 'images/drug-databases.webp'
      }
    ]
  },
  {
    path: "/patient/using-platform",
    title: 'Using Our Platform',
    content: 'Get help with using the telemedicine platform. Our support team is available to assist you with any technical issues.',
    image: 'images/technical-support.webp',
    sections: [
      {
        title: "Support Guides",
        content: "Find detailed guides to troubleshoot common issues...",
        image: 'images/support-guides.webp'
      },
      {
        title: "Contact Support",
        content: "Reach out to our support team for personalized assistance...",
        image: 'images/contact-support.webp'
      }
    ]
  },
  {
    path: "/patient/health-articles",
    title: 'Health Articles',
    content: 'Find answers to common questions about telemedicine. Get the information you need to make the most of our services.',
    image: 'images/faqs.webp',
    sections: [
      {
        title: "General FAQs",
        content: "Browse through our general FAQs for quick answers...",
        image: 'images/general-faqs.webp'
      },
      {
        title: "Technical FAQs",
        content: "Find solutions to technical issues in our technical FAQs...",
        image: 'images/technical-faqs.webp'
      }
    ]
  },
  {
    path: "/support",
    title: "Customer Services and Technical Support",
    description: "if you have any problems with Doctorelec please submit a ticket as technical support or customer services, a customer servives will contact you shortly.",
    keywords: "feedback, customer services, support, healthcare, telemedicine",
    content: 'Read the latest articles and insights about telemedicine. Stay informed about industry news, trends, and best practices.',
    image: 'images/blog.webp',
    sections: [
      {
        title: "Latest Articles",
        content: "Explore our latest articles on telemedicine trends...",
        image: 'images/latest-articles.webp'
      },
      {
        title: "Industry Insights",
        content: "Get insights into the telemedicine industry...",
        image: 'images/industry-insights.webp'
      }
    ]
  },
  {
    path: "/faq",
    title: 'Common Asked Questions',
    description: "Common Asked Question for DoctorElec, if you have any question you can contact at support.",
    keywords: "faq, commonly asked question, healthcare, telemedicine, doctorelec",
    content: 'Read the latest articles and insights about telemedicine. Stay informed about industry news, trends, and best practices.',
    image: 'images/blog.webp',
    sections: [
      {
        title: "Latest Articles",
        content: "Explore our latest articles on telemedicine trends...",
        image: 'images/latest-articles.webp'
      },
      {
        title: "Industry Insights",
        content: "Get insights into the telemedicine industry...",
        image: 'images/industry-insights.webp'
      }
    ]
  },
  {
    path: "/blog",
    title: 'Blog',
    content: 'Read the latest articles and insights about telemedicine. Stay informed about industry news, trends, and best practices.',
    image: 'images/blog.webp',
    sections: [
      {
        title: "Latest Articles",
        content: "Explore our latest articles on telemedicine trends...",
        image: 'images/latest-articles.webp'
      },
      {
        title: "Industry Insights",
        content: "Get insights into the telemedicine industry...",
        image: 'images/industry-insights.webp'
      }
    ]
  }
];

export default resources;


// const resources = [
//     {
//       path: "/guides/telemedicine",
//       title: 'Guides for Healthcare Professionals',
//       content: 'Comprehensive guides and manuals for effective online consultations. Learn the best practices and protocols to provide top-notch care remotely.',
//       image: 'images/professional-guides.webp',
//       sections: [
//         {
//           title: "Best Practices",
//           content: "Discover the best practices for conducting online consultations...",
//           image: 'images/best-practices.webp'
//         },
//         {
//           title: "Protocols",
//           content: "Understand the protocols for various medical conditions...",
//           image: 'images/protocols.webp'
//         }
//       ]
//     },
//     {
//       path: "/training-courses",
//       title: 'Training and Courses',
//       content: 'Online courses and webinars to improve your telemedicine skills. Stay updated with the latest trends and techniques in the field.',
//       image: 'images/training-courses.webp',
//       sections: [
//         {
//           title: "Online Courses",
//           content: "Enroll in online courses to enhance your skills...",
//           image: 'images/online-courses.webp'
//         },
//         {
//           title: "Webinars",
//           content: "Attend webinars on the latest trends in telemedicine...",
//           image: 'images/webinars.webp'
//         }
//       ]
//     },
//     {
//       path: "/patient-information",
//       title: 'Patient Information',
//       content: 'Guides and videos to help patients prepare for their online consultations. Ensure a smooth and efficient experience with our resources.',
//       image: 'images/patient-info.webp',
//       sections: [
//         {
//           title: "Preparation Guides",
//           content: "Guides to help patients prepare for their online consultations...",
//           image: 'images/preparation-guides.webp'
//         },
//         {
//           title: "Video Tutorials",
//           content: "Watch video tutorials on how to use our telemedicine platform...",
//           image: 'images/video-tutorials.webp'
//         }
//       ]
//     },
//     {
//       path: "/clinical-tools",
//       title: 'Clinical Tools',
//       content: 'Access to medical calculators, drug databases, and more. Enhance your clinical decision-making with these valuable resources.',
//       image: 'images/clinical-tools.webp',
//       sections: [
//         {
//           title: "Medical Calculators",
//           content: "Use our medical calculators for accurate measurements...",
//           image: 'images/medical-calculators.webp'
//         },
//         {
//           title: "Drug Databases",
//           content: "Access comprehensive drug databases for quick reference...",
//           image: 'images/drug-databases.webp'
//         }
//       ]
//     },
//     {
//       path: "/technical-support",
//       title: 'Technical Support',
//       content: 'Get help with using the telemedicine platform. Our support team is available to assist you with any technical issues.',
//       image: 'images/technical-support.webp',
//       sections: [
//         {
//           title: "Support Guides",
//           content: "Find detailed guides to troubleshoot common issues...",
//           image: 'images/support-guides.webp'
//         },
//         {
//           title: "Contact Support",
//           content: "Reach out to our support team for personalized assistance...",
//           image: 'images/contact-support.webp'
//         }
//       ]
//     },
//     {
//       path: "/faqs",
//       title: 'FAQs',
//       content: 'Find answers to common questions about telemedicine. Get the information you need to make the most of our services.',
//       image: 'images/faqs.webp',
//       sections: [
//         {
//           title: "General FAQs",
//           content: "Browse through our general FAQs for quick answers...",
//           image: 'images/general-faqs.webp'
//         },
//         {
//           title: "Technical FAQs",
//           content: "Find solutions to technical issues in our technical FAQs...",
//           image: 'images/technical-faqs.webp'
//         }
//       ]
//     },
//     {
//       path: "/blog",
//       title: 'Blog',
//       content: 'Read the latest articles and insights about telemedicine. Stay informed about industry news, trends, and best practices.',
//       image: 'images/blog.webp',
//       sections: [
//         {
//           title: "Latest Articles",
//           content: "Explore our latest articles on telemedicine trends...",
//           image: 'images/latest-articles.webp'
//         },
//         {
//           title: "Industry Insights",
//           content: "Get insights into the telemedicine industry...",
//           image: 'images/industry-insights.webp'
//         }
//       ]
//     }
//   ];
  
//   export default resources;
  