import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './EditProfile.css';
import './LightMode.css';
import './DarkMode.css';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/AuthContext';
import axiosProfile from '../utils/axiosProfile';

function CreateProfile() {
    const { user } = useAuth();
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        bio: '',
        photo: null,
        address: '',
        city: '',
        state: '',
        zipcode: '',
        occupation: '',
        medical_speciality: '',
        license_number: '',
        issue_date: '',
        license_type: '',
        certification: null,
        accreditation: '',
    });
    const [profilePhoto, setProfilePhoto] = useState(null);
    const navigate = useNavigate();
    const { darkMode } = useTheme();

    // Agregar useEffect para verificar los datos de user
    useEffect(() => {
        console.log('User data from useAuth:', user);
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value || '',  // Asegurarse de que no se pase null
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            setFormData((prevData) => ({
                ...prevData,
                [e.target.name]: file,
            }));
            if (e.target.name === 'photo') {
                const previewUrl = URL.createObjectURL(file);
                setProfilePhoto(previewUrl);
            }
        } else {
            alert("Please select a valid image file (png or jpg).");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Final Form Data:', formData); // Verificar datos finales
        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (formData[key] !== null && formData[key] !== '' && formData[key] !== undefined) {
                if (key === 'issue_date' && formData[key]) {
                    const date = new Date(formData[key]);
                    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                    data.append(key, formattedDate);
                } else {
                    data.append(key, formData[key]);
                }
            }
        });
    
        // Aquí se asegura de que se pase el user_id correcto
        if (user) {
            data.append('user_id', user.user_id);  // Asegúrate de usar user.user_id
            data.append('username', user.username);
            data.append('role', user.role);
        } else {
            console.error('User data is not available');
        }
    
        // Log para verificar los datos enviados 
        //COMMENT IN DEBUG
        for (let pair of data.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
    
        try {
            const response = await axiosProfile.post('/profiles/create', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`,  // Incluye el token JWT en los encabezados
                },
            });
            console.log(response.data);
            navigate('/dashboard');
        } catch (error) {
            console.error("Error creating profile:", error);
        }
    };

    const nextStep = () => {
        console.log('Form Data at Step:', currentStep, formData); // Verificar datos en el paso actual
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    return (
        <div className={`container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <div className={`card mx-auto ${darkMode ? 'dark-mode' : 'light-mode'}`} style={{ maxWidth: '600px' }}>
                <div className="card-body">
                    <h2 className={`card-title text-center ${darkMode ? 'dark-mode' : 'light-mode'}`}>Create Profile</h2>
                    {profilePhoto && (
                        <div className="text-center mb-3">
                            <img src={profilePhoto} alt="Profile" className="profile-photo-edit rounded-circle" />
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        {currentStep === 1 && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="first_name" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>First Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="first_name"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="last_name" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Last Name</label>
                                    <input
                                        type="text"
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="last_name"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="bio" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Bio</label>
                                    <textarea
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="bio"
                                        name="bio"
                                        value={formData.bio}
                                        onChange={handleChange}
                                        rows="3"
                                        required
                                    ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="photo" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Photo</label>
                                    <input
                                        type="file"
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="photo"
                                        name="photo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn btn-secondary" onClick={prevStep} disabled={currentStep === 1}>Back</button>
                                    <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
                                </div>
                            </>
                        )}
                        {currentStep === 2 && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="address" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Address</label>
                                    <input
                                        type="text"
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="city" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>City</label>
                                    <input
                                        type="text"
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="state" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>State</label>
                                    <input
                                        type="text"
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="state"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="zipcode" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Zip Code</label>
                                    <input
                                        type="text"
                                        className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                        id="zipcode"
                                        name="zipcode"
                                        value={formData.zipcode}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn btn-secondary" onClick={prevStep}>Back</button>
                                    <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>
                                </div>
                            </>
                        )}
                        {currentStep === 3 && (
                            <>
                                {user?.role === 'patient' && (
                                    <div className="mb-3">
                                        <label htmlFor="occupation" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Occupation</label>
                                        <input
                                            type="text"
                                            className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                            id="occupation"
                                            name="occupation"
                                            value={formData.occupation}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}
                                {user?.role === 'doctor' && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="medical_speciality" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Medical Speciality</label>
                                            <input
                                                type="text"
                                                className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                                id="medical_speciality"
                                                name="medical_speciality"
                                                value={formData.medical_speciality}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="license_number" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>License Number</label>
                                            <input
                                                type="text"
                                                className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                                id="license_number"
                                                name="license_number"
                                                value={formData.license_number}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="issue_date" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Issue Date</label>
                                            <input
                                                type="date"
                                                className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                                id="issue_date"
                                                name="issue_date"
                                                value={formData.issue_date}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="license_type" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>License Type</label>
                                            <input
                                                type="text"
                                                className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                                id="license_type"
                                                name="license_type"
                                                value={formData.license_type}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="certification" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Certification</label>
                                            <input
                                                type="file"
                                                className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                                id="certification"
                                                name="certification"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="accreditation" className={`form-label ${darkMode ? 'dark-mode' : 'light-mode'}`}>Accreditation</label>
                                            <input
                                                type="text"
                                                className={`form-control ${darkMode ? 'dark-mode' : 'light-mode'}`}
                                                id="accreditation"
                                                name="accreditation"
                                                value={formData.accreditation}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn btn-secondary" onClick={prevStep}>Back</button>
                                    <button type="submit" className="btn btn-purple">Create Profile</button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateProfile;
