import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SEO from './SEO'; 

const RoleSelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const RoleCardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const RoleCard = styled.div`
  width: 350px;
  height: 350px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden; /* Ensure content doesn't overflow */
  margin: 10px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: 85%; /* Adjust height to fit within the container */
    object-fit: cover; /* Ensures the image covers the container without stretching */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  span { 
    padding: 10px;
    width: 100%;
    background-color: #6200EE;
    color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;

    img {
      height: 100px; /* Adjust height to fit within the container */
    }

    span {
      padding: 8px;
      font-size: 14px;
    }
  }
`;

const RoleSelection = () => {
  const navigate = useNavigate();

  const handleRoleSelection = (role) => {
    navigate(`/register/${role}`);
  };

  return (
 <>
    <SEO />
    <RoleSelectionContainer>
      <h1 className='text-purple'>Select your role</h1>
      <RoleCardContainer>
        <RoleCard onClick={() => handleRoleSelection('doctor')}>
          <img src="/images/hospital.webp" alt="Healthcare Provider" />
          <span>Healthcare Provider</span>
        </RoleCard>
        <RoleCard onClick={() => handleRoleSelection('patient')}>
          <img src="/images/techniques-speech-therapy.webp" alt="Patient" />
          <span>Patient</span>
        </RoleCard>
      </RoleCardContainer>
    </RoleSelectionContainer>
  </>
  );
};

export default RoleSelection;

