// import React, { useState } from 'react';
// import axios from 'axios';
// import SelectedMedications from './SelectedMedications'; // Asegúrate de que este componente esté creado e importado

// const PrescriptionForm = ({ consultationUuid }) => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [medications, setMedications] = useState([]);
//   const [selectedMedications, setSelectedMedications] = useState([]);
//   const [selectedFrequencies, setSelectedFrequencies] = useState({});
//   const [formData, setFormData] = useState({
//     endDate: '',
//     quantityPrescribed: '',
//     directionsForUse: '',
//     numberOfRefills: '',
//   });

//   const handleSearchMedications = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(`/create_prescription/${consultationUuid}`, { search_term: searchTerm });
//       setMedications(response.data.medications);
//     } catch (error) {
//       console.error('Error searching medications:', error);
//     }
//   };

//   const handleMedicationSelect = (medication) => {
//     setSelectedMedications([...selectedMedications, medication]);
//   };

//   const handleFrequencyChange = (medicationId, frequency) => {
//     setSelectedFrequencies({
//       ...selectedFrequencies,
//       [medicationId]: frequency,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post(`/create_prescription/${consultationUuid}`, {
//         ...formData,
//         selectedMedications,
//         selectedFrequencies,
//       });
//       alert('Prescription created successfully!');
//     } catch (error) {
//       console.error('Error creating prescription:', error);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div>
//         <h5>Search for Medications</h5>
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           placeholder="Enter medication name"
//         />
//         <button onClick={handleSearchMedications}>Search</button>
//       </div>

//       {medications.length > 0 && (
//         <div>
//           <h5>Search Results</h5>
//           <ul>
//             {medications.map((medication) => (
//               <li key={medication.id}>
//                 {medication.drugName} ({medication.strength})
//                 <button type="button" onClick={() => handleMedicationSelect(medication)}>
//                   Add
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}

//       {selectedMedications.length > 0 && (
//         <div>
//           <h5>Selected Medications</h5>
//           <SelectedMedications
//             selectedMedications={selectedMedications}
//             onFrequencyChange={handleFrequencyChange}
//           />
//         </div>
//       )}

//       <div>
//         <h5>Prescription Details</h5>
//         <input
//           type="date"
//           value={formData.endDate}
//           onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
//           placeholder="End Date"
//         />
//         <input
//           type="number"
//           value={formData.quantityPrescribed}
//           onChange={(e) => setFormData({ ...formData, quantityPrescribed: e.target.value })}
//           placeholder="Quantity Prescribed"
//         />
//         <textarea
//           value={formData.directionsForUse}
//           onChange={(e) => setFormData({ ...formData, directionsForUse: e.target.value })}
//           placeholder="Directions for Use"
//         ></textarea>
//         <input
//           type="number"
//           value={formData.numberOfRefills}
//           onChange={(e) => setFormData({ ...formData, numberOfRefills: e.target.value })}
//           placeholder="Number of Refills"
//         />
//       </div>

//       <button type="submit">Create Prescription</button>
//     </form>
//   );
// };

// export default PrescriptionForm;

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import MedicationSearch from './MedicationSearch';
import SelectedMedications from './SelectedMedications';
import DoctorSignature from './DoctorSignature';
import axios from 'axios';

const PrescriptionForm = ({ consultationUuid }) => {
  const [selectedMedications, setSelectedMedications] = useState([]);
  const [signature, setSignature] = useState('');
  const [formData, setFormData] = useState({
    endDate: '',
    quantityPrescribed: '',
    directionsForUse: '',
    numberOfRefills: '',
  });

  const handleFrequencyChange = (medicationId, frequency) => {
    setSelectedMedications(
      selectedMedications.map((med) =>
        med.id === medicationId ? { ...med, frequency } : med
      )
    );
  };

  const removeMedication = (medicationId) => {
    setSelectedMedications(
      selectedMedications.filter((med) => med.id !== medicationId)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/create_prescription/${consultationUuid}`, {
        ...formData,
        selectedMedications,
        signature,
      });
      alert('Prescription created successfully!');
    } catch (error) {
      console.error('Error creating prescription:', error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <MedicationSearch
        selectedMedications={selectedMedications}
        setSelectedMedications={setSelectedMedications}
      />
      <SelectedMedications
        selectedMedications={selectedMedications}
        onFrequencyChange={handleFrequencyChange}
        removeMedication={removeMedication}
      />
      <div className="prescription-details mt-4">
        <h5>Prescription Details</h5>
        <Form.Group controlId="endDate">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            value={formData.endDate}
            onChange={(e) =>
              setFormData({ ...formData, endDate: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group controlId="quantityPrescribed">
          <Form.Label>Quantity Prescribed</Form.Label>
          <Form.Control
            type="number"
            value={formData.quantityPrescribed}
            onChange={(e) =>
              setFormData({ ...formData, quantityPrescribed: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group controlId="directionsForUse">
          <Form.Label>Directions for Use</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={formData.directionsForUse}
            onChange={(e) =>
              setFormData({ ...formData, directionsForUse: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group controlId="numberOfRefills">
          <Form.Label>Number of Refills</Form.Label>
          <Form.Control
            type="number"
            value={formData.numberOfRefills}
            onChange={(e) =>
              setFormData({ ...formData, numberOfRefills: e.target.value })
            }
          />
        </Form.Group>
      </div>
      <DoctorSignature saveSignature={setSignature} />
      <Button variant="success" type="submit" className="mt-4">
        Create Prescription
      </Button>
    </Form>
  );
};

export default PrescriptionForm;
