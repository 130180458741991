import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng, e) => {
    e.preventDefault();
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <a href="#" onClick={(e) => changeLanguage('en', e)}>English</a>
      <a href="#" onClick={(e) => changeLanguage('es', e)}>Español</a>
    </div>
  );
};

export default LanguageSelector;
