// //import axios from 'axios';
// import axiosInstance from '../utils/axiosInstance';
// const refreshToken = async () => {
//     const refreshToken = localStorage.getItem('refreshToken');
//     if (refreshToken) {
//         try {
//             const response = await axiosInstance.post('/token/refresh/', { refresh: refreshToken });
//             localStorage.setItem('accessToken', response.data.access);
//             localStorage.setItem('refreshToken', response.data.refresh);
//             return response.data.access;
//         } catch (error) {
//             console.error("Error refreshing token:", error);
//             return null;
//         }
//     }
//     return null;
// };

// export default refreshToken;
import axios from 'axios';

const RefreshToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post('http://localhost:8000/api/token/refresh/', {
            refresh: refreshToken
        });
        const { access } = response.data;
        localStorage.setItem('accessToken', access);
        return access;
    } catch (error) {
        console.error("Failed to refresh token:", error);
        return null;
    }
};

export default RefreshToken;
