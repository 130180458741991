import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosConsultation from '../../utils/axiosConsultation';
import axiosProfile from '../../utils/axiosProfile';
import { Helmet } from 'react-helmet';
import { FaTwitter, FaLinkedin, FaFacebook, FaInstagram, FaShareAlt, FaStar } from 'react-icons/fa';
import Error404 from '../Error404';
import styled from 'styled-components';
import { IconContext } from 'react-icons';

// const PageTitle = styled.h1`
//     text-align: center;
//     font-size: 2.5rem;
//     color: #4A4A4A;
//     margin-bottom: 2rem;
//     font-family: 'Raleway', sans-serif;
    
// `;

const ProfileContainer = styled.div`
    max-width: 900px;
    margin: 2rem auto;
    padding: 2rem;
    background: linear-gradient(135deg, #f5f7fa, #73BFB8);
    border-radius: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`;

const ProfileHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 2.5rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #d1d1d1;
    padding-bottom: 1.5rem;
`;

const DoctorPhoto = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #6200EE;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const DoctorInfo = styled.div`
    flex: 1;
`;

// const DoctorName = styled.h2`
//     margin: 0;
//     font-size: 2.6rem;
//     color: #333;
// `;

const Username = styled.h4`
    margin: 0.5rem 0;
    font-size: 1.2rem;
    color: #888;
`;

const Specialty = styled.h3`
    font-size: 1.5rem;
    color: #555;
    margin: 0.5rem 0;
`;

const PageTitle = styled.h1`
    text-align: center;
    font-size: 2.5rem;
    color: #4A4A4A;
    margin-bottom: 2rem;
    font-family: 'Raleway', sans-serif;
`;

const DoctorName = styled.h2`
    margin: 0;
    font-size: 2.6rem;
    color: #333;
    font-family: 'Raleway', sans-serif;
`;

const Bio = styled.p`
    font-size: 1rem;
    color: #555;
    margin: 1rem 0;
    line-height: 1.6;
    font-family: 'Raleway', sans-serif;
`;

// Aplicar el resto a otros componentes según sea necesario

// const Bio = styled.p`
//     font-size: 1rem;
//     color: #555;
//     margin: 1rem 0;
//     line-height: 1.6;
// `;

const Location = styled.p`
    font-size: 1rem;
    color: #777;
`;

const SocialLinks = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-top: 1.5rem;

    a {
        font-size: 2rem;
        transition: transform 0.3s ease;
        color: inherit;

        &:hover {
            transform: scale(1.2);
            color: #73BFB8;
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
`;

const ShareButton = styled.button`
    display: flex;
    align-items: center;
    padding: 0.7rem 2rem;
    background-color: #73BFB8;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #5DAF9F;
    }

    svg {
        margin-right: 0.5rem;
    }
`;

const ConsultButton = styled.button`
    padding: 0.7rem 2rem;
    background-color: #6200EE;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #4E00B8;
    }
`;

const Testimonials = styled.div`
    margin-top: 3rem;

    blockquote {
        font-size: 1.2rem;
        color: #555;
        margin: 0.5rem 0;
        padding-left: 1rem;
        border-left: 4px solid #6200EE;
        font-style: italic;
        background: #f9f9f9;
        padding: 1rem;
        border-radius: 10px;
    }

    cite {
        display: block;
        font-size: 1rem;
        color: #777;
        margin-top: 0.5rem;
        text-align: right;
    }
`;

const Rating = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: #FFC107;

    span {
        margin-left: 0.5rem;
        font-size: 1.2rem;
        color: #333;
    }
`;

function PublicDoctorProfile() {
    const { username } = useParams();
    const navigate = useNavigate();
    const [doctor, setDoctor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [testimonials, setTestimonials] = useState([]);
    const [socialLinks, setSocialLinks] = useState({});

    useEffect(() => {
        const fetchDoctorProfile = async () => {
            try {
                const response = await axiosProfile.get(`/profiles/public/${username}`);
                if (response.data) {
                    setDoctor(response.data);

                    const ratingsResponse = await axiosConsultation.get(`/doctors/${username}/ratings`);
                    setTestimonials(ratingsResponse.data);

                    const socialLinksResponse = await axiosProfile.get(`/profiles/social_links/${username}`);
                    if (socialLinksResponse.data) {
                        setSocialLinks(socialLinksResponse.data);
                    }

                } else {
                    navigate('/404');
                }
            } catch (error) {
                navigate('/404');
            } finally {
                setLoading(false);
            }
        };

        fetchDoctorProfile();
    }, [username, navigate]);

    const calculateAverageRating = () => {
        const total = testimonials.reduce((acc, testimonial) => acc + testimonial.rating, 0);
        return (total / testimonials.length).toFixed(1);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!doctor) {
        return <Error404 />;
    }

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: `${doctor.first_name} ${doctor.last_name} - Doctor Profile`,
                url: window.location.href,
            }).catch(error => console.error('Error sharing:', error));
        } else {
            alert('Tu navegador no soporta la funcionalidad de compartir.');
        }
    };

    return (
        <IconContext.Provider value={{ color: "currentColor", className: "global-class-name" }}>
            <Helmet>
                 <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap" rel="stylesheet" />
                <title>{`${doctor.first_name} ${doctor.last_name} - Doctor Profile`}</title>
                <meta name="description" content={`${doctor.bio}`} />
                <meta property="og:title" content={`${doctor.first_name} ${doctor.last_name} - Doctor Profile`} />
                <meta property="og:description" content={`${doctor.bio}`} />
                <meta property="og:image" content={`http://127.0.0.1:5003/uploads/${doctor.photo}`} />
                <meta property="og:url" content={window.location.href} />
                <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap" rel="stylesheet" />
            </Helmet>

            <PageTitle>Doctor Profile</PageTitle>

            <ProfileContainer>
                <ProfileHeader>
                    <DoctorPhoto src={`http://127.0.0.1:5003/uploads/${doctor.photo}`} alt={`Foto del Dr. ${doctor.first_name} ${doctor.last_name}`} />
                    <DoctorInfo>
                        <DoctorName>{doctor.first_name} {doctor.last_name}</DoctorName>
                        <Username>@{doctor.username ? doctor.username : "N/A"}</Username>
                        <Specialty>{doctor.medical_speciality}</Specialty>
                        <Bio>{doctor.bio}</Bio>
                        <Location>{doctor.city}, {doctor.state}</Location>
                        <Rating>
                            {[...Array(5)].map((star, index) => (
                                <FaStar key={index} color={index < calculateAverageRating() ? "#FFC107" : "#E4E5E9"} />
                            ))}
                            <span className="text-purple">{calculateAverageRating()}</span>
                        </Rating>
                    </DoctorInfo>
                </ProfileHeader>

                <SocialLinks>
                    {socialLinks.x && <a href={socialLinks.x} target="_blank" rel="noopener noreferrer"><FaTwitter aria-label="X" style={{ color: "#1DA1F2" }} /></a>}
                    {socialLinks.linkedin && <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin aria-label="LinkedIn" style={{ color: "#0077B5" }} /></a>}
                    {socialLinks.facebook && <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer"><FaFacebook aria-label="Facebook" style={{ color: "#4267B2" }} /></a>}
                    {socialLinks.instagram && <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram aria-label="Instagram" style={{ color: "#C13584" }} /></a>}
                </SocialLinks>

                <ButtonContainer>
                    <ShareButton onClick={handleShare}>
                        <FaShareAlt /> Share Profile
                    </ShareButton>
                    <ConsultButton onClick={() => window.location.href = '/register/patient'}>
                        Start Medical Consultation
                    </ConsultButton>
                </ButtonContainer>
                <br />

                <hr />

                <h2 className="text-purple">Patient Reviews</h2>
               
                <Testimonials>
                    {testimonials.map((testimonial, index) => (
                        <div key={index}>
                            <Rating>
                                {[...Array(5)].map((star, i) => (
                                    <FaStar key={i} color={i < testimonial.rating ? "#FFC107" : "#E4E5E9"} />
                                ))}
                                <span className="text-purple">{testimonial.rating}</span>
                            </Rating>
                            <blockquote>"{testimonial.testimonial}"</blockquote>
                            <cite>- @{testimonial.patient_username}</cite>
                        </div>
                    ))}
                </Testimonials>
            </ProfileContainer>
        </IconContext.Provider>
    );
}

export default PublicDoctorProfile;

// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axiosConsultation from '../../utils/axiosConsultation';
// import axiosProfile from '../../utils/axiosProfile';
// import { Helmet } from 'react-helmet';
// import { FaTwitter, FaLinkedin, FaFacebook, FaInstagram, FaShareAlt, FaStar } from 'react-icons/fa';
// import Error404 from '../Error404';
// import styled from 'styled-components';
// import { IconContext } from 'react-icons';

// const PageTitle = styled.h1`
//     text-align: center;
//     font-size: 2.5rem;
//     color: #4A4A4A;
//     margin-bottom: 2rem;
// `;

// const ProfileContainer = styled.div`
//     max-width: 900px;
//     margin: 2rem auto;
//     padding: 2rem;
//     background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
//     border-radius: 20px;
//     box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
// `;

// const ProfileHeader = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 2.5rem;  /* Aumentar el espacio entre elementos */
//     margin-bottom: 2rem;
//     border-bottom: 2px solid #d1d1d1;
//     padding-bottom: 1.5rem;
// `;

// const DoctorPhoto = styled.img`
//     width: 150px;
//     height: 150px;
//     border-radius: 50%;
//     object-fit: cover;
//     border: 4px solid #6200EE;  /* Usar el color principal */
//     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
// `;

// const DoctorInfo = styled.div`
//     flex: 1;
// `;

// const DoctorName = styled.h2`
//     margin: 0;
//     font-size: 2.6rem;  /* Aumentar el tamaño de la fuente */
//     color: #333;
// `;

// const Username = styled.h4`
//     margin: 0.5rem 0;
//     font-size: 1.2rem;
//     color: #888;
// `;

// const Specialty = styled.h3`
//     font-size: 1.5rem;
//     color: #555;
//     margin: 0.5rem 0;
// `;

// const Bio = styled.p`
//     font-size: 1rem;
//     color: #555;
//     margin: 1rem 0;
//     line-height: 1.6;
// `;

// const Location = styled.p`
//     font-size: 1rem;
//     color: #777;
// `;

// const SocialLinks = styled.div`
//     display: flex;
//     gap: 1.5rem;
//     margin-top: 1.5rem;

//     a {
//         font-size: 2rem;
//         transition: transform 0.3s ease;
//         color: inherit;

//         &:hover {
//             transform: scale(1.2);
//             color: #73BFB8;  /* Nuevo color añadido */
//         }
//     }
// `;

// const ButtonContainer = styled.div`
//     display: flex;
//     gap: 1rem;
//     margin-top: 2rem;
// `;

// const ShareButton = styled.button`
//     display: flex;
//     align-items: center;
//     padding: 0.7rem 2rem;
//     background-color: #73BFB8;  /* Color diferenciado para Share */
//     color: white;
//     border: none;
//     border-radius: 8px;
//     cursor: pointer;
//     font-size: 1rem;
//     transition: background-color 0.3s ease;

//     &:hover {
//         background-color: #5DAF9F;  /* Sombra al hacer hover */
//     }

//     svg {
//         margin-right: 0.5rem;
//     }
// `;

// const ConsultButton = styled.button`
//     padding: 0.7rem 2rem;
//     background-color: #6200EE;  /* Color más llamativo para Consultar */
//     color: white;
//     border: none;
//     border-radius: 8px;
//     cursor: pointer;
//     font-size: 1rem;
//     transition: background-color 0.3s ease;

//     &:hover {
//         background-color: #4E00B8;  /* Sombra más oscura al hacer hover */
//     }
// `;

// const Testimonials = styled.div`
//     margin-top: 3rem;

//     blockquote {
//         font-size: 1.2rem;
//         color: #555;
//         margin: 0.5rem 0;
//         padding-left: 1rem;
//         border-left: 4px solid #6200EE;
//         font-style: italic;
//         background: #f9f9f9;  /* Fondo sombreado para destacar */
//         padding: 1rem;
//         border-radius: 10px;
//     }

//     cite {
//         display: block;
//         font-size: 1rem;
//         color: #777;
//         margin-top: 0.5rem;
//         text-align: right;
//     }
// `;

// const Rating = styled.div`
//     display: flex;
//     align-items: center;
//     font-size: 1.5rem;
//     color: #FFC107;

//     span {
//         margin-left: 0.5rem;
//         font-size: 1.2rem;
//         color: #333;
//     }
// `;

// function PublicDoctorProfile() {
//     const { username } = useParams();
//     const navigate = useNavigate();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [testimonials, setTestimonials] = useState([]);
//     const [socialLinks, setSocialLinks] = useState({});

//     useEffect(() => {
//         const fetchDoctorProfile = async () => {
//             try {
//                 const response = await axiosProfile.get(`/profiles/public/${username}`);
//                 if (response.data) {
//                     setDoctor(response.data);

//                     const ratingsResponse = await axiosConsultation.get(`/doctors/${username}/ratings`);
//                     setTestimonials(ratingsResponse.data);

//                     const socialLinksResponse = await axiosProfile.get(`/profiles/social_links/${username}`);
//                     if (socialLinksResponse.data) {
//                         setSocialLinks(socialLinksResponse.data);
//                     }

//                 } else {
//                     navigate('/404');
//                 }
//             } catch (error) {
//                 navigate('/404');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchDoctorProfile();
//     }, [username, navigate]);

//     const calculateAverageRating = () => {
//         const total = testimonials.reduce((acc, testimonial) => acc + testimonial.rating, 0);
//         return (total / testimonials.length).toFixed(1);
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (!doctor) {
//         return <Error404 />;
//     }

//     const handleShare = () => {
//         if (navigator.share) {
//             navigator.share({
//                 title: `${doctor.first_name} ${doctor.last_name} - Doctor Profile`,
//                 url: window.location.href,
//             }).catch(error => console.error('Error sharing:', error));
//         } else {
//             alert('Tu navegador no soporta la funcionalidad de compartir.');
//         }
//     };

//     return (
//         <IconContext.Provider value={{ color: "currentColor", className: "global-class-name" }}>
//             <Helmet>
//                 <title>{`${doctor.first_name} ${doctor.last_name} - Doctor Profile`}</title>
//                 <meta name="description" content={`${doctor.bio}`} />
//                 <meta property="og:title" content={`${doctor.first_name} ${doctor.last_name} - Doctor Profile`} />
//                 <meta property="og:description" content={`${doctor.bio}`} />
//                 <meta property="og:image" content={`http://127.0.0.1:5003/uploads/${doctor.photo}`} />
//                 <meta property="og:url" content={window.location.href} />
//             </Helmet>

//             <PageTitle>Doctor Profile</PageTitle>

//             <ProfileContainer>
//                 <ProfileHeader>
//                     <DoctorPhoto src={`http://127.0.0.1:5003/uploads/${doctor.photo}`} alt={`Foto del Dr. ${doctor.first_name} ${doctor.last_name}`} />
//                     <DoctorInfo>
//                         <DoctorName>{doctor.first_name} {doctor.last_name}</DoctorName>
//                         <Username>@{doctor.username ? doctor.username : "N/A"}</Username>
//                         <Specialty>{doctor.medical_speciality}</Specialty>
//                         <Bio>{doctor.bio}</Bio>
//                         <Location>{doctor.city}, {doctor.state}</Location>
//                         <Rating>
//                             {[...Array(5)].map((star, index) => (
//                                 <FaStar key={index} color={index < calculateAverageRating() ? "#FFC107" : "#E4E5E9"} />
//                             ))}
//                             <span className="text-purple">{calculateAverageRating()}</span>
//                         </Rating>
//                     </DoctorInfo>
//                 </ProfileHeader>

//                 <SocialLinks>
//                     {socialLinks.x && <a href={socialLinks.x} target="_blank" rel="noopener noreferrer"><FaTwitter aria-label="X" style={{ color: "#1DA1F2" }} /></a>}
//                     {socialLinks.linkedin && <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin aria-label="LinkedIn" style={{ color: "#0077B5" }} /></a>}
//                     {socialLinks.facebook && <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer"><FaFacebook aria-label="Facebook" style={{ color: "#4267B2" }} /></a>}
//                     {socialLinks.instagram && <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram aria-label="Instagram" style={{ color: "#C13584" }} /></a>}
//                 </SocialLinks>

//                 <ButtonContainer>
//                     <ShareButton onClick={handleShare}>
//                         <FaShareAlt /> Share Profile
//                     </ShareButton>
//                     <ConsultButton onClick={() => window.location.href = '/register/patient'}>
//                         Start Medical Consultation
//                     </ConsultButton>
//                 </ButtonContainer>
//                 <br />

//                 <hr />

//                 <h2 className="text-purple">Patient Reviews</h2>
               
//                 <Testimonials>
//                     {testimonials.map((testimonial, index) => (
//                         <div key={index}>
//                             <Rating>
//                                 {[...Array(5)].map((star, i) => (
//                                     <FaStar key={i} color={i < testimonial.rating ? "#FFC107" : "#E4E5E9"} />
//                                 ))}
//                                 <span className="text-purple">{testimonial.rating}</span>
//                             </Rating>
//                             <blockquote>"{testimonial.testimonial}"</blockquote>
//                             <cite>- @{testimonial.patient_username}</cite>
//                         </div>
//                     ))}
//                 </Testimonials>
//             </ProfileContainer>
//         </IconContext.Provider>
//     );
// }

// export default PublicDoctorProfile;

// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axiosConsultation from '../../utils/axiosConsultation';
// import axiosProfile from '../../utils/axiosProfile';
// import { Helmet } from 'react-helmet';
// import { FaTwitter, FaLinkedin, FaFacebook, FaInstagram, FaShareAlt, FaStar } from 'react-icons/fa';
// import Error404 from '../Error404';
// import styled from 'styled-components';
// import { IconContext } from 'react-icons';
// const PageTitle = styled.h1`
//     text-align: center;
//     font-size: 2.5rem;
//     color: #4A4A4A;
//     margin-bottom: 2rem;
// `;

// const ProfileContainer = styled.div`
//     max-width: 900px;
//     margin: 2rem auto;
//     padding: 2rem;
//     background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
//     border-radius: 20px;
//     box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
// `;

// const ProfileHeader = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 2.5rem;  /* Aumentar el espacio entre elementos */
//     margin-bottom: 2rem;
//     border-bottom: 2px solid #d1d1d1;
//     padding-bottom: 1.5rem;
// `;

// const DoctorPhoto = styled.img`
//     width: 150px;
//     height: 150px;
//     border-radius: 50%;
//     object-fit: cover;
//     border: 4px solid #6200EE;  /* Usar el color principal */
//     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
// `;

// const DoctorInfo = styled.div`
//     flex: 1;
// `;

// const DoctorName = styled.h2`
//     margin: 0;
//     font-size: 2.6rem;  /* Aumentar el tamaño de la fuente */
//     color: #333;
// `;

// const Username = styled.h4`
//     margin: 0.5rem 0;
//     font-size: 1.2rem;
//     color: #888;
// `;

// const Specialty = styled.h3`
//     font-size: 1.5rem;
//     color: #555;
//     margin: 0.5rem 0;
// `;

// const Bio = styled.p`
//     font-size: 1rem;
//     color: #555;
//     margin: 1rem 0;
//     line-height: 1.6;
// `;

// const Location = styled.p`
//     font-size: 1rem;
//     color: #777;
// `;

// const SocialLinks = styled.div`
//     display: flex;
//     gap: 1.5rem;
//     margin-top: 1.5rem;

//     a {
//         font-size: 2rem;
//         transition: transform 0.3s ease;
//         color: inherit;

//         &:hover {
//             transform: scale(1.2);
//             color: #73BFB8;  /* Nuevo color añadido */
//         }
//     }
// `;

// const ButtonContainer = styled.div`
//     display: flex;
//     gap: 1rem;
//     margin-top: 2rem;
// `;

// const ShareButton = styled.button`
//     display: flex;
//     align-items: center;
//     padding: 0.7rem 2rem;
//     background-color: #73BFB8;  /* Color diferenciado para Share */
//     color: white;
//     border: none;
//     border-radius: 8px;
//     cursor: pointer;
//     font-size: 1rem;
//     transition: background-color 0.3s ease;

//     &:hover {
//         background-color: #5DAF9F;  /* Sombra al hacer hover */
//     }

//     svg {
//         margin-right: 0.5rem;
//     }
// `;

// const ConsultButton = styled.button`
//     padding: 0.7rem 2rem;
//     background-color: #6200EE;  /* Color más llamativo para Consultar */
//     color: white;
//     border: none;
//     border-radius: 8px;
//     cursor: pointer;
//     font-size: 1rem;
//     transition: background-color 0.3s ease;

//     &:hover {
//         background-color: #4E00B8;  /* Sombra más oscura al hacer hover */
//     }
// `;

// const Testimonials = styled.div`
//     margin-top: 3rem;

//     blockquote {
//         font-size: 1.2rem;
//         color: #555;
//         margin: 0.5rem 0;
//         padding-left: 1rem;
//         border-left: 4px solid #6200EE;
//         font-style: italic;
//         background: #f9f9f9;  /* Fondo sombreado para destacar */
//         padding: 1rem;
//         border-radius: 10px;
//     }

//     cite {
//         display: block;
//         font-size: 1rem;
//         color: #777;
//         margin-top: 0.5rem;
//         text-align: right;
//     }
// `;

// const Rating = styled.div`
//     display: flex;
//     align-items: center;
//     font-size: 1.5rem;
//     color: #FFC107;

//     span {
//         margin-left: 0.5rem;
//         font-size: 1.2rem;
//         color: #333;
//     }
// `;

// // const PageTitle = styled.h1`
// //     text-align: center;
// //     font-size: 2.5rem;
// //     color: #4A4A4A;
// //     margin-bottom: 2rem;
// // `;

// // const ProfileContainer = styled.div`
// //     max-width: 900px;
// //     margin: 2rem auto;
// //     padding: 2rem;
// //     background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
// //     border-radius: 20px;
// //     box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
// // `;

// // const ProfileHeader = styled.div`
// //     display: flex;
// //     align-items: center;
// //     gap: 2rem;
// //     margin-bottom: 2rem;
// //     border-bottom: 2px solid #d1d1d1;
// //     padding-bottom: 1.5rem;
// // `;

// // const DoctorPhoto = styled.img`
// //     width: 150px;
// //     height: 150px;
// //     border-radius: 50%;
// //     object-fit: cover;
// //     border: 4px solid #007BFF;
// //     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
// // `;

// // const DoctorInfo = styled.div`
// //     flex: 1;
// // `;

// // const DoctorName = styled.h2`
// //     margin: 0;
// //     font-size: 2.2rem;
// //     color: #333;
// // `;

// // const Username = styled.h4`
// //     margin: 0.5rem 0;
// //     font-size: 1.2rem;
// //     color: #888;
// // `;

// // const Specialty = styled.h3`
// //     font-size: 1.5rem;
// //     color: #555;
// //     margin: 0.5rem 0;
// // `;

// // const Bio = styled.p`
// //     font-size: 1rem;
// //     color: #555;
// //     margin: 1rem 0;
// //     line-height: 1.6;
// // `;

// // const Location = styled.p`
// //     font-size: 1rem;
// //     color: #777;
// // `;

// // const SocialLinks = styled.div`
// //     display: flex;
// //     gap: 1.5rem;
// //     margin-top: 1.5rem;

// //     a {
// //         font-size: 2rem;
// //         transition: transform 0.3s ease;
// //         color: inherit;

// //         &:hover {
// //             transform: scale(1.2);
// //             color: #007BFF;
// //         }
// //     }
// // `;

// // const ButtonContainer = styled.div`
// //     display: flex;
// //     gap: 1rem;
// //     margin-top: 2rem;
// // `;

// // const ShareButton = styled.button`
// //     display: flex;
// //     align-items: center;
// //     padding: 0.7rem 2rem;
// //     background-color: #28a745;
// //     color: white;
// //     border: none;
// //     border-radius: 8px;
// //     cursor: pointer;
// //     font-size: 1rem;
// //     transition: background-color 0.3s ease;

// //     &:hover {
// //         background-color: #218838;
// //     }

// //     svg {
// //         margin-right: 0.5rem;
// //     }
// // `;

// // const ConsultButton = styled.button`
// //     padding: 0.7rem 2rem;
// //     background-color: #007BFF;
// //     color: white;
// //     border: none;
// //     border-radius: 8px;
// //     cursor: pointer;
// //     font-size: 1rem;
// //     transition: background-color 0.3s ease;

// //     &:hover {
// //         background-color: #0056b3;
// //     }
// // `;

// // const Testimonials = styled.div`
// //     margin-top: 3rem;

// //     blockquote {
// //         font-size: 1.2rem;
// //         color: #555;
// //         margin: 0.5rem 0;
// //         padding-left: 1rem;
// //         border-left: 4px solid #007BFF;
// //         font-style: italic;
// //     }

// //     cite {
// //         display: block;
// //         font-size: 1rem;
// //         color: #777;
// //         margin-top: 0.5rem;
// //         text-align: right;
// //     }
// // `;

// // const Rating = styled.div`
// //     display: flex;
// //     align-items: center;
// //     font-size: 1.5rem;
// //     color: #FFC107;

// //     span {
// //         margin-left: 0.5rem;
// //         font-size: 1.2rem;
// //         color: #333;
// //     }
// // `;

// function PublicDoctorProfile() {
//     const { username } = useParams();
//     const navigate = useNavigate();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [testimonials, setTestimonials] = useState([]);
//     const [socialLinks, setSocialLinks] = useState({});

//     useEffect(() => {
//         const fetchDoctorProfile = async () => {
//             try {
//                 const response = await axiosProfile.get(`/profiles/public/${username}`);
//                 if (response.data) {
//                     setDoctor(response.data);

//                     const ratingsResponse = await axiosConsultation.get(`/doctors/${username}/ratings`);
//                     setTestimonials(ratingsResponse.data);

//                     const socialLinksResponse = await axiosProfile.get(`/profiles/social_links/${username}`);
//                     if (socialLinksResponse.data) {
//                         setSocialLinks(socialLinksResponse.data);
//                     }

//                 } else {
//                     navigate('/404');
//                 }
//             } catch (error) {
//                 navigate('/404');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchDoctorProfile();
//     }, [username, navigate]);

//     const calculateAverageRating = () => {
//         const total = testimonials.reduce((acc, testimonial) => acc + testimonial.rating, 0);
//         return (total / testimonials.length).toFixed(1);
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (!doctor) {
//         return <Error404 />;
//     }

//     const handleShare = () => {
//         if (navigator.share) {
//             navigator.share({
//                 title: `${doctor.first_name} ${doctor.last_name} - Doctor Profile`,
//                 url: window.location.href,
//             }).catch(error => console.error('Error sharing:', error));
//         } else {
//             alert('Tu navegador no soporta la funcionalidad de compartir.');
//         }
//     };

//     return (
//         <IconContext.Provider value={{ color: "currentColor", className: "global-class-name" }}>
//             <Helmet>
//                 <title>{`${doctor.first_name} ${doctor.last_name} - Doctor Profile`}</title>
//                 <meta name="description" content={`${doctor.bio}`} />
//                 <meta property="og:title" content={`${doctor.first_name} ${doctor.last_name} - Doctor Profile`} />
//                 <meta property="og:description" content={`${doctor.bio}`} />
//                 <meta property="og:image" content={`http://127.0.0.1:5003/uploads/${doctor.photo}`} />
//                 <meta property="og:url" content={window.location.href} />
//             </Helmet>

//             <PageTitle>Doctor Profile</PageTitle>

//             <ProfileContainer>
//                 <ProfileHeader>
//                     <DoctorPhoto src={`http://127.0.0.1:5003/uploads/${doctor.photo}`} alt={`Foto del Dr. ${doctor.first_name} ${doctor.last_name}`} />
//                     <DoctorInfo>
//                         <DoctorName>{doctor.first_name} {doctor.last_name}</DoctorName>
//                         <Username>@{doctor.username ? doctor.username : "N/A"}</Username>
//                         <Specialty>{doctor.medical_speciality}</Specialty>
//                         <Bio>{doctor.bio}</Bio>
//                         <Location>{doctor.city}, {doctor.state}</Location>
//                         <Rating>
//                             {[...Array(5)].map((star, index) => (
//                                 <FaStar key={index} color={index < calculateAverageRating() ? "#FFC107" : "#E4E5E9"} />
//                             ))}
//                             <span className="text-purple">{calculateAverageRating()}</span>
//                         </Rating>
//                     </DoctorInfo>
//                 </ProfileHeader>

//                 <SocialLinks>
//                     {socialLinks.x && <a href={socialLinks.x} target="_blank" rel="noopener noreferrer"><FaTwitter aria-label="X" style={{ color: "#1DA1F2" }} /></a>}
//                     {socialLinks.linkedin && <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin aria-label="LinkedIn" style={{ color: "#0077B5" }} /></a>}
//                     {socialLinks.facebook && <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer"><FaFacebook aria-label="Facebook" style={{ color: "#4267B2" }} /></a>}
//                     {socialLinks.instagram && <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram aria-label="Instagram" style={{ color: "#C13584" }} /></a>}
//                 </SocialLinks>

//                 <ButtonContainer>
//                     <ShareButton onClick={handleShare}>
//                         <FaShareAlt /> Share Profile
//                     </ShareButton>
//                     <ConsultButton onClick={() => window.location.href = '/register/patient'}>
//                         Start Medical Consultation
//                     </ConsultButton>
//                 </ButtonContainer>
//                 <br />

//                 <hr />

//                 <h2 className="text-purple">Patient Reviews</h2>
               
//                 <Testimonials>
//                     {testimonials.map((testimonial, index) => (
//                         <div key={index}>
//                             <Rating>
//                                 {[...Array(5)].map((star, i) => (
//                                     <FaStar key={i} color={i < testimonial.rating ? "#FFC107" : "#E4E5E9"} />
//                                 ))}
//                                 <span className="text-purple">{testimonial.rating}</span>
//                             </Rating>
//                             <blockquote>"{testimonial.testimonial}"</blockquote>
//                             <cite>- @{testimonial.patient_username}</cite>
//                         </div>
//                     ))}
//                 </Testimonials>
//             </ProfileContainer>
//         </IconContext.Provider>
//     );
// }

// export default PublicDoctorProfile;

// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axiosConsultation from '../../utils/axiosConsultation';
// import axiosProfile from '../../utils/axiosProfile';
// import { Helmet } from 'react-helmet';
// import { FaTwitter, FaLinkedin, FaFacebook, FaInstagram, FaShareAlt, FaStar } from 'react-icons/fa';
// import Error404 from '../Error404';
// import styled from 'styled-components';
// import { IconContext } from 'react-icons';

// const PageTitle = styled.h1`
//     text-align: center;
//     font-size: 2.5rem;
//     color: #6200EE;
//     margin-bottom: 1.5rem;
// `;

// const ProfileContainer = styled.div`
//     max-width: 900px;
//     margin: 2rem auto;
//     padding: 2rem;
//     background-color: #fff;
//     border-radius: 12px;
//     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
// `;

// const ProfileHeader = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 2rem;
//     margin-bottom: 2rem;
//     border-bottom: 2px solid #E0E0E0;
//     padding-bottom: 1.5rem;
// `;

// const DoctorPhoto = styled.img`
//     width: 150px;
//     height: 150px;
//     border-radius: 50%;
//     object-fit: cover;
//     border: 4px solid #6200EE;
// `;

// const DoctorInfo = styled.div`
//     flex: 1;
// `;

// const DoctorName = styled.h2`
//     margin: 0;
//     font-size: 2rem;
//     color: #333;
// `;

// const Username = styled.h4`
//     margin: 0.5rem 0;
//     font-size: 1.2rem;
//     color: #888;
// `;

// const Specialty = styled.h3`
//     font-size: 1.5rem;
//     color: #555;
// `;

// const Bio = styled.p`
//     font-size: 1rem;
//     color: #555;
// `;

// const Location = styled.p`
//     font-size: 1rem;
//     color: #777;
// `;

// const SocialLinks = styled.div`
//     display: flex;
//     gap: 1.5rem;
//     margin-top: 1rem;

//     a {
//         font-size: 2rem;
//         transition: transform 0.3s ease;

//         &:hover {
//             transform: scale(1.2);
//         }
//     }
// `;

// const ButtonContainer = styled.div`
//     display: flex;
//     gap: 1rem;
//     margin-top: 2rem;
// `;

// const ShareButton = styled.button`
//     display: flex;
//     align-items: center;
//     padding: 0.5rem 1.5rem;
//     background-color: #28a745;
//     color: white;
//     border: none;
//     border-radius: 4px;
//     cursor: pointer;
//     font-size: 1rem;
//     transition: background-color 0.3s ease;

//     &:hover {
//         background-color: #218838;
//     }

//     svg {
//         margin-right: 0.5rem;
//     }
// `;

// const ConsultButton = styled.button`
//     padding: 0.5rem 1.5rem;
//     background-color: #007BFF;
//     color: white;
//     border: none;
//     border-radius: 4px;
//     cursor: pointer;
//     font-size: 1rem;
//     transition: background-color 0.3s ease;

//     &:hover {
//         background-color: #0056b3;
//     }
// `;

// const Testimonials = styled.div`
//     margin-top: 3rem;

//     blockquote {
//         font-size: 1.2rem;
//         color: #555;
//         margin: 0.5rem 0;
//         padding-left: 1rem;
//         border-left: 4px solid #007BFF;
//     }

//     cite {
//         display: block;
//         font-size: 1rem;
//         color: #777;
//         margin-top: 0.5rem;
//     }
// `;

// const Rating = styled.div`
//     display: flex;
//     align-items: center;
//     font-size: 1.5rem;
//     color: #FFC107;

//     span {
//         margin-left: 0.5rem;
//         font-size: 1rem;
//         color: #333;
//     }
// `;

// function PublicDoctorProfile() {
//     const { username } = useParams();
//     const navigate = useNavigate();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [testimonials, setTestimonials] = useState([]);
//     const [socialLinks, setSocialLinks] = useState({});

//     useEffect(() => {
//         const fetchDoctorProfile = async () => {
//             try {
//                 const response = await axiosProfile.get(`/profiles/public/${username}`);
//                 if (response.data) {
//                     setDoctor(response.data);

//                     const ratingsResponse = await axiosConsultation.get(`/doctors/${username}/ratings`);
//                     setTestimonials(ratingsResponse.data);

//                     const socialLinksResponse = await axiosProfile.get(`/profiles/social_links/${username}`);
//                     if (socialLinksResponse.data) {
//                         setSocialLinks(socialLinksResponse.data);
//                     }

//                 } else {
//                     navigate('/404');
//                 }
//             } catch (error) {
//                 navigate('/404');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchDoctorProfile();
//     }, [username, navigate]);

//     const calculateAverageRating = () => {
//         const total = testimonials.reduce((acc, testimonial) => acc + testimonial.rating, 0);
//         return (total / testimonials.length).toFixed(1);
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (!doctor) {
//         return <Error404 />;
//     }

//     const handleShare = () => {
//         if (navigator.share) {
//             navigator.share({
//                 title: `${doctor.first_name} ${doctor.last_name} - Doctor Profile`,
//                 url: window.location.href,
//             }).catch(error => console.error('Error sharing:', error));
//         } else {
//             alert('Tu navegador no soporta la funcionalidad de compartir.');
//         }
//     };

//     return (
//         <IconContext.Provider value={{ color: "currentColor", className: "global-class-name" }}>
//             <Helmet>
//                 <title>{`${doctor.first_name} ${doctor.last_name} - Doctor Profile`}</title>
//                 <meta name="description" content={`${doctor.bio}`} />
//                 <meta property="og:title" content={`${doctor.first_name} ${doctor.last_name} - Doctor Profile`} />
//                 <meta property="og:description" content={`${doctor.bio}`} />
//                 <meta property="og:image" content={`http://127.0.0.1:5003/uploads/${doctor.photo}`} />
//                 <meta property="og:url" content={window.location.href} />
//             </Helmet>

//             <PageTitle>Doctor Profile</PageTitle> {/* Título de la página agregado */}

//             <ProfileContainer>
//                 <ProfileHeader>
//                     <DoctorPhoto src={`http://127.0.0.1:5003/uploads/${doctor.photo}`} alt={`Foto del Dr. ${doctor.first_name} ${doctor.last_name}`} />
//                     <DoctorInfo>
//                         <DoctorName>{doctor.first_name} {doctor.last_name}</DoctorName>
//                         <Username>@{doctor.username ? doctor.username : "N/A"}</Username>
//                         <Specialty>{doctor.medical_speciality}</Specialty>
//                         <Bio>{doctor.bio}</Bio>
//                         <Location>{doctor.city}, {doctor.state}</Location>
//                         <Rating>
//                             {[...Array(5)].map((star, index) => (
//                                 <FaStar key={index} color={index < calculateAverageRating() ? "#FFC107" : "#E4E5E9"} />
//                             ))}
//                             <span className="text-purple">{calculateAverageRating()}</span>
//                         </Rating>
//                     </DoctorInfo>
//                 </ProfileHeader>

//                 <SocialLinks>
//                     {socialLinks.x && <a href={socialLinks.x} target="_blank" rel="noopener noreferrer"><FaTwitter aria-label="X" style={{ color: "#1DA1F2" }} /></a>}
//                     {socialLinks.linkedin && <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin aria-label="LinkedIn" style={{ color: "#0077B5" }} /></a>}
//                     {socialLinks.facebook && <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer"><FaFacebook aria-label="Facebook" style={{ color: "#4267B2" }} /></a>}
//                     {socialLinks.instagram && <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer"><FaInstagram aria-label="Instagram" style={{ color: "#C13584" }} /></a>}
//                 </SocialLinks>

//                 <ButtonContainer>
//                     <ShareButton onClick={handleShare}>
//                         <FaShareAlt /> Share Profile
//                     </ShareButton>
//                     <ConsultButton onClick={() => window.location.href = '/register/patient'}>
//                         Start Medical Consultation
//                     </ConsultButton>
//                 </ButtonContainer>
//                 <br />

//                 <hr />

//                 <h2 className="text-purple">Patient Reviews</h2>
               
//                 <Testimonials>
//                     {testimonials.map((testimonial, index) => (
//                         <div key={index}>
//                             <Rating>
//                                 {[...Array(5)].map((star, i) => (
//                                     <FaStar key={i} color={i < testimonial.rating ? "#FFC107" : "#E4E5E9"} />
//                                 ))}
//                                 <span className="text-purple">{testimonial.rating}</span>
//                             </Rating>
//                             <blockquote>"{testimonial.testimonial}"</blockquote>
//                             <cite>- @{testimonial.patient_username}</cite>
//                         </div>
//                     ))}
//                 </Testimonials>
//             </ProfileContainer>
//         </IconContext.Provider>
//     );
// }

// export default PublicDoctorProfile;
