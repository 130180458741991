import React from 'react';
import { Card, Image } from 'react-bootstrap';

const PatientOverview = ({ patient }) => {
  return (
    <Card className="doctor-overview shadow-sm"> {/* Usamos la misma clase que en DoctorOverview */}
      <Card.Body className="text-center d-flex flex-column align-items-center">
        <Image
          src={patient.photo ? `http://localhost:5003/uploads/${patient.photo}` : 'default-image.png'}
          roundedCircle
          className="doctor-photo" 
          alt={`${patient.first_name || 'Patient'} ${patient.last_name || ''}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'default-image.png';
          }}
        />
        <h3 className="doctor-card-title mt-3">
          {patient.first_name || 'N/A'} {patient.last_name || ''}
        </h3>
        <Card.Text className="mt-3">
          <strong>Age:</strong> {patient.age || 'Not available'}
          <br />
          <strong>BMI:</strong> {patient.weight || 'Not available'} kg
          <br />
          <strong>Height:</strong> {patient.height || 'Not available'} cm
          <br />
          <strong>Medical Conditions:</strong> {patient.conditions || 'None'}
          <br />
          <strong>Medications:</strong> {patient.medications || 'None'}
          <br />
          <strong>Allergies:</strong> {patient.allergies || 'None'}
          <br />
          <strong>Last Lab Results:</strong> {patient.last_lab_results || 'Not available'}
          <br />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PatientOverview;

// import React from 'react';
// import { Card } from 'react-bootstrap';

// const PatientOverview = ({ patient }) => {
//   return (
//     <Card className="patient-overview shadow-sm">
//       <Card.Body className="text-center d-flex flex-column align-items-center">
//       <img
//           src={patient.photo ? `http://localhost:5003/uploads/${patient.photo}` : 'default-image.png'}
//           roundedCircle
//           className="doctor-photo rounded-circle"
//           alt={`${patient.first_name || 'Patient'} ${patient.last_name || ''}`}
//           onError={(e) => {
//             e.target.onerror = null;
//             e.target.src = 'default-image.png';
//           }}
//         />
//         {/* <img
//           src={patient.photo ? `http://localhost:5003/uploads/${patient.photo}` : 'default-image.png'}
//           className="doctor-photo"
//           alt={`${patient.first_name || 'Patient'} ${patient.last_name || ''}`}
//           onError={(e) => {
//             e.target.onerror = null;
//             e.target.src = 'default-image.png';
//           }}
//         /> */}
//         <h3 className="patient-card-title mt-3 text-black">
//         <strong>Patient Name:</strong>     {patient.first_name} {patient.last_name}
//         </h3>
//         <Card.Text className="mt-3">
//           {/* <strong>Name:</strong>     {patient.first_name} {patient.last_name} */}
//           <strong>Age:</strong> {patient.age || 'Not available'}
//           <br />
//           <strong>BMI:</strong> {patient.weight || 'Not available'} kg
//           <br />
//           <strong>:</strong> {patient.height || 'Not available'} cm
//           <br />
//           <strong>Medical Conditions:</strong> {patient.conditions || 'None'}
//           <br />
//           <strong>Medications:</strong> {patient.medications || 'None'}
//           <br />
//           <strong>Allergies:</strong> {patient.allergies || 'None'}
//           <br />
//           <strong></strong> {patient.last_lab_results || 'Not available'}
//           <br />
//         </Card.Text>
//       </Card.Body>
//     </Card>
//   );
// };

// export default PatientOverview;

// import React from 'react';
// import { Card, Image } from 'react-bootstrap';

// const PatientOverview = ({ patient }) => {
//   return (
//     <Card className="patient-overview shadow-sm">
//       <Card.Body className="text-center d-flex flex-column align-items-center">
//         <Image
//           src={patient.photo ? `http://localhost:5003/uploads/${patient.photo}` : 'default-image.png'}
//           roundedCircle
//           className="patient-photo"
//           alt={`${patient.first_name || 'Patient'} ${patient.last_name || ''}`}
//           onError={(e) => {
//             e.target.onerror = null;
//             e.target.src = 'default-image.png';
//           }}
//         />
//         <h3 className="patient-card-title mt-3 text-dark">
//           Name: {patient.first_name || 'N/A'} {patient.last_name || ''}
//         </h3>
//         <Card.Text className="mt-3">
//           <strong>Bio:</strong> {patient.bio || 'No bio available'}
//           <br />
//         </Card.Text>
//       </Card.Body>
//     </Card>
//   );
// };

// export default PatientOverview;

// import React from 'react';
// import { Card, Image } from 'react-bootstrap';
// import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

// const PatientOverview = ({ patient }) => {
//   return (
//     <Card className="patient-overview shadow-sm">
//       <Card.Body className="text-center d-flex flex-column align-items-center">
//         <Image
//           src={patient.photo ? `http://localhost:5003/uploads/${patient.photo}` : 'default-image.png'}
//           roundedCircle
//           className="patient-photo"
//           alt={`${patient.first_name || 'Patient'} ${patient.last_name || ''}`}
//           onError={(e) => {
//             e.target.onerror = null;
//             e.target.src = 'default-image.png';
//           }}
//         />
//         <h3 className="patient-card-title mt-3">
//           {patient.first_name || 'N/A'} {patient.last_name || ''}
//         </h3>
//         <Card.Text className="mt-3">
//           <strong>Email:</strong> {patient.email || 'Not available'}
//           <br />
//           <strong>Phone:</strong> {patient.phone || 'Not available'}
//           <br />
//           <strong>Bio:</strong> {patient.bio || 'No bio available'}
//           <br />
//         </Card.Text>
//       </Card.Body>
//     </Card>
//   );
// };

// export default PatientOverview;
