import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import meta from '../data/meta';

const SEO = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const metaData = meta[path];

  if (!metaData) return null;

  const fullTitle = `${t(metaData.title)} - DoctorElec`;
  const fullUrl = `https://www.doctorelec.com${path}`;
  const defaultImage = `${window.location.origin}/images/default-image.webp`;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{fullTitle}</title>
      <meta name="description" content={t(metaData.description)} />
      <meta name="keywords" content={metaData.keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link rel="icon" href={`${window.location.origin}/favicon.ico`} />
      <link rel="canonical" href={fullUrl} />

      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={t(metaData.description)} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:image" content={metaData.image ? metaData.image : defaultImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={t(metaData.description)} />
      <meta name="twitter:image" content={metaData.image ? metaData.image : defaultImage} />

      <link rel="apple-touch-icon" href={`${window.location.origin}/logo192.png`} />
      <link rel="manifest" href={`${window.location.origin}/manifest.json`} />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": fullUrl,
          "name": fullTitle,
          "description": t(metaData.description),
          "image": metaData.image ? `https://www.doctorelec.com${metaData.image}` : defaultImage,
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
