import services from './services';
import resources from './resources';

const meta = {
  "/": {
    title: "Welcome to DoctorElec",
    description: "DoctorElec offers advanced telemedicine solutions tailored for your healthcare needs, providing access to top doctors online.",
    image: "/images/home-image.webp",
    keywords: "telemedicine, telehealth, healthcare, online doctor consultations, virtual healthcare, medical services online, health tech, AI, digital health, doctor marketplace",
  },
  "/features": {
    title: "Features",
    description: "Discover the unique features that DoctorElec provides to enhance your telehealth experience, including online consultations and personalized healthcare.",
    image: "/images/features-image.webp",
    keywords: "features, telemedicine, healthcare technology, virtual doctor visits, digital health features, online health services, AI-driven healthcare, health tech, telehealth platform",
  },
  "/pricing": {
    title: "Pricing - DoctorElec",
    description: "Find the best pricing options for DoctorElec's telehealth services and choose the plan that suits your healthcare needs.",
    image: "/images/pricing-image.webp",
    keywords: "pricing, telemedicine, healthcare costs, online medical services, telehealth pricing, affordable healthcare, doctor marketplace, DoctorElec plans",
  },
  "/faq": {
    title: "Commonly asked questions",
    description: "Get answers to frequently asked questions about DoctorElec's telemedicine services and learn how to get started.",
    image: "/images/pricing-image.webp",
    keywords: "faq, commonly asked questions, telemedicine, healthcare, online doctor consultations, telehealth platform, virtual healthcare, DoctorElec",
  },
  "/terms": {
    title: "Terms and conditions",
    description: "At DoctorElec, we believe in transparency. Review our terms and conditions to understand our telehealth services.",
    image: "/images/pricing-image.webp",
    keywords: "terms, conditions, user agreement, healthcare, telemedicine, service agreement, telehealth platform, DoctorElec terms",
  },
  "/login": {
    title: "Login to your account",
    description: "Unlock the benefits of affordable telemedicine services by logging into your DoctorElec account.",
    image: "/images/pricing-image.webp",
    keywords: "login, account access, telemedicine, online healthcare services, DoctorElec, telehealth platform, virtual healthcare login",
  },
  "/register": {
    title: "Get started and create a new account",
    description: "Join DoctorElec, the leading telehealth platform. Register as a healthcare provider or patient to access top-notch telemedicine services.",
    image: "/images/pricing-image.webp",
    keywords: "registration, create account, telemedicine, healthcare provider registration, patient registration, telehealth platform, DoctorElec",
  },
  "/register/patient": {
    title: "Create an account as a patient",
    description: "Get started by creating a new account in DoctorElec and access the best online medical consultations.",
    image: "/images/pricing-image.webp",
    keywords: "patient registration, create account, telemedicine, online doctor consultation, healthcare services, virtual healthcare, DoctorElec",
  },
  "/register/doctor": {
    title: "Create an account as a Doctor",
    description: "Get started by creating a new account in DoctorElec and offer your medical services online.",
    image: "/images/pricing-image.webp",
    keywords: "doctor registration, create account, healthcare provider, telemedicine, online medical services, digital health platform, DoctorElec",
  },
  "/forgot-password": {
    title: "Forgot Password",
    description: "Reset your DoctorElec account password easily.",
    image: "/images/pricing-image.webp",
    keywords: "forgot password, reset password, account recovery, telemedicine, online healthcare services, DoctorElec support",
  },
  "/support": {
    title: "Customer Service and Technical Support",
    description: "If you have any issues with DoctorElec, please submit a ticket for technical support or customer service. Our team will contact you shortly.",
    image: "/images/pricing-image.webp",
    keywords: "customer service, technical support, telemedicine support, healthcare assistance, DoctorElec support, online healthcare help",
  },
};


services.forEach(service => {
  meta[service.path] = {
    title: `${service.title}`,
    description: service.description,
    image: service.image,
    keywords: service.keywords,
  };
});

services.concat(resources).forEach(item => {
  meta[item.path] = {
    title: `${item.title}`,
    description: item.description,
    image: item.image,
    keywords: item.keywords,
  };
});


export default meta;
