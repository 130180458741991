import React, { useState } from 'react';
//import axios from 'axios';
import './ForgotPassword.css';
import axiosInstance from '../utils/axiosInstance';
import SEO from './SEO';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/request-reset-email/', { email });
            setMessage(response.data.success);
        } catch (error) {
            if (error.response) {
                // Aquí puedes ver los errores de validación del servidor
                setMessage(error.response.data.email || 'Error sending password reset link');
            } else {
                setMessage('Error sending password reset link');
            }
        }
    };

    return (
        <div className="forgot-password">
            <SEO />
            <h2>Forgot Password</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit">Send Reset Link</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default ForgotPassword;
